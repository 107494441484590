import axiosInstance from '../utils/axiosConfig';
import { AxiosError } from 'axios';

export const imageService = {
  getImageGenerationCount: async () => {
    try {
      const response = await axiosInstance.get('/images/count/');
      return response.data.count;
    } catch (error) {
      console.error('Error fetching image generation count:', error);
      return 0;
    }
  },

  generateImage: async (contentId: string) => {
    try {
      const response = await axiosInstance.post('/generate-image/', { content_id: contentId });
      return response.data;
    } catch (error) {
      console.error('Error generating image:', error);
      throw error;
    }
  },

  getImageGenerationHistory: async (contentId: string) => {
    try {
      const response = await axiosInstance.get(`/image-generation-history/${contentId}/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching image generation history:', error);
      throw error;
    }
  },

  getAllImages: async () => {
    console.log('Fetching all images...');
    try {
      console.log('Making request to /images/');
      const response = await axiosInstance.get('/images/', {
        // Add timeout and error handling options
        timeout: 10000,
        validateStatus: (status) => {
          console.log('Response status:', status);
          return status >= 200 && status < 300;
        }
      });
      console.log('Response received:', response.data);
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error('Error fetching all images:', {
        message: axiosError.message,
        status: axiosError.response?.status,
        statusText: axiosError.response?.statusText,
        data: axiosError.response?.data,
        config: {
          url: axiosError.config?.url,
          method: axiosError.config?.method,
          headers: axiosError.config?.headers
        }
      });

      // Check if it's a timeout error
      if (axiosError.code === 'ECONNABORTED') {
        console.error('Request timed out');
      }

      // Check if it's a network error
      if (!axiosError.response) {
        console.error('Network error - no response received');
      }

      // Log the full error object for debugging
      console.error('Full error object:', JSON.stringify(axiosError, null, 2));

      throw axiosError;
    }
  }
};

export default imageService;
