import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import imageService from '../services/imageService';
import ContentHistoryList from '../components/ContentHistoryList';
import { Content, ImageHistory, Campaign } from '../types/content';

const LoadingSpinner: React.FC = () => (
  <div className="flex items-center justify-center p-8">
    <div className="text-center">
      <div className="animate-spin rounded-full h-16 w-16 border-4 border-blue-200 border-t-transparent mx-auto"></div>
      <p className="mt-6 text-xl font-medium text-gray-600">Loading image management...</p>
    </div>
  </div>
);

const ErrorDisplay: React.FC<{ error: string }> = ({ error }) => (
  <div className="flex items-center justify-center p-8">
    <div className="text-center bg-white rounded-2xl shadow-xl p-8 max-w-md animate-fade-in">
      <div className="text-red-500 text-5xl mb-4">⚠️</div>
      <h2 className="text-2xl font-bold text-gray-900 mb-4">Oops! Something went wrong</h2>
      <p className="text-gray-600 mb-6">{error}</p>
      <button
        onClick={() => window.location.reload()}
        className="bg-gradient-to-r from-blue-600 to-indigo-600 text-white px-8 py-3 rounded-lg font-semibold
                 hover:from-blue-700 hover:to-indigo-700 transition-all duration-200 hover:scale-105"
      >
        Try Again
      </button>
    </div>
  </div>
);

interface ImageHistoryWithContent extends Omit<ImageHistory, 'content'> {
  content?: {
    id: number;
    content_id: string;
    topic?: string;
    content_type?: string;
    platform?: string;
    campaign?: {
      id: number;
      name: string;
      campaign_type: string;
      primary_purpose: string;
      status: string;
    } | null;
  };
  source?: string;
}

const ImagePreview: React.FC<{ url: string; onClose: () => void }> = ({ url, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div 
      className="fixed inset-0 bg-black/80 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div 
        className="relative max-w-[90%] max-h-[90%] bg-white p-4 rounded-lg"
        onClick={e => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute -top-10 right-0 text-white hover:text-gray-300"
        >
          Close
        </button>
        <img
          src={url}
          alt="Full size preview"
          className="max-w-full max-h-[80vh] object-contain"
        />
      </div>
    </div>
  );
};

const ImageManagement: React.FC = () => {
  const [images, setImages] = useState<ImageHistoryWithContent[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);
  const [filter, setFilter] = useState('all');
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [showDebug, setShowDebug] = useState(false);
  const { user } = useAuth();

  const getImageUrl = (url: string | undefined | null): string | undefined => {
    if (!url) return undefined;
    if (url.startsWith('http') && !url.includes('oaidalleapiprodscus')) {
      // If it's a localhost URL, replace it with the media URL
      if (url.includes('localhost:8020/media')) {
        const mediaUrl = process.env.REACT_APP_MEDIA_URL || 'http://50.184.85.141/media';
        return url.replace('http://localhost:8020/media', mediaUrl);
      }
      return url;
    }
    if (url.startsWith('data:')) return url;
    
    // Use REACT_APP_MEDIA_URL for media files
    if (url.startsWith('/media/') || url.includes('/media/')) {
      const mediaUrl = process.env.REACT_APP_MEDIA_URL || 'http://50.184.85.141/media';
      const cleanPath = url.replace(/^\/media\//, '').replace(/.*\/media\//, '');
      return `${mediaUrl}/${cleanPath}`;
    }
    
    // Use API URL for other URLs
    const baseUrl = process.env.REACT_APP_API_URL || 'http://50.184.85.141';
    return `${baseUrl}${url.startsWith('/') ? '' : '/'}${url}`;
  };

  const fetchImages = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      console.log('Fetching images...');
      const data = await imageService.getAllImages();
      console.log('Received images:', data);
      setImages(data);
    } catch (error) {
      console.error('Error fetching images:', error);
      setError('Failed to fetch images. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  const handleFilterChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter(event.target.value);
    setCurrentPage(1);
  }, []);

  const filteredImages = images.filter((image) => {
    if (filter === 'all') return true;
    if (filter === 'simplified') return image.source === 'simplified';
    if (filter === 'content') return image.source === 'content';
    return true;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredImages.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredImages.length / itemsPerPage);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  if (isLoading) return <LoadingSpinner />;
  if (error) return <ErrorDisplay error={error} />;

  return (
    <div className="w-full pb-12">
      <div className="text-center mb-12 animate-fade-in">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Image Management</h1>
        <p className="text-xl text-gray-600">View and manage all generated images</p>
      </div>

      <div className="bg-white rounded-2xl shadow-xl p-8 mb-8 animate-fade-in">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6">
          <div className="flex items-center gap-4">
            <button 
              onClick={fetchImages}
              className="px-6 py-3 bg-gradient-to-r from-blue-600 to-indigo-600 text-white rounded-lg font-semibold
                       hover:from-blue-700 hover:to-indigo-700 transition-all duration-200 hover:scale-105 mb-4 md:mb-0"
            >
              Refresh Images
            </button>
            <button
              onClick={() => setShowDebug(!showDebug)}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
            >
              {showDebug ? 'Hide Debug' : 'Show Debug'}
            </button>
          </div>

          <div className="w-full md:w-64">
            <label htmlFor="filter" className="block text-sm font-medium text-gray-700 mb-2">Filter Images</label>
            <select
              id="filter"
              value={filter}
              onChange={handleFilterChange}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 
                       focus:border-blue-500 transition-all duration-200"
            >
              <option value="all">All Images</option>
              <option value="simplified">Simplified Content</option>
              <option value="content">Regular Content</option>
            </select>
          </div>
        </div>

        {currentItems.length === 0 ? (
          <div className="text-center py-12 bg-gray-50 rounded-lg">
            <p className="text-gray-600 mb-4">No images found.</p>
            <p className="text-sm text-gray-500">Generate content with images to see them here.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {currentItems.map((image) => {
              // Try local_image_url first, then generated_image_url
              const rawImageUrl = image.local_image_url || image.generated_image_url;
              const imageUrl = getImageUrl(rawImageUrl);
              console.log('Image data:', {
                id: image.id,
                local_url: image.local_image_url,
                generated_url: image.generated_image_url,
                using_url: imageUrl
              });
              
              return (
                <div key={image.id} className="bg-gray-50 rounded-lg p-4 flex flex-col">
                  <div className="relative w-full mb-4">
                    <div className="h-[200px] bg-gray-100 rounded-lg flex items-center justify-center">
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="Generated content"
                          className="max-w-full max-h-full object-contain cursor-pointer hover:opacity-90 transition-opacity rounded-lg"
                          onClick={() => setSelectedImage(imageUrl)}
                          onError={(e) => {
                            console.error('Image load error:', e);
                            const target = e.target as HTMLImageElement;
                            target.onerror = null;
                            target.style.display = 'none';
                          }}
                        />
                      ) : (
                        <div className="text-gray-400">No image available</div>
                      )}
                    </div>
                  </div>
                  <div className="text-sm space-y-2">
                    <p className="text-gray-500">Created: {formatDateTime(image.created_at)}</p>
                    {image.content && (
                      <>
                        {image.content.campaign ? (
                          <p className="text-gray-700 font-medium">
                            Campaign: {image.content.campaign.name}
                          </p>
                        ) : image.content.content_type === 'quick' ? (
                          <p className="text-gray-700 font-medium">
                            Type: Quick Content
                          </p>
                        ) : null}
                        {image.content.topic && (
                          <p className="text-gray-600">
                            Topic: {image.content.topic}
                          </p>
                        )}
                        {image.content.platform && (
                          <p className="text-gray-600">
                            Platform: {image.content.platform.replace(/_/g, ' ')}
                          </p>
                        )}
                      </>
                    )}
                    
                    {showDebug && (
                      <div className="mt-4 p-2 bg-gray-100 rounded text-xs font-mono">
                        <p>ID: {image.id}</p>
                        <p>Source: {image.source}</p>
                        <p>Content Type: {image.content?.content_type}</p>
                        <p>Content ID: {image.content?.content_id}</p>
                        <p>Campaign: {image.content?.campaign?.name || 'None'}</p>
                        <p>Local URL: {image.local_image_url || 'None'}</p>
                        <p>Generated URL: {image.generated_image_url || 'None'}</p>
                        <p>Final URL: {imageUrl || 'None'}</p>
                      </div>
                    )}
                  </div>
                  {image.download_url && (
                    <div className="mt-4">
                      <a
                        href={getImageUrl(image.download_url)}
                        className="inline-block px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 text-sm"
                        download
                      >
                        Download
                      </a>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}

        {totalPages > 1 && (
          <div className="mt-8 flex justify-center space-x-2">
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i}
                onClick={() => paginate(i + 1)}
                className={`px-4 py-2 rounded-lg font-medium transition-all duration-200 
                          ${currentPage === i + 1 
                            ? 'bg-gradient-to-r from-blue-600 to-indigo-600 text-white' 
                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
              >
                {i + 1}
              </button>
            ))}
          </div>
        )}
      </div>

      {selectedImage && (
        <ImagePreview
          url={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </div>
  );
};

export default ImageManagement;
