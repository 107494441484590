import React, { useState, useEffect } from 'react';
import { ContentRequirements, Service, ProductImageSelection, Platform } from '../types';
import { Product } from '../../../types/content';
import { TopicGenerator } from './TopicGenerator';
import { ProductServiceSelector } from './ProductServiceSelector';
import { AUDIENCE_OPTIONS } from '../constants';

interface ContentFormProps {
  requirements: ContentRequirements;
  onChange: (requirements: ContentRequirements) => void;
  businessProfile: any;
  onProductImageSelect?: (selection: ProductImageSelection) => void;
  selectedPlatforms: Platform[];
}

export const ContentForm: React.FC<ContentFormProps> = ({
  requirements,
  onChange,
  businessProfile,
  onProductImageSelect,
  selectedPlatforms
}) => {
  const requiredFields = ['objective', 'targetAudience', 'tone', 'topic'];
  const [customObjective, setCustomObjective] = useState('');
  const [customAudience, setCustomAudience] = useState('');
  const [showCustomObjective, setShowCustomObjective] = useState(false);
  const [showCustomAudience, setShowCustomAudience] = useState(false);

  const isVideoPlatform = (platform: string) => {
    return ['youtube_video', 'youtube_short', 'tiktok_video'].includes(platform);
  };

  const hasVideoContent = selectedPlatforms.some(p => isVideoPlatform(p.name));

  useEffect(() => {
    if (!requirements.selectedProducts || !requirements.selectedServices) {
      onChange({
        ...requirements,
        selectedProducts: requirements.selectedProducts || [],
        selectedServices: requirements.selectedServices || []
      });
    }
  }, []);

  useEffect(() => {
    const hasObjectiveInOptions = objectiveOptions.some(opt => opt.value === requirements.objective);
    const hasAudienceInOptions = AUDIENCE_OPTIONS.some(opt => opt.value === requirements.targetAudience);

    if (hasObjectiveInOptions) {
      setShowCustomObjective(false);
      setCustomObjective('');
    }

    if (hasAudienceInOptions) {
      setShowCustomAudience(false);
      setCustomAudience('');
    }
  }, [requirements.objective, requirements.targetAudience]);

  const handleChange = (field: keyof ContentRequirements, value: any) => {
    onChange({
      ...requirements,
      [field]: value
    });
  };

  const handleTopicSelect = (topic: string) => {
    handleChange('topic', topic);
  };

  const handleProductsChange = (products: Product[]) => {
    handleChange('selectedProducts', products);
  };

  const handleServicesChange = (services: Service[]) => {
    handleChange('selectedServices', services);
  };

  const toneOptions = [
    { value: 'professional', label: 'Professional' },
    { value: 'casual', label: 'Casual' },
    { value: 'friendly', label: 'Friendly' },
    { value: 'formal', label: 'Formal' },
    { value: 'humorous', label: 'Humorous' },
    { value: 'informative', label: 'Informative' }
  ];

  const objectiveOptions = [
    { value: '', label: 'Change Objective' },
    { value: 'Product Launch', label: 'Product Launch' },
    { value: 'Brand Awareness', label: 'Brand Awareness' },
    { value: 'Lead Generation', label: 'Lead Generation' },
    { value: 'Sales Promotion', label: 'Sales Promotion' },
    { value: 'Event Promotion', label: 'Event Promotion' },
    { value: 'Customer Retention', label: 'Customer Retention' },
    { value: 'custom', label: 'Change to Custom Option...' }
  ];

  const handleObjectiveChange = (value: string) => {
    if (value === 'custom') {
      setShowCustomObjective(true);
      setCustomObjective('');
    } else if (value === '') {
      setShowCustomObjective(false);
      setCustomObjective('');
      handleChange('objective', '');
    } else {
      setShowCustomObjective(false);
      setCustomObjective('');
      handleChange('objective', value);
    }
  };

  const handleAudienceChange = (value: string) => {
    if (value === 'custom') {
      setShowCustomAudience(true);
      setCustomAudience('');
    } else if (value === '') {
      setShowCustomAudience(false);
      setCustomAudience('');
      handleChange('targetAudience', '');
    } else {
      setShowCustomAudience(false);
      setCustomAudience('');
      handleChange('targetAudience', value);
    }
  };

  const handleCustomObjectiveChange = (value: string) => {
    setCustomObjective(value);
    handleChange('objective', value);
  };

  const handleCustomAudienceChange = (value: string) => {
    setCustomAudience(value);
    handleChange('targetAudience', value);
  };

  const prepareTopicInputs = () => {
    const selectedProducts = requirements.selectedProducts || [];
    const selectedServices = requirements.selectedServices || [];

    let keywords = requirements.objective || '';
    if (selectedProducts.length > 0) {
      keywords = `${selectedProducts.map(p => p.name).join(', ')} - ${keywords}`;
    }
    if (selectedServices.length > 0) {
      keywords = `${keywords} (featuring ${selectedServices.map(s => s.name).join(', ')})`;
    }

    return {
      targetAudience: requirements.targetAudience,
      keywords,
      creativityLevel: 0.7,
      selectedProducts: selectedProducts.map(p => ({
        name: p.name,
        description: p.description || '',
        isPrimary: true
      })),
      selectedServices: selectedServices.map(s => ({
        name: s.name,
        description: s.description || '',
        isPrimary: false
      }))
    };
  };

  const calculateProgress = () => {
    const completedFields = requiredFields.filter(field => 
      !!requirements[field as keyof ContentRequirements]
    ).length;
    return (completedFields / requiredFields.length) * 100;
  };

  return (
    <div className="w-full p-6 space-y-8">
      {/* Progress Bar */}
      <div className="mb-8">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-xl font-bold text-gray-900">Content Requirements</h2>
          <span className="text-sm font-medium text-gray-600">
            {Math.round(calculateProgress())}% Complete
          </span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div
            className="bg-blue-600 h-2 rounded-full transition-all duration-300"
            style={{ width: `${calculateProgress()}%` }}
          ></div>
        </div>
      </div>

      {/* Step 1: Campaign Objective */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
        <div className="flex items-center mb-4">
          <div className="bg-blue-600 text-white w-8 h-8 rounded-full flex items-center justify-center font-bold mr-3">
            1
          </div>
          <h3 className="text-lg font-semibold text-gray-900">Campaign Objective</h3>
        </div>
        
        {requirements.objective && (
          <div className="mb-4 p-3 bg-green-50 border border-green-200 rounded-lg">
            <div className="text-sm font-medium text-green-800 flex items-center">
              <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
              </svg>
              {requirements.objective}
            </div>
          </div>
        )}

        <div className="space-y-3">
          <select
            value={showCustomObjective ? 'custom' : ''}
            onChange={(e) => handleObjectiveChange(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
          >
            {objectiveOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          
          {showCustomObjective && (
            <div className="mt-3">
              <input
                type="text"
                value={customObjective}
                onChange={(e) => handleCustomObjectiveChange(e.target.value)}
                placeholder="Enter custom objective"
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          )}
        </div>
      </div>

      {/* Step 2: Target Audience */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
        <div className="flex items-center mb-4">
          <div className="bg-blue-600 text-white w-8 h-8 rounded-full flex items-center justify-center font-bold mr-3">
            2
          </div>
          <h3 className="text-lg font-semibold text-gray-900">Target Audience</h3>
        </div>

        {requirements.targetAudience && (
          <div className="mb-4 p-3 bg-green-50 border border-green-200 rounded-lg">
            <div className="text-sm font-medium text-green-800 flex items-center">
              <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
              </svg>
              {requirements.targetAudience}
            </div>
          </div>
        )}

        <div className="space-y-3">
          <select
            value={showCustomAudience ? 'custom' : ''}
            onChange={(e) => handleAudienceChange(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
          >
            {AUDIENCE_OPTIONS.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          
          {showCustomAudience && (
            <div className="mt-3">
              <input
                type="text"
                value={customAudience}
                onChange={(e) => handleCustomAudienceChange(e.target.value)}
                placeholder="Enter custom target audience"
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          )}
        </div>
      </div>

      {/* Step 3: Content Tone */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
        <div className="flex items-center mb-4">
          <div className="bg-blue-600 text-white w-8 h-8 rounded-full flex items-center justify-center font-bold mr-3">
            3
          </div>
          <h3 className="text-lg font-semibold text-gray-900">Content Tone</h3>
        </div>

        {requirements.tone && (
          <div className="mb-4 p-3 bg-green-50 border border-green-200 rounded-lg">
            <div className="text-sm font-medium text-green-800 flex items-center">
              <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
              </svg>
              {requirements.tone}
            </div>
          </div>
        )}

        <select
          value={requirements.tone || ''}
          onChange={(e) => handleChange('tone', e.target.value)}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
        >
          <option value="">Select tone</option>
          {toneOptions.map(option => (
            <option 
              key={option.value} 
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {/* Step 4: Products & Services */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
        <div className="flex items-center mb-4">
          <div className="bg-blue-600 text-white w-8 h-8 rounded-full flex items-center justify-center font-bold mr-3">
            4
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-900">Products & Services</h3>
            <p className="text-sm text-gray-600 mt-1">
              Selected products will be the primary focus of the generated content
            </p>
          </div>
        </div>

        <ProductServiceSelector
          selectedProducts={requirements.selectedProducts || []}
          selectedServices={requirements.selectedServices || []}
          onProductsChange={handleProductsChange}
          onServicesChange={handleServicesChange}
          onProductImageSelect={onProductImageSelect}
        />
      </div>

      {/* Step 5: Topic Selection */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
        <div className="flex items-center mb-4">
          <div className="bg-blue-600 text-white w-8 h-8 rounded-full flex items-center justify-center font-bold mr-3">
            5
          </div>
          <h3 className="text-lg font-semibold text-gray-900">Topic Selection</h3>
        </div>

        {requirements.topic && (
          <div className="mb-4 p-3 bg-green-50 border border-green-200 rounded-lg">
            <div className="text-sm font-medium text-green-800 flex items-center">
              <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
              </svg>
              Selected Topic: {requirements.topic}
            </div>
          </div>
        )}

        <TopicGenerator
          businessProfile={businessProfile}
          contentType="social_media"
          onTopicSelect={handleTopicSelect}
          inputs={prepareTopicInputs()}
        />
      </div>

      {/* Video Content Notice */}
      {hasVideoContent && (
        <div className="bg-blue-50 rounded-xl p-6 border border-blue-100">
          <div className="flex items-center mb-4">
            <svg className="w-6 h-6 text-blue-600 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
            <h3 className="text-lg font-semibold text-blue-900">Video Content Generation</h3>
          </div>
          <p className="text-blue-700 mb-4">
            When you click "Generate Content", the system will automatically create:
          </p>
          <ul className="space-y-2 text-blue-600">
            <li className="flex items-center">
              <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
              </svg>
              Complete video scripts with timestamps
            </li>
            <li className="flex items-center">
              <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
              </svg>
              Platform-optimized descriptions with hashtags
            </li>
            <li className="flex items-center">
              <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
              </svg>
              SEO-optimized metadata
            </li>
          </ul>
        </div>
      )}

      {/* Form Status */}
      <div className="bg-gray-50 rounded-xl p-6">
        <h3 className="text-sm font-semibold text-gray-900 mb-4">Required Fields Status</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {requiredFields.map(key => {
            const value = requirements[key as keyof ContentRequirements];
            const isComplete = !!value;
            
            return (
              <div 
                key={key}
                className={`flex items-center p-3 rounded-lg ${
                  isComplete ? 'bg-green-50 text-green-800' : 'bg-gray-100 text-gray-600'
                }`}
              >
                <div className={`w-6 h-6 rounded-full flex items-center justify-center mr-3 ${
                  isComplete ? 'bg-green-500 text-white' : 'bg-gray-300'
                }`}>
                  {isComplete ? '✓' : '!'}
                </div>
                <span className="font-medium">
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
