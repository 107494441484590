export const TONE_OPTIONS = [
  { value: 'professional', label: 'Professional' },
  { value: 'casual', label: 'Casual' },
  { value: 'friendly', label: 'Friendly' },
  { value: 'formal', label: 'Formal' },
  { value: 'humorous', label: 'Humorous' },
  { value: 'informative', label: 'Informative' }
];

export const OBJECTIVE_OPTIONS = [
  { value: '', label: 'Change Objective' },
  { value: 'Product Launch', label: 'Product Launch' },
  { value: 'Brand Awareness', label: 'Brand Awareness' },
  { value: 'Lead Generation', label: 'Lead Generation' },
  { value: 'Sales Promotion', label: 'Sales Promotion' },
  { value: 'Event Promotion', label: 'Event Promotion' },
  { value: 'Customer Retention', label: 'Customer Retention' },
  { value: 'custom', label: 'Change to Custom Option...' }
];

export const AUDIENCE_OPTIONS = [
  { value: '', label: 'Change Target Audience' },
  // Customer Status
  { value: 'New customers', label: '👥 New customers' },
  { value: 'Existing customers', label: '👥 Existing customers' },
  { value: 'Lapsed customers', label: '👥 Lapsed customers' },
  
  // Gender
  { value: 'Men', label: '⚧ Men' },
  { value: 'Women', label: '⚧ Women' },
  { value: 'Non-binary individuals', label: '⚧ Non-binary individuals' },
  
  // Age Groups
  { value: 'Teenagers (13–17 years old)', label: '📅 Teenagers (13–17 years old)' },
  { value: 'Young Adults (18–24 years old)', label: '📅 Young Adults (18–24 years old)' },
  { value: 'Adults (25–40 years old)', label: '📅 Adults (25–40 years old)' },
  { value: 'Middle-Aged Adults (41–60 years old)', label: '📅 Middle-Aged Adults (41–60 years old)' },
  { value: 'Seniors (60+ years old)', label: '📅 Seniors (60+ years old)' },
  
  // Event Related
  { value: 'Event attendees', label: '🎫 Event attendees' },
  { value: 'Event organizers or volunteers', label: '🎫 Event organizers or volunteers' },
  { value: 'Potential attendees', label: '🎫 Potential attendees' },
  
  // Shopping Behavior
  { value: 'Frequent shoppers', label: '🛍️ Frequent shoppers' },
  { value: 'Bargain hunters or deal seekers', label: '🛍️ Bargain hunters or deal seekers' },
  { value: 'Brand loyalists', label: '🛍️ Brand loyalists' },
  { value: 'Online-only shoppers', label: '🛍️ Online-only shoppers' },
  { value: 'In-store shoppers', label: '🛍️ In-store shoppers' },
  
  // Business Decision Makers
  { value: 'Small business owners', label: '💼 Small business owners' },
  { value: 'Startup founders', label: '💼 Startup founders' },
  { value: 'Corporate decision-makers (e.g., CEOs, CMOs)', label: '💼 Corporate decision-makers' },
  { value: 'Industry professionals', label: '💼 Industry professionals' },
  
  // Life Stage
  { value: 'Students', label: '🎓 Students' },
  { value: 'Recent graduates', label: '🎓 Recent graduates' },
  { value: 'Newlyweds or couples', label: '💑 Newlyweds or couples' },
  { value: 'Parents (e.g., parents of toddlers, school-age children, or teens)', label: '👨‍👩‍👧‍👦 Parents' },
  { value: 'Retirees', label: '🌅 Retirees' },
  
  // Location Based
  { value: 'Local community members', label: '📍 Local community members' },
  { value: 'Regional, national, or international customers', label: '🌎 Regional/national/international customers' },
  { value: 'Urban dwellers', label: '🏙️ Urban dwellers' },
  { value: 'Suburban or rural residents', label: '🏘️ Suburban or rural residents' },
  
  // Interest Based
  { value: 'Eco-conscious consumers', label: '🌱 Eco-conscious consumers' },
  { value: 'Social justice advocates', label: '✊ Social justice advocates' },
  { value: 'Wellness and mindfulness seekers', label: '🧘 Wellness and mindfulness seekers' },
  
  { value: 'custom', label: 'Change to Custom Option...' }
];

export const REQUIRED_FIELDS = ['objective', 'targetAudience', 'tone', 'topic'];

export const INITIAL_REQUIREMENTS = {
  objective: '',
  tone: '',
  targetAudience: '',
  topic: '',
  selectedProducts: [],
  selectedServices: [],
  generateImages: true
};

export const ERROR_MESSAGES = {
  OBJECTIVE_REQUIRED: 'Objective is required',
  AUDIENCE_REQUIRED: 'Target audience is required',
  TONE_REQUIRED: 'Content tone is required',
  TOPIC_REQUIRED: 'Topic is required',
  PLATFORM_REQUIRED: 'Please select at least one platform',
  BUSINESS_PROFILE_REQUIRED: 'Business profile is required for content generation',
  CAMPAIGN_DETAILS_REQUIRED: 'Campaign details are required when creating campaign content',
  CONTENT_GENERATION_FAILED: 'Failed to generate content. Please try again.',
  AUTHENTICATION_ERROR: 'Authentication error. Please refresh the page and try again.',
  NETWORK_ERROR: 'Network error. Please check your internet connection and try again.',
  UNEXPECTED_ERROR: 'An unexpected error occurred. Please try again.',
  PLATFORM_CONTENT_GENERATION_FAILED: 'Failed to generate content for some platforms. Please try again.'
};
