import { useState, useEffect } from 'react';
import { Feature, Platform, Testimonial } from '../../../services/landing/types';

interface UseLandingPageReturn {
  features: Feature[];
  platforms: Platform[];
  testimonials: Testimonial[];
  loading: boolean;
  error: string | null;
}

/**
 * useLandingPage Hook
 * 
 * Manages data fetching and state for the landing page.
 * Provides features, platforms, and testimonials data.
 */
export const useLandingPage = (): UseLandingPageReturn => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [features, setFeatures] = useState<Feature[]>([]);
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        // TODO: Replace with actual API calls
        // For now, using mock data
        const mockFeatures: Feature[] = [
          {
            id: 'ai-content',
            title: "AI-Powered Content Creation",
            description: "Create engaging content in seconds with our advanced AI technology",
            icon: "✨",
            benefits: [
              'Save hours of work',
              'Consistent quality',
              'Platform-optimized content'
            ]
          },
          {
            id: 'multi-platform',
            title: "Multi-Platform Support",
            description: "Optimize your content for every social media platform automatically",
            icon: "🌐",
            benefits: [
              'One-click publishing',
              'Platform-specific formatting',
              'Scheduled posting'
            ]
          },
          {
            id: 'analytics',
            title: "Smart Analytics",
            description: "Track and analyze your content performance across all platforms",
            icon: "📊",
            benefits: [
              'Real-time insights',
              'Performance tracking',
              'AI-powered recommendations'
            ]
          }
        ];

        const mockPlatforms: Platform[] = [
          {
            id: 'instagram',
            name: "Instagram",
            icon: "📸",
            description: "Visual content and stories",
            contentTypes: [
              {
                id: 'post',
                name: 'Post',
                format: 'image',
                supportedPlatforms: ['instagram']
              }
            ],
            features: [
              'Image optimization',
              'Hashtag suggestions',
              'Story creation'
            ]
          },
          {
            id: 'twitter',
            name: "Twitter",
            icon: "🐦",
            description: "Short-form text content",
            contentTypes: [
              {
                id: 'tweet',
                name: 'Tweet',
                format: 'text',
                supportedPlatforms: ['twitter']
              }
            ],
            features: [
              'Thread creation',
              'Trend analysis',
              'Engagement optimization'
            ]
          },
          {
            id: 'linkedin',
            name: "LinkedIn",
            icon: "💼",
            description: "Professional networking",
            contentTypes: [
              {
                id: 'post',
                name: 'Post',
                format: 'text',
                supportedPlatforms: ['linkedin']
              }
            ],
            features: [
              'Professional tone',
              'B2B targeting',
              'Industry insights'
            ]
          },
          {
            id: 'facebook',
            name: "Facebook",
            icon: "👥",
            description: "Community engagement",
            contentTypes: [
              {
                id: 'post',
                name: 'Post',
                format: 'text',
                supportedPlatforms: ['facebook']
              }
            ],
            features: [
              'Community building',
              'Event promotion',
              'Group management'
            ]
          }
        ];

        const mockTestimonials: Testimonial[] = [
          {
            id: '1',
            client: {
              name: "Sarah Johnson",
              title: "Marketing Manager",
              company: "Tech Solutions Inc",
              industry: "Technology",
              image: ""
            },
            content: "This platform has revolutionized our content creation process. We're saving hours every week!",
            rating: 5,
            date: "2024-01",
            metrics: [
              {
                label: "Time Saved",
                beforeValue: 40,
                afterValue: 5,
                unit: "hours/month",
                timeframe: "monthly",
                improvement: 87.5
              }
            ],
            tags: ["Time Saving", "Efficiency"],
            featured: true
          },
          {
            id: '2',
            client: {
              name: "Michael Chen",
              title: "Social Media Director",
              company: "Global Brands",
              industry: "Marketing",
              image: ""
            },
            content: "The AI-generated content is incredibly engaging and saves us tremendous time.",
            rating: 5,
            date: "2024-01",
            metrics: [
              {
                label: "Engagement Rate",
                beforeValue: 2,
                afterValue: 8,
                unit: "%",
                timeframe: "monthly",
                improvement: 300
              }
            ],
            tags: ["AI Content", "Engagement"],
            featured: true
          }
        ];

        // Simulate API delay
        await new Promise(resolve => setTimeout(resolve, 1000));

        setFeatures(mockFeatures);
        setPlatforms(mockPlatforms);
        setTestimonials(mockTestimonials);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load content');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    features,
    platforms,
    testimonials,
    loading,
    error
  };
};
