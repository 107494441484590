import { simplifiedContentApi } from '../pages/simplified-content/api/contentApi';
import { Content, TopicIdeasResponse, ContentStatus, CONTENT_STATUSES } from '../types/content';
import { 
  GenerateContentResponse, 
  GenerateContentParams,
  Platform,
  ContentRequirements,
  GeneratedContent,
  BusinessProfile,
  ContentType,
  PlatformContent,
  ContentMetadata,
  UpdateContentParams
} from '../pages/simplified-content/types';

// Helper function to convert GenerateContentResponse to Content
const convertToContent = (response: GenerateContentResponse): Content => {
  // Get the first platform's content as default
  const platformKey = Object.keys(response.generated_content)[0];
  const platformContent = response.generated_content[platformKey];

  // Create metadata if it doesn't exist
  const metadata: ContentMetadata = response.metadata || {
    source: 'simplified_content',
    version: '2.0',
    generated_at: new Date().toISOString(),
    platform_specific: {
      [platformKey]: {
        platform_id: platformContent?.metadata?.platform_id || '',
        platform_name: platformKey,
        content_type: response.content_type
      }
    }
  };

  return {
    id: response.id,
    created_at: response.created_at,
    content_type: response.content_type,
    content: platformContent?.text || '',
    platform: platformKey,
    content_status: response.workflow_status as ContentStatus,  // Use workflow_status
    target_audience: response.requirements.targetAudience,
    campaign: response.campaign,
    content_format: 'text', // Default format
    content_id: response.id.toString(),
    platform_images: response.platform_images,
    user: response.user,
    metadata,
    platform_metadata: platformContent?.metadata || {}
  };
};

// Helper function to convert array of GenerateContentResponse to Content[]
const convertToContents = (responses: GenerateContentResponse[]): Content[] => {
  return responses.map(convertToContent);
};

// Helper function to create platform content with required fields
const createPlatformContent = (text: string, platform: Platform, contentType: ContentType): PlatformContent => ({
  text,
  generated_at: new Date().toISOString(),
  platform_specific_requirements: platform.requirements || [],
  status: 'draft',
  metadata: {
    platform_id: platform.id,
    platform_name: platform.name,
    content_type: contentType,
    version: '2.0',
    generated_at: new Date().toISOString()
  }
});

export const contentService = {
  getContents: async (): Promise<Content[]> => {
    const response = await simplifiedContentApi.getAllContent();
    return convertToContents(response);
  },

  getContentHistory: async (): Promise<Content[]> => {
    const response = await simplifiedContentApi.getAllContent();
    return convertToContents(response);
  },

  createContent: async (contentData: Partial<Content>): Promise<Content> => {
    // Transform Content type to format expected by simplifiedContentApi
    const platform: Platform = {
      id: '1',
      name: contentData.platform || '',
      requirements: []
    };

    const requirements: ContentRequirements = {
      objective: '',
      tone: '',
      targetAudience: contentData.target_audience || '',
      topic: '',
      selectedProducts: [],
      selectedServices: []
    };

    const contentType: ContentType = contentData.content_type as ContentType || 'quick';
    const generated_content: GeneratedContent = {
      [contentData.platform || '']: createPlatformContent(
        contentData.content || '',
        platform,
        contentType
      )
    };

    const response = await simplifiedContentApi.saveContent({
      platforms: [platform],
      requirements,
      campaign: contentData.campaign || null,
      generated_content,
      platform_images: contentData.platform_images,
      business_profile: null
    });
    
    if (response.failures.length > 0) {
      throw new Error(response.failures[0].error);
    }
    
    return convertToContent(response.successes[0]);
  },

  generateQuickContent: async (contentData: Partial<Content>): Promise<{ generated_content: string; content?: Content }> => {
    const platform: Platform = {
      id: '1',
      name: contentData.platform || '',
      requirements: []
    };

    const params: GenerateContentParams = {
      platforms: [platform],
      requirements: {
        objective: '',
        tone: '',
        targetAudience: contentData.target_audience || '',
        topic: '',
        selectedProducts: [],
        selectedServices: []
      },
      campaign: contentData.campaign || null,
      businessProfile: null
    };

    const response = await simplifiedContentApi.generateContent(params);
    const platformKey = Object.keys(response.generated_content)[0];

    return {
      generated_content: response.generated_content[platformKey]?.text || '',
      content: convertToContent(response)
    };
  },

  deleteContent: async (id: number): Promise<void> => {
    await simplifiedContentApi.deleteContent(id);
  },

  generateTopicIdeas: async (data: any): Promise<TopicIdeasResponse> => {
    const response = await simplifiedContentApi.generateTopicIdeas(data);
    return {
      topic_ideas: response.topic_ideas,
      content: [], // Add empty content array as required by the type
      message: undefined // Optional message
    };
  },

  generateContent: async (data: GenerateContentParams): Promise<string> => {
    const response = await simplifiedContentApi.generateContent(data);
    const platformKey = Object.keys(response.generated_content)[0];
    return response.generated_content[platformKey]?.text || '';
  },

  getContentVariations: async (contentId: number): Promise<Content[]> => {
    const content = await simplifiedContentApi.getContent(contentId.toString());
    return [convertToContent(content)];
  },

  updateContent: async (contentId: number, updates: Partial<Content>): Promise<Content> => {
    try {
      console.log('Content service: Updating content:', { contentId, updates });
      
      // Get current content to see its status
      const currentContent = await simplifiedContentApi.getContent(contentId.toString());
      console.log('Current content:', currentContent);

      const newStatus = updates.content_status;
      if (newStatus && !CONTENT_STATUSES.includes(newStatus)) {
        throw new Error(`Invalid status: ${newStatus}. Must be one of: ${CONTENT_STATUSES.join(', ')}`);
      }

      // Create update data with workflow_status
      const updateData: UpdateContentParams = {
        workflow_status: newStatus  // Send as workflow_status
      };

      console.log('Sending update request:', {
        contentId,
        updateData,
        originalStatus: updates.content_status,
        currentStatus: currentContent.workflow_status,
        allowedStatuses: CONTENT_STATUSES
      });

      const response = await simplifiedContentApi.updateContent(contentId.toString(), updateData);
      
      console.log('Update response:', response);

      return convertToContent(response);
    } catch (error: any) {
      console.error('Content update error:', {
        contentId,
        error: error.message,
        apiError: error.response?.data,
        sentStatus: updates.content_status,
        allowedStatuses: CONTENT_STATUSES
      });
      throw error;
    }
  }
};
