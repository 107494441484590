import React from 'react';
import { Route } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import AdminDashboard from '../pages/admin/AdminDashboard';
import AIConfiguration from '../pages/admin/AIConfiguration';
import AuditLogs from '../pages/admin/AuditLogs';
import Testing from '../pages/admin/Testing';
import Users from '../pages/admin/Users';
import PromptHistory from '../pages/admin/PromptHistory';
import SystemPrompt from '../pages/admin/SystemPrompt';
import PlatformInstructions from '../pages/admin/PlatformInstructions';
import SubscriptionErrors from '../pages/admin/SubscriptionErrors';

export const adminRoutes = (
  <>
    <Route path="/admin" element={<PrivateRoute><AdminDashboard /></PrivateRoute>} />
    <Route path="/admin/ai-configuration" element={<PrivateRoute><AIConfiguration /></PrivateRoute>} />
    <Route path="/admin/audit-logs" element={<PrivateRoute><AuditLogs /></PrivateRoute>} />
    <Route path="/admin/testing" element={<PrivateRoute><Testing /></PrivateRoute>} />
    <Route path="/admin/users" element={<PrivateRoute><Users /></PrivateRoute>} />
    <Route path="/admin/prompt-history" element={<PrivateRoute><PromptHistory /></PrivateRoute>} />
    <Route path="/admin/system-prompt" element={<PrivateRoute><SystemPrompt /></PrivateRoute>} />
    <Route path="/admin/platform-instructions" element={<PrivateRoute><PlatformInstructions /></PrivateRoute>} />
    <Route path="/admin/subscription-errors" element={<PrivateRoute><SubscriptionErrors /></PrivateRoute>} />
  </>
);
