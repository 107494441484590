import React from 'react';

interface FormFieldProps {
  label: string;
  id: string;
  error?: string;
  required?: boolean;
  helpText?: string;
  children: React.ReactNode;
}

const FormField: React.FC<FormFieldProps> = ({
  label,
  id,
  error,
  required = false,
  helpText,
  children,
}) => {
  console.log('FormField rendered:', { id, label }); // Debug log

  return (
    <div className="form-field relative">
      <label 
        htmlFor={id} 
        className="block text-sm font-medium text-gray-700 mb-1"
      >
        {label}
        {required && <span className="text-red-500 ml-1">*</span>}
      </label>
      <div className="relative">
        {children}
      </div>
      {helpText && (
        <p className="mt-1 text-sm text-gray-500" id={`${id}-description`}>
          {helpText}
        </p>
      )}
      {error && (
        <div 
          className="mt-1 text-sm text-red-600" 
          id={`${id}-error`}
          role="alert"
        >
          {error}
        </div>
      )}
    </div>
  );
};

export default React.memo(FormField);
