import React, { useState } from 'react';
import { ResearchHistoryProps, ResearchReport } from '../../types/research';

const ResearchHistory: React.FC<ResearchHistoryProps> = ({ reports, onDelete, isDeleting }) => {
  const [expandedReport, setExpandedReport] = useState<number | null>(null);
  const [deletingId, setDeletingId] = useState<number | null>(null);

  const toggleReport = (reportId: number) => {
    setExpandedReport(expandedReport === reportId ? null : reportId);
  };

  const handleDelete = async (reportId: number, e: React.MouseEvent) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to delete this research report?')) {
      setDeletingId(reportId);
      try {
        await onDelete(reportId);
      } finally {
        setDeletingId(null);
      }
    }
  };

  const handleDownload = (report: ResearchReport, e: React.MouseEvent) => {
    e.stopPropagation();

    const content = `
${report.title}
Generated on: ${formatDate(report.created_at)}

${report.report_content}
    `.trim();

    const blob = new Blob([content], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${report.title.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (reports.length === 0) {
    return (
      <div className="text-center py-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Marketing Research Reports History</h2>
        <p className="text-gray-600">No research reports generated yet.</p>
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-6">Marketing Research Reports History</h2>
      <div className="space-y-4">
        {reports.map((report) => (
          <div
            key={report.id}
            className="border border-gray-200 rounded-lg overflow-hidden transition-all duration-200 hover:shadow-md"
          >
            <div
              onClick={() => toggleReport(report.id)}
              className="flex items-center justify-between p-4 cursor-pointer bg-gray-50 hover:bg-gray-100"
            >
              <div className="flex-1">
                <h3 className="text-lg font-medium text-gray-900">{report.title}</h3>
                <p className="text-sm text-gray-500">{formatDate(report.created_at)}</p>
              </div>
              <div className="flex items-center space-x-4">
                <button
                  onClick={(e) => handleDownload(report, e)}
                  className="text-blue-600 hover:text-blue-800 focus:outline-none"
                  title="Download Report"
                >
                  <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg>
                </button>
                <button
                  onClick={(e) => handleDelete(report.id, e)}
                  className={`text-red-600 hover:text-red-800 focus:outline-none ${
                    deletingId === report.id || isDeleting ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  disabled={deletingId === report.id || isDeleting}
                  title="Delete Report"
                >
                  {deletingId === report.id ? (
                    <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  ) : (
                    <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  )}
                </button>
                <svg
                  className={`w-5 h-5 text-gray-500 transform transition-transform duration-200 ${
                    expandedReport === report.id ? 'rotate-180' : ''
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </div>

            {expandedReport === report.id && (
              <div className="p-4 border-t border-gray-200">
                <div className="prose prose-sm max-w-none">
                  {report.report_content.split('\n').map((paragraph, index) => (
                    <p key={index} className="mb-2 text-gray-600">
                      {paragraph}
                    </p>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResearchHistory;
