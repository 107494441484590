import React from 'react';
import FormInput from '../../common/FormInput';
import { BusinessProfileFormSectionProps } from '../types/businessProfileForm';

const ContactInfoForm: React.FC<BusinessProfileFormSectionProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  return (
    <div className="space-y-6">
      <FormInput
        label="Contact Name"
        name="contact_name"
        value={values.contact_name}
        onChange={(e) => handleChange('contact_name', e.target.value)}
        onBlur={handleBlur}
        error={errors.contact_name}
        touched={touched.contact_name}
        placeholder="Enter contact name"
        required
      />

      <FormInput
        label="Phone Number"
        name="phone_number"
        value={values.phone_number}
        onChange={(e) => handleChange('phone_number', e.target.value)}
        onBlur={handleBlur}
        error={errors.phone_number}
        touched={touched.phone_number}
        placeholder="Enter phone number"
        required
      />

      <FormInput
        label="Email Address"
        name="email"
        type="email"
        value={values.email}
        onChange={(e) => handleChange('email', e.target.value)}
        onBlur={handleBlur}
        error={errors.email}
        touched={touched.email}
        placeholder="Enter email address"
        required
      />
    </div>
  );
};

export default ContactInfoForm;
