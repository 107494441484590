import axiosInstance from '../utils/axiosConfig';

export interface PaymentMethodInfo {
  last4: string;
  brand: string;
  exp_month: number;
  exp_year: number;
  cardholder_name: string | null;
  is_default: boolean;
}

export interface SubscriptionStatus {
  active: boolean;
  plan: string | null;
  current_period_end: string | null;
  subscription_id: string | null;
  cancel_at_period_end: boolean;
  status: 'active' | 'past_due' | 'cancelled' | 'expired' | 'incomplete' | null;
  // Retry information
  in_retry_period: boolean;
  next_payment_attempt: string | null;
  days_until_retry: number | null;
  days_until_renewal: number | null;
  payment_retry_count: number;
  payment_failure_reason: string | null;
}

export const subscriptionService = {
  // Get subscription status
  getStatus: async (): Promise<SubscriptionStatus> => {
    try {
      const response = await axiosInstance.get('/subscriptions/status/');
      return {
        ...response.data,
        // Ensure all fields have proper types
        active: Boolean(response.data.active),
        plan: response.data.plan || null,
        current_period_end: response.data.current_period_end || null,
        subscription_id: response.data.subscription_id || null,
        cancel_at_period_end: Boolean(response.data.cancel_at_period_end),
        status: response.data.status || null,
        in_retry_period: Boolean(response.data.in_retry_period),
        next_payment_attempt: response.data.next_payment_attempt || null,
        days_until_retry: typeof response.data.days_until_retry === 'number' ? response.data.days_until_retry : null,
        days_until_renewal: typeof response.data.days_until_renewal === 'number' ? response.data.days_until_renewal : null,
        payment_retry_count: response.data.payment_retry_count || 0
      };
    } catch (error) {
      console.error('Error getting subscription status:', error);
      throw error;
    }
  },

  // Get current payment method
  getCurrentPaymentMethod: async () => {
    try {
      const response = await axiosInstance.get('/payment-methods/current/');
      return response.data as PaymentMethodInfo;
    } catch (error) {
      console.error('Error getting current payment method:', error);
      throw error;
    }
  },

  // Update payment method (regular update)
  updatePaymentMethod: async (paymentMethodId: string, cardholderName: string) => {
    try {
      console.log('Updating payment method with ID:', paymentMethodId);
      const response = await axiosInstance.post('/payment-methods/update/', {
        payment_method_id: paymentMethodId,
        cardholder_name: cardholderName,
      });
      console.log('Payment method update response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating payment method:', error);
      throw error;
    }
  },

  // Update payment method during retry period
  updatePaymentMethodForRetry: async (paymentMethodId: string, cardholderName: string) => {
    try {
      console.log('Updating payment method during retry period with ID:', paymentMethodId);
      const response = await axiosInstance.post('/subscriptions/update-payment/', {
        payment_method_id: paymentMethodId,
        cardholder_name: cardholderName,
      });
      console.log('Payment method update response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating payment method during retry:', error);
      throw error;
    }
  },

  // Cancel subscription
  cancelSubscription: async () => {
    try {
      const response = await axiosInstance.post('/subscriptions/cancel/');
      return response.data;
    } catch (error) {
      console.error('Error canceling subscription:', error);
      throw error;
    }
  },

  // Reinstate subscription
  reinstateSubscription: async () => {
    try {
      console.log('Attempting to reinstate subscription');
      const response = await axiosInstance.post('/subscriptions/reinstate/');
      console.log('Subscription reinstatement response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error reinstating subscription:', error);
      throw error;
    }
  },

  // Change subscription plan
  changePlan: async (newPlanId: string, stripePriceId: string) => {
    try {
      const response = await axiosInstance.post('/subscriptions/change-plan/', {
        new_plan_id: newPlanId,
        stripe_price_id: stripePriceId,
      });
      return response.data;
    } catch (error) {
      console.error('Error changing subscription plan:', error);
      throw error;
    }
  },

  // Get Stripe configuration
  getStripeConfig: async () => {
    try {
      const response = await axiosInstance.get('/pricing-tiers/');
      return response.data.stripe_config;
    } catch (error) {
      console.error('Error getting Stripe config:', error);
      throw error;
    }
  },

  // Get billing history
  getBillingHistory: async () => {
    try {
      const response = await axiosInstance.get('/payment-methods/billing-history/');
      return response.data;
    } catch (error) {
      console.error('Error getting billing history:', error);
      throw error;
    }
  },
};
