import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ValueProposition } from '../../components/ValueProposition';
import { Testimonials } from '../../components/Testimonials';
import { StatsCard } from '../../components/common/StatsCard';
import { useLandingPage } from './hooks/useLandingPage';
import { useAnalytics, EventCategory, EventAction } from '../../services/analytics';
import { useAuth } from '../../contexts/AuthContext';
import AuthNav from '../../components/auth/AuthNav';

/**
 * LandingPage Component
 * 
 * Main landing page that showcases our premium AI-powered content
 * creation platform. Includes value proposition, platform demo,
 * and customer testimonials.
 */
export const LandingPage: React.FC = () => {
  const {
    platforms,
    testimonials,
    loading,
    error
  } = useLandingPage();

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const analytics = useAnalytics();

  // Track page view on mount
  useEffect(() => {
    analytics.trackPageView({
      path: '/landing',
      title: 'Landing Page',
      properties: {
        hasError: !!error,
        platformsCount: platforms.length,
        testimonialsCount: testimonials.length
      }
    });
  }, [analytics, error, platforms.length, testimonials.length]);

  // Track scroll depth
  useEffect(() => {
    let scrollDepths = new Set<number>();
    const depths = [25, 50, 75, 100];

    const handleScroll = () => {
      const scrollPercent = Math.round(
        (window.scrollY + window.innerHeight) / document.documentElement.scrollHeight * 100
      );

      depths.forEach(depth => {
        if (scrollPercent >= depth && !scrollDepths.has(depth)) {
          scrollDepths.add(depth);
          analytics.trackEvent({
            category: EventCategory.Landing,
            action: 'Scroll Depth',
            label: `${depth}%`,
            value: depth
          });
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [analytics]);

  // Track CTA clicks and route to appropriate page
  const handleCtaClick = useCallback((location: string) => {
    analytics.trackEvent({
      category: EventCategory.Landing,
      action: EventAction.CtaClick,
      label: location,
      properties: {
        destination: isAuthenticated ? '/dashboard' : '/pricing'
      }
    });

    navigate(isAuthenticated ? '/dashboard' : '/pricing');
  }, [analytics, isAuthenticated, navigate]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-900 to-indigo-900">
        <div className="text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-400 mx-auto"></div>
          <p className="mt-4 text-blue-100">Loading amazing content...</p>
        </div>
      </div>
    );
  }

  if (error) {
    analytics.trackEvent({
      category: EventCategory.Landing,
      action: 'Error',
      label: error
    });

    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-900 to-indigo-900">
        <div className="text-center">
          <div className="text-red-400 text-6xl mb-4">⚠️</div>
          <h2 className="text-2xl font-bold text-white mb-2">Oops! Something went wrong</h2>
          <p className="text-blue-100">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="mt-6 bg-white text-blue-900 px-6 py-2 rounded-lg hover:bg-blue-50"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <main className="min-h-screen" data-testid="landing-page">
      <AuthNav />
      {/* Hero Section */}
      <section className="relative bg-gradient-to-r from-blue-900 to-indigo-900 text-white overflow-hidden">
        {/* Background Pattern */}
        <div className="absolute inset-0 opacity-10">
          <div 
            className="absolute inset-0 bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]"
            style={{ backgroundImage: 'url(/images/grid-pattern.svg)' }}
          ></div>
        </div>

        {/* Content */}
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 lg:py-32">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            {/* Left Column */}
            <div className="lg:col-span-7 animate-fade-in">
              <h1 className="text-5xl md:text-6xl font-extrabold mb-6 leading-tight animate-slide-up text-white">
                Create Better Content,{' '}
                <span className="text-blue-400">10x Faster</span>
              </h1>
              <p className="text-xl md:text-2xl text-blue-100 mb-12 max-w-3xl animate-slide-up delay-100">
                Our AI-powered platform helps you create engaging, platform-optimized content in minutes, not hours. Perfect for every social media channel.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 animate-slide-up delay-200">
                <button
                  onClick={() => handleCtaClick('hero-primary')}
                  className="bg-white text-blue-900 px-8 py-4 rounded-lg font-semibold text-lg
                           hover:bg-blue-50 transition-all duration-200 hover:scale-105"
                >
                  Transform Your Content
                </button>
                <button
                  onClick={() => handleCtaClick('hero-secondary')}
                  className="border-2 border-white text-white px-8 py-4 rounded-lg font-semibold text-lg
                           hover:bg-white/10 transition-all duration-200 hover:scale-105"
                >
                  Watch Demo
                </button>
              </div>
              <div className="mt-8 flex items-center text-blue-200 text-sm animate-slide-up delay-300">
                <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                Join 250k+ content pieces created • Save 98% of your time
              </div>

              {/* Supported Platforms */}
              <div className="mt-12 animate-fade-in delay-500">
                <p className="text-sm text-blue-200 mb-4">Supported Platforms</p>
                <div className="grid grid-cols-3 md:grid-cols-6 gap-6 items-center">
                  <div className="flex flex-col items-center">
                    <img src="/images/logos/instagram-logo.svg" alt="Instagram" className="h-8 mb-2" />
                    <span className="text-xs text-blue-200">Instagram</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <img src="/images/logos/linkedin-logo.svg" alt="LinkedIn" className="h-8 mb-2" />
                    <span className="text-xs text-blue-200">LinkedIn</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <img src="/images/logos/facebook-logo.svg" alt="Facebook" className="h-8 mb-2" />
                    <span className="text-xs text-blue-200">Facebook</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <img src="/images/logos/x-logo.svg" alt="X (Twitter)" className="h-8 mb-2" />
                    <span className="text-xs text-blue-200">X (Twitter)</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <img src="/images/logos/tiktok-logo.svg" alt="TikTok" className="h-8 mb-2" />
                    <span className="text-xs text-blue-200">TikTok</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <img src="/images/logos/youtube-logo.svg" alt="YouTube" className="h-8 mb-2" />
                    <span className="text-xs text-blue-200">YouTube</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="hidden lg:block lg:col-span-5">
              <div className="relative mt-12 lg:mt-0 animate-float">
                <img
                  src="/images/platform-preview.svg"
                  alt="Platform Preview"
                  className="rounded-lg shadow-xl"
                />
                <StatsCard
                  value={250}
                  suffix="k+"
                  label="Content Pieces Created"
                  className="absolute -bottom-8 -left-8 animate-slide-up delay-300"
                />
                <StatsCard
                  value={98}
                  suffix="%"
                  label="Time Saved"
                  className="absolute -top-8 -right-8 animate-slide-up delay-400"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Value Proposition Section */}
      <ValueProposition />

      {/* Platform Section */}
      <section className="bg-gradient-to-r from-blue-900 to-indigo-900 text-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-4xl md:text-5xl lg:text-6xl font-extrabold tracking-tight mb-6">
              Revolutionize Your Content Strategy
            </h2>
            <p className="text-xl md:text-2xl text-blue-100 mb-8 max-w-3xl mx-auto">
              Join industry leaders who save 40+ hours per month with our AI-powered platform. Create premium content that drives engagement across all channels.
            </p>
          </div>

          {/* Stats */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-4xl mx-auto mb-12">
            <div className="bg-blue-800/50 rounded-lg p-6 backdrop-blur-sm border border-blue-700/30">
              <div className="text-3xl font-bold mb-2">98%</div>
              <div className="text-blue-100">Time Saved vs Manual Creation</div>
            </div>
            <div className="bg-blue-800/50 rounded-lg p-6 backdrop-blur-sm border border-blue-700/30">
              <div className="text-3xl font-bold mb-2">3x</div>
              <div className="text-blue-100">Higher Engagement Rate</div>
            </div>
            <div className="bg-blue-800/50 rounded-lg p-6 backdrop-blur-sm border border-blue-700/30">
              <div className="text-3xl font-bold mb-2">250k+</div>
              <div className="text-blue-100">Content Pieces Created</div>
            </div>
          </div>

          <button
            onClick={() => handleCtaClick('platform-section')}
            className="bg-white text-blue-900 px-8 py-4 rounded-lg font-semibold text-lg
                     hover:bg-blue-50 transition-all duration-200 hover:scale-105 mx-auto block mb-20"
          >
            Transform Your Content Strategy
          </button>

          {/* Platform Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Instagram */}
            <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-lg p-8 shadow-sm">
              <div className="flex items-center gap-3 mb-4">
                <img src="/images/logos/instagram-logo.svg" alt="Instagram" className="w-10 h-10" />
                <h3 className="text-lg font-semibold text-gray-900">Instagram</h3>
              </div>
              <p className="text-gray-800 mb-4">Visual content and stories</p>
              <div className="mb-4">
                <div className="text-sm font-medium text-gray-900 mb-2">Supported Content</div>
                <div className="flex gap-2">
                  <span className="px-2 py-1 bg-blue-200/50 text-gray-700 text-sm rounded">Post</span>
                  <span className="px-2 py-1 bg-blue-200/50 text-gray-700 text-sm rounded">Story</span>
                  <span className="px-2 py-1 bg-blue-200/50 text-gray-700 text-sm rounded">Reel</span>
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-900 mb-2">Platform Features</div>
                <ul className="space-y-2">
                  <li className="flex items-center text-gray-800">
                    <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Image optimization
                  </li>
                  <li className="flex items-center text-gray-800">
                    <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Hashtag suggestions
                  </li>
                  <li className="flex items-center text-gray-800">
                    <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Reel script generation
                  </li>
                </ul>
              </div>
            </div>

            {/* LinkedIn */}
            <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-lg p-8 shadow-sm">
              <div className="flex items-center gap-3 mb-4">
                <img src="/images/logos/linkedin-logo.svg" alt="LinkedIn" className="w-10 h-10" />
                <h3 className="text-lg font-semibold text-gray-900">LinkedIn</h3>
              </div>
              <p className="text-gray-800 mb-4">Professional network content</p>
              <div className="mb-4">
                <div className="text-sm font-medium text-gray-900 mb-2">Supported Content</div>
                <div className="flex gap-2">
                  <span className="px-2 py-1 bg-blue-200/50 text-gray-700 text-sm rounded">Post</span>
                  <span className="px-2 py-1 bg-blue-200/50 text-gray-700 text-sm rounded">Article</span>
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-900 mb-2">Platform Features</div>
                <ul className="space-y-2">
                  <li className="flex items-center text-gray-800">
                    <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Professional formatting
                  </li>
                  <li className="flex items-center text-gray-800">
                    <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    B2B targeting
                  </li>
                  <li className="flex items-center text-gray-800">
                    <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Industry insights
                  </li>
                </ul>
              </div>
            </div>

            {/* Facebook */}
            <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-lg p-8 shadow-sm">
              <div className="flex items-center gap-3 mb-4">
                <img src="/images/logos/facebook-logo.svg" alt="Facebook" className="w-10 h-10" />
                <h3 className="text-lg font-semibold text-gray-900">Facebook</h3>
              </div>
              <p className="text-gray-800 mb-4">Engage your community</p>
              <div className="mb-4">
                <div className="text-sm font-medium text-gray-900 mb-2">Supported Content</div>
                <div className="flex gap-2">
                  <span className="px-2 py-1 bg-blue-200/50 text-gray-700 text-sm rounded">Post</span>
                  <span className="px-2 py-1 bg-blue-200/50 text-gray-700 text-sm rounded">Image Post</span>
                  <span className="px-2 py-1 bg-blue-200/50 text-gray-700 text-sm rounded">Reel</span>
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-900 mb-2">Platform Features</div>
                <ul className="space-y-2">
                  <li className="flex items-center text-gray-800">
                    <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Engaging post formats
                  </li>
                  <li className="flex items-center text-gray-800">
                    <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Reel script creation
                  </li>
                  <li className="flex items-center text-gray-800">
                    <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Community engagement
                  </li>
                </ul>
              </div>
            </div>

            {/* X (Twitter) */}
            <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-lg p-8 shadow-sm">
              <div className="flex items-center gap-3 mb-4">
                <img src="/images/logos/x-logo.svg" alt="X (Twitter)" className="w-10 h-10" />
                <h3 className="text-lg font-semibold text-gray-900">X (Twitter)</h3>
              </div>
              <p className="text-gray-800 mb-4">Real-time engagement</p>
              <div className="mb-4">
                <div className="text-sm font-medium text-gray-900 mb-2">Supported Content</div>
                <div className="flex gap-2">
                  <span className="px-2 py-1 bg-blue-200/50 text-gray-700 text-sm rounded">Tweet</span>
                  <span className="px-2 py-1 bg-blue-200/50 text-gray-700 text-sm rounded">Thread</span>
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-900 mb-2">Platform Features</div>
                <ul className="space-y-2">
                  <li className="flex items-center text-gray-800">
                    <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Thread creation
                  </li>
                  <li className="flex items-center text-gray-800">
                    <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Viral content optimization
                  </li>
                  <li className="flex items-center text-gray-800">
                    <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Trend analysis
                  </li>
                </ul>
              </div>
            </div>

            {/* TikTok */}
            <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-lg p-8 shadow-sm">
              <div className="flex items-center gap-3 mb-4">
                <img src="/images/logos/tiktok-logo.svg" alt="TikTok" className="w-10 h-10" />
                <h3 className="text-lg font-semibold text-gray-900">TikTok</h3>
              </div>
              <p className="text-gray-800 mb-4">Short form video content</p>
              <div className="mb-4">
                <div className="text-sm font-medium text-gray-900 mb-2">Supported Content</div>
                <div className="flex gap-2">
                  <span className="px-2 py-1 bg-blue-200/50 text-gray-700 text-sm rounded">Video</span>
                  <span className="px-2 py-1 bg-blue-200/50 text-gray-700 text-sm rounded">Short</span>
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-900 mb-2">Platform Features</div>
                <ul className="space-y-2">
                  <li className="flex items-center text-gray-800">
                    <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Video script generation
                  </li>
                  <li className="flex items-center text-gray-800">
                    <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Trending content ideas
                  </li>
                  <li className="flex items-center text-gray-800">
                    <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Hashtag optimization
                  </li>
                </ul>
              </div>
            </div>

            {/* YouTube */}
            <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-lg p-8 shadow-sm">
              <div className="flex items-center gap-3 mb-4">
                <img src="/images/logos/youtube-logo.svg" alt="YouTube" className="w-10 h-10" />
                <h3 className="text-lg font-semibold text-gray-900">YouTube</h3>
              </div>
              <p className="text-gray-800 mb-4">Long & short video content</p>
              <div className="mb-4">
                <div className="text-sm font-medium text-gray-900 mb-2">Supported Content</div>
                <div className="flex gap-2">
                  <span className="px-2 py-1 bg-blue-200/50 text-gray-700 text-sm rounded">Long Video</span>
                  <span className="px-2 py-1 bg-blue-200/50 text-gray-700 text-sm rounded">Short</span>
                </div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-900 mb-2">Platform Features</div>
                <ul className="space-y-2">
                  <li className="flex items-center text-gray-800">
                    <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Long form video scripts
                  </li>
                  <li className="flex items-center text-gray-800">
                    <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Short form video scripts
                  </li>
                  <li className="flex items-center text-gray-800">
                    <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    SEO optimization
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Call to Action */}
          <div className="text-center mt-16">
            <button
              onClick={() => handleCtaClick('platform-section-bottom')}
              className="bg-white text-blue-900 px-8 py-4 rounded-lg font-semibold text-lg
                       hover:bg-blue-50 transition-all duration-200 hover:scale-105"
            >
              Transform Your Content Strategy
            </button>
            <p className="mt-4 text-sm text-blue-200">
              Join industry leaders already optimizing content across all platforms
            </p>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <Testimonials testimonials={testimonials} />
    </main>
  );
};

export default LandingPage;
