import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const AuthNav: React.FC = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <nav className="absolute top-0 right-0 mt-4 mr-8 z-50">
      {!isAuthenticated ? (
        <div className="flex items-center space-x-4">
          <Link
            to="/login"
            className="flex items-center text-white hover:text-blue-200 transition-colors duration-200"
          >
            <svg
              className="w-6 h-6 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="font-medium">Login</span>
          </Link>
          <Link
            to="/pricing"
            className="px-4 py-2 bg-white text-blue-900 rounded-lg font-medium hover:bg-blue-50 transition-all duration-200 hover:scale-105"
          >
            Get Started
          </Link>
        </div>
      ) : (
        <div className="flex items-center space-x-4">
          <Link
            to="/dashboard"
            className="flex items-center text-white hover:text-blue-200 transition-colors duration-200"
          >
            <span className="font-medium">Dashboard</span>
          </Link>
          <button
            onClick={handleLogout}
            className="px-4 py-2 bg-white text-blue-900 rounded-lg font-medium hover:bg-blue-50 transition-all duration-200 hover:scale-105"
          >
            Logout
          </button>
        </div>
      )}
    </nav>
  );
};

export default AuthNav;
