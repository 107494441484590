import React, { useState, useEffect } from 'react';
import { adminService } from '../../services/admin';
import { AIConfig } from '../../types/admin';

interface AIConfigPanelProps {
  setError: (error: string | null) => void;
}

const AIConfigPanel: React.FC<AIConfigPanelProps> = ({ setError }) => {
  const [config, setConfig] = useState<AIConfig>({
    model: 'gpt-4',
    api_type: 'chat',
    temperature: 0.7,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [models, setModels] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch both config and models in parallel
        const [configData, modelsData] = await Promise.all([
          adminService.getAIConfig(),
          adminService.getOpenAIModels()
        ]);
        
        if (configData) {
          setConfig(configData);
          setHasChanges(false);
        }
        if (modelsData) {
          setModels(modelsData);
        }
        setError(null);
      } catch (err) {
        setError('Failed to load AI configuration');
        console.error('Error loading AI config:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    // Set up periodic model refresh (every hour)
    const refreshInterval = setInterval(async () => {
      try {
        const modelsData = await adminService.getOpenAIModels();
        if (modelsData) {
          setModels(modelsData);
        }
      } catch (err) {
        console.error('Error refreshing models:', err);
      }
    }, 3600000); // 1 hour in milliseconds

    return () => clearInterval(refreshInterval);
  }, [setError]);

  const handleChange = (field: keyof AIConfig, value: string | number) => {
    setConfig(prev => ({
      ...prev,
      [field]: value
    }));
    setHasChanges(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      await adminService.updateAIConfig(config);
      setError(null);
      setHasChanges(false);
    } catch (err) {
      setError('Failed to update AI configuration');
      console.error('Error updating AI config:', err);
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-gray-900">AI Configuration</h2>
          {hasChanges && (
            <span className="text-sm text-yellow-600">
              You have unsaved changes
            </span>
          )}
        </div>

        <div className="mb-6 p-4 bg-blue-50 rounded-md">
          <p className="text-sm text-blue-800">
            Note: These settings apply to both Chat and Assistant APIs in the testing panel.
            Changes will affect all new tests run after saving.
          </p>
        </div>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Model
            </label>
            <select
              value={config.model}
              onChange={(e) => handleChange('model', e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              disabled={isSaving}
            >
              {models.map((model) => (
                <option key={model} value={model}>
                  {model}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Temperature ({config.temperature})
            </label>
            <input
              type="range"
              min="0"
              max="2"
              step="0.1"
              value={config.temperature}
              onChange={(e) => handleChange('temperature', parseFloat(e.target.value))}
              className="w-full"
              disabled={isSaving}
            />
            <div className="flex justify-between text-xs text-gray-500 mt-1">
              <span>More Focused (0)</span>
              <span>Balanced (1)</span>
              <span>More Creative (2)</span>
            </div>
          </div>

          <div className="pt-4">
            <button
              type="submit"
              disabled={isSaving || !hasChanges}
              className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
                ${hasChanges ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-400'} 
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                ${isSaving || !hasChanges ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isSaving ? (
                <div className="flex items-center justify-center">
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2"></div>
                  Saving...
                </div>
              ) : (
                'Save Configuration'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AIConfigPanel;
