import React, { createContext, useContext, useState, useEffect } from 'react';
import { subscriptionService } from '../services/subscription';
import { useAuth } from './AuthContext';

interface SubscriptionContextType {
  hasActiveSubscription: boolean;
  isLoading: boolean;
  currentPlan: string | null;
  currentPeriodEnd: string;
  cancelAtPeriodEnd: boolean;
  subscriptionStatus: 'active' | 'past_due' | 'cancelled' | 'expired' | 'incomplete' | null;
  // Retry information
  inRetryPeriod: boolean;
  nextPaymentAttempt: string | null;
  daysUntilRetry: number | null;
  daysUntilRenewal: number | null;
  paymentRetryCount: number;
  paymentFailureReason: string | null;
  // Methods
  checkSubscriptionStatus: () => Promise<boolean>;
  cancelSubscription: () => Promise<void>;
  reinstateSubscription: () => Promise<void>;
  updatePaymentMethod: (paymentMethodId: string, cardholderName: string) => Promise<void>;
  changePlan: (newPlanId: string, stripePriceId: string) => Promise<void>;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

export const useSubscription = () => {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error('useSubscription must be used within a SubscriptionProvider');
  }
  return context;
};

export const SubscriptionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPlan, setCurrentPlan] = useState<string | null>(null);
  const [currentPeriodEnd, setCurrentPeriodEnd] = useState<string>('');
  const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState<'active' | 'past_due' | 'cancelled' | 'expired' | 'incomplete' | null>(null);
  // Retry information
  const [inRetryPeriod, setInRetryPeriod] = useState(false);
  const [nextPaymentAttempt, setNextPaymentAttempt] = useState<string | null>(null);
  const [daysUntilRetry, setDaysUntilRetry] = useState<number | null>(null);
  const [daysUntilRenewal, setDaysUntilRenewal] = useState<number | null>(null);
  const [paymentRetryCount, setPaymentRetryCount] = useState(0);
  const [paymentFailureReason, setPaymentFailureReason] = useState<string | null>(null);
  const { isAuthenticated, user } = useAuth();

  const checkSubscriptionStatus = async (): Promise<boolean> => {
    if (!isAuthenticated || !user) {
      setHasActiveSubscription(false);
      setCurrentPlan(null);
      setCancelAtPeriodEnd(false);
      setCurrentPeriodEnd('');
      setSubscriptionStatus(null);
      setInRetryPeriod(false);
      setNextPaymentAttempt(null);
      setDaysUntilRetry(null);
      setDaysUntilRenewal(null);
      setPaymentRetryCount(0);
      setPaymentFailureReason(null);
      setIsLoading(false);
      return false;
    }

    try {
      console.log('Checking subscription status for user:', user.email);
      const response = await subscriptionService.getStatus();
      console.log('Subscription status response:', response);
      
      const {
        active,
        plan,
        cancel_at_period_end,
        current_period_end,
        status,
        in_retry_period,
        next_payment_attempt,
        days_until_retry,
        days_until_renewal,
        payment_retry_count,
        payment_failure_reason
      } = response;
      
      console.log('Setting subscription status:', {
        active,
        plan,
        cancel_at_period_end,
        current_period_end,
        status,
        in_retry_period,
        next_payment_attempt,
        days_until_retry,
        days_until_renewal,
        payment_retry_count,
        payment_failure_reason
      });
      
      // Consider subscription active if it's in retry period or normally active
      const isActive = active || in_retry_period;
      
      setHasActiveSubscription(isActive);
      setCurrentPlan(plan);
      setCancelAtPeriodEnd(cancel_at_period_end || false);
      setCurrentPeriodEnd(current_period_end || '');
      setSubscriptionStatus(status);
      setInRetryPeriod(in_retry_period || false);
      setNextPaymentAttempt(next_payment_attempt || null);
      setDaysUntilRetry(days_until_retry || null);
      setDaysUntilRenewal(days_until_renewal || null);
      setPaymentRetryCount(payment_retry_count || 0);
      setPaymentFailureReason(payment_failure_reason || null);
      
      return isActive;
    } catch (error) {
      console.error('Error checking subscription status:', error);
      setHasActiveSubscription(false);
      setCurrentPlan(null);
      setCancelAtPeriodEnd(false);
      setCurrentPeriodEnd('');
      setSubscriptionStatus(null);
      setInRetryPeriod(false);
      setNextPaymentAttempt(null);
      setDaysUntilRetry(null);
      setDaysUntilRenewal(null);
      setPaymentRetryCount(0);
      setPaymentFailureReason(null);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const cancelSubscription = async (): Promise<void> => {
    if (!isAuthenticated) {
      throw new Error('User must be authenticated to cancel subscription');
    }

    try {
      console.log('Attempting to cancel subscription');
      await subscriptionService.cancelSubscription();
      console.log('Subscription canceled successfully');
      setCancelAtPeriodEnd(true);
      await checkSubscriptionStatus(); // Refresh subscription status
    } catch (error) {
      console.error('Error canceling subscription:', error);
      throw error;
    }
  };

  const reinstateSubscription = async (): Promise<void> => {
    if (!isAuthenticated) {
      throw new Error('User must be authenticated to reinstate subscription');
    }

    try {
      console.log('Attempting to reinstate subscription');
      await subscriptionService.reinstateSubscription();
      console.log('Subscription reinstated successfully');
      setCancelAtPeriodEnd(false);
      await checkSubscriptionStatus(); // Refresh subscription status
    } catch (error) {
      console.error('Error reinstating subscription:', error);
      throw error;
    }
  };

  const updatePaymentMethod = async (paymentMethodId: string, cardholderName: string): Promise<void> => {
    if (!isAuthenticated) {
      throw new Error('User must be authenticated to update payment method');
    }

    try {
      console.log('Attempting to update payment method');
      if (inRetryPeriod) {
        await subscriptionService.updatePaymentMethodForRetry(paymentMethodId, cardholderName);
      } else {
        await subscriptionService.updatePaymentMethod(paymentMethodId, cardholderName);
      }
      console.log('Payment method updated successfully');
      await checkSubscriptionStatus(); // Refresh subscription status
    } catch (error) {
      console.error('Error updating payment method:', error);
      throw error;
    }
  };

  const changePlan = async (newPlanId: string, stripePriceId: string): Promise<void> => {
    if (!isAuthenticated) {
      throw new Error('User must be authenticated to change plan');
    }

    try {
      console.log('Attempting to change plan');
      await subscriptionService.changePlan(newPlanId, stripePriceId);
      console.log('Plan changed successfully');
      await checkSubscriptionStatus(); // Refresh subscription status
    } catch (error) {
      console.error('Error changing plan:', error);
      throw error;
    }
  };

  useEffect(() => {
    let mounted = true;

    const checkStatus = async () => {
      if (isAuthenticated && user) {
        try {
          const active = await checkSubscriptionStatus();
          if (mounted) {
            setHasActiveSubscription(active);
          }
        } catch (error) {
          console.error('Error in subscription check:', error);
          if (mounted) {
            setHasActiveSubscription(false);
          }
        } finally {
          if (mounted) {
            setIsLoading(false);
          }
        }
      } else {
        if (mounted) {
          setHasActiveSubscription(false);
          setCurrentPlan(null);
          setCancelAtPeriodEnd(false);
          setCurrentPeriodEnd('');
          setSubscriptionStatus(null);
          setInRetryPeriod(false);
          setNextPaymentAttempt(null);
          setDaysUntilRetry(null);
          setDaysUntilRenewal(null);
          setPaymentRetryCount(0);
          setPaymentFailureReason(null);
          setIsLoading(false);
        }
      }
    };

    checkStatus();

    return () => {
      mounted = false;
    };
  }, [isAuthenticated, user]);

  const value = {
    hasActiveSubscription,
    isLoading,
    currentPlan,
    currentPeriodEnd,
    cancelAtPeriodEnd,
    subscriptionStatus,
    inRetryPeriod,
    nextPaymentAttempt,
    daysUntilRetry,
    daysUntilRenewal,
    paymentRetryCount,
    paymentFailureReason,
    checkSubscriptionStatus,
    cancelSubscription,
    reinstateSubscription,
    updatePaymentMethod,
    changePlan,
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};
