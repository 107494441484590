/**
 * Analytics Service
 * 
 * Handles tracking of page views and events.
 * Currently logs to console, but can be extended to use actual analytics services.
 */

export enum EventCategory {
  Landing = 'Landing',
  Content = 'Content',
  Campaign = 'Campaign',
  User = 'User',
  System = 'System'
}

export enum EventAction {
  View = 'View',
  Click = 'Click',
  Submit = 'Submit',
  Error = 'Error',
  Success = 'Success',
  CtaClick = 'CTA Click'
}

interface PageViewProps {
  path: string;
  title: string;
  properties?: Record<string, any>;
}

interface EventProps {
  category: EventCategory;
  action: string;
  label?: string;
  value?: number;
  properties?: Record<string, any>;
}

class Analytics {
  private isInitialized = false;

  constructor() {
    this.initialize();
  }

  private initialize() {
    if (this.isInitialized) return;
    
    // TODO: Initialize actual analytics service
    console.log('Analytics service initialized');
    this.isInitialized = true;
  }

  trackPageView({ path, title, properties = {} }: PageViewProps) {
    console.log('Page View:', {
      path,
      title,
      properties,
      timestamp: new Date().toISOString()
    });
  }

  trackEvent({ category, action, label, value, properties = {} }: EventProps) {
    console.log('Event:', {
      category,
      action,
      label,
      value,
      properties,
      timestamp: new Date().toISOString()
    });
  }
}

// Export singleton instance
export const useAnalytics = () => {
  // Create singleton instance
  const analytics = new Analytics();

  return analytics;
};
