import React from 'react';
import { Content } from '../types/content';

interface ContentPreviewModalProps {
  content: Content | null;
  onClose: () => void;
}

const ContentPreviewModal: React.FC<ContentPreviewModalProps> = ({ content, onClose }) => {
  if (!content) return null;

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'draft':
        return 'bg-yellow-100 text-yellow-800';
      case 'published':
        return 'bg-green-100 text-green-800';
      case 'failed':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg max-w-3xl w-full max-h-[90vh] overflow-y-auto">
        {/* Header */}
        <div className="border-b border-gray-200 px-6 py-4">
          <div className="flex justify-between items-start">
            <div>
              <h2 className="text-2xl font-bold text-gray-900">{content.topic || 'Untitled Content'}</h2>
              <p className="mt-1 text-sm text-gray-500">
                Created on {new Date(content.created_at).toLocaleString()}
              </p>
            </div>
            <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(content.content_status)}`}>
              {content.content_status}
            </span>
          </div>
        </div>

        {/* Content Body */}
        <div className="px-6 py-4 space-y-6">
          {/* Basic Information */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h3 className="text-sm font-medium text-gray-500">Content Type</h3>
              <p className="mt-1 text-sm text-gray-900">{content.content_type}</p>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">Platform</h3>
              <p className="mt-1 text-sm text-gray-900">{content.platform}</p>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">Target Audience</h3>
              <p className="mt-1 text-sm text-gray-900">{content.target_audience}</p>
            </div>
            {content.campaign && (
              <div>
                <h3 className="text-sm font-medium text-gray-500">Campaign</h3>
                <p className="mt-1 text-sm text-gray-900">{content.campaign.name}</p>
              </div>
            )}
          </div>

          {/* Main Content */}
          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-2">Content</h3>
            <div className="bg-gray-50 rounded-lg p-4">
              <p className="text-sm text-gray-900 whitespace-pre-wrap">{content.content}</p>
            </div>
          </div>

          {/* Platform Images */}
          {content.platform_images && (
            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-2">Platform Images</h3>
              <div className="grid grid-cols-2 gap-4">
                {Object.entries(content.platform_images).map(([platform, image]) => (
                  <div key={platform} className="border rounded-lg p-4">
                    <h4 className="text-sm font-medium text-gray-900 mb-2">{platform}</h4>
                    {image.error ? (
                      <p className="text-sm text-red-600">{image.error}</p>
                    ) : (
                      <div>
                        <img
                          src={image.local_image_url}
                          alt={`${platform} content`}
                          className="w-full h-auto rounded-lg"
                        />
                        <div className="mt-2 flex justify-end">
                          <a
                            href={image.download_url}
                            download
                            className="text-sm text-blue-600 hover:text-blue-800"
                          >
                            Download Image
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Metadata */}
          {content.metadata && (
            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-2">Metadata</h3>
              <div className="bg-gray-50 rounded-lg p-4">
                <dl className="grid grid-cols-2 gap-x-4 gap-y-2 text-sm">
                  <div className="text-gray-500">Source</div>
                  <div className="text-gray-900">{content.metadata.source}</div>
                  <div className="text-gray-500">Version</div>
                  <div className="text-gray-900">{content.metadata.version}</div>
                  <div className="text-gray-500">Generated At</div>
                  <div className="text-gray-900">
                    {new Date(content.metadata.generated_at).toLocaleString()}
                  </div>
                  {content.metadata.platform_specific && Object.entries(content.metadata.platform_specific).map(([platform, data]) => (
                    <React.Fragment key={platform}>
                      <div className="col-span-2 mt-2">
                        <h4 className="font-medium text-gray-700">{platform} Details</h4>
                      </div>
                      <div className="text-gray-500">Platform ID</div>
                      <div className="text-gray-900">{data.platform_id}</div>
                      <div className="text-gray-500">Content Type</div>
                      <div className="text-gray-900">{data.content_type}</div>
                    </React.Fragment>
                  ))}
                </dl>
              </div>
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="border-t border-gray-200 px-6 py-4">
          <button
            onClick={onClose}
            className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContentPreviewModal;
