import { useEffect, useCallback } from 'react';

export const useUnsavedChangesWarning = (
  hasUnsavedChanges: boolean,
  message = 'You have unsaved changes. Are you sure you want to leave?'
): void => {
  const handleWindowClose = useCallback((e: BeforeUnloadEvent) => {
    if (!hasUnsavedChanges) return;
    e.preventDefault();
    e.returnValue = message;
    return message;
  }, [hasUnsavedChanges, message]);

  const handlePopState = useCallback((e: PopStateEvent) => {
    if (!hasUnsavedChanges) return;
    if (window.confirm(message)) {
      return;
    }
    if (e.state) {
      window.history.pushState(e.state, '');
    }
    e.preventDefault();
  }, [hasUnsavedChanges, message]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowClose);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [handleWindowClose, handlePopState]);
};
