import React from 'react';
import { ServiceFormData } from '../../types/businessProfile';
import FormField from '../common/FormField';
import FormActions from '../common/FormActions';
import Input from '../common/Input';
import TextArea from '../common/TextArea';
import Card from '../common/Card';
import AlertBanner from '../common/AlertBanner';
import { useForm } from '../../hooks/useForm';
import { required, minLength, maxLength, validateNumeric, ValidationRule } from '../../utils/validation';

interface ServiceFormProps {
  onSubmit: (service: ServiceFormData) => void;
  onCancel: () => void;
}

const ServiceForm: React.FC<ServiceFormProps> = ({ 
  onSubmit, 
  onCancel 
}): React.ReactElement => {
  const optionalPriceValidation: ValidationRule<string | undefined> = {
    validate: (value: string | undefined) => !value || validateNumeric(value),
    message: 'Must be a number'
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    formError,
    handleChange,
    handleBlur,
    setFormError,
    validateForm,
  } = useForm<ServiceFormData>(
    {
      name: {
        initialValue: '',
        validate: [
          required,
          minLength(2),
          maxLength(100),
        ],
      },
      description: {
        initialValue: '',
        validate: [
          maxLength(500),
        ],
      },
      retail_price: {
        initialValue: '',
        validate: [optionalPriceValidation],
      },
      sale_price: {
        initialValue: '',
        validate: [optionalPriceValidation],
      },
      special_price: {
        initialValue: '',
        validate: [optionalPriceValidation],
      },
      campaign_price: {
        initialValue: '',
        validate: [optionalPriceValidation],
      },
      show_retail_price: {
        initialValue: false,
      },
      show_sale_price: {
        initialValue: false,
      },
      show_special_price: {
        initialValue: false,
      },
      show_campaign_price: {
        initialValue: false,
      },
      is_active: {
        initialValue: true, // Default to active
      },
    },
    {
      persistenceKey: 'new-service-form',
      persistenceEnabled: true,
      warnOnUnsavedChanges: true,
      unsavedChangesMessage: 'You have unsaved changes to this service. Are you sure you want to leave?',
    }
  );

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    try {
      await onSubmit({
        name: values.name,
        description: values.description,
        retail_price: values.retail_price,
        sale_price: values.sale_price,
        special_price: values.special_price,
        campaign_price: values.campaign_price,
        show_retail_price: values.show_retail_price,
        show_sale_price: values.show_sale_price,
        show_special_price: values.show_special_price,
        show_campaign_price: values.show_campaign_price,
        is_active: values.is_active,
      });
    } catch (err) {
      setFormError('Failed to add service. Please try again.');
    }
  };

  const handlePriceChange = (name: string, value: string) => {
    console.log(`Price change - ${name}:`, value);
    handleChange(name as keyof ServiceFormData, value);
  };

  const handleCheckboxChange = (name: string, checked: boolean) => {
    console.log(`Checkbox change - ${name}:`, checked);
    handleChange(name as keyof ServiceFormData, checked);
  };

  return (
    <Card>
      <form onSubmit={handleSubmit} className="space-y-4">
        {formError && (
          <AlertBanner
            type="error"
            message={formError}
            onDismiss={() => setFormError(null)}
          />
        )}

        <FormField
          label="Service Name"
          id="name"
          required
          error={touched.name ? errors.name : undefined}
        >
          <Input
            id="name"
            name="name"
            value={values.name}
            onChange={(e) => handleChange('name', e.target.value)}
            onBlur={() => handleBlur('name')}
            required
            placeholder="Enter service name"
            autoFocus
            error={touched.name && !!errors.name}
          />
        </FormField>

        <FormField
          label="Description"
          id="description"
          error={touched.description ? errors.description : undefined}
        >
          <TextArea
            id="description"
            name="description"
            value={values.description}
            onChange={(e) => handleChange('description', e.target.value)}
            onBlur={() => handleBlur('description')}
            placeholder="Enter service description"
          />
        </FormField>

        <div className="border-t pt-4 mt-4">
          <h3 className="text-lg font-medium mb-4">Pricing Information</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Retail Price */}
            <div className="flex flex-col space-y-2">
              <FormField
                label="Retail Price"
                id="retail_price"
                error={touched.retail_price ? errors.retail_price : undefined}
              >
                <div className="flex items-center space-x-4">
                  <Input
                    id="retail_price"
                    name="retail_price"
                    type="number"
                    step="0.01"
                    value={values.retail_price}
                    onChange={(e) => handlePriceChange('retail_price', e.target.value)}
                    onBlur={() => handleBlur('retail_price')}
                    placeholder="0.00"
                    className="flex-1"
                  />
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={values.show_retail_price}
                      onChange={(e) => handleCheckboxChange('show_retail_price', e.target.checked)}
                      className="form-checkbox h-4 w-4 text-blue-600"
                    />
                    <span className="text-sm text-gray-600">Show in content</span>
                  </label>
                </div>
              </FormField>
            </div>

            {/* Sale Price */}
            <div className="flex flex-col space-y-2">
              <FormField
                label="Sale Price"
                id="sale_price"
                error={touched.sale_price ? errors.sale_price : undefined}
              >
                <div className="flex items-center space-x-4">
                  <Input
                    id="sale_price"
                    name="sale_price"
                    type="number"
                    step="0.01"
                    value={values.sale_price}
                    onChange={(e) => handlePriceChange('sale_price', e.target.value)}
                    onBlur={() => handleBlur('sale_price')}
                    placeholder="0.00"
                    className="flex-1"
                  />
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={values.show_sale_price}
                      onChange={(e) => handleCheckboxChange('show_sale_price', e.target.checked)}
                      className="form-checkbox h-4 w-4 text-blue-600"
                    />
                    <span className="text-sm text-gray-600">Show in content</span>
                  </label>
                </div>
              </FormField>
            </div>

            {/* Special Price */}
            <div className="flex flex-col space-y-2">
              <FormField
                label="Special Price"
                id="special_price"
                error={touched.special_price ? errors.special_price : undefined}
              >
                <div className="flex items-center space-x-4">
                  <Input
                    id="special_price"
                    name="special_price"
                    type="number"
                    step="0.01"
                    value={values.special_price}
                    onChange={(e) => handlePriceChange('special_price', e.target.value)}
                    onBlur={() => handleBlur('special_price')}
                    placeholder="0.00"
                    className="flex-1"
                  />
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={values.show_special_price}
                      onChange={(e) => handleCheckboxChange('show_special_price', e.target.checked)}
                      className="form-checkbox h-4 w-4 text-blue-600"
                    />
                    <span className="text-sm text-gray-600">Show in content</span>
                  </label>
                </div>
              </FormField>
            </div>

            {/* Campaign Price */}
            <div className="flex flex-col space-y-2">
              <FormField
                label="Campaign Price"
                id="campaign_price"
                error={touched.campaign_price ? errors.campaign_price : undefined}
              >
                <div className="flex items-center space-x-4">
                  <Input
                    id="campaign_price"
                    name="campaign_price"
                    type="number"
                    step="0.01"
                    value={values.campaign_price}
                    onChange={(e) => handlePriceChange('campaign_price', e.target.value)}
                    onBlur={() => handleBlur('campaign_price')}
                    placeholder="0.00"
                    className="flex-1"
                  />
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={values.show_campaign_price}
                      onChange={(e) => handleCheckboxChange('show_campaign_price', e.target.checked)}
                      className="form-checkbox h-4 w-4 text-blue-600"
                    />
                    <span className="text-sm text-gray-600">Show in content</span>
                  </label>
                </div>
              </FormField>
            </div>
          </div>
        </div>

        <FormField
          label="Status"
          id="is_active"
        >
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={values.is_active}
              onChange={(e) => handleChange('is_active', e.target.checked)}
              className="form-checkbox h-4 w-4 text-blue-600"
            />
            <span className="text-sm text-gray-600">Active</span>
          </label>
        </FormField>

        <FormActions
          onCancel={onCancel}
          submitLabel="Add Service"
          cancelLabel="Cancel"
          isSubmitting={isSubmitting}
        />
      </form>
    </Card>
  );
};

export default ServiceForm;
