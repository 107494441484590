import React, { useEffect, useState } from 'react';
import { useBusinessProfile } from '../hooks/useBusinessProfile';
import BusinessProfileForm from '../components/business-profile/BusinessProfileForm';
import BusinessProfileView from '../components/business-profile/BusinessProfileView';
import ProductList from '../components/business-profile/ProductList';
import ServiceList from '../components/business-profile/ServiceList';
import AccountSection from '../components/business-profile/AccountSection';
import CreateProfilePrompt from '../components/business-profile/CreateProfilePrompt';

const LoadingSpinner: React.FC = () => (
  <div className="flex items-center justify-center min-h-[60vh]">
    <div className="text-center animate-fade-in">
      <div className="animate-spin rounded-full h-16 w-16 border-4 border-blue-600 border-t-transparent mx-auto"></div>
      <p className="mt-6 text-xl font-medium text-gray-600">Loading your profile...</p>
    </div>
  </div>
);

const BusinessProfilePage: React.FC = () => {
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const {
    profile,
    products,
    services,
    isEditing,
    isAddingProduct,
    isAddingService,
    isUploadingCSV,
    editingProduct,
    editingService,
    isLoading,
    error,
    successMessage,
    isProductsOpen,
    isServicesOpen,
    setIsEditing,
    setIsProductsOpen,
    setIsServicesOpen,
    setIsAddingProduct,
    setIsAddingService,
    setIsUploadingCSV,
    setEditingProduct,
    setEditingService,
    fetchBusinessProfile,
    refreshProduct,
    handleSubmit,
    handleAddProduct,
    handleAddService,
    handleUpdateProduct,
    handleUpdateService,
    handleDeleteProduct,
    handleDeleteService,
    handleCSVUpload,
    handleDeleteProfile,
  } = useBusinessProfile();

  useEffect(() => {
    fetchBusinessProfile();
  }, [fetchBusinessProfile]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="relative px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-12 animate-fade-in">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Profile</h1>
        <p className="text-xl text-gray-600">Manage your information, products, and services</p>
      </div>

      {error && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-8 rounded-lg animate-fade-in" role="alert">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}

      {successMessage && (
        <div className="bg-green-50 border-l-4 border-green-500 p-4 mb-8 rounded-lg animate-fade-in" role="alert">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-green-700">{successMessage}</p>
            </div>
          </div>
        </div>
      )}

      <div className="max-w-7xl mx-auto">
        {!profile && !isEditing ? (
          <div className="bg-white rounded-2xl shadow-xl p-8 animate-fade-in">
            <CreateProfilePrompt onCreateClick={() => setIsEditing(true)} />
          </div>
        ) : isEditing ? (
          <div className="bg-white rounded-2xl shadow-xl p-8 animate-fade-in">
            <BusinessProfileForm
              profile={profile}
              onSubmit={handleSubmit}
              onCancel={() => {
                setIsEditing(false);
                fetchBusinessProfile();
              }}
            />
          </div>
        ) : profile ? (
          <div className="space-y-8 animate-fade-in">
            <div className="bg-white rounded-2xl shadow-xl p-8">
              <BusinessProfileView
                profile={profile}
                onEdit={() => setIsEditing(true)}
                onDelete={handleDeleteProfile}
                isOpen={isProfileOpen}
                onToggle={() => setIsProfileOpen(!isProfileOpen)}
              />
            </div>

            <div className="bg-white rounded-2xl shadow-xl p-8">
              <ProductList
                products={products}
                isOpen={isProductsOpen}
                isAdding={isAddingProduct}
                isUploadingCSV={isUploadingCSV}
                editingProduct={editingProduct}
                onToggle={() => setIsProductsOpen(!isProductsOpen)}
                onAdd={() => {
                  setIsAddingProduct(true);
                  setIsProductsOpen(true);
                }}
                onUploadCSV={() => {
                  setIsUploadingCSV(true);
                  setIsProductsOpen(true);
                }}
                onAddSubmit={handleAddProduct}
                onAddCancel={() => setIsAddingProduct(false)}
                onCSVUpload={handleCSVUpload}
                onCSVCancel={() => setIsUploadingCSV(false)}
                onEdit={setEditingProduct}
                onEditSubmit={handleUpdateProduct}
                onEditCancel={() => setEditingProduct(null)}
                onDelete={handleDeleteProduct}
                refreshProduct={refreshProduct}
              />
            </div>

            <div className="bg-white rounded-2xl shadow-xl p-8">
              <ServiceList
                services={services}
                isOpen={isServicesOpen}
                isAdding={isAddingService}
                isUploadingCSV={isUploadingCSV}
                editingService={editingService}
                onToggle={() => setIsServicesOpen(!isServicesOpen)}
                onAdd={() => {
                  setIsAddingService(true);
                  setIsServicesOpen(true);
                }}
                onUploadCSV={() => {
                  setIsUploadingCSV(true);
                  setIsServicesOpen(true);
                }}
                onAddSubmit={handleAddService}
                onAddCancel={() => setIsAddingService(false)}
                onCSVUpload={handleCSVUpload}
                onCSVCancel={() => setIsUploadingCSV(false)}
                onEdit={setEditingService}
                onEditSubmit={handleUpdateService}
                onEditCancel={() => setEditingService(null)}
                onDelete={handleDeleteService}
              />
            </div>

            <div className="bg-white rounded-2xl shadow-xl p-8">
              <AccountSection 
                isOpen={isAccountOpen}
                onToggle={() => setIsAccountOpen(!isAccountOpen)}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default BusinessProfilePage;
