import React, { forwardRef } from 'react';

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
  fullWidth?: boolean;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(({
  error,
  fullWidth = true,
  className = '',
  rows = 3,
  ...props
}, ref) => {
  const baseClasses = 'rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50';
  const widthClasses = fullWidth ? 'w-full' : '';
  const errorClasses = error ? 'border-red-300' : 'border-gray-300';
  const classes = `${baseClasses} ${widthClasses} ${errorClasses} ${className}`;

  return (
    <textarea
      ref={ref}
      rows={rows}
      className={classes}
      {...props}
    />
  );
});

TextArea.displayName = 'TextArea';

export default TextArea;
