import { useCallback } from 'react';

interface UploadState {
  fileId: string;
  fileName: string;
  fileSize: number;
  uploadedChunks: number[];
  lastUpdated: number;
}

const STORAGE_PREFIX = 'upload_state_';
const STORAGE_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours

export const useUploadStorage = () => {
  const getStorageKey = useCallback((fileId: string) => {
    return `${STORAGE_PREFIX}${fileId}`;
  }, []);

  const saveUploadState = useCallback((
    fileId: string,
    fileName: string,
    fileSize: number,
    uploadedChunks: number[]
  ) => {
    const state: UploadState = {
      fileId,
      fileName,
      fileSize,
      uploadedChunks,
      lastUpdated: Date.now(),
    };
    localStorage.setItem(getStorageKey(fileId), JSON.stringify(state));
  }, [getStorageKey]);

  const getUploadState = useCallback((fileId: string): UploadState | null => {
    const stateJson = localStorage.getItem(getStorageKey(fileId));
    if (!stateJson) return null;

    try {
      const state = JSON.parse(stateJson) as UploadState;
      const now = Date.now();

      // Check if state is expired
      if (now - state.lastUpdated > STORAGE_EXPIRY) {
        localStorage.removeItem(getStorageKey(fileId));
        return null;
      }

      return state;
    } catch {
      localStorage.removeItem(getStorageKey(fileId));
      return null;
    }
  }, [getStorageKey]);

  const updateUploadedChunk = useCallback((
    fileId: string,
    chunkIndex: number
  ) => {
    const state = getUploadState(fileId);
    if (!state) return;

    const uploadedChunks = [...state.uploadedChunks, chunkIndex];
    saveUploadState(fileId, state.fileName, state.fileSize, uploadedChunks);
  }, [getUploadState, saveUploadState]);

  const clearUploadState = useCallback((fileId: string) => {
    localStorage.removeItem(getStorageKey(fileId));
  }, [getStorageKey]);

  const getResumableUploads = useCallback((): UploadState[] => {
    const resumableUploads: UploadState[] = [];
    const now = Date.now();

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (!key?.startsWith(STORAGE_PREFIX)) continue;

      try {
        const stateJson = localStorage.getItem(key);
        if (!stateJson) continue;

        const state = JSON.parse(stateJson) as UploadState;
        if (now - state.lastUpdated <= STORAGE_EXPIRY) {
          resumableUploads.push(state);
        } else {
          localStorage.removeItem(key);
        }
      } catch {
        localStorage.removeItem(key);
      }
    }

    return resumableUploads;
  }, []);

  const cleanupExpiredUploads = useCallback(() => {
    const now = Date.now();

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (!key?.startsWith(STORAGE_PREFIX)) continue;

      try {
        const stateJson = localStorage.getItem(key);
        if (!stateJson) continue;

        const state = JSON.parse(stateJson) as UploadState;
        if (now - state.lastUpdated > STORAGE_EXPIRY) {
          localStorage.removeItem(key);
        }
      } catch {
        localStorage.removeItem(key);
      }
    }
  }, []);

  return {
    saveUploadState,
    getUploadState,
    updateUploadedChunk,
    clearUploadState,
    getResumableUploads,
    cleanupExpiredUploads,
  };
};
