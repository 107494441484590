import React, { useState } from 'react';
import { ResearchFormProps } from '../../types/research';
import ResearchTopics from './ResearchTopics';

const ResearchForm: React.FC<ResearchFormProps> = ({ onSubmit, isLoading }) => {
  const [query, setQuery] = useState('');
  const [includeProducts, setIncludeProducts] = useState(true);
  const [includeServices, setIncludeServices] = useState(true);
  const [includeCampaigns, setIncludeCampaigns] = useState(true);
  const [inputMethod, setInputMethod] = useState<'custom' | 'topics'>('custom');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!query.trim()) return;
    
    await onSubmit({
      query,
      include_products: includeProducts,
      include_services: includeServices,
      include_campaigns: includeCampaigns,
    });
  };

  const handleTopicSelect = (selectedQuestion: string) => {
    // Only update the query, don't submit the form
    setQuery(selectedQuestion);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-4">Generate Marketing Research Report</h2>
      
      <div className="mb-4">
        <div className="flex rounded-lg bg-gray-100 p-1">
          <button
            type="button" // Explicitly set type to prevent form submission
            onClick={() => setInputMethod('custom')}
            className={`flex-1 py-2 px-4 rounded-md text-sm font-medium ${
              inputMethod === 'custom'
                ? 'bg-white shadow-sm text-gray-900'
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            Custom Query
          </button>
          <button
            type="button" // Explicitly set type to prevent form submission
            onClick={() => setInputMethod('topics')}
            className={`flex-1 py-2 px-4 rounded-md text-sm font-medium ${
              inputMethod === 'topics'
                ? 'bg-white shadow-sm text-gray-900'
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            Choose Topic
          </button>
        </div>
      </div>

      {/* Move topic selection outside the form */}
      {inputMethod === 'topics' && (
        <div className="mb-4">
          <ResearchTopics onSelectTopic={handleTopicSelect} maxHeight={240} />
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        {inputMethod === 'custom' && (
          <div className="flex-grow">
            <label htmlFor="query" className="block text-sm font-medium text-gray-700 mb-2">
              Research Query
            </label>
            <textarea
              id="query"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              rows={4}
              placeholder="Enter your research question or topic (e.g., 'What new services should I consider offering?' or 'What market trends should I be aware of?')"
              required
            />
          </div>
        )}

        {query && (
          <div className="bg-blue-50 border-l-4 border-blue-500 p-4 rounded-lg">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-blue-700">Selected Topic: {query}</p>
              </div>
            </div>
          </div>
        )}

        <div className="space-y-3">
          <h3 className="text-lg font-medium text-gray-900">Include in Research Context:</h3>
          
          <div className="flex items-center">
            <input
              type="checkbox"
              id="includeProducts"
              checked={includeProducts}
              onChange={(e) => setIncludeProducts(e.target.checked)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="includeProducts" className="ml-2 block text-sm text-gray-700">
              Products
            </label>
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              id="includeServices"
              checked={includeServices}
              onChange={(e) => setIncludeServices(e.target.checked)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="includeServices" className="ml-2 block text-sm text-gray-700">
              Services
            </label>
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              id="includeCampaigns"
              checked={includeCampaigns}
              onChange={(e) => setIncludeCampaigns(e.target.checked)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="includeCampaigns" className="ml-2 block text-sm text-gray-700">
              Campaign Data
            </label>
          </div>
        </div>

        <div>
          <button
            type="submit"
            disabled={isLoading || !query.trim()}
            className={`w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white 
              ${isLoading || !query.trim() 
                ? 'bg-gray-400 cursor-not-allowed' 
                : 'bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700'
              }`}
          >
            {isLoading ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Generating Report...
              </>
            ) : (
              'Generate Marketing Research Report'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResearchForm;
