import { useState, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { BusinessProfile } from '../types/business';
import { Product, Service } from '../types/products';
import { ProductFormData, ServiceFormData } from '../types/businessProfile';
import { SUCCESS_MESSAGES, ERROR_MESSAGES, CONFIRMATION_MESSAGES } from '../constants/businessProfile';
import { businessService } from '../services/business';

export const useBusinessProfile = () => {
  const { 
    user,
    getBusinessProfile, 
    createBusinessProfile, 
    updateBusinessProfile, 
    deleteBusinessProfile, 
    getProducts, 
    getServices, 
    createProduct, 
    createService, 
    deleteProduct, 
    deleteService, 
    uploadCSV, 
    updateProduct, 
    updateService 
  } = useAuth();

  const [profile, setProfile] = useState<BusinessProfile | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [isAddingService, setIsAddingService] = useState(false);
  const [isUploadingCSV, setIsUploadingCSV] = useState(false);
  const [editingProduct, setEditingProduct] = useState<Product | null>(null);
  const [editingService, setEditingService] = useState<Service | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);

  const fetchBusinessProfile = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Use businessService directly to get the full profile
      const profileData = await businessService.getBusinessProfile();
      console.log('Fetched profile data:', profileData); // Debug log
      setProfile(profileData);
      
      const productsData = await getProducts();
      console.log('Fetched products data:', productsData); // Debug log
      setProducts(productsData as Product[]);
      
      const servicesData = await getServices();
      console.log('Fetched services data:', servicesData); // Debug log
      setServices(servicesData as Service[]);
    } catch (err) {
      console.error('Error fetching business profile:', err);
      setError(ERROR_MESSAGES.FETCH_PROFILE);
    } finally {
      setIsLoading(false);
    }
  }, [getProducts, getServices]);

  const refreshProduct = async (productId: number) => {
    try {
      const updatedProduct = await businessService.getProduct(productId);
      console.log('Refreshed product:', updatedProduct);

      setProducts(currentProducts => 
        currentProducts.map(p => 
          p.id === productId ? { ...updatedProduct, key: Date.now() } : p
        )
      );

      return updatedProduct;
    } catch (err) {
      console.error('Error refreshing product:', err);
      await fetchBusinessProfile();
      throw err;
    }
  };

  const handleSubmit = async (profileData: BusinessProfile) => {
    setIsLoading(true);
    setError(null);
    try {
      if (!user?.id) {
        throw new Error('User information not available');
      }

      const dataWithUser = {
        ...profileData,
        user: user.id
      };

      console.log('Submitting profile data:', dataWithUser); // Debug log

      if (profile) {
        await updateBusinessProfile(dataWithUser);
      } else {
        await createBusinessProfile(dataWithUser);
      }
      setIsEditing(false);
      setSuccessMessage(profile ? SUCCESS_MESSAGES.PROFILE_UPDATED : SUCCESS_MESSAGES.PROFILE_CREATED);
      await fetchBusinessProfile();
    } catch (err: any) {
      console.error('Error saving business profile:', err);
      setError(err.response?.data?.error || ERROR_MESSAGES.SAVE_PROFILE);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddProduct = async (product: ProductFormData) => {
    try {
      console.log('Adding product with data:', product);
      const newProduct = await createProduct(product);
      console.log('Created product:', newProduct);
      await refreshProduct(newProduct.id);
      setIsAddingProduct(false);
      setSuccessMessage(SUCCESS_MESSAGES.PRODUCT_ADDED);
    } catch (err) {
      setError(ERROR_MESSAGES.ADD_PRODUCT);
      console.error('Error adding product:', err);
    }
  };

  const handleAddService = async (service: ServiceFormData) => {
    try {
      const newService = await createService(service);
      setServices(prevServices => [...prevServices, newService as Service]);
      setIsAddingService(false);
      setSuccessMessage(SUCCESS_MESSAGES.SERVICE_ADDED);
    } catch (err) {
      setError(ERROR_MESSAGES.ADD_SERVICE);
      console.error('Error adding service:', err);
    }
  };

  const handleUpdateProduct = async (id: number, productData: ProductFormData) => {
    try {
      console.log('Updating product with data:', productData);
      const updatedProduct = await updateProduct(id, productData);
      console.log('Updated product response:', updatedProduct);
      await refreshProduct(id);
      setEditingProduct(null);
      setSuccessMessage(SUCCESS_MESSAGES.PRODUCT_UPDATED);
    } catch (err) {
      setError(ERROR_MESSAGES.UPDATE_PRODUCT);
      console.error('Error updating product:', err);
    }
  };

  const handleUpdateService = async (id: number, serviceData: ServiceFormData) => {
    try {
      const updatedService = await updateService(id, serviceData);
      setServices(prevServices => 
        prevServices.map(s => s.id === id ? (updatedService as Service) : s)
      );
      setEditingService(null);
      setSuccessMessage(SUCCESS_MESSAGES.SERVICE_UPDATED);
    } catch (err) {
      setError(ERROR_MESSAGES.UPDATE_SERVICE);
      console.error('Error updating service:', err);
    }
  };

  const handleDeleteProduct = async (productId: number) => {
    if (window.confirm(CONFIRMATION_MESSAGES.DELETE_PRODUCT)) {
      try {
        await deleteProduct(productId);
        setProducts(prevProducts => prevProducts.filter(p => p.id !== productId));
        setSuccessMessage(SUCCESS_MESSAGES.PRODUCT_DELETED);
      } catch (err) {
        setError(ERROR_MESSAGES.DELETE_PRODUCT);
        console.error('Error deleting product:', err);
      }
    }
  };

  const handleDeleteService = async (serviceId: number) => {
    if (window.confirm(CONFIRMATION_MESSAGES.DELETE_SERVICE)) {
      try {
        await deleteService(serviceId);
        setServices(prevServices => prevServices.filter(s => s.id !== serviceId));
        setSuccessMessage(SUCCESS_MESSAGES.SERVICE_DELETED);
      } catch (err) {
        setError(ERROR_MESSAGES.DELETE_SERVICE);
        console.error('Error deleting service:', err);
      }
    }
  };

  const handleCSVUpload = async (file: File) => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await uploadCSV(file);
      if (result && result.products && result.services) {
        setProducts(result.products as Product[]);
        setServices(result.services as Service[]);
        setIsUploadingCSV(false);
        setSuccessMessage(SUCCESS_MESSAGES.CSV_UPLOADED);
      } else {
        throw new Error(ERROR_MESSAGES.INVALID_RESPONSE);
      }
    } catch (err) {
      setError(ERROR_MESSAGES.UPLOAD_CSV);
      console.error('Error uploading CSV file:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteProfile = async () => {
    if (window.confirm(CONFIRMATION_MESSAGES.DELETE_PROFILE)) {
      setIsLoading(true);
      setError(null);
      try {
        await deleteBusinessProfile();
        setProfile(null);
        setIsEditing(false);
        setSuccessMessage(SUCCESS_MESSAGES.PROFILE_DELETED);
        await fetchBusinessProfile();
      } catch (err) {
        setError(ERROR_MESSAGES.DELETE_PROFILE);
        console.error('Error deleting business profile:', err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return {
    profile,
    products,
    services,
    isEditing,
    isAddingProduct,
    isAddingService,
    isUploadingCSV,
    editingProduct,
    editingService,
    isLoading,
    error,
    successMessage,
    isProductsOpen,
    isServicesOpen,
    setIsEditing,
    setIsAddingProduct,
    setIsAddingService,
    setIsUploadingCSV,
    setEditingProduct,
    setEditingService,
    setIsProductsOpen,
    setIsServicesOpen,
    fetchBusinessProfile,
    refreshProduct,
    handleSubmit,
    handleAddProduct,
    handleAddService,
    handleUpdateProduct,
    handleUpdateService,
    handleDeleteProduct,
    handleDeleteService,
    handleCSVUpload,
    handleDeleteProfile,
  };
};
