import React from 'react';
import { Service } from '../../types/business';
import { ServiceListProps } from '../../types/businessProfile';
import CollapsibleCard from './CollapsibleCard';
import ServiceForm from './ServiceForm';
import EditServiceForm from './EditServiceForm';
import CSVUploadForm from './CSVUploadForm';
import Button from '../common/Button';

const formatPrice = (price: number | undefined) => {
  if (price === undefined) return '';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(price);
};

const ServiceList: React.FC<ServiceListProps> = ({
  services,
  isOpen,
  isAdding,
  isUploadingCSV,
  editingService,
  onToggle,
  onAdd,
  onUploadCSV,
  onAddSubmit,
  onAddCancel,
  onCSVUpload,
  onCSVCancel,
  onEdit,
  onEditSubmit,
  onEditCancel,
  onDelete,
}) => {
  return (
    <CollapsibleCard
      title="Services"
      isOpen={isOpen}
      onToggle={onToggle}
      actionButtons={
        <div className="flex space-x-2">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onAdd();
            }}
            variant="success"
            size="sm"
          >
            Add Service
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onUploadCSV();
            }}
            variant="secondary"
            size="sm"
          >
            Upload CSV
          </Button>
        </div>
      }
    >
      {isAdding && (
        <div className="mb-6">
          <ServiceForm onSubmit={onAddSubmit} onCancel={onAddCancel} />
        </div>
      )}
      {isUploadingCSV && (
        <div className="mb-6">
          <CSVUploadForm onUpload={onCSVUpload} onCancel={onCSVCancel} />
        </div>
      )}
      {services && services.length > 0 ? (
        <div className="space-y-4">
          {services.map(service => (
            <div key={service.id}>
              {editingService?.id === service.id ? (
                <EditServiceForm
                  service={service}
                  onSubmit={onEditSubmit}
                  onCancel={onEditCancel}
                />
              ) : (
                <div className="flex justify-between p-4 bg-gray-50 rounded-lg">
                  <div className="flex-grow">
                    <p className="font-semibold text-gray-800">{service.name}</p>
                    <p className="text-sm text-gray-600 mb-2">{service.description}</p>
                    {/* Service Prices */}
                    {service.pricing && (
                      <div className="space-y-1">
                        {service.pricing.show_retail_price && service.pricing.retail_price && (
                          <p className="text-sm">
                            <span className="font-medium">Retail:</span> {formatPrice(service.pricing.retail_price)}
                          </p>
                        )}
                        {service.pricing.show_sale_price && service.pricing.sale_price && (
                          <p className="text-sm text-green-600">
                            <span className="font-medium">Sale:</span> {formatPrice(service.pricing.sale_price)}
                          </p>
                        )}
                        {service.pricing.show_special_price && service.pricing.special_price && (
                          <p className="text-sm text-blue-600">
                            <span className="font-medium">Special:</span> {formatPrice(service.pricing.special_price)}
                          </p>
                        )}
                        {service.pricing.show_campaign_price && service.pricing.campaign_price && (
                          <p className="text-sm text-purple-600">
                            <span className="font-medium">Campaign:</span> {formatPrice(service.pricing.campaign_price)}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="flex space-x-2 ml-4">
                    <Button
                      onClick={() => onEdit(service)}
                      variant="primary"
                      size="sm"
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => onDelete(service.id)}
                      variant="danger"
                      size="sm"
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500 text-center py-4">
          No services available. Add your first service or upload a CSV file.
        </p>
      )}
    </CollapsibleCard>
  );
};

export default ServiceList;
