import React, { useEffect, useState, useCallback } from 'react';
import { CampaignSelector } from './components/CampaignSelector';
import { PlatformSelector } from './components/PlatformSelector';
import { ContentForm } from './components/ContentForm';
import { StateLogger } from './components/StateLogger';
import { ContentPreview } from './components/ContentPreview';
import { ContentReview } from './components/ContentReview';
import { ProductImagePreview } from './components/ProductImagePreview';
import { useContentForm } from './hooks/useContentForm';
import { useStateTracker } from './hooks/useStateTracker';
import { 
  Campaign, 
  Platform, 
  GeneratedContent, 
  PlatformImages, 
  CampaignDetails, 
  ContentRequirements, 
  BusinessProfile, 
  ContentMetadata, 
  ProductImageSelection, 
  SelectedProductWithImage,
  VideoScripts,
  PlatformDescriptions
} from './types';
import { simplifiedContentApi } from './api/contentApi';
import axios, { AxiosError } from 'axios';

const initialRequirements: ContentRequirements = {
  objective: '',
  tone: '',
  targetAudience: '',
  topic: '',
  selectedProducts: [],
  selectedServices: [],
  generateImages: true,
  videoScript: {} as VideoScripts,
  platformDescription: {} as PlatformDescriptions
};

const createMetadata = (contentType: 'campaign' | 'quick', state: any): ContentMetadata => {
  const metadata: ContentMetadata = {
    source: 'simplified_content',
    version: '2.0',
    generated_at: new Date().toISOString(),
    platform_specific: {}
  };

  state.platforms.forEach((platform: Platform) => {
    if (metadata.platform_specific) {
      metadata.platform_specific[platform.name] = {
        platform_id: platform.id,
        platform_name: platform.name,
        content_type: contentType,
        video_script: state.requirements.videoScript?.[platform.name],
        platform_description: state.requirements.platformDescription?.[platform.name]
      };
    }
  });

  return metadata;
};

export const SimplifiedContentPage: React.FC = () => {
  const {
    state,
    setCampaign,
    setPlatforms,
    setRequirements,
    setBusinessProfile
  } = useContentForm();

  const { stateHistory } = useStateTracker();

  const [availablePlatforms, setAvailablePlatforms] = useState<Platform[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [generatedContent, setGeneratedContent] = useState<GeneratedContent | null>(null);
  const [platformImages, setPlatformImages] = useState<PlatformImages | null>(null);
  const [savedToHistory, setSavedToHistory] = useState(false);
  const [generatingImages, setGeneratingImages] = useState(false);
  const [selectedPlatformCount, setSelectedPlatformCount] = useState(0);
  const [completedImageCount, setCompletedImageCount] = useState(0);
  const [campaignDetails, setCampaignDetails] = useState<CampaignDetails | null>(null);
  const [isContentComplete, setIsContentComplete] = useState(false);

  const handleError = useCallback((error: unknown) => {
    console.error('API Error:', error);
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<{ detail?: string; error?: string }>;
      if (axiosError.response?.status === 403) {
        setError('Authentication error. Please refresh the page and try again.');
      } else if (axiosError.response?.data?.detail) {
        setError(axiosError.response.data.detail);
      } else if (axiosError.response?.data?.error) {
        setError(axiosError.response.data.error);
      } else if (axiosError.message.includes('Network Error')) {
        setError('Network error. Please check your internet connection and try again.');
      } else {
        setError(axiosError.message);
      }
    } else if (error instanceof Error) {
      if (error.message.includes('Missing content for platform')) {
        setError('Failed to generate content for some platforms. Please try again.');
      } else {
        setError(error.message);
      }
    } else {
      setError('An unexpected error occurred. Please try again.');
    }
  }, []);

  const handleReset = useCallback(() => {
    setCampaign(null);
    setCampaignDetails(null);
    setPlatforms([]);
    setRequirements({
      ...initialRequirements
    });
    setGeneratedContent(null);
    setPlatformImages(null);
    setError(null);
    setSavedToHistory(false);
    setGeneratingImages(false);
    setCompletedImageCount(0);
    setSelectedPlatformCount(0);
    setIsContentComplete(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [setCampaign, setPlatforms, setRequirements]);

  useEffect(() => {
    setSelectedPlatformCount(state.platforms.length);
    setCompletedImageCount(0);
  }, [state.platforms]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const platformsData = await simplifiedContentApi.getPlatforms();
        setAvailablePlatforms(platformsData);

        // Fetch business profile for content generation
        const profileData = await simplifiedContentApi.getBusinessProfile();
        setBusinessProfile(profileData);

      } catch (err) {
        handleError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, [handleError, setBusinessProfile]);

  const handleCampaignSelect = useCallback(async (campaign: Campaign | null) => {
    console.log('Campaign selected:', campaign);
    setCampaign(campaign);
    
    if (campaign) {
      try {
        setIsLoading(true);
        setError(null);
        
        const details = await simplifiedContentApi.getCampaignDetails(campaign.id);
        console.log('Campaign details:', details);
        setCampaignDetails(details);
        
        const newRequirements: ContentRequirements = {
          ...state.requirements,
          objective: details.form_values.objective,
          targetAudience: details.form_values.targetAudience,
          selectedProducts: state.requirements.selectedProducts || [],
          selectedServices: state.requirements.selectedServices || [],
          generateImages: state.requirements.generateImages !== undefined ? state.requirements.generateImages : true,
          videoScript: state.requirements.videoScript || {},
          platformDescription: state.requirements.platformDescription || {}
        };
        setRequirements(newRequirements);
        
        if (details.social_media_platforms && details.social_media_platforms.length > 0) {
          const campaignPlatforms = availablePlatforms.filter(
            p => details.social_media_platforms.includes(p.name)
          );
          setPlatforms(campaignPlatforms);
        }
      } catch (err) {
        handleError(err);
        setCampaignDetails(null);
      } finally {
        setIsLoading(false);
      }
    } else {
      setCampaignDetails(null);
      const resetRequirements: ContentRequirements = {
        ...initialRequirements,
        selectedProducts: state.requirements.selectedProducts || [],
        selectedServices: state.requirements.selectedServices || [],
        generateImages: state.requirements.generateImages !== undefined ? state.requirements.generateImages : true,
        videoScript: {},
        platformDescription: {}
      };
      setRequirements(resetRequirements);
    }
  }, [setCampaign, setRequirements, setPlatforms, availablePlatforms, state.requirements]);

  const handleProductImageSelect = useCallback((selection: ProductImageSelection) => {
    if (selection.confirmed) {
      // Update requirements to use the selected product image
      setRequirements({
        ...state.requirements,
        generateImages: false, // Disable AI image generation when using product image
        selectedProducts: state.requirements.selectedProducts.map(product => 
          product.id === selection.productId
            ? {
                ...product,
                selectedImage: {
                  id: selection.imageId,
                  image: selection.imageUrl,
                  order: 0,
                  created_at: new Date().toISOString()
                }
              }
            : product
        )
      });

      // Create platform images using the selected product image
      const newPlatformImages: PlatformImages = {};
      state.platforms.forEach(platform => {
        newPlatformImages[platform.name] = {
          image_url: selection.imageUrl,
          local_image_url: selection.imageUrl,
          download_url: selection.imageUrl,
          message: 'Using selected product image',
          status: 'draft'
        };
      });
      setPlatformImages(newPlatformImages);
    }
  }, [state.requirements, state.platforms, setRequirements]);

  const handleSubmit = async () => {
    console.log('Submitting with state:', state);

    if (state.platforms.length === 0) {
      setError('Please select at least one platform');
      return;
    }

    if (!state.requirements.objective || 
        !state.requirements.tone || 
        !state.requirements.targetAudience || 
        !state.requirements.topic) {
      setError('Please fill in all content requirements');
      return;
    }

    if (!state.businessProfile) {
      setError('Business profile is required for content generation');
      return;
    }

    if (state.campaign && !campaignDetails) {
      setError('Campaign details are required when creating campaign content');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);
      setGeneratedContent(null);
      setPlatformImages(null);
      setSavedToHistory(false);
      setGeneratingImages(false);
      setCompletedImageCount(0);
      setIsContentComplete(false);

      // Generate content without saving
      const contentResponse = await simplifiedContentApi.generateContent({
        campaign: state.campaign,
        platforms: state.platforms,
        requirements: state.requirements,
        businessProfile: state.businessProfile
      });

      if (!contentResponse || !contentResponse.generated_content) {
        throw new Error('Failed to generate content. Please try again.');
      }

      setGeneratedContent(contentResponse.generated_content);

      // Handle images based on requirements
      if (state.requirements.generateImages) {
        // Generate new images using AI
        setGeneratingImages(true);
        try {
          const imageResults = await simplifiedContentApi.generateImage(
            contentResponse.generated_content,
            state.requirements
          );
          setPlatformImages(imageResults);
          setCompletedImageCount(Object.keys(imageResults).length);
        } catch (imageError) {
          console.error('Image generation failed:', imageError);
          handleError(imageError);
        } finally {
          setGeneratingImages(false);
        }
      } else {
        // Use selected product image if available
        const selectedProduct = state.requirements.selectedProducts.find(p => p.selectedImage);
        if (selectedProduct?.selectedImage) {
          const productImages: PlatformImages = {};
          state.platforms.forEach(platform => {
            productImages[platform.name] = {
              image_url: selectedProduct.selectedImage!.image,
              local_image_url: selectedProduct.selectedImage!.image,
              download_url: selectedProduct.selectedImage!.image,
              message: 'Using selected product image',
              status: 'draft'
            };
          });
          setPlatformImages(productImages);
          setCompletedImageCount(state.platforms.length);
        }
      }

    } catch (err) {
      handleError(err);
      setGeneratedContent(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegenerateText = (platform: string, newContent: string) => {
    if (generatedContent) {
      setGeneratedContent({
        ...generatedContent,
        [platform]: {
          ...generatedContent[platform],
          text: newContent
        }
      });
    }
  };

  const handleRegenerateImage = (platform: string, newImageUrl: string) => {
    if (platformImages) {
      setPlatformImages({
        ...platformImages,
        [platform]: {
          ...platformImages[platform],
          image_url: newImageUrl
        }
      });
    }
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // Create metadata with video content
      const metadata = createMetadata(state.campaign ? 'campaign' : 'quick', state);

      // Add campaign details to metadata if available
      if (state.campaign && campaignDetails) {
        metadata.campaign_details = {
          id: state.campaign.id,
          name: state.campaign.name,
          type: campaignDetails.type,
          category: campaignDetails.category,
          primary_purpose: campaignDetails.primary_purpose,
          target_audience: campaignDetails.target_audience
        };
      }

      // Save content
      const saveResult = await simplifiedContentApi.saveContent({
        platforms: state.platforms,
        requirements: state.requirements,
        campaign: state.campaign,
        generated_content: generatedContent!,
        platform_images: platformImages,
        business_profile: state.businessProfile,
        metadata
      });

      if (saveResult.successes.length > 0) {
        setSavedToHistory(true);
        setIsContentComplete(true);
      } else if (saveResult.failures.length > 0) {
        const errors = saveResult.failures.map(f => `${f.platform}: ${f.error}`).join(', ');
        throw new Error(`Failed to save content: ${errors}`);
      }

    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(generatingImages);
  }, [generatingImages]);

  const handleGenerateImagesChange = (checked: boolean) => {
    // Clear any selected product images when enabling AI image generation
    const updatedProducts = checked ? 
      state.requirements.selectedProducts.map(product => ({ ...product, selectedImage: undefined })) :
      state.requirements.selectedProducts;

    setRequirements({
      ...state.requirements,
      generateImages: checked,
      selectedProducts: updatedProducts
    });

    // Clear platform images if disabling image generation
    if (!checked) {
      setPlatformImages(null);
    }
  };

  if (isLoading && !state.campaign) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading...</p>
        </div>
      </div>
    );
  }

  // Find the selected product with image
  const selectedProductWithImage = state.requirements.selectedProducts.find(p => p.selectedImage);

  return (
    <div className="min-h-screen bg-gray-50 pb-16">
      <div className="bg-white border-b border-gray-200 shadow-sm">
        <div className="max-w-4xl mx-auto px-4 py-6">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Create Content</h1>
              <p className="mt-1 text-sm text-gray-600">
                Fill in the details below to create {state.campaign ? 'campaign' : 'quick'} content
              </p>
            </div>
            {(generatedContent || state.campaign || state.platforms.length > 0 || 
              state.requirements.objective || state.requirements.tone || 
              state.requirements.targetAudience || state.requirements.topic) && (
              <button
                onClick={handleReset}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
              >
                Reset Form
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="max-w-4xl mx-auto px-4 pt-6">
        {/* Error and Success Messages */}
        {error && (
          <div className="mb-6">
            <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded-r-lg">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                  {error.includes('Authentication') && (
                    <button
                      onClick={() => window.location.reload()}
                      className="mt-2 text-sm text-red-600 hover:text-red-800 underline"
                    >
                      Refresh Page
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Campaign Section */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 mb-6">
          <div className="p-6">
            <div className="flex items-center space-x-3 mb-4">
              <svg className="w-5 h-5 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
              </svg>
              <h2 className="text-lg font-semibold text-gray-900">Campaign Selection</h2>
            </div>
            <CampaignSelector
              selectedCampaign={state.campaign}
              onSelect={handleCampaignSelect}
              optional={true}
            />
          </div>
          
          {campaignDetails && (
            <div className="px-6 py-4 border-t border-gray-100 bg-gray-50">
              <h3 className="text-sm font-medium text-gray-900 mb-3">Campaign Details</h3>
              <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div className="bg-white p-4 rounded-lg shadow-sm">
                  <dt className="text-sm font-medium text-gray-500">Type</dt>
                  <dd className="mt-1 text-sm text-gray-900">{campaignDetails.type}</dd>
                </div>
                {campaignDetails.category && (
                  <div className="bg-white p-4 rounded-lg shadow-sm">
                    <dt className="text-sm font-medium text-gray-500">Category</dt>
                    <dd className="mt-1 text-sm text-gray-900">{campaignDetails.category}</dd>
                  </div>
                )}
                <div className="bg-white p-4 rounded-lg shadow-sm">
                  <dt className="text-sm font-medium text-gray-500">Primary Purpose</dt>
                  <dd className="mt-1 text-sm text-gray-900">{campaignDetails.primary_purpose}</dd>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-sm">
                  <dt className="text-sm font-medium text-gray-500">Target Audience</dt>
                  <dd className="mt-1 text-sm text-gray-900">{campaignDetails.target_audience || 'Not specified'}</dd>
                </div>
              </dl>
            </div>
          )}
        </div>

        {/* Platform Selection */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 mb-6">
          <div className="p-6">
            <div className="flex items-center space-x-3 mb-4">
              <svg className="w-5 h-5 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
              </svg>
              <div>
                <h2 className="text-lg font-semibold text-gray-900">Platform Selection</h2>
                <p className="text-sm text-gray-600 mt-1">Choose the platforms for your content</p>
              </div>
            </div>
            <PlatformSelector
              platforms={availablePlatforms}
              selectedPlatforms={state.platforms}
              onSelect={setPlatforms}
            />
          </div>
        </div>

        {/* Content Form */}
        <div className="mb-6">
          <ContentForm
            requirements={state.requirements}
            onChange={setRequirements}
            businessProfile={state.businessProfile}
            onProductImageSelect={handleProductImageSelect}
            selectedPlatforms={state.platforms}
          />
        </div>

        {/* Product Image Preview */}
        {selectedProductWithImage && !state.requirements.generateImages && (
          <ProductImagePreview
            selectedProduct={selectedProductWithImage}
            onClearSelection={() => handleGenerateImagesChange(true)}
          />
        )}

        {/* Generate Button Section */}
        <div className="mb-8 space-y-4">
          {/* Image Generation Checkbox */}
          <div className="flex items-center justify-end space-x-2 px-4">
            <input
              type="checkbox"
              id="generateImages"
              checked={state.requirements.generateImages !== false}
              onChange={(e) => handleGenerateImagesChange(e.target.checked)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="generateImages" className="text-sm text-gray-700">
              Generate accompanying images
            </label>
          </div>

          {/* Generate Button */}
          <button
            onClick={handleSubmit}
            disabled={isLoading || state.platforms.length === 0 || !state.businessProfile}
            className={`w-full py-4 px-6 rounded-xl text-white font-medium transition-all duration-200 ${
              isLoading || state.platforms.length === 0 || !state.businessProfile
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700 transform hover:scale-[1.02] hover:shadow-lg'
            }`}
          >
            {isLoading ? (
              <span className="flex items-center justify-center space-x-3">
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span>
                  {generatingImages
                    ? `Generating Images (${completedImageCount}/${selectedPlatformCount})...`
                    : 'Generating Content...'
                  }
                </span>
              </span>
            ) : (
              <span className="flex items-center justify-center space-x-2">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
                <span>
                  Generate {state.campaign ? 'Campaign' : 'Quick'} Content for {state.platforms.length} Platform{state.platforms.length !== 1 ? 's' : ''}
                </span>
              </span>
            )}
          </button>
        </div>

      {/* Content Review */}
      {generatedContent && (
        <ContentReview
          platforms={state.platforms}
          generatedContent={generatedContent}
          platformImages={platformImages}
          requirements={state.requirements}
          businessProfile={state.businessProfile || null}
          onRegenerateText={handleRegenerateText}
          onRegenerateImage={handleRegenerateImage}
          onSave={handleSave}
          isLoading={isLoading}
        />
      )}

        {/* Completion Message */}
        {isContentComplete && (
          <div className="space-y-4">
            <div className="bg-green-50 border border-green-200 rounded-xl p-6">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <svg className="h-8 w-8 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-4">
                  <p className="text-xl font-bold text-green-800">
                    Content Creation Complete
                  </p>
                  <p className="mt-1 text-base text-green-700">
                    Your content has been generated and saved. View it in the History Tab.
                  </p>
                </div>
              </div>
            </div>
            <button
              onClick={handleReset}
              className="w-full py-4 px-6 rounded-xl text-white font-medium bg-blue-600 hover:bg-blue-700 transform hover:scale-[1.02] hover:shadow-lg transition-all duration-200"
            >
              Start New Content Creation
            </button>
          </div>
        )}

        <StateLogger
          currentState={state}
          stateHistory={stateHistory}
        />
      </div>
    </div>
  );
};

export default SimplifiedContentPage;
