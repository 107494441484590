// Previous imports remain the same...
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axiosInstance from '../utils/axiosConfig';
import { authService } from '../services/auth';
import { campaignService } from '../services/campaign';
import { contentService } from '../services/content';
import { businessService } from '../services/business';
import { handleAxiosError } from '../services/api';
import { BusinessProfile } from '../types/business';
import { ProductFormData, ServiceFormData } from '../types/businessProfile';
import { Content, TopicIdeasResponse } from '../types/content';
import { Campaign, CampaignAnalytics } from '../types/campaign';
import { ProductDescriptionResponse, BatchGenerateResponse } from '../types/productDescriptions';
import { useSubscription } from './SubscriptionContext';

// ... (previous interfaces remain the same)

export type SubscriptionStatus = 'active' | 'past_due' | 'cancelled' | 'expired' | 'incomplete' | null;

export type User = {
  id: number;
  username: string;
  email: string;
  has_active_subscription: boolean;
  subscription_plan: string | null;
  subscription_status: SubscriptionStatus;
  has_seen_welcome?: boolean;
  is_staff: boolean;
  is_superuser: boolean;
};

export type ProductImage = {
  id: number;
  image: string;
  order: number;
  created_at: string;
};

export type ProductPricing = {
  retail_price?: number;
  sale_price?: number;
  special_price?: number;
  campaign_price?: number;
  show_retail_price: boolean;
  show_sale_price: boolean;
  show_special_price: boolean;
  show_campaign_price: boolean;
};

export type Product = {
  id: number;
  name: string;
  description: string;
  images?: ProductImage[];
  pricing?: ProductPricing;
};

export type Service = {
  id: number;
  name: string;
  description: string;
  pricing?: ProductPricing;
};

interface RegistrationResponse {
  checkout_url: string;
  session_id: string;
}

interface LoginResponse {
  message: string;
  user: User;
  has_active_subscription: boolean;
  subscription_plan: string | null;
}

type AuthContextType = {
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  signup: (username: string, email: string, password: string, subscription_plan: number) => Promise<RegistrationResponse>;
  isAuthenticated: boolean;
  isLoading: boolean;
  hasValidSubscription: boolean;
  canAccessRoute: (route: string) => boolean;
  getCampaigns: () => Promise<Campaign[]>;
  createCampaign: (campaignData: Partial<Campaign>) => Promise<Campaign>;
  updateCampaign: (id: number, campaignData: Partial<Campaign>) => Promise<Campaign>;
  deleteCampaign: (id: number) => Promise<void>;
  getCampaignAnalytics: (id: number) => Promise<CampaignAnalytics>;
  getContents: () => Promise<Content[]>;
  getContentHistory: () => Promise<Content[]>;
  createContent: (contentData: Partial<Content>) => Promise<Content>;
  generateQuickContent: (contentData: Partial<Content>) => Promise<{ generated_content: string; content?: Content }>;
  deleteContent: (id: number) => Promise<void>;
  getBusinessProfile: () => Promise<BusinessProfile>;
  createBusinessProfile: (profileData: Partial<BusinessProfile>) => Promise<BusinessProfile>;
  updateBusinessProfile: (profileData: Partial<BusinessProfile>) => Promise<BusinessProfile>;
  saveBusinessProfile: (profileData: Partial<BusinessProfile>) => Promise<BusinessProfile>;
  refreshBusinessProfile: () => Promise<void>;
  generateTopicIdeas: (data: any) => Promise<TopicIdeasResponse>;
  generateContent: (data: any) => Promise<string>;
  fetchCsrfToken: () => Promise<string>;
  refreshUser: () => Promise<void>;
  getContentVariations: (contentId: number) => Promise<Content[]>;
  updateContent: (contentId: number, updates: Partial<Content>) => Promise<Content>;
  clearBusinessProfile: () => Promise<void>;
  deleteBusinessProfile: () => Promise<void>;
  getProducts: () => Promise<Product[]>;
  getServices: () => Promise<Service[]>;
  createProduct: (productData: ProductFormData) => Promise<Product>;
  createService: (serviceData: ServiceFormData) => Promise<Service>;
  deleteProduct: (id: number) => Promise<void>;
  deleteService: (id: number) => Promise<void>;
  uploadCSV: (file: File) => Promise<{ products: Product[], services: Service[] }>;
  updateProduct: (id: number, productData: ProductFormData) => Promise<Product>;
  updateService: (id: number, serviceData: ServiceFormData) => Promise<Service>;
  uploadProductImage: (productId: number, image: File, order?: number) => Promise<ProductImage>;
  deleteProductImage: (productId: number, imageId: number) => Promise<void>;
  markWelcomeSeen: () => Promise<void>;
  generateProductDescription: (productId: number, temperature?: number) => Promise<ProductDescriptionResponse>;
  saveProductDescription: (productId: number, description: string) => Promise<ProductDescriptionResponse>;
  batchGenerateDescriptions: (productIds: number[], temperature?: number) => Promise<BatchGenerateResponse>;
  exportProducts: () => Promise<void>;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Routes that are accessible without an active subscription
const SUBSCRIPTION_FREE_ROUTES = [
  '/business-profile',
  '/subscription',
  '/account',
  '/logout',
  '/campaign-management',
  '/image-management'
];

// Routes that require admin access
const ADMIN_ROUTES = [
  '/admin'
];

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasValidSubscription, setHasValidSubscription] = useState<boolean>(false);
  const [csrfTokenFetched, setCsrfTokenFetched] = useState<boolean>(false);

  const isValidSubscriptionStatus = (status: SubscriptionStatus): boolean => {
    return status === 'active' || status === 'past_due';
  };

  const fetchCsrfToken = useCallback(async () => {
    if (csrfTokenFetched) return;
    try {
      const response = await axiosInstance.get('/get-csrf-token/');
      setCsrfTokenFetched(true);
      return response.data.csrfToken;
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
      throw error;
    }
  }, [csrfTokenFetched]);

  const checkAuth = useCallback(async () => {
    if (!csrfTokenFetched) {
      await fetchCsrfToken();
    }
    
    try {
      const userData = await authService.getCurrentUser();
      setUser(userData);
      setIsAuthenticated(true);
      
      // Consider subscription valid if:
      // 1. Status is active, or
      // 2. Status is past_due (which indicates retry period)
      const isValid = isValidSubscriptionStatus(userData.subscription_status);
      setHasValidSubscription(isValid);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setUser(null);
      setIsAuthenticated(false);
      setHasValidSubscription(false);
    } finally {
      setIsLoading(false);
    }
  }, [csrfTokenFetched, fetchCsrfToken]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const canAccessRoute = (route: string): boolean => {
    if (!isAuthenticated || !user) return false;
    
    // Admin routes check
    if (ADMIN_ROUTES.some(adminRoute => route.startsWith(adminRoute))) {
      return user.is_staff === true;
    }
    
    // Free routes are always accessible when authenticated
    if (SUBSCRIPTION_FREE_ROUTES.some(freeRoute => route.startsWith(freeRoute))) {
      return true;
    }
    
    // Allow access if:
    // 1. Subscription is active, or
    // 2. Status is past_due (retry period)
    return isValidSubscriptionStatus(user.subscription_status);
  };

  const login = async (email: string, password: string) => {
    await fetchCsrfToken();
    
    const response = await authService.login(email, password);
    setUser(response.user);
    setIsAuthenticated(true);
    
    // Consider subscription valid if:
    // 1. Status is active, or
    // 2. Status is past_due (which indicates retry period)
    const isValid = isValidSubscriptionStatus(response.user.subscription_status);
    setHasValidSubscription(isValid);
  };

  const logout = async () => {
    await authService.logout();
    setUser(null);
    setIsAuthenticated(false);
    setHasValidSubscription(false);
  };

  const refreshUser = async () => {
    console.log('Manually refreshing user data');
    await checkAuth();
  };

  const contextValue = {
    user,
    login,
    logout,
    signup: authService.signup,
    isAuthenticated,
    isLoading,
    hasValidSubscription,
    canAccessRoute,
    getCampaigns: campaignService.getCampaigns,
    createCampaign: campaignService.createCampaign,
    updateCampaign: campaignService.updateCampaign,
    deleteCampaign: campaignService.deleteCampaign,
    getCampaignAnalytics: campaignService.getCampaignAnalytics,
    getContents: contentService.getContents,
    getContentHistory: contentService.getContentHistory,
    createContent: contentService.createContent,
    generateQuickContent: contentService.generateQuickContent,
    deleteContent: contentService.deleteContent,
    getBusinessProfile: businessService.getBusinessProfile,
    createBusinessProfile: businessService.createBusinessProfile,
    updateBusinessProfile: businessService.updateBusinessProfile,
    saveBusinessProfile: businessService.updateBusinessProfile,
    refreshBusinessProfile: async () => {
      await businessService.getBusinessProfile();
    },
    generateTopicIdeas: contentService.generateTopicIdeas,
    generateContent: contentService.generateContent,
    fetchCsrfToken,
    refreshUser,
    getContentVariations: contentService.getContentVariations,
    updateContent: contentService.updateContent,
    clearBusinessProfile: businessService.clearBusinessProfile,
    deleteBusinessProfile: businessService.deleteBusinessProfile,
    getProducts: businessService.getProducts,
    getServices: businessService.getServices,
    createProduct: businessService.createProduct,
    createService: businessService.createService,
    deleteProduct: businessService.deleteProduct,
    deleteService: businessService.deleteService,
    uploadCSV: businessService.uploadCSV,
    updateProduct: businessService.updateProduct,
    updateService: businessService.updateService,
    uploadProductImage: businessService.uploadProductImage,
    deleteProductImage: businessService.deleteProductImage,
    markWelcomeSeen: async () => {
      await authService.markWelcomeSeen();
      if (user) {
        setUser({ ...user, has_seen_welcome: true });
      }
    },
    generateProductDescription: businessService.generateProductDescription,
    saveProductDescription: businessService.saveProductDescription,
    batchGenerateDescriptions: businessService.batchGenerateDescriptions,
    exportProducts: businessService.exportProducts
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
