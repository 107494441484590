import { useState, useCallback, useEffect, useRef } from 'react';
import { ValidationRule } from '../utils/validation';
import { useFormPersistence } from './useFormPersistence';
import { useUnsavedChangesWarning } from './useUnsavedChangesWarning';

export interface FieldConfig<T> {
  initialValue: T;
  validate?: ValidationRule<T>[];
}

export type FormConfig<T> = {
  [K in keyof T]: FieldConfig<T[K]>;
};

export interface FormState<T> {
  values: T;
  errors: { [K in keyof T]?: string };
  touched: { [K in keyof T]?: boolean };
}

export interface UseFormOptions {
  persistenceKey?: string;
  persistenceEnabled?: boolean;
  excludeFromPersistence?: string[];
  warnOnUnsavedChanges?: boolean;
  unsavedChangesMessage?: string;
}

export interface UseFormReturn<T> {
  values: T;
  errors: { [K in keyof T]?: string };
  touched: { [K in keyof T]?: boolean };
  isSubmitting: boolean;
  formError: string | null;
  isDirty: boolean;
  handleChange: <K extends keyof T>(name: K, value: T[K]) => void;
  handleBlur: (name: keyof T) => void;
  setFieldValue: <K extends keyof T>(name: K, value: T[K]) => void;
  setFieldError: (name: keyof T, error: string | undefined) => void;
  setFormError: (error: string | null) => void;
  resetForm: () => void;
  validateField: (name: keyof T) => string | undefined;
  validateForm: () => boolean;
}

export const useForm = <T extends Record<string, any>>(
  config: FormConfig<T>,
  options: UseFormOptions = {}
): UseFormReturn<T> => {
  const initialValues = Object.keys(config).reduce((acc, key) => {
    acc[key as keyof T] = config[key as keyof T].initialValue;
    return acc;
  }, {} as T);

  const [formState, setFormState] = useState<FormState<T>>({
    values: initialValues,
    errors: {},
    touched: {},
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  
  const isInitialized = useRef(false);
  const isUpdatingRef = useRef(false);
  const previousValuesRef = useRef(initialValues);

  useEffect(() => {
    console.log('Form initialized with values:', formState.values);
    isInitialized.current = true;
  }, []);

  useFormPersistence(
    formState.values,
    {
      key: options.persistenceKey || 'form',
      enabled: !!options.persistenceKey && options.persistenceEnabled !== false,
      excludeFields: options.excludeFromPersistence || [],
    },
    (restoredValues) => {
      if (!isInitialized.current) {
        console.log('Restoring form values:', restoredValues);
        setFormState(prev => ({
          ...prev,
          values: {
            ...prev.values,
            ...restoredValues,
          },
        }));
      }
    }
  );

  useUnsavedChangesWarning(
    isDirty && (options.warnOnUnsavedChanges ?? false),
    options.unsavedChangesMessage
  );

  useEffect(() => {
    if (!isInitialized.current || isUpdatingRef.current) return;

    const hasChanges = Object.keys(config).some(key => {
      const initialValue = config[key as keyof T].initialValue;
      const currentValue = formState.values[key as keyof T];
      return JSON.stringify(initialValue) !== JSON.stringify(currentValue);
    });
    setIsDirty(hasChanges);
  }, [formState.values, config]);

  const validateField = useCallback((name: keyof T): string | undefined => {
    const field = config[name];
    const value = formState.values[name];

    if (!field.validate) return undefined;

    for (const validator of field.validate) {
      if (!validator.validate(value)) {
        return validator.message;
      }
    }

    return undefined;
  }, [config, formState.values]);

  const validateForm = useCallback((): boolean => {
    const newErrors: { [K in keyof T]?: string } = {};
    let isValid = true;

    (Object.keys(config) as Array<keyof T>).forEach(name => {
      const error = validateField(name);
      if (error) {
        isValid = false;
        newErrors[name] = error;
      }
    });

    setFormState(prev => ({
      ...prev,
      errors: newErrors,
      touched: Object.keys(config).reduce((acc, key) => {
        acc[key as keyof T] = true;
        return acc;
      }, {} as { [K in keyof T]: boolean }),
    }));

    return isValid;
  }, [config, validateField]);

  const handleChange = useCallback(<K extends keyof T>(name: K, value: T[K]) => {
    console.log('handleChange called:', { name, value });
    isUpdatingRef.current = true;
    
    setFormState(prev => {
      const newValues = { ...prev.values, [name]: value };
      console.log('Previous values:', prev.values);
      console.log('New values:', newValues);
      
      return {
        ...prev,
        values: newValues,
        errors: { ...prev.errors, [name]: undefined },
      };
    });
    
    setFormError(null);
    isUpdatingRef.current = false;
  }, []);

  const handleBlur = useCallback((name: keyof T) => {
    const error = validateField(name);
    setFormState(prev => ({
      ...prev,
      touched: { ...prev.touched, [name]: true },
      errors: { ...prev.errors, [name]: error },
    }));
  }, [validateField]);

  const setFieldValue = useCallback(<K extends keyof T>(name: K, value: T[K]) => {
    console.log('setFieldValue called:', { name, value });
    isUpdatingRef.current = true;
    
    setFormState(prev => {
      const newValues = { ...prev.values, [name]: value };
      console.log('Previous values:', prev.values);
      console.log('New values:', newValues);
      
      return {
        ...prev,
        values: newValues,
      };
    });
    
    isUpdatingRef.current = false;
  }, []);

  const setFieldError = useCallback((name: keyof T, error: string | undefined) => {
    setFormState(prev => ({
      ...prev,
      errors: { ...prev.errors, [name]: error },
    }));
  }, []);

  const resetForm = useCallback(() => {
    console.log('Resetting form to:', initialValues);
    setFormState({
      values: initialValues,
      errors: {},
      touched: {},
    });
    setFormError(null);
    setIsSubmitting(false);
    setIsDirty(false);
  }, [initialValues]);

  // Track value changes
  useEffect(() => {
    if (JSON.stringify(previousValuesRef.current) !== JSON.stringify(formState.values)) {
      console.log('Form values changed:', formState.values);
      previousValuesRef.current = formState.values;
    }
  }, [formState.values]);

  return {
    values: formState.values,
    errors: formState.errors,
    touched: formState.touched,
    isSubmitting,
    formError,
    isDirty,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldError,
    setFormError,
    resetForm,
    validateField,
    validateForm,
  };
};
