import React from 'react';
import FormField from '../common/FormField';
import FormActions from '../common/FormActions';
import Card from '../common/Card';
import AlertBanner from '../common/AlertBanner';
import Button from '../common/Button';
import { useForm } from '../../hooks/useForm';
import { ValidationRule } from '../../utils/validation';
import { useCSVPreview } from '../../hooks/useCSVPreview';
import { useFileUpload } from '../../hooks/useFileUpload';
import LoadingSpinner from '../common/LoadingSpinner';
import FileDropZone from '../common/FileDropZone';
import FileIcon from '../common/FileIcon';
import ProgressBar from '../common/ProgressBar';

interface CSVUploadFormProps {
  onUpload: (file: File) => Promise<void>;
  onCancel: () => void;
}

interface CSVUploadFormData {
  file: File | null;
}

const csvFileValidation: ValidationRule<File | null> = {
  validate: (file: File | null) => {
    if (!file) {
      return false;
    }
    if (file.type !== 'text/csv' && !file.name.endsWith('.csv')) {
      return false;
    }
    if (file.size > 5 * 1024 * 1024) { // 5MB limit
      return false;
    }
    return true;
  },
  message: 'Please select a valid CSV file (max 5MB)'
};

const CSVUploadForm: React.FC<CSVUploadFormProps> = ({ 
  onUpload, 
  onCancel 
}): React.ReactElement => {
  const {
    values,
    errors: formErrors,
    touched,
    isSubmitting,
    formError,
    handleChange,
    handleBlur,
    setFormError,
    validateForm,
  } = useForm<CSVUploadFormData>(
    {
      file: {
        initialValue: null,
        validate: [csvFileValidation],
      },
    },
    {
      persistenceKey: 'csv-upload-form',
      persistenceEnabled: false,
      warnOnUnsavedChanges: true,
      unsavedChangesMessage: 'You have a file selected but not uploaded. Are you sure you want to leave?',
    }
  );

  const { preview, errors: csvErrors, isValid, isLoading, parseCSV } = useCSVPreview();
  const { 
    progress, 
    isUploading, 
    uploadFile, 
    resetProgress, 
    cancelUpload,
    retryUpload,
    error: uploadError,
    retryCount,
    maxRetries,
  } = useFileUpload(onUpload);

  const handleFileChange = async (files: FileList | null) => {
    resetProgress();
    const file = files?.[0] || null;
    handleChange('file', file);
    if (file) {
      await parseCSV(file);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFileChange(e.target.files);
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    if (!values.file) {
      setFormError('Please select a file');
      return;
    }

    if (!isValid) {
      setFormError('Please fix the CSV validation errors before uploading');
      return;
    }

    try {
      await uploadFile(values.file);
    } catch (err) {
      if (err instanceof Error && err.message === 'Upload cancelled') {
        return;
      }
      // Error handling is now done through the uploadError state
    }
  };

  const handleCancel = () => {
    if (isUploading) {
      cancelUpload();
    } else {
      onCancel();
    }
  };

  const handleRetry = async () => {
    try {
      await retryUpload();
    } catch (err) {
      // Error handling is done through the uploadError state
    }
  };

  const renderUploadStatus = () => {
    if (uploadError) {
      return (
        <div className="mt-4">
          <AlertBanner
            type="error"
            message={`Upload failed: ${uploadError.message}`}
          />
          {retryCount < maxRetries && (
            <div className="mt-2 flex justify-center">
              <Button
                type="button"
                variant="primary"
                size="sm"
                onClick={handleRetry}
                className="mr-2"
              >
                Retry Upload
              </Button>
              <Button
                type="button"
                variant="secondary"
                size="sm"
                onClick={resetProgress}
              >
                Cancel
              </Button>
            </div>
          )}
          {retryCount >= maxRetries && (
            <p className="text-sm text-red-600 mt-2 text-center">
              Maximum retry attempts reached. Please try again later.
            </p>
          )}
        </div>
      );
    }

    if (isUploading) {
      return (
        <div className="w-full max-w-xs mt-4">
          <ProgressBar 
            progress={progress} 
            showPercentage={true}
          />
          <Button
            type="button"
            variant="danger"
            size="sm"
            className="mt-2 w-full"
            onClick={cancelUpload}
          >
            Cancel Upload
          </Button>
        </div>
      );
    }

    return null;
  };

  return (
    <Card>
      <form onSubmit={handleSubmit} className="space-y-4">
        {formError && (
          <AlertBanner
            type="error"
            message={formError}
            onDismiss={() => setFormError(null)}
          />
        )}

        <FormField
          label="Upload CSV File"
          id="csv-file"
          required
          error={touched.file ? formErrors.file : undefined}
        >
          <FileDropZone
            id="csv-file"
            accept=".csv"
            onFilesDrop={handleFileChange}
            onChange={handleInputChange}
            onBlur={() => handleBlur('file')}
            acceptedFileTypes={['.csv', 'text/csv']}
            required
          >
            <div className="p-8 text-center">
              {values.file ? (
                <div className="flex flex-col items-center space-y-2">
                  <FileIcon fileType="csv" className="text-blue-500 w-12 h-12" />
                  <p className="text-gray-700">{values.file.name}</p>
                  <p className="text-sm text-gray-500">
                    {(values.file.size / 1024).toFixed(1)} KB
                  </p>
                  {renderUploadStatus()}
                </div>
              ) : (
                <div className="text-gray-500">
                  <FileIcon fileType="csv" className="mx-auto h-12 w-12" />
                  <p className="mt-1">Drag and drop your CSV file here, or click to browse</p>
                  <p className="text-sm text-gray-400 mt-1">CSV files only, up to 5MB</p>
                </div>
              )}
            </div>
          </FileDropZone>
        </FormField>

        <div className="bg-gray-50 p-4 rounded-lg space-y-3">
          <h4 className="font-medium text-gray-700">CSV File Requirements:</h4>
          <ul className="list-disc list-inside text-sm text-gray-600 space-y-1">
            <li>File must be in CSV format</li>
            <li>Maximum file size: 5MB</li>
            <li>Required columns:
              <ul className="list-disc list-inside ml-4">
                <li>type (product or service)</li>
                <li>name</li>
                <li>description</li>
              </ul>
            </li>
          </ul>
          <div className="mt-3">
            <h5 className="font-medium text-gray-700 mb-2">Example Format:</h5>
            <pre className="bg-white p-3 rounded border border-gray-200 text-sm text-gray-600 overflow-x-auto">
              type,name,description{'\n'}
              product,Widget A,A high-quality widget{'\n'}
              service,Consulting,Expert business consulting
            </pre>
          </div>
        </div>

        {isLoading && (
          <div className="flex justify-center">
            <LoadingSpinner size="small" />
          </div>
        )}

        {csvErrors.length > 0 && (
          <div className="border border-red-200 rounded-lg p-4 bg-red-50">
            <h5 className="font-medium text-red-700 mb-2">CSV Validation Errors:</h5>
            <ul className="list-disc list-inside text-sm text-red-600 space-y-1">
              {csvErrors.map((error, index) => (
                <li key={index}>
                  Row {error.row}: {error.message}
                </li>
              ))}
            </ul>
          </div>
        )}

        {preview.length > 0 && isValid && (
          <div className="border border-green-200 rounded-lg p-4 bg-green-50">
            <h5 className="font-medium text-green-700 mb-2">Preview:</h5>
            <div className="max-h-60 overflow-y-auto">
              <table className="min-w-full text-sm">
                <thead>
                  <tr>
                    <th className="text-left font-medium text-green-700 p-2">Type</th>
                    <th className="text-left font-medium text-green-700 p-2">Name</th>
                    <th className="text-left font-medium text-green-700 p-2">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {preview.map((row, index) => (
                    <tr key={index} className="border-t border-green-200">
                      <td className="p-2">{row.type}</td>
                      <td className="p-2">{row.name}</td>
                      <td className="p-2">{row.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <FormActions
          onCancel={handleCancel}
          submitLabel="Upload"
          cancelLabel={isUploading ? 'Cancel Upload' : 'Cancel'}
          isSubmitting={isSubmitting || isUploading}
        />
      </form>
    </Card>
  );
};

export default CSVUploadForm;
