import { Content, FilterOptions, CONTENT_STATUSES, ContentStatus } from '../types/content';

export const ITEMS_PER_PAGE = 10;

// Validate status to ensure it's a valid ContentStatus
const validateFilterStatus = (status: string): ContentStatus => {
  return CONTENT_STATUSES.includes(status as ContentStatus) ? status as ContentStatus : 'draft';
};

export const filterContents = (
  contents: Content[],
  searchTerm: string,
  filterOptions: FilterOptions
): Content[] => {
  if (!contents) return [];
  
  return contents.filter(content => {
    if (!content) return false;

    // Search term filtering
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      const matchesSearch = 
        (content.content || '').toLowerCase().includes(searchLower) ||
        (content.topic?.toLowerCase() || '').includes(searchLower) ||
        (content.platform || '').toLowerCase().includes(searchLower) ||
        (content.target_audience || '').toLowerCase().includes(searchLower);

      if (!matchesSearch) return false;
    }

    // Date filtering
    if (filterOptions.dateFrom && content.created_at) {
      const contentDate = new Date(content.created_at);
      const fromDate = new Date(filterOptions.dateFrom);
      if (contentDate < fromDate) return false;
    }

    if (filterOptions.dateTo && content.created_at) {
      const contentDate = new Date(content.created_at);
      const toDate = new Date(filterOptions.dateTo);
      if (contentDate > toDate) return false;
    }

    // Campaign filtering
    if (filterOptions.campaign) {
      if (!content.campaign || !content.campaign.id || content.campaign.id.toString() !== filterOptions.campaign) {
        return false;
      }
    }

    // Status filtering
    if (filterOptions.status) {
      const validStatus = validateFilterStatus(filterOptions.status);
      if (validStatus && content.content_status !== validStatus) {
        return false;
      }
    }

    return true;
  });
};

export interface GroupedContent {
  campaignGroups: {
    [key: string]: {
      campaign: Content['campaign'];
      contents: Content[];
    };
  };
  quickContent: Content[];
}

export const groupContentsByCampaignAndQuick = (contents: Content[]): GroupedContent => {
  const grouped: GroupedContent = {
    campaignGroups: {},
    quickContent: []
  };

  if (!contents || !Array.isArray(contents)) return grouped;

  // Sort contents by created_at before grouping
  const sortedContents = [...contents].sort((a, b) => {
    if (!a.created_at || !b.created_at) return 0;
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  // Process all content synchronously
  for (const content of sortedContents) {
    if (!content) continue;

    try {
      // Check if content belongs to a campaign
      const campaign = content.campaign;
      const hasCampaign = campaign && typeof campaign === 'object' && 'id' in campaign && campaign.id !== null;

      // If content has a valid campaign, add it to campaign groups
      if (hasCampaign && campaign) {
        console.log('Processing campaign content:', {
          id: content.id,
          campaignId: campaign.id,
          contentType: content.content_type,
          platform: content.platform,
          content: !!content.content
        });

        const campaignId = campaign.id.toString();
        if (!grouped.campaignGroups[campaignId]) {
          grouped.campaignGroups[campaignId] = {
            campaign: campaign,
            contents: []
          };
        }
        // Only add content if it has actual content
        if (content.content) {
          grouped.campaignGroups[campaignId].contents.push(content);
        }
      } 
      // If content has no campaign, add to quick content
      else {
        console.log('Processing quick content:', {
          id: content.id,
          contentType: content.content_type,
          platform: content.platform,
          content: !!content.content
        });
        // Only add content if it has actual content
        if (content.content) {
          grouped.quickContent.push(content);
        }
      }
    } catch (error) {
      console.error('Error processing content:', error, {
        contentId: content.id,
        contentType: content.content_type,
        campaign: content.campaign,
        platform: content.platform
      });
    }
  }

  // Sort contents within each group by created_at
  Object.values(grouped.campaignGroups).forEach(group => {
    if (group && Array.isArray(group.contents)) {
      group.contents.sort((a, b) => {
        if (!a.created_at || !b.created_at) return 0;
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      });
    }
  });

  // Sort quick content by created_at
  if (Array.isArray(grouped.quickContent)) {
    grouped.quickContent.sort((a, b) => {
      if (!a.created_at || !b.created_at) return 0;
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });
  }

  return grouped;
};

export const useFilterPresets = () => {
  const loadFilterPresets = () => {
    try {
      const savedPresets = localStorage.getItem('filterPresets');
      return savedPresets ? JSON.parse(savedPresets) : [];
    } catch (error) {
      console.error('Error loading filter presets:', error);
      return [];
    }
  };

  const saveFilterPreset = (name: string, options: FilterOptions) => {
    try {
      const presets = loadFilterPresets();
      const newPreset = { name, options };
      const updatedPresets = [...presets, newPreset];
      localStorage.setItem('filterPresets', JSON.stringify(updatedPresets));
      return updatedPresets;
    } catch (error) {
      console.error('Error saving filter preset:', error);
      return [];
    }
  };

  const deleteFilterPreset = (name: string) => {
    try {
      const presets = loadFilterPresets();
      const updatedPresets = presets.filter((preset: { name: string }) => preset.name !== name);
      localStorage.setItem('filterPresets', JSON.stringify(updatedPresets));
      return updatedPresets;
    } catch (error) {
      console.error('Error deleting filter preset:', error);
      return [];
    }
  };

  return {
    filterPresets: loadFilterPresets(),
    loadFilterPresets,
    saveFilterPreset,
    deleteFilterPreset,
  };
};
