import React, { useState, useCallback } from 'react';
import type { Product, ProductImage } from '../../../types/content';
import type { Service, SelectedProductWithImage, ProductImageSelection } from '../types/index';
import { simplifiedContentApi } from '../api/contentApi';

interface ProductServiceSelectorProps {
  selectedProducts: SelectedProductWithImage[];
  selectedServices: Service[];
  onProductsChange: (products: SelectedProductWithImage[]) => void;
  onServicesChange: (services: Service[]) => void;
  onProductImageSelect?: (selection: ProductImageSelection) => void;
}

interface SelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  items: (Product | Service)[];
  selectedItems: (SelectedProductWithImage | Service)[];
  onSelect: (item: Product | Service) => void;
  onSearch: (query: string) => void;
  isLoading: boolean;
  hasMore: boolean;
  onLoadMore: () => void;
  onImageSelect?: (productId: number, image: ProductImage) => void;
  isProductModal?: boolean;
}

const isProduct = (item: Product | Service): item is Product => {
  return 'images' in item;
};

const SelectionModal: React.FC<SelectionModalProps> = ({
  isOpen,
  onClose,
  title,
  items,
  selectedItems,
  onSelect,
  onSearch,
  isLoading,
  hasMore,
  onLoadMore,
  onImageSelect,
  isProductModal
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedProductId, setExpandedProductId] = useState<number | null>(null);
  const [pendingImage, setPendingImage] = useState<{productId: number; image: ProductImage} | null>(null);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    onSearch(query);
  }, [onSearch]);

  const handleImageSelect = (productId: number, image: ProductImage) => {
    setPendingImage({ productId, image });
  };

  const handleConfirmImage = () => {
    if (pendingImage && onImageSelect) {
      onImageSelect(pendingImage.productId, pendingImage.image);
      setPendingImage(null);
      onClose();
    }
  };

  const handleCancelImage = () => {
    setPendingImage(null);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl w-full max-w-lg max-h-[80vh] overflow-hidden shadow-xl transform transition-all flex flex-col">
        <div className="p-6 border-b border-gray-200">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold text-gray-900">{title}</h2>
            <button
              onClick={onClose}
              className="rounded-full p-2 hover:bg-gray-100 transition-colors"
            >
              <svg className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="relative">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Search..."
              className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
            />
            <svg className="w-5 h-5 text-gray-400 absolute left-3 top-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
        </div>

        <div className="overflow-y-auto flex-1 p-6">
          <div className="space-y-3">
            {items.map(item => {
              const isSelected = selectedItems.some(selected => selected.id === item.id);
              const isProductItem = isProduct(item);
              const selectedProduct = isProductItem 
                ? selectedItems.find(selected => selected.id === item.id) as SelectedProductWithImage
                : null;
              const hasImages = isProductItem && item.images && item.images.length > 0;

              return (
                <div key={item.id} className="border rounded-lg overflow-hidden">
                  <div
                    onClick={() => {
                      if (isProductItem && hasImages) {
                        setExpandedProductId(expandedProductId === item.id ? null : item.id);
                      }
                      onSelect(item);
                    }}
                    className={`p-4 cursor-pointer transition-all duration-200 ${
                      isSelected
                        ? 'bg-blue-50 border-blue-500'
                        : 'hover:border-blue-300 hover:bg-gray-50'
                    }`}
                  >
                    <div className="flex items-center justify-between">
                      <div>
                        <div className="font-medium text-gray-900">{item.name}</div>
                        {item.description && (
                          <div className="text-sm text-gray-600 mt-1">
                            {item.description}
                          </div>
                        )}
                        {hasImages && (
                          <div className="text-sm text-blue-600 mt-1">
                            {item.images.length} image{item.images.length !== 1 ? 's' : ''} available
                          </div>
                        )}
                      </div>
                      {isSelected && (
                        <svg className="w-6 h-6 text-blue-600" fill="currentColor" viewBox="0 0 20 20">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                        </svg>
                      )}
                    </div>
                  </div>

                  {/* Product Images Section */}
                  {isProductItem && expandedProductId === item.id && hasImages && (
                    <div className="p-4 bg-gray-50 border-t border-gray-200">
                      <h4 className="text-sm font-medium text-gray-700 mb-2">Select Image</h4>
                      <div className="grid grid-cols-2 gap-2">
                        {item.images.map((image) => (
                          <div
                            key={image.id}
                            onClick={() => handleImageSelect(item.id, image)}
                            className={`relative cursor-pointer rounded-lg overflow-hidden border-2 transition-all ${
                              pendingImage?.image.id === image.id
                                ? 'border-blue-500 shadow-md transform scale-[1.02]'
                                : 'border-transparent hover:border-blue-300'
                            }`}
                          >
                            <img
                              src={image.image}
                              alt={`${item.name} - Image ${image.order}`}
                              className="w-full h-32 object-cover"
                            />
                            {pendingImage?.image.id === image.id && (
                              <div className="absolute top-2 right-2 bg-blue-500 rounded-full p-1">
                                <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
                                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                </svg>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
            {isLoading && (
              <div className="flex justify-center py-4">
                <svg className="animate-spin h-6 w-6 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            )}
            {hasMore && !isLoading && (
              <button
                onClick={onLoadMore}
                className="w-full p-3 text-blue-600 hover:text-blue-800 font-medium rounded-lg hover:bg-blue-50 transition-colors"
              >
                Load More
              </button>
            )}
          </div>
        </div>

        {/* Fixed Footer with Confirmation Buttons */}
        {pendingImage && (
          <div className="border-t border-gray-200 p-4 bg-white">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <img
                  src={pendingImage.image.image}
                  alt="Selected image"
                  className="w-12 h-12 rounded-lg object-cover"
                />
                <div>
                  <h4 className="font-medium text-blue-900">Use This Image?</h4>
                  <p className="text-sm text-blue-700">
                    This will replace AI image generation
                  </p>
                </div>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={handleCancelImage}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirmImage}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Use This Image
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const ProductServiceSelector: React.FC<ProductServiceSelectorProps> = ({
  selectedProducts,
  selectedServices,
  onProductsChange,
  onServicesChange,
  onProductImageSelect
}) => {
  const [showProductModal, setShowProductModal] = useState(false);
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [isLoadingServices, setIsLoadingServices] = useState(false);
  const [hasMoreProducts, setHasMoreProducts] = useState(true);
  const [hasMoreServices, setHasMoreServices] = useState(true);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const loadProducts = useCallback(async (isInitial = false) => {
    if (isInitial) setPage(1);
    setIsLoadingProducts(true);
    try {
      const response = await simplifiedContentApi.getProducts();
      const newProducts = response.results;
      setProducts(prev => isInitial ? newProducts : [...prev, ...newProducts]);
      setHasMoreProducts(!!response.next);
    } catch (error) {
      console.error('Error loading products:', error);
    } finally {
      setIsLoadingProducts(false);
    }
  }, []);

  const loadServices = useCallback(async (isInitial = false) => {
    if (isInitial) setPage(1);
    setIsLoadingServices(true);
    try {
      const response = await simplifiedContentApi.getServices();
      const newServices = response.results;
      setServices(prev => isInitial ? newServices : [...prev, ...newServices]);
      setHasMoreServices(!!response.next);
    } catch (error) {
      console.error('Error loading services:', error);
    } finally {
      setIsLoadingServices(false);
    }
  }, []);

  const handleProductSelect = (item: Product | Service) => {
    if (!isProduct(item)) return;
    
    const isSelected = selectedProducts.some(p => p.id === item.id);
    if (isSelected) {
      onProductsChange(selectedProducts.filter(p => p.id !== item.id));
    } else {
      const productWithImage: SelectedProductWithImage = {
        ...item,
        selectedImage: undefined
      };
      onProductsChange([...selectedProducts, productWithImage]);
    }
  };

  const handleServiceSelect = (item: Product | Service) => {
    if (isProduct(item)) return;
    
    const isSelected = selectedServices.some(s => s.id === item.id);
    if (isSelected) {
      onServicesChange(selectedServices.filter(s => s.id !== item.id));
    } else {
      onServicesChange([...selectedServices, item]);
    }
  };

  const handleProductImageSelect = (productId: number, image: ProductImage) => {
    // First update the product's selected image
    const updatedProducts = selectedProducts.map(product => {
      if (product.id === productId) {
        return {
          ...product,
          selectedImage: image
        };
      }
      return product;
    });
    onProductsChange(updatedProducts);

    // Then notify parent about image selection with confirmed=true
    if (onProductImageSelect) {
      onProductImageSelect({
        productId,
        imageId: image.id,
        imageUrl: image.image,
        confirmed: true
      });
    }
  };

  const handleProductSearch = useCallback((query: string) => {
    setSearchQuery(query);
    loadProducts(true);
  }, [loadProducts]);

  const handleServiceSearch = useCallback((query: string) => {
    setSearchQuery(query);
    loadServices(true);
  }, [loadServices]);

  return (
    <div className="space-y-6">
      <div>
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center space-x-2">
            <svg className="w-5 h-5 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
            </svg>
            <h3 className="font-medium text-gray-900">Products</h3>
          </div>
        </div>

        {/* Product list */}
        <div className="space-y-2">
          {selectedProducts.map(product => (
            <div
              key={product.id}
              className="flex items-center justify-between p-3 bg-blue-50 border border-blue-200 rounded-lg group hover:border-blue-300 transition-colors"
            >
              <div className="flex items-center space-x-3">
                {product.selectedImage && (
                  <img
                    src={product.selectedImage.image}
                    alt={product.name}
                    className="w-10 h-10 rounded-lg object-cover"
                  />
                )}
                <span className="text-sm font-medium text-blue-700">{product.name}</span>
                {product.images && product.images.length > 0 && (
                  <button
                    onClick={() => {
                      setShowProductModal(true);
                      loadProducts(true);
                    }}
                    className="text-xs text-blue-600 hover:text-blue-800"
                  >
                    View Images ({product.images.length})
                  </button>
                )}
              </div>
              <button
                onClick={() => handleProductSelect(product)}
                className="text-blue-600 hover:text-blue-800 opacity-0 group-hover:opacity-100 transition-opacity"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </button>
            </div>
          ))}
          <button
            onClick={() => {
              setShowProductModal(true);
              loadProducts(true);
            }}
            className="w-full p-3 border border-dashed border-gray-300 rounded-lg text-sm font-medium text-gray-600 hover:border-blue-500 hover:text-blue-600 hover:bg-blue-50 transition-all duration-200"
          >
            <div className="flex items-center justify-center space-x-2">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
              <span>Add Product</span>
            </div>
          </button>
        </div>
      </div>

      {/* Services section */}
      <div>
        <div className="flex items-center space-x-2 mb-3">
          <svg className="w-5 h-5 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
          </svg>
          <h3 className="font-medium text-gray-900">Services</h3>
        </div>
        <div className="space-y-2">
          {selectedServices.map(service => (
            <div
              key={service.id}
              className="flex items-center justify-between p-3 bg-green-50 border border-green-200 rounded-lg group hover:border-green-300 transition-colors"
            >
              <span className="text-sm font-medium text-green-700">{service.name}</span>
              <button
                onClick={() => handleServiceSelect(service)}
                className="text-green-600 hover:text-green-800 opacity-0 group-hover:opacity-100 transition-opacity"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </button>
            </div>
          ))}
          <button
            onClick={() => {
              setShowServiceModal(true);
              loadServices(true);
            }}
            className="w-full p-3 border border-dashed border-gray-300 rounded-lg text-sm font-medium text-gray-600 hover:border-green-500 hover:text-green-600 hover:bg-green-50 transition-all duration-200"
          >
            <div className="flex items-center justify-center space-x-2">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
              <span>Add Service</span>
            </div>
          </button>
        </div>
      </div>

      <SelectionModal
        isOpen={showProductModal}
        onClose={() => setShowProductModal(false)}
        title="Select Products"
        items={products}
        selectedItems={selectedProducts}
        onSelect={handleProductSelect}
        onSearch={handleProductSearch}
        isLoading={isLoadingProducts}
        hasMore={hasMoreProducts}
        onLoadMore={() => {
          setPage(prev => prev + 1);
          loadProducts();
        }}
        onImageSelect={handleProductImageSelect}
        isProductModal={true}
      />

      <SelectionModal
        isOpen={showServiceModal}
        onClose={() => setShowServiceModal(false)}
        title="Select Services"
        items={services}
        selectedItems={selectedServices}
        onSelect={handleServiceSelect}
        onSearch={handleServiceSearch}
        isLoading={isLoadingServices}
        hasMore={hasMoreServices}
        onLoadMore={() => {
          setPage(prev => prev + 1);
          loadServices();
        }}
        isProductModal={false}
      />
    </div>
  );
};
