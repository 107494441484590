import axiosInstance from '../utils/axiosConfig';

export interface BillingPeriodTotals {
  total_campaigns: number;
  quick_content_total: number;
  campaign_content_total: number;
  grand_total: number;
  image_generation_total: number;
  research_reports_total: number;
  total_products_with_descriptions: number;
  product_descriptions_this_period: number;
  billing_period_start: string;
  billing_period_end: string;
  current_time: string;
  subscription_status: string;
  debug_info?: {
    content_breakdown: Array<{
      content_type: string;
      status: string;
      count: number;
    }>;
    user_id: number;
    billing_start: string;
    billing_end: string;
    content_counts: {
      total: number;
      quick: number;
      campaign: number;
      image_generation: number;
      research_reports: number;
      total_products_with_descriptions: number;
      product_descriptions_this_period: number;
      by_type: Record<string, number>;
    };
  };
}

export const contentSummaryService = {
  getBillingPeriodTotals: async (): Promise<BillingPeriodTotals> => {
    const response = await axiosInstance.get('/content-summary/billing-period/');
    console.group('Content Summary Service');
    console.log('Raw response:', response);
    console.log('Response data:', response.data);
    console.log('Products with descriptions:', response.data.total_products_with_descriptions);
    console.log('Descriptions this period:', response.data.product_descriptions_this_period);
    console.log('Debug info:', response.data.debug_info);
    console.groupEnd();
    return response.data;
  }
};

export default contentSummaryService;
