import React from 'react';
import { NotificationType } from '../../hooks/shared/useNotification';

interface NotificationSystemProps {
  message: string | null;
  type: NotificationType;
  onClose?: () => void;
}

const NotificationSystem: React.FC<NotificationSystemProps> = ({ message, type, onClose }) => {
  if (!message) return null;

  const baseStyles = "fixed top-0 left-0 right-0 p-4 text-center transition-all duration-300 ease-in-out z-50";
  
  const typeStyles = {
    success: 'bg-green-500 text-white',
    error: 'bg-red-500 text-white',
    info: 'bg-blue-500 text-white'
  };

  return (
    <div 
      className={`${baseStyles} ${typeStyles[type]}`}
      style={{
        transform: message ? 'translateY(0)' : 'translateY(-100%)',
      }}
      role="alert"
    >
      <div className="container mx-auto px-4 flex items-center justify-between">
        <span className="flex-grow">{message}</span>
        {onClose && (
          <button
            onClick={onClose}
            className="ml-4 text-white hover:text-gray-200 focus:outline-none"
            aria-label="Close notification"
          >
            <svg
              className="h-5 w-5"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default NotificationSystem;
