import React, { createContext, useContext, useCallback, useState } from 'react';
import { Content } from '../types/content';
import { Campaign, CampaignFormData } from '../types/campaign';
import { ValidationRules, validateField, ValidationErrors } from '../utils/validation';
import { ContentType } from '../pages/simplified-content/types';

export type FormState = {
  [key: string]: any;
  errors: ValidationErrors;
  touched: Record<string, boolean>;
} & Partial<{
  contentType: ContentType;
  content: Partial<Content>;
  campaign: Partial<CampaignFormData>;
  keywords: string;
}>;

interface FormContextType {
  state: FormState;
  errors: ValidationErrors;
  touched: Record<string, boolean>;
  setField: (name: string, value: any) => void;
  setError: (name: string, error: string) => void;
  clearError: (name: string) => void;
  touchField: (name: string) => void;
  resetForm: () => void;
  validateField: (name: string, rules?: ValidationRules) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  isValid: () => boolean;
}

interface FormProviderProps {
  children: React.ReactNode;
  initialState?: FormState;
  validationRules?: ValidationRules;
}

const defaultInitialState: FormState = {
  errors: {},
  touched: {},
};

const FormContext = createContext<FormContextType | undefined>(undefined);

export const FormProvider: React.FC<FormProviderProps> = ({ 
  children, 
  initialState = defaultInitialState,
  validationRules = {}
}) => {
  const [state, setState] = useState<FormState>(initialState);
  const [errors, setErrors] = useState<ValidationErrors>(initialState.errors || {});
  const [touched, setTouched] = useState<Record<string, boolean>>(initialState.touched || {});

  const setField = useCallback((name: string, value: any) => {
    setState(prev => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const setError = useCallback((name: string, error: string) => {
    setErrors(prev => ({
      ...prev,
      [name]: error,
    }));
  }, []);

  const clearError = useCallback((name: string) => {
    setErrors(prev => {
      const newErrors = { ...prev };
      delete newErrors[name];
      return newErrors;
    });
  }, []);

  const touchField = useCallback((name: string) => {
    setTouched(prev => ({
      ...prev,
      [name]: true,
    }));
  }, []);

  const resetForm = useCallback(() => {
    setState(initialState);
    setErrors(initialState.errors || {});
    setTouched(initialState.touched || {});
  }, [initialState]);

  const validateFormField = useCallback((name: string, rules: ValidationRules = validationRules) => {
    // Skip validation for social_media_platforms
    if (name === 'social_media_platforms') {
      return;
    }

    const error = validateField(name, state[name], rules, state);
    if (error) {
      setErrors(prev => ({
        ...prev,
        [name]: error,
      }));
    } else {
      clearError(name);
    }
  }, [state, validationRules, clearError]);

  const handleChange = useCallback((
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    const newValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;

    setState(prev => ({
      ...prev,
      [name]: newValue,
    }));

    // Skip validation for social_media_platforms
    if (name !== 'social_media_platforms' && validationRules[name]) {
      validateFormField(name, validationRules);
    }
  }, [validationRules, validateFormField]);

  const isValid = useCallback((): boolean => {
    // Filter out social_media_platforms from required fields check
    const requiredFields = Object.keys(validationRules)
      .filter(field => 
        field !== 'social_media_platforms' && 
        validationRules[field].some(rule => rule.validate.toString().includes('required'))
      );
    return requiredFields.every(field => !errors[field]);
  }, [errors, validationRules]);

  const value = {
    state,
    errors,
    touched,
    setField,
    setError,
    clearError,
    touchField,
    resetForm,
    validateField: validateFormField,
    handleChange,
    isValid,
  };

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

export const useForm = () => {
  const context = useContext(FormContext);
  if (context === undefined) {
    throw new Error('useForm must be used within a FormProvider');
  }
  return context;
};
