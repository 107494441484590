import { useState, useCallback, useRef } from 'react';
import { useUploadStorage } from './useUploadStorage';

interface ChunkProgress {
  uploaded: number;
  total: number;
  currentChunk: number;
  totalChunks: number;
}

interface UseChunkUploadReturn {
  progress: ChunkProgress;
  isUploading: boolean;
  uploadFile: (file: File) => Promise<void>;
  cancelUpload: () => void;
  error: Error | null;
  resumeUpload: (fileId: string, file: File) => Promise<void>;
  hasResumableUpload: (file: File) => boolean;
}

const CHUNK_SIZE = 1024 * 1024; // 1MB chunks

export const useChunkUpload = (
  onUpload: (file: File) => Promise<void>,
  endpoint: string = '/api/upload'
): UseChunkUploadReturn => {
  const [progress, setProgress] = useState<ChunkProgress>({
    uploaded: 0,
    total: 0,
    currentChunk: 0,
    totalChunks: 0,
  });
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const abortController = useRef<AbortController | null>(null);

  const {
    saveUploadState,
    getUploadState,
    updateUploadedChunk,
    clearUploadState,
    getResumableUploads,
  } = useUploadStorage();

  const cancelUpload = useCallback(() => {
    if (abortController.current) {
      abortController.current.abort();
      abortController.current = null;
    }
    setIsUploading(false);
    setError(null);
    setProgress({
      uploaded: 0,
      total: 0,
      currentChunk: 0,
      totalChunks: 0,
    });
  }, []);

  const uploadChunk = useCallback(async (
    chunk: Blob,
    chunkIndex: number,
    totalChunks: number,
    fileId: string,
    fileName: string
  ): Promise<void> => {
    const formData = new FormData();
    formData.append('chunk', chunk);
    formData.append('chunkIndex', chunkIndex.toString());
    formData.append('totalChunks', totalChunks.toString());
    formData.append('fileId', fileId);
    formData.append('fileName', fileName);

    const response = await fetch(endpoint, {
      method: 'POST',
      body: formData,
      signal: abortController.current?.signal,
    });

    if (!response.ok) {
      throw new Error(`Upload failed with status ${response.status}`);
    }

    updateUploadedChunk(fileId, chunkIndex);
  }, [endpoint, updateUploadedChunk]);

  const processFileUpload = useCallback(async (
    file: File,
    fileId: string,
    uploadedChunks: number[] = []
  ): Promise<void> => {
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let uploadedSize = 0;

    setProgress({
      uploaded: 0,
      total: file.size,
      currentChunk: 0,
      totalChunks,
    });

    saveUploadState(fileId, file.name, file.size, uploadedChunks);

    for (let i = 0; i < totalChunks; i++) {
      if (uploadedChunks.includes(i)) {
        uploadedSize += Math.min(CHUNK_SIZE, file.size - i * CHUNK_SIZE);
        setProgress(prev => ({
          ...prev,
          uploaded: uploadedSize,
          currentChunk: i + 1,
        }));
        continue;
      }

      const start = i * CHUNK_SIZE;
      const end = Math.min(start + CHUNK_SIZE, file.size);
      const chunk = file.slice(start, end);

      await uploadChunk(chunk, i, totalChunks, fileId, file.name);

      uploadedSize += chunk.size;
      setProgress(prev => ({
        ...prev,
        uploaded: uploadedSize,
        currentChunk: i + 1,
      }));
    }

    clearUploadState(fileId);
  }, [uploadChunk, saveUploadState, clearUploadState]);

  const uploadFile = useCallback(async (file: File): Promise<void> => {
    try {
      setIsUploading(true);
      setError(null);
      abortController.current = new AbortController();

      const fileId = `${file.name}-${Date.now()}`;
      await processFileUpload(file, fileId);
      await onUpload(file);
    } catch (err) {
      if (err instanceof Error) {
        if (err.name === 'AbortError') {
          setError(new Error('Upload cancelled'));
        } else {
          setError(err);
        }
      } else {
        setError(new Error('Upload failed'));
      }
      throw err;
    } finally {
      setIsUploading(false);
      abortController.current = null;
    }
  }, [onUpload, processFileUpload]);

  const resumeUpload = useCallback(async (fileId: string, file: File): Promise<void> => {
    const state = getUploadState(fileId);
    if (!state || state.fileSize !== file.size) {
      throw new Error('Invalid resume state');
    }

    try {
      setIsUploading(true);
      setError(null);
      abortController.current = new AbortController();

      await processFileUpload(file, fileId, state.uploadedChunks);
      await onUpload(file);
    } catch (err) {
      if (err instanceof Error) {
        if (err.name === 'AbortError') {
          setError(new Error('Upload cancelled'));
        } else {
          setError(err);
        }
      } else {
        setError(new Error('Upload failed'));
      }
      throw err;
    } finally {
      setIsUploading(false);
      abortController.current = null;
    }
  }, [onUpload, processFileUpload, getUploadState]);

  const hasResumableUpload = useCallback((file: File): boolean => {
    const uploads = getResumableUploads();
    return uploads.some(upload => 
      upload.fileName === file.name && 
      upload.fileSize === file.size &&
      upload.uploadedChunks.length > 0
    );
  }, [getResumableUploads]);

  return {
    progress,
    isUploading,
    uploadFile,
    cancelUpload,
    error,
    resumeUpload,
    hasResumableUpload,
  };
};
