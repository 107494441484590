import React, { useState } from 'react';
import { Outlet, useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import SubscriptionStatus from './SubscriptionStatus';

// Icons as SVG components
const DashboardIcon = () => (
  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
  </svg>
);

const ContentIcon = () => (
  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
  </svg>
);

const HistoryIcon = () => (
  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
);

const StorageIcon = () => (
  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
  </svg>
);

const ImageIcon = () => (
  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
  </svg>
);

const CampaignIcon = () => (
  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
  </svg>
);

const BusinessProfileIcon = () => (
  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
  </svg>
);

const ResearchIcon = () => (
  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
  </svg>
);

const AdminIcon = () => (
  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
);

const LogoutIcon = () => (
  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
  </svg>
);

export interface LayoutProps {
  children?: React.ReactNode;
}

interface MenuItem {
  text: string;
  path: string;
  type?: 'divider' | 'spacer' | 'section';
  icon?: JSX.Element;
  onClick?: () => void;
}

function Layout({ children }: LayoutProps) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const baseMenuItems: MenuItem[] = [
    { text: '', path: '', type: 'spacer' },
    { text: '', path: '', type: 'spacer' },
    { text: '', path: '', type: 'spacer' },
    { text: '', path: '', type: 'spacer' },
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
    { text: '', path: '', type: 'spacer' },
    { text: '', path: '', type: 'spacer' },
    { text: 'Campaigns', icon: <CampaignIcon />, path: '/campaigns' },
    { text: 'Create', icon: <ContentIcon />, path: '/simplified-content' },
    { text: 'Content', icon: <HistoryIcon />, path: '/content-history' },
    { text: 'Research', icon: <ResearchIcon />, path: '/research' },
    { text: 'Profile', icon: <BusinessProfileIcon />, path: '/business-profile' },
    { text: 'Image Management', icon: <ImageIcon />, path: '/images' },
    { text: 'History', icon: <StorageIcon />, path: '/storage' },
    { text: '', path: '', type: 'divider' },
    { text: 'Logout', icon: <LogoutIcon />, path: '', onClick: handleLogout },
  ];

  const adminMenuItems: MenuItem[] = user?.is_staff ? [
    { text: '', path: '', type: 'divider' },
    { text: 'Admin Dashboard', icon: <AdminIcon />, path: '/admin' }
  ] : [];

  const menuItems = [...baseMenuItems, ...adminMenuItems];

  const isPathActive = (path: string) => {
    return location.pathname === path;
  };

  const renderNavItems = () => (
    <div className="space-y-2 px-3">
      {menuItems.map((item, index) => {
        if (item.type === 'spacer') {
          return <div key={`spacer-${index}`} className="h-2" />;
        }
        if (item.type === 'divider') {
          return (
            <div key={`divider-${index}`} className="my-4">
              <div className="h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent" />
            </div>
          );
        }
        if (item.type === 'section') {
          return (
            <div key={`section-${index}`} className="px-4 py-2 text-sm font-semibold text-gray-500">
              {item.text}
            </div>
          );
        }

        const isActive = item.path ? isPathActive(item.path) : false;

        if (item.onClick) {
          return (
            <button
              key={item.text}
              onClick={item.onClick}
              className="w-full group flex items-center gap-3 px-4 py-3 text-sm font-medium rounded-xl transition-all duration-200 ease-in-out
                text-gray-600 hover:bg-red-50 hover:text-red-600"
            >
              <span className="text-gray-400 group-hover:text-red-500 transition-colors duration-200">
                {item.icon}
              </span>
              <span className="flex-1">{item.text}</span>
            </button>
          );
        }

        return (
          <Link
            key={item.text}
            to={item.path}
            className={`w-full group relative flex items-center gap-3 px-4 py-3 text-sm font-medium rounded-xl transition-all duration-200 ease-in-out
              ${isActive 
                ? 'bg-gradient-to-r from-blue-600 to-indigo-600 text-white shadow-lg shadow-blue-500/30' 
                : 'hover:bg-gradient-to-r hover:from-blue-50 hover:to-indigo-50 text-gray-600 hover:text-blue-600'
              }
            `}
          >
            <span className={`transition-colors duration-200 ${isActive ? 'text-white' : 'text-gray-400 group-hover:text-blue-500'}`}>
              {item.icon}
            </span>
            <span className="flex-1">{item.text}</span>
            {isActive && (
              <span className="absolute inset-y-0 left-0 w-1 bg-white rounded-full" />
            )}
          </Link>
        );
      })}

      {/* Bottom section with subscription status */}
      <div className="mt-auto pt-8">
        <SubscriptionStatus />
      </div>
    </div>
  );

  return (
    <div className="min-h-screen min-h-[-webkit-fill-available] bg-gray-100 flex flex-col">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-50 bg-gradient-to-r from-blue-900 to-indigo-900 text-white h-32 shadow-lg">
        <div className="h-full px-4 flex items-center justify-between lg:px-8">
          {/* Left side with logo */}
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <img 
                src="/images/media_magic_logo.jpeg" 
                alt="Media Magic" 
                className="h-24 w-auto rounded shadow-lg transition-transform hover:scale-105"
              />
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <div className="lg:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="p-2 rounded-md text-white hover:bg-blue-800"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>

          {/* Right Side Content */}
          <div className="flex items-center space-x-6">
            {user && (
              <span className="text-lg">
                Welcome, {user.email}
              </span>
            )}
          </div>
        </div>
      </header>

      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden fixed inset-0 z-40 bg-black bg-opacity-50">
          <div className="fixed inset-y-0 left-0 w-64 bg-white shadow-lg">
            <div className="h-32 flex items-center justify-between px-4 border-b border-gray-200">
              <div className="flex items-center">
                <img 
                  src="/images/media_magic_logo.jpeg" 
                  alt="Media Magic" 
                  className="h-24 w-auto rounded mr-2"
                />
              </div>
              <button
                onClick={() => setIsMobileMenuOpen(false)}
                className="p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="mt-4 overflow-y-auto">{renderNavItems()}</div>
          </div>
        </div>
      )}

      {/* Desktop sidebar */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        <div className="flex-1 flex flex-col min-h-0 bg-white border-r border-gray-200">
          <div className="flex-1 flex flex-col pt-32 pb-4 overflow-y-auto">
            {renderNavItems()}
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="lg:pl-64 flex flex-col flex-1">
        <main className="flex-1 py-6 px-4 sm:px-6 lg:px-8 mt-32">
          <div className="max-w-[1280px] w-full mx-auto min-h-[720px]">
            {children || <Outlet />}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Layout;
