import React, { useState } from 'react';
import { Content } from '../../types/content';
import type { ContentRequirements } from '../../pages/simplified-content/types';

type ContentWithRequirements = Content & { requirements?: ContentRequirements };

interface ContentExportModalProps {
  content: ContentWithRequirements | null;
  onClose: () => void;
}

interface FormattedContent {
  topic: string;
  content: string;
}

const ContentExportModal: React.FC<ContentExportModalProps> = ({ content, onClose }) => {
  const [selectedFormat, setSelectedFormat] = useState<'text' | 'json'>('text');
  const [includeMetadata, setIncludeMetadata] = useState(false);
  
  if (!content) return null;

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const getFullUrl = (url: string | undefined | null): string | undefined => {
    if (!url) return undefined;
    if (url.startsWith('http') && !url.includes('oaidalleapiprodscus')) {
      // If it's a localhost URL, replace it with the media URL
      if (url.includes('localhost:8020/media')) {
        const mediaUrl = process.env.REACT_APP_MEDIA_URL || 'http://50.184.85.141/media';
        return url.replace('http://localhost:8020/media', mediaUrl);
      }
      return url;
    }
    if (url.startsWith('data:')) return url;
    
    // Use REACT_APP_MEDIA_URL for media files
    if (url.startsWith('/media/') || url.includes('/media/')) {
      const mediaUrl = process.env.REACT_APP_MEDIA_URL || 'http://50.184.85.141/media';
      const cleanPath = url.replace(/^\/media\//, '').replace(/.*\/media\//, '');
      return `${mediaUrl}/${cleanPath}`;
    }
    
    // Use API URL for other URLs
    const baseUrl = process.env.REACT_APP_API_URL || 'http://50.184.85.141';
    return `${baseUrl}${url.startsWith('/') ? '' : '/'}${url}`;
  };

  const getImageUrl = (): string | undefined => {
    // First check for platform images
    if (content.platform_images && content.platform && content.platform_images[content.platform]) {
      const platformImage = content.platform_images[content.platform];
      // Try each URL field in order
      const urls = [
        platformImage.local_image_url,
        platformImage.download_url,
        platformImage.image_url
      ];
      
      for (const url of urls) {
        const fullUrl = getFullUrl(url);
        if (fullUrl) {
          console.log('Using platform image URL:', fullUrl);
          return fullUrl;
        }
      }
    }
    
    // Then check for product image
    if (content.product?.images && content.product.images.length > 0) {
      const productImage = content.product.images[0];
      const imageUrl = getFullUrl(productImage.image);
      console.log('Using product image URL:', imageUrl);
      return imageUrl;
    }
    
    // Then check for direct content images
    const urls = [
      content.local_image_url,
      content.download_url,
      content.generated_image_url
    ];
    
    for (const url of urls) {
      const fullUrl = getFullUrl(url);
      if (fullUrl) {
        console.log('Using content image URL:', fullUrl);
        return fullUrl;
      }
    }
    
    // Finally check image history
    if (content.imageHistory && content.imageHistory.length > 0) {
      const latestImage = content.imageHistory[content.imageHistory.length - 1];
      const historyUrls = [
        latestImage.local_image_url,
        latestImage.download_url,
        latestImage.generated_image_url,
        latestImage.image_url
      ];
      
      for (const url of historyUrls) {
        const fullUrl = getFullUrl(url);
        if (fullUrl) {
          console.log('Using image history URL:', fullUrl);
          return fullUrl;
        }
      }
    }

    console.log('No image URL found');
    return undefined;
  };

  const getDownloadUrl = () => {
    return getImageUrl();
  };

  const handleImageDownload = async () => {
    const downloadUrl = getDownloadUrl();
    if (!downloadUrl) return;

    try {
      const response = await fetch(downloadUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `image-${content.id}${downloadUrl.match(/\.[^.]+$/)?.[0] || '.png'}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading image:', error);
      window.open(downloadUrl, '_blank');
    }
  };

  const getFormattedContent = () => {
    // Get the topic from requirements.topic, falling back to content.topic if needed
    const topicToUse = content.requirements?.topic || content.topic || '';
    
    const contentOnly: string | FormattedContent = selectedFormat === 'text' 
      ? `${topicToUse}\n\n${content.content}`
      : {
          topic: topicToUse,
          content: content.content
        };

    if (!includeMetadata) {
      return selectedFormat === 'text' 
        ? contentOnly as string
        : JSON.stringify(contentOnly, null, 2);
    }

    if (selectedFormat === 'text') {
      return `${contentOnly}\n\n${'-'.repeat(50)}\n\n` +
        `Campaign Objective: ${content.requirements?.objective || ''}\n` +
        `Platform: ${content.platform}\n` +
        `Type: ${content.content_type}\n` +
        `Created: ${formatDate(content.created_at)}\n` +
        `Status: ${content.content_status}\n` +
        (content.target_audience ? `Target Audience: ${content.target_audience}\n` : '') +
        (content.campaign?.name ? `Campaign: ${content.campaign.name}\n` : '');
    } else {
      const formattedJson = {
        topic: (contentOnly as FormattedContent).topic,
        content: (contentOnly as FormattedContent).content,
        metadata: {
          campaign_objective: content.requirements?.objective || '',
          platform: content.platform,
          content_type: content.content_type,
          created_at: content.created_at,
          status: content.content_status,
          target_audience: content.target_audience,
          campaign: content.campaign?.name
        }
      };
      return JSON.stringify(formattedJson, null, 2);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(getFormattedContent());
  };

  const handleDownload = () => {
    const blob = new Blob([getFormattedContent()], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `content-export-${content.id}.${selectedFormat === 'json' ? 'json' : 'txt'}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const imageUrl = getImageUrl();

  const metadata = {
    campaign_objective: content.requirements?.objective || '',
    platform: content.platform,
    type: content.content_type,
    created: formatDate(content.created_at),
    status: content.content_status,
    target_audience: content.target_audience,
    campaign: content.campaign?.name
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] flex flex-col">
        <div className="flex justify-between items-center p-6 border-b border-gray-200 bg-gradient-to-r from-blue-600 to-indigo-600 flex-shrink-0">
          <div>
            <h2 className="text-2xl font-bold text-white">Content Export</h2>
            <p className="text-blue-100 mt-1">
              {content.requirements?.topic || content.topic || 'Untitled'}
            </p>
          </div>
          <button
            onClick={onClose}
            className="text-white hover:text-gray-200 transition-colors"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="p-6 space-y-4 flex-shrink-0">
          <div className="flex flex-wrap gap-4">
            <button
              onClick={() => setSelectedFormat('text')}
              className={`px-4 py-2 rounded-lg transition-colors ${
                selectedFormat === 'text'
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
            >
              Text Format
            </button>
            <button
              onClick={() => setSelectedFormat('json')}
              className={`px-4 py-2 rounded-lg transition-colors ${
                selectedFormat === 'json'
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
            >
              JSON Format
            </button>
            <button
              onClick={handleDownload}
              className="px-4 py-2 rounded-lg bg-green-600 text-white hover:bg-green-700 transition-colors flex items-center space-x-2 ml-auto"
            >
              Download
            </button>
          </div>
        </div>

        <div className="overflow-y-auto flex-grow px-6 pb-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-4">
              {imageUrl && (
                <div className="bg-gray-50 rounded-lg p-4 relative group">
                  <img
                    src={imageUrl}
                    alt={content.requirements?.topic || content.topic || 'Content image'}
                    className="w-full h-auto rounded-lg shadow-lg"
                  />
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity bg-black bg-opacity-50 rounded-lg">
                    <button
                      onClick={handleImageDownload}
                      className="bg-white text-gray-800 px-4 py-2 rounded-lg shadow-lg hover:bg-gray-100 transition-colors flex items-center space-x-2"
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                      </svg>
                      <span>Download Image</span>
                    </button>
                  </div>
                </div>
              )}

              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="font-semibold text-gray-900 mb-2">Metadata Preview</h3>
                <div className="grid grid-cols-2 gap-4 text-sm">
                  {Object.entries(metadata).map(([key, value]) => value && (
                    <div key={key} className="flex flex-col">
                      <span className="text-gray-500 capitalize">{key.replace(/_/g, ' ')}</span>
                      <span className="text-gray-900">{value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="mb-2">
                <label className="flex items-center space-x-2 text-gray-700">
                  <input
                    type="checkbox"
                    checked={includeMetadata}
                    onChange={(e) => setIncludeMetadata(e.target.checked)}
                    className="form-checkbox h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                  />
                  <span>Include metadata in export</span>
                </label>
              </div>
              <pre className="whitespace-pre-wrap font-mono text-sm bg-white rounded-lg p-4 shadow-sm overflow-y-auto max-h-[400px]">
                {getFormattedContent()}
              </pre>
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-4 p-6 border-t border-gray-200 flex-shrink-0">
          <button
            onClick={handleCopy}
            className="bg-gray-600 hover:bg-gray-700 text-white px-6 py-2 rounded-lg transition-colors flex items-center"
          >
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
            </svg>
            Copy Content
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContentExportModal;
