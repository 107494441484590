import { Campaign as BaseCampaign } from './campaign';
import { PlatformImages, ContentMetadata, PlatformContent } from '../pages/simplified-content/types';

export const CONTENT_STATUSES = [
  'draft',
  'waiting_for_approval',
  'approved',
  'published',
  'archived'
] as const;

export type ContentStatus = typeof CONTENT_STATUSES[number];

// Platform names exactly as they appear in the backend
export const VIDEO_PLATFORMS = [
  'youtube_video',
  'youtube_short',
  'tiktok_video'
] as const;

export type VideoPlatform = typeof VIDEO_PLATFORMS[number];

// Add platform normalization function
export const normalizePlatform = (platform: string): string => {
  const normalizedPlatform = platform.trim().toLowerCase();
  // Handle the youtube_shorts -> youtube_short conversion
  if (normalizedPlatform === 'youtube_shorts') {
    return 'youtube_short';
  }
  return platform;
};

export interface VideoGenerationOptions {
  customBranding: boolean;
  subtitleLanguages: number;
  expedited: boolean;
}

export interface VideoPricing {
  basePrice: number;
  brandingPrice: number;
  subtitlesPrice: number;
  expeditedPrice: number;
  totalPrice: number;
}

export interface VideoGenerationOrder {
  id: string;
  content_id: string;
  platform: VideoPlatform;
  status: 'pending_payment' | 'paid' | 'processing' | 'completed' | 'failed';
  stripe_payment_id?: string;
  payment_status?: 'succeeded' | 'paid' | 'canceled' | 'failed' | null;
  payment_amount: number;
  video_length_minutes: number;
  options: VideoGenerationOptions;
  created_at: string;
  updated_at: string;
  completion_url?: string;
  video_url?: string;
}

export interface Content {
  id: number;
  created_at: string;
  content_type: 'campaign' | 'quick';
  content: string;
  platform: string;
  platform_objective?: string;
  platform_metadata?: Record<string, any>;
  platform_metrics?: Record<string, number>;
  topic?: string;
  content_status: ContentStatus;
  target_audience: string;
  campaign: BaseCampaign | null;
  content_format: string;
  parent_content_id?: number | null;
  content_id: string;
  local_image_path?: string;
  local_image_url?: string;
  download_url?: string;
  generated_image_url?: string;
  imageHistory?: ImageHistory[];
  platform_images?: PlatformImages;
  product?: Product | null;
  user?: number;
  metadata?: ContentMetadata;
  video_order?: VideoGenerationOrder;
  generated_content?: Record<string, PlatformContent>;
}

export type { Campaign } from './campaign';

export interface ImageHistory {
  id: number;
  created_at: string;
  image_url: string;
  content?: number;
  prompt?: string;
  generated_image_url?: string;
  local_image_path?: string;
  local_image_url?: string;
  download_url?: string;
  source?: string;
  user?: number;
}

export interface Product {
  id: number;
  name: string;
  description: string;
  images: ProductImage[];
  pricing?: ProductPricing;
}

export interface ProductImage {
  id: number;
  image: string;
  order: number;
  created_at: string;
}

export interface ProductPricing {
  retail_price?: number;
  sale_price?: number;
  special_price?: number;
  campaign_price?: number;
  show_retail_price: boolean;
  show_sale_price: boolean;
  show_special_price: boolean;
  show_campaign_price: boolean;
}

export interface FilterOptions {
  dateFrom: string;
  dateTo: string;
  campaign: string;
  status: string;
}

export interface TopicIdeasResponse {
  topic_ideas: string[];
  content: Content[];
  message?: string;
}

export type SortDirection = 'asc' | 'desc';

export interface ContentValidation {
  canGenerate: boolean;
  message?: string;
}

export interface ContentGenerationParams {
  businessProfile: any;
  selectedTopic: string;
  targetAudience: string;
  selectedCampaign: BaseCampaign | null;
  platform?: string;
  platform_objective?: string;
  parentContentId?: string;
}

export interface ContentGenerationResponse {
  content: string;
  message?: string;
}

export interface ContentHistoryGroup {
  campaign: BaseCampaign | null;
  contents: Content[];
}

export interface ContentHistoryGroups {
  [key: string]: ContentHistoryGroup;
}

export interface ImageGenerationResponse {
  image_url: string;
  local_image_path: string;
  download_url: string;
  prompt: string;
}

export const getContentId = (content: Content): string => {
  return content.content_id?.toString() || content.id.toString();
};

export const createImageHistoryEntry = (
  response: ImageGenerationResponse,
  contentId: number
): ImageHistory => ({
  id: Date.now(),
  created_at: new Date().toISOString(),
  image_url: response.image_url,
  content: contentId,
  prompt: response.prompt,
  generated_image_url: response.image_url,
  local_image_path: response.local_image_path,
  download_url: response.download_url,
  local_image_url: response.download_url,
  source: 'content'
});

export const VIDEO_PRICING = {
  FIRST_MINUTE_PRICE: 20.00,
  ADDITIONAL_MINUTE_PRICE: 10.00,
  BRANDING_PRICE: 5.00,
  SUBTITLE_PRICE_PER_LANGUAGE: 5.00,
  EXPEDITED_PRICE: 15.00
} as const;

export const calculateVideoPricing = (
  videoLengthMinutes: number,
  options: VideoGenerationOptions
): VideoPricing => {
  // Calculate base price with new pricing structure:
  // First minute costs $20, each additional minute costs $10
  const basePrice = videoLengthMinutes <= 1 
    ? VIDEO_PRICING.FIRST_MINUTE_PRICE 
    : VIDEO_PRICING.FIRST_MINUTE_PRICE + (videoLengthMinutes - 1) * VIDEO_PRICING.ADDITIONAL_MINUTE_PRICE;
    
  const brandingPrice = options.customBranding ? VIDEO_PRICING.BRANDING_PRICE : 0;
  const subtitlesPrice = options.subtitleLanguages * VIDEO_PRICING.SUBTITLE_PRICE_PER_LANGUAGE;
  const expeditedPrice = options.expedited ? VIDEO_PRICING.EXPEDITED_PRICE : 0;
  
  return {
    basePrice,
    brandingPrice,
    subtitlesPrice,
    expeditedPrice,
    totalPrice: basePrice + brandingPrice + subtitlesPrice + expeditedPrice
  };
};

export const isVideoPlatform = (platform: string): platform is VideoPlatform => {
  // Normalize the platform string by trimming and converting to lowercase
  const normalizedPlatform = normalizePlatform(platform).trim().toLowerCase();
  
  // Debug logging for platform validation
  console.log('Platform Validation:', {
    input: platform,
    normalized: normalizedPlatform,
    validPlatforms: VIDEO_PLATFORMS,
    matches: VIDEO_PLATFORMS.map(p => ({
      platform: p,
      matches: p.toLowerCase() === normalizedPlatform,
      inputLength: normalizedPlatform.length,
      validLength: p.length,
      comparison: {
        platformLower: p.toLowerCase(),
        normalizedPlatform,
        equal: p.toLowerCase() === normalizedPlatform
      }
    }))
  });

  // Check if the normalized platform matches any of the valid platforms (case-insensitive)
  return VIDEO_PLATFORMS.some(p => p.toLowerCase() === normalizedPlatform);
};
