import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import ResearchForm from '../components/research/ResearchForm';
import ResearchHistory from '../components/research/ResearchHistory';
import { ResearchReport } from '../types/research';
import { researchService } from '../services/research';

const Research: React.FC = () => {
  const [reports, setReports] = useState<ResearchReport[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fetchReports();
  }, []);

  const fetchReports = async () => {
    try {
      const response = await researchService.getReports();
      setReports(response);
    } catch (err: any) {
      console.error('Error fetching research reports:', err);
      setError('Failed to load research reports');
    }
  };

  const handleGenerateReport = async (data: {
    query: string;
    include_products: boolean;
    include_services: boolean;
    include_campaigns: boolean;
  }) => {
    setIsLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const response = await researchService.generateReport(data);
      setReports(prevReports => [response, ...prevReports]);
      setSuccessMessage('Research report generated successfully');
    } catch (err: any) {
      console.error('Error generating research report:', err);
      if (err.response?.data?.error) {
        if (err.response.data.limit_reached) {
          setError(`${err.response.data.error}. ${err.response.data.upgrade_info.message}`);
        } else {
          setError(err.response.data.error);
        }
      } else {
        setError('Failed to generate research report');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteReport = async (id: number) => {
    setIsDeleting(true);
    setError(null);
    setSuccessMessage(null);

    try {
      await researchService.deleteReport(id);
      setReports(prevReports => prevReports.filter(report => report.id !== id));
      setSuccessMessage('Research report deleted successfully');
    } catch (err: any) {
      console.error('Error deleting research report:', err);
      if (err.response?.data?.error) {
        setError(err.response.data.error);
      } else {
        setError('Failed to delete research report');
      }
    } finally {
      setIsDeleting(false);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Marketing Research & Reporting</h1>
        <p className="text-xl text-gray-600">
          Generate AI-powered marketing research reports based on your business profile
        </p>
      </div>

      {error && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6 rounded-lg" role="alert">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}

      {successMessage && (
        <div className="bg-green-50 border-l-4 border-green-500 p-4 mb-6 rounded-lg" role="alert">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-green-700">{successMessage}</p>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="lg:order-1">
          <div className="bg-white rounded-2xl shadow-xl p-8 sticky top-4">
            <ResearchForm onSubmit={handleGenerateReport} isLoading={isLoading} />
          </div>
        </div>
        <div className="lg:order-2">
          <div className="bg-white rounded-2xl shadow-xl p-8">
            <ResearchHistory 
              reports={reports} 
              onDelete={handleDeleteReport}
              isDeleting={isDeleting}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Research;
