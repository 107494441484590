interface ResearchTopic {
    id: number;
    category: string;
    question: string;
    description?: string;
}

export const researchTopics: ResearchTopic[] = [
    // Market Opportunities (1-15)
    {
        id: 1,
        category: "Market Opportunities",
        question: "What new services could complement my existing offerings?",
        description: "Identify potential service expansions that align with current capabilities"
    },
    {
        id: 2,
        category: "Market Opportunities",
        question: "Which emerging trends in my industry align with my capabilities?",
        description: "Analyze industry trends that match your business strengths"
    },
    {
        id: 3,
        category: "Market Opportunities",
        question: "What underserved markets exist in my area of operation?",
        description: "Identify gaps in local market coverage"
    },
    {
        id: 4,
        category: "Market Opportunities",
        question: "Which customer segments are currently underserved by my business?",
        description: "Analyze potential customer groups not currently targeted"
    },
    {
        id: 5,
        category: "Market Opportunities",
        question: "What seasonal opportunities could I capitalize on?",
        description: "Identify seasonal trends and opportunities"
    },
    {
        id: 6,
        category: "Market Opportunities",
        question: "How can I expand my business geographically?",
        description: "Analyze potential for geographic expansion"
    },
    {
        id: 7,
        category: "Market Opportunities",
        question: "What cross-selling opportunities exist within my current customer base?",
        description: "Identify opportunities to sell additional products/services"
    },
    {
        id: 8,
        category: "Market Opportunities",
        question: "Which emerging technologies could create new opportunities?",
        description: "Explore technology-driven market opportunities"
    },
    {
        id: 9,
        category: "Market Opportunities",
        question: "What partnership opportunities could benefit my business?",
        description: "Identify potential strategic partnerships"
    },
    {
        id: 10,
        category: "Market Opportunities",
        question: "How can I adapt my offerings for different market segments?",
        description: "Analyze market segmentation opportunities"
    },
    {
        id: 11,
        category: "Market Opportunities",
        question: "What international market opportunities exist for my business?",
        description: "Explore potential for international expansion"
    },
    {
        id: 12,
        category: "Market Opportunities",
        question: "Which market niches are currently underserved?",
        description: "Identify specialized market opportunities"
    },
    {
        id: 13,
        category: "Market Opportunities",
        question: "What subscription-based services could I offer?",
        description: "Explore recurring revenue opportunities"
    },
    {
        id: 14,
        category: "Market Opportunities",
        question: "How can I leverage current market disruptions?",
        description: "Identify opportunities from market changes"
    },
    {
        id: 15,
        category: "Market Opportunities",
        question: "What complementary products could I add to my lineup?",
        description: "Analyze product line expansion opportunities"
    },
    // Competitor Analysis (16-30)
    {
        id: 16,
        category: "Competitor Analysis",
        question: "What are my competitors' unique selling propositions?",
        description: "Analyze key differentiators of competitors"
    },
    {
        id: 17,
        category: "Competitor Analysis",
        question: "How do my competitors' pricing strategies compare to mine?",
        description: "Analyze market pricing patterns"
    },
    {
        id: 18,
        category: "Competitor Analysis",
        question: "What marketing channels are my competitors using effectively?",
        description: "Identify successful competitor marketing strategies"
    },
    {
        id: 19,
        category: "Competitor Analysis",
        question: "What gaps exist in my competitors' service offerings?",
        description: "Find opportunities in competitor service gaps"
    },
    {
        id: 20,
        category: "Competitor Analysis",
        question: "How do my competitors handle customer service?",
        description: "Analyze competitor customer service approaches"
    },
    {
        id: 21,
        category: "Competitor Analysis",
        question: "What technology solutions are my competitors using?",
        description: "Analyze competitor technology adoption"
    },
    {
        id: 22,
        category: "Competitor Analysis",
        question: "How do my competitors' product features compare to mine?",
        description: "Compare product feature sets"
    },
    {
        id: 23,
        category: "Competitor Analysis",
        question: "What are my competitors' expansion strategies?",
        description: "Analyze competitor growth patterns"
    },
    {
        id: 24,
        category: "Competitor Analysis",
        question: "How do competitors differentiate their brand messaging?",
        description: "Analyze competitor brand positioning"
    },
    {
        id: 25,
        category: "Competitor Analysis",
        question: "What customer segments are my competitors targeting?",
        description: "Identify competitor target markets"
    },
    {
        id: 26,
        category: "Competitor Analysis",
        question: "How do competitors handle online presence and e-commerce?",
        description: "Analyze competitor digital strategies"
    },
    {
        id: 27,
        category: "Competitor Analysis",
        question: "What loyalty programs do competitors offer?",
        description: "Analyze competitor retention strategies"
    },
    {
        id: 28,
        category: "Competitor Analysis",
        question: "How do competitors handle seasonal promotions?",
        description: "Analyze competitor promotional strategies"
    },
    {
        id: 29,
        category: "Competitor Analysis",
        question: "What partnerships have competitors formed?",
        description: "Analyze competitor strategic alliances"
    },
    {
        id: 30,
        category: "Competitor Analysis",
        question: "How do competitors handle product innovation?",
        description: "Analyze competitor innovation strategies"
    },
    // Customer Insights (31-45)
    {
        id: 31,
        category: "Customer Insights",
        question: "What are the primary pain points of my target customers?",
        description: "Identify key customer challenges"
    },
    {
        id: 32,
        category: "Customer Insights",
        question: "How do customers prefer to interact with businesses like mine?",
        description: "Analyze customer communication preferences"
    },
    {
        id: 33,
        category: "Customer Insights",
        question: "What factors influence customer purchasing decisions?",
        description: "Understand customer decision drivers"
    },
    {
        id: 34,
        category: "Customer Insights",
        question: "How are customer needs evolving in my industry?",
        description: "Track changing customer preferences"
    },
    {
        id: 35,
        category: "Customer Insights",
        question: "What customer feedback patterns emerge about my industry?",
        description: "Analyze customer feedback themes"
    },
    {
        id: 36,
        category: "Customer Insights",
        question: "How do customers research products in my industry?",
        description: "Understand customer research behavior"
    },
    {
        id: 37,
        category: "Customer Insights",
        question: "What are customers' post-purchase expectations?",
        description: "Analyze customer service expectations"
    },
    {
        id: 38,
        category: "Customer Insights",
        question: "How do customers perceive value in my industry?",
        description: "Understand value perception"
    },
    {
        id: 39,
        category: "Customer Insights",
        question: "What role does brand loyalty play in customer decisions?",
        description: "Analyze brand loyalty factors"
    },
    {
        id: 40,
        category: "Customer Insights",
        question: "How do customers compare different solutions?",
        description: "Understand comparison process"
    },
    {
        id: 41,
        category: "Customer Insights",
        question: "What customer service channels are most effective?",
        description: "Analyze service channel preferences"
    },
    {
        id: 42,
        category: "Customer Insights",
        question: "How do customers define quality in my industry?",
        description: "Understand quality perception"
    },
    {
        id: 43,
        category: "Customer Insights",
        question: "What role does convenience play in customer choices?",
        description: "Analyze convenience factors"
    },
    {
        id: 44,
        category: "Customer Insights",
        question: "How do customers share experiences with others?",
        description: "Understand word-of-mouth patterns"
    },
    {
        id: 45,
        category: "Customer Insights",
        question: "What customer segments are growing fastest?",
        description: "Identify growing market segments"
    },
    // Digital Strategy (46-60)
    {
        id: 46,
        category: "Digital Strategy",
        question: "How can I improve my website's conversion rate?",
        description: "Optimize website performance"
    },
    {
        id: 47,
        category: "Digital Strategy",
        question: "What digital marketing channels are most effective?",
        description: "Analyze digital marketing ROI"
    },
    {
        id: 48,
        category: "Digital Strategy",
        question: "How can I improve my social media engagement?",
        description: "Optimize social media strategy"
    },
    {
        id: 49,
        category: "Digital Strategy",
        question: "What content types resonate most with my audience?",
        description: "Analyze content effectiveness"
    },
    {
        id: 50,
        category: "Digital Strategy",
        question: "How can I leverage emerging digital technologies?",
        description: "Explore new digital opportunities"
    },
    {
        id: 51,
        category: "Digital Strategy",
        question: "What SEO strategies are most effective?",
        description: "Optimize search engine visibility"
    },
    {
        id: 52,
        category: "Digital Strategy",
        question: "How can I improve mobile user experience?",
        description: "Optimize mobile strategy"
    },
    {
        id: 53,
        category: "Digital Strategy",
        question: "What email marketing strategies work best?",
        description: "Optimize email campaigns"
    },
    {
        id: 54,
        category: "Digital Strategy",
        question: "How can I leverage marketing automation?",
        description: "Implement automation strategies"
    },
    {
        id: 55,
        category: "Digital Strategy",
        question: "What digital advertising channels are most effective?",
        description: "Optimize ad spend"
    },
    {
        id: 56,
        category: "Digital Strategy",
        question: "How can I improve online customer service?",
        description: "Enhance digital support"
    },
    {
        id: 57,
        category: "Digital Strategy",
        question: "What role should AI play in my digital strategy?",
        description: "Integrate AI solutions"
    },
    {
        id: 58,
        category: "Digital Strategy",
        question: "How can I improve data collection and analysis?",
        description: "Enhance data strategy"
    },
    {
        id: 59,
        category: "Digital Strategy",
        question: "What digital tools could streamline operations?",
        description: "Optimize digital operations"
    },
    {
        id: 60,
        category: "Digital Strategy",
        question: "How can I protect customer data privacy?",
        description: "Enhance digital security"
    },
    // Product Innovation (61-75)
    {
        id: 61,
        category: "Product Innovation",
        question: "What new features do customers want most?",
        description: "Identify desired features"
    },
    {
        id: 62,
        category: "Product Innovation",
        question: "How can I improve product sustainability?",
        description: "Enhance environmental impact"
    },
    {
        id: 63,
        category: "Product Innovation",
        question: "What packaging innovations could benefit my products?",
        description: "Optimize packaging design"
    },
    {
        id: 64,
        category: "Product Innovation",
        question: "How can I reduce production costs?",
        description: "Optimize manufacturing"
    },
    {
        id: 65,
        category: "Product Innovation",
        question: "What customization options should I offer?",
        description: "Enhance product personalization"
    },
    {
        id: 66,
        category: "Product Innovation",
        question: "How can I improve product durability?",
        description: "Enhance product quality"
    },
    {
        id: 67,
        category: "Product Innovation",
        question: "What new materials could improve my products?",
        description: "Explore material innovations"
    },
    {
        id: 68,
        category: "Product Innovation",
        question: "How can I make products more user-friendly?",
        description: "Improve usability"
    },
    {
        id: 69,
        category: "Product Innovation",
        question: "What product line extensions make sense?",
        description: "Analyze product expansion"
    },
    {
        id: 70,
        category: "Product Innovation",
        question: "How can I improve product safety?",
        description: "Enhance safety features"
    },
    {
        id: 71,
        category: "Product Innovation",
        question: "What automation opportunities exist in production?",
        description: "Optimize manufacturing process"
    },
    {
        id: 72,
        category: "Product Innovation",
        question: "How can I reduce environmental impact?",
        description: "Improve sustainability"
    },
    {
        id: 73,
        category: "Product Innovation",
        question: "What smart features could I add?",
        description: "Integrate smart technology"
    },
    {
        id: 74,
        category: "Product Innovation",
        question: "How can I improve product maintenance?",
        description: "Enhance serviceability"
    },
    {
        id: 75,
        category: "Product Innovation",
        question: "What packaging sustainability improvements are possible?",
        description: "Optimize packaging sustainability"
    },
    // Brand Development (76-90)
    {
        id: 76,
        category: "Brand Development",
        question: "How can I strengthen brand recognition?",
        description: "Enhance brand visibility"
    },
    {
        id: 77,
        category: "Brand Development",
        question: "What brand values resonate with customers?",
        description: "Align brand values"
    },
    {
        id: 78,
        category: "Brand Development",
        question: "How can I improve brand consistency?",
        description: "Enhance brand alignment"
    },
    {
        id: 79,
        category: "Brand Development",
        question: "What brand story should I tell?",
        description: "Develop brand narrative"
    },
    {
        id: 80,
        category: "Brand Development",
        question: "How can I build brand trust?",
        description: "Enhance brand credibility"
    },
    {
        id: 81,
        category: "Brand Development",
        question: "What brand partnerships make sense?",
        description: "Explore brand collaborations"
    },
    {
        id: 82,
        category: "Brand Development",
        question: "How can I improve brand loyalty?",
        description: "Enhance customer loyalty"
    },
    {
        id: 83,
        category: "Brand Development",
        question: "What brand extensions are possible?",
        description: "Explore brand expansion"
    },
    {
        id: 84,
        category: "Brand Development",
        question: "How can I measure brand performance?",
        description: "Track brand metrics"
    },
    {
        id: 85,
        category: "Brand Development",
        question: "What brand experiences should I create?",
        description: "Develop brand experiences"
    },
    {
        id: 86,
        category: "Brand Development",
        question: "How can I improve brand communication?",
        description: "Enhance brand messaging"
    },
    {
        id: 87,
        category: "Brand Development",
        question: "What brand assets need updating?",
        description: "Refresh brand elements"
    },
    {
        id: 88,
        category: "Brand Development",
        question: "How can I make my brand more authentic?",
        description: "Enhance brand authenticity"
    },
    {
        id: 89,
        category: "Brand Development",
        question: "What brand touchpoints need improvement?",
        description: "Optimize brand interactions"
    },
    {
        id: 90,
        category: "Brand Development",
        question: "How can I improve brand differentiation?",
        description: "Enhance brand uniqueness"
    },
    // Future Planning (91-100)
    {
        id: 91,
        category: "Future Planning",
        question: "What industry changes should I prepare for?",
        description: "Anticipate market changes"
    },
    {
        id: 92,
        category: "Future Planning",
        question: "How can I future-proof my business?",
        description: "Enhance business resilience"
    },
    {
        id: 93,
        category: "Future Planning",
        question: "What skills will my team need in the future?",
        description: "Plan workforce development"
    },
    {
        id: 94,
        category: "Future Planning",
        question: "How should I adapt to changing demographics?",
        description: "Prepare for demographic shifts"
    },
    {
        id: 95,
        category: "Future Planning",
        question: "What technology investments should I prioritize?",
        description: "Plan technology adoption"
    },
    {
        id: 96,
        category: "Future Planning",
        question: "How can I prepare for economic changes?",
        description: "Build economic resilience"
    },
    {
        id: 97,
        category: "Future Planning",
        question: "What sustainability measures should I implement?",
        description: "Plan environmental initiatives"
    },
    {
        id: 98,
        category: "Future Planning",
        question: "How should I evolve my business model?",
        description: "Plan business transformation"
    },
    {
        id: 99,
        category: "Future Planning",
        question: "What expansion opportunities should I pursue?",
        description: "Plan growth strategy"
    },
    {
        id: 100,
        category: "Future Planning",
        question: "How can I improve long-term competitiveness?",
        description: "Enhance competitive position"
    }
];

export const getTopicsByCategory = () => {
    const categories = new Map<string, ResearchTopic[]>();
    
    researchTopics.forEach(topic => {
        if (!categories.has(topic.category)) {
            categories.set(topic.category, []);
        }
        categories.get(topic.category)?.push(topic);
    });
    
    return categories;
};

export default researchTopics;
