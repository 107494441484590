import React from 'react';
import FormInput from '../../common/FormInput';
import { BusinessProfileFormSectionProps } from '../types/businessProfileForm';

type ColorField = 'primary_colors' | 'secondary_colors';

const VisualGuidelinesForm: React.FC<BusinessProfileFormSectionProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  // Helper function to handle color array changes
  const handleColorChange = (field: ColorField, index: number, value: string) => {
    const colors = [...(values[field] || [])];
    colors[index] = value;
    handleChange(field, colors);
  };

  // Helper function to add new color
  const addColor = (field: ColorField) => {
    const colors = [...(values[field] || []), '#000000'];
    handleChange(field, colors);
  };

  // Helper function to remove color
  const removeColor = (field: ColorField, index: number) => {
    const colors = [...(values[field] || [])];
    colors.splice(index, 1);
    handleChange(field, colors);
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-gray-900">Visual Guidelines</h3>
      
      <FormInput
        label="Logo Usage Guidelines"
        name="logo_usage_guidelines"
        value={values.logo_usage_guidelines}
        onChange={(e) => handleChange('logo_usage_guidelines', e.target.value)}
        onBlur={handleBlur}
        error={errors.logo_usage_guidelines}
        touched={touched.logo_usage_guidelines}
        multiline
        rows={4}
        maxLength={1000}
        helpText="Specify guidelines for how and where to use the brand logo (max 1000 characters)"
      />

      {/* Primary Colors */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">
          Primary Colors
        </label>
        <div className="space-y-2">
          {(values.primary_colors || []).map((color: string, index: number) => (
            <div key={index} className="flex items-center space-x-2">
              <input
                type="color"
                value={color}
                onChange={(e) => handleColorChange('primary_colors', index, e.target.value)}
                className="h-8 w-14 p-0 border-gray-300 rounded-md"
              />
              <input
                type="text"
                value={color}
                onChange={(e) => handleColorChange('primary_colors', index, e.target.value)}
                className="w-28 px-4 py-2 bg-gray-50 border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 hover:border-gray-300"
              />
              <button
                type="button"
                onClick={() => removeColor('primary_colors', index)}
                className="text-red-600 hover:text-red-800"
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => addColor('primary_colors')}
            className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
          >
            Add Primary Color
          </button>
        </div>
      </div>

      {/* Secondary Colors */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">
          Secondary Colors
        </label>
        <div className="space-y-2">
          {(values.secondary_colors || []).map((color: string, index: number) => (
            <div key={index} className="flex items-center space-x-2">
              <input
                type="color"
                value={color}
                onChange={(e) => handleColorChange('secondary_colors', index, e.target.value)}
                className="h-8 w-14 p-0 border-gray-300 rounded-md"
              />
              <input
                type="text"
                value={color}
                onChange={(e) => handleColorChange('secondary_colors', index, e.target.value)}
                className="w-28 px-4 py-2 bg-gray-50 border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 hover:border-gray-300"
              />
              <button
                type="button"
                onClick={() => removeColor('secondary_colors', index)}
                className="text-red-600 hover:text-red-800"
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => addColor('secondary_colors')}
            className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
          >
            Add Secondary Color
          </button>
        </div>
      </div>

      {/* Typography */}
      <div className="space-y-4">
        <h4 className="text-md font-medium text-gray-700">Typography</h4>
        
        <FormInput
          label="Heading Font"
          name="heading_font"
          value={values.heading_font}
          onChange={(e) => handleChange('heading_font', e.target.value)}
          onBlur={handleBlur}
          error={errors.heading_font}
          touched={touched.heading_font}
          placeholder="Arial, Helvetica, sans-serif"
          helpText="Font family for headings"
        />

        <FormInput
          label="Body Font"
          name="body_font"
          value={values.body_font}
          onChange={(e) => handleChange('body_font', e.target.value)}
          onBlur={handleBlur}
          error={errors.body_font}
          touched={touched.body_font}
          placeholder="Georgia, Times New Roman, serif"
          helpText="Font family for body text"
        />

        <FormInput
          label="Special Font"
          name="special_font"
          value={values.special_font}
          onChange={(e) => handleChange('special_font', e.target.value)}
          onBlur={handleBlur}
          error={errors.special_font}
          touched={touched.special_font}
          placeholder="Roboto, sans-serif"
          helpText="Font family for special text elements"
        />
      </div>
    </div>
  );
};

export default VisualGuidelinesForm;
