import { useEffect, useRef } from 'react';

export interface FormPersistenceOptions {
  key: string;
  enabled?: boolean;
  excludeFields?: string[];
}

export const useFormPersistence = <T extends Record<string, any>>(
  values: T,
  options: FormPersistenceOptions,
  onRestore?: (values: T) => void
): void => {
  const { key, enabled = true, excludeFields = [] } = options;
  const isInitialMount = useRef(true);
  const hasRestoredRef = useRef(false);
  const isRestoringRef = useRef(false);

  // Save form state when values change
  useEffect(() => {
    if (!enabled || isRestoringRef.current) return;

    // Skip saving on initial mount
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    try {
      const persistedValues = { ...values };
      excludeFields.forEach(field => {
        delete persistedValues[field];
      });

      // Only persist non-empty values
      const cleanedValues = Object.entries(persistedValues).reduce((acc, [key, value]) => {
        if (value !== null && value !== undefined && value !== '') {
          acc[key] = value;
        }
        return acc;
      }, {} as Record<string, any>);

      if (Object.keys(cleanedValues).length > 0) {
        console.log('Saving form state:', cleanedValues);
        localStorage.setItem(key, JSON.stringify(cleanedValues));
      }
    } catch (error) {
      console.error('Error saving form state:', error);
      // Clear potentially corrupted data
      localStorage.removeItem(key);
    }
  }, [values, key, enabled, excludeFields]);

  // Restore form state on mount only
  useEffect(() => {
    if (!enabled || hasRestoredRef.current || isRestoringRef.current) return;

    try {
      isRestoringRef.current = true;
      const persistedData = localStorage.getItem(key);

      if (persistedData && onRestore) {
        const parsedData = JSON.parse(persistedData) as T;
        
        // Only restore non-empty values
        const cleanedData = Object.entries(parsedData).reduce((acc, [key, value]) => {
          if (value !== null && value !== undefined && value !== '') {
            acc[key] = value;
          }
          return acc;
        }, {} as Record<string, any>);

        // Only restore if we have valid data
        if (Object.keys(cleanedData).length > 0) {
          console.log('Restoring form data:', cleanedData);
          onRestore(cleanedData as T);
        }
      }
    } catch (error) {
      console.error('Error restoring form data:', error);
      // Clear potentially corrupted data
      localStorage.removeItem(key);
    } finally {
      hasRestoredRef.current = true;
      isRestoringRef.current = false;
    }
  }, [key, enabled, onRestore]);

  // Clean up persisted data when component unmounts
  useEffect(() => {
    return () => {
      if (enabled) {
        try {
          localStorage.removeItem(key);
        } catch (error) {
          console.error('Error cleaning up form data:', error);
        }
      }
    };
  }, [key, enabled]);
};
