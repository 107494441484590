import React from 'react';

interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label: string;
  error?: string;
  touched?: boolean;
  multiline?: boolean;
  rows?: number;
  helpText?: string;
  maxLength?: number;
}

const FormInput: React.FC<FormInputProps> = ({
  label,
  error,
  touched,
  multiline = false,
  rows = 3,
  helpText,
  className = '',
  maxLength,
  ...props
}) => {
  const baseClasses = "w-full px-4 py-2 bg-gray-50 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200";
  const inputClasses = `${baseClasses} ${
    touched && error
      ? 'border-red-300 bg-red-50'
      : 'border-gray-200 hover:border-gray-300'
  } ${className}`;

  return (
    <div className="space-y-1">
      <label className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      {helpText && (
        <p className="text-sm text-gray-500 mb-1">{helpText}</p>
      )}
      {multiline ? (
        <textarea
          {...(props as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
          rows={rows}
          maxLength={maxLength}
          className={`${inputClasses} resize-none`}
        />
      ) : (
        <input
          {...(props as React.InputHTMLAttributes<HTMLInputElement>)}
          maxLength={maxLength}
          className={inputClasses}
        />
      )}
      {touched && error && (
        <p className="text-sm text-red-600">{error}</p>
      )}
    </div>
  );
};

export default FormInput;

export type { FormInputProps };
