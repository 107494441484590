import React from 'react';
import FormInput from '../../common/FormInput';
import { BusinessProfileFormSectionProps } from '../types/businessProfileForm';

const BrandIdentityForm: React.FC<BusinessProfileFormSectionProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-gray-900">Brand Identity</h3>
      
      <FormInput
        label="Mission Statement"
        name="mission_statement"
        value={values.mission_statement}
        onChange={(e) => handleChange('mission_statement', e.target.value)}
        onBlur={handleBlur}
        error={errors.mission_statement}
        touched={touched.mission_statement}
        multiline
        rows={4}
        maxLength={1000}
        helpText="What your brand stands for and its purpose (max 1000 characters)"
      />

      <FormInput
        label="Brand Personality"
        name="brand_personality"
        value={values.brand_personality}
        onChange={(e) => handleChange('brand_personality', e.target.value)}
        onBlur={handleBlur}
        error={errors.brand_personality}
        touched={touched.brand_personality}
        multiline
        rows={3}
        maxLength={500}
        helpText="The tone, traits, and attributes that define your brand (max 500 characters)"
      />

      <FormInput
        label="Industry Target Demographics"
        name="industry_target_demographics"
        value={values.industry_target_demographics}
        onChange={(e) => handleChange('industry_target_demographics', e.target.value)}
        onBlur={handleBlur}
        error={errors.industry_target_demographics}
        touched={touched.industry_target_demographics}
        multiline
        rows={3}
        maxLength={500}
        helpText="Describe the key demographics and characteristics of your industry's target market. This differs from campaign-specific targeting and helps shape overall brand messaging. (max 500 characters)"
      />

      <FormInput
        label="Market Segments"
        name="market_segments"
        value={values.market_segments}
        onChange={(e) => handleChange('market_segments', e.target.value)}
        onBlur={handleBlur}
        error={errors.market_segments}
        touched={touched.market_segments}
        multiline
        rows={3}
        maxLength={1000}
        placeholder="Retail, Wholesale, Distributors, Education, Government"
        helpText="List the distinct market segments your business serves (e.g., 'Retail, Wholesale, Distributors, Education, Government'). These segments can be targeted specifically during campaign creation. (max 1000 characters)"
      />
    </div>
  );
};

export default BrandIdentityForm;
