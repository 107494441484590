import React, { useState, useEffect } from 'react';
import { adminService } from '../../services/admin';
import { PromptHistory, PromptHistoryStats } from '../../types/admin';
import PromptHistorySettings from './PromptHistorySettings';

interface PromptHistoryPanelProps {
  setError: (error: string | null) => void;
}

const PromptHistoryPanel: React.FC<PromptHistoryPanelProps> = ({ setError }) => {
  const [prompts, setPrompts] = useState<PromptHistory[]>([]);
  const [stats, setStats] = useState<PromptHistoryStats | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [promptType, setPromptType] = useState<string>('');
  const [days, setDays] = useState<number>(7);
  const [expandedPrompt, setExpandedPrompt] = useState<number | null>(null);

  const promptTypes = [
    { value: '', label: 'All Types' },
    { value: 'simplified-content', label: 'Simplified Content' },
    { value: 'research', label: 'Marketing Research' },
    { value: 'image', label: 'Image Generation' },
    { value: 'product-description', label: 'Product Description' }
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [promptsResponse, statsResponse] = await Promise.all([
          adminService.getPromptHistory({ page, prompt_type: promptType, days }),
          adminService.getPromptHistoryStats(days)
        ]);

        setPrompts(promptsResponse.results);
        setTotalPages(promptsResponse.total_pages);
        setStats(statsResponse);
        setError(null);
      } catch (err) {
        setError('Failed to load prompt history');
        console.error('Error loading prompt history:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [page, promptType, days, setError]);

  const handleDownload = async () => {
    try {
      await adminService.downloadPromptHistory();
      setError(null);
    } catch (err) {
      setError('Failed to download prompt history');
      console.error('Error downloading prompt history:', err);
    }
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString();
  };

  const formatCost = (cost: number | string | undefined) => {
    if (cost === undefined || cost === null) return '$0.0000';
    // Convert string to number if needed
    const numericCost = typeof cost === 'string' ? parseFloat(cost) : cost;
    return `$${numericCost.toFixed(4)}`;
  };

  const getContentReference = (prompt: PromptHistory) => {
    if (!prompt.metadata) return '';

    // First try to get the explicit content reference
    if (prompt.metadata.content_reference) {
      return prompt.metadata.content_reference;
    }

    // Build reference based on content type and ID
    const contentType = prompt.metadata.content_type;
    const contentId = prompt.metadata.content_id || 
                     prompt.metadata.product_id || 
                     prompt.metadata.report_id;

    if (contentType && contentId) {
      return `${contentType}-${contentId}`;
    }

    // For image prompts, try to get parent content reference
    if (prompt.prompt_type === 'image' && prompt.metadata.parent_prompt_id) {
      return `image-${prompt.metadata.parent_prompt_id}`;
    }

    return '';
  };

  const renderPromptDetails = (prompt: PromptHistory) => {
    try {
      const promptData = JSON.parse(prompt.prompt);
      const responseData = prompt.response;

      return (
        <div className="bg-gray-50 rounded-lg max-w-full">
          {/* Blue header bar with Close text and X icon */}
          <div 
            onClick={() => setExpandedPrompt(null)}
            className="bg-blue-600 h-10 rounded-t-lg cursor-pointer hover:bg-blue-700 flex items-center justify-between px-4"
          >
            <span className="text-white text-lg font-semibold">Close</span>
            <span className="text-white text-xl font-bold">×</span>
          </div>

          {/* Content */}
          <div className="p-4 space-y-6 overflow-x-hidden">
            <div>
              <h4 className="font-semibold mb-2">Content Reference:</h4>
              <p className="text-sm text-gray-700 break-all">{getContentReference(prompt)}</p>
            </div>

            <div>
              <h4 className="font-semibold mb-2">Messages:</h4>
              {promptData.messages?.map((msg: any, idx: number) => (
                <div key={idx} className="mb-2 pl-4 border-l-2 border-blue-500">
                  <p className="font-medium text-sm text-gray-600">{msg.role}:</p>
                  <p className="whitespace-pre-wrap text-sm break-words">{msg.content}</p>
                </div>
              ))}
            </div>

            {promptData.requirements && (
              <div>
                <h4 className="font-semibold mb-2">Requirements:</h4>
                <pre className="bg-white p-2 rounded text-sm overflow-x-auto whitespace-pre-wrap break-words max-w-full">
                  {JSON.stringify(promptData.requirements, null, 2)}
                </pre>
              </div>
            )}

            {promptData.platform && (
              <div>
                <h4 className="font-semibold mb-2">Platform:</h4>
                <pre className="bg-white p-2 rounded text-sm overflow-x-auto whitespace-pre-wrap break-words max-w-full">
                  {JSON.stringify(promptData.platform, null, 2)}
                </pre>
              </div>
            )}

            {responseData && (
              <div>
                <h4 className="font-semibold mb-2">Response:</h4>
                <div className="space-y-2">
                  {responseData.content && (
                    <div className="bg-white p-2 rounded">
                      <p className="font-medium text-sm">Generated Content:</p>
                      <p className="whitespace-pre-wrap text-sm break-words">{responseData.content}</p>
                    </div>
                  )}
                  {responseData.image_url && (
                    <div className="bg-white p-2 rounded">
                      <p className="font-medium text-sm">Generated Image:</p>
                      <img src={responseData.image_url} alt="Generated" className="mt-2 max-w-full h-auto" />
                    </div>
                  )}
                  {responseData.usage && (
                    <div className="bg-white p-2 rounded">
                      <p className="font-medium text-sm">Token Usage:</p>
                      <pre className="text-sm whitespace-pre-wrap break-words">
                        {JSON.stringify(responseData.usage, null, 2)}
                      </pre>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } catch (error) {
      return (
        <div className="bg-gray-50 rounded-lg max-w-full">
          {/* Blue header bar with Close text and X icon */}
          <div 
            onClick={() => setExpandedPrompt(null)}
            className="bg-blue-600 h-10 rounded-t-lg cursor-pointer hover:bg-blue-700 flex items-center justify-between px-4"
          >
            <span className="text-white text-lg font-semibold">Close</span>
            <span className="text-white text-xl font-bold">×</span>
          </div>

          {/* Content */}
          <div className="p-4 overflow-x-hidden">
            <p className="text-sm text-gray-500">Raw prompt:</p>
            <pre className="whitespace-pre-wrap text-sm mt-2 break-words">{prompt.prompt}</pre>
          </div>
        </div>
      );
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Settings Section */}
      <PromptHistorySettings setError={setError} />

      {/* Stats Section */}
      {stats && (
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-semibold mb-4">Usage Statistics</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="p-4 bg-gray-50 rounded-lg">
              <p className="text-sm text-gray-500">Total Prompts</p>
              <p className="text-2xl font-semibold">{stats.total_prompts || 0}</p>
            </div>
            <div className="p-4 bg-gray-50 rounded-lg">
              <p className="text-sm text-gray-500">Total Tokens</p>
              <p className="text-2xl font-semibold">{stats.total_tokens || 0}</p>
            </div>
            <div className="p-4 bg-gray-50 rounded-lg">
              <p className="text-sm text-gray-500">Total Cost</p>
              <p className="text-2xl font-semibold">{formatCost(stats.total_cost)}</p>
            </div>
          </div>
        </div>
      )}

      {/* Filters Section */}
      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex flex-wrap gap-4 items-center justify-between mb-4">
          <div className="flex gap-4 items-center">
            <select
              value={promptType}
              onChange={(e) => setPromptType(e.target.value)}
              className="rounded-md border border-gray-300 px-3 py-2"
            >
              {promptTypes.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
            <select
              value={days}
              onChange={(e) => setDays(Number(e.target.value))}
              className="rounded-md border border-gray-300 px-3 py-2"
            >
              <option value={7}>Last 7 days</option>
              <option value={30}>Last 30 days</option>
              <option value={90}>Last 90 days</option>
            </select>
          </div>
          <button
            onClick={handleDownload}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Download History
          </button>
        </div>

        {/* Prompts Table */}
        {prompts.length === 0 ? (
          <div className="text-center text-gray-500 py-8">
            No prompt history available
          </div>
        ) : (
          <>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Type
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Reference ID
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      User
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Tokens
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Cost
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {prompts.map((prompt) => (
                    <React.Fragment key={prompt.id}>
                      <tr className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {prompt.prompt_type}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600">
                          {getContentReference(prompt)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {prompt.created_by_email}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {prompt.tokens_used || 0}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatCost(prompt.cost)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatDate(prompt.created_at)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <button
                            onClick={() => setExpandedPrompt(expandedPrompt === prompt.id ? null : prompt.id)}
                            className="text-blue-600 hover:text-blue-800"
                          >
                            {expandedPrompt === prompt.id ? 'Hide Details' : 'Show Details'}
                          </button>
                        </td>
                      </tr>
                      {expandedPrompt === prompt.id && (
                        <tr>
                          <td colSpan={7} className="px-6 py-4">
                            {renderPromptDetails(prompt)}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            {totalPages > 1 && (
              <div className="px-6 py-4 border-t border-gray-200 flex items-center justify-between">
                <button
                  onClick={() => setPage(p => Math.max(1, p - 1))}
                  disabled={page === 1}
                  className={`px-4 py-2 text-sm font-medium rounded-md ${
                    page === 1
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
                  }`}
                >
                  Previous
                </button>
                <span className="text-sm text-gray-700">
                  Page {page} of {totalPages}
                </span>
                <button
                  onClick={() => setPage(p => Math.min(totalPages, p + 1))}
                  disabled={page === totalPages}
                  className={`px-4 py-2 text-sm font-medium rounded-md ${
                    page === totalPages
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
                  }`}
                >
                  Next
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PromptHistoryPanel;
