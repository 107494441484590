import axiosInstance from '../utils/axiosConfig';
import { BusinessProfile } from '../types/business';
import { Product, Service, ProductImage } from '../types/products';
import { ProductFormData, ServiceFormData } from '../types/businessProfile';
import { ProductDescriptionResponse, BatchGenerateResponse } from '../types/productDescriptions';
import { fetchCsrfToken, handleAxiosError } from './api';

export const businessService = {
  getBusinessProfile: async (): Promise<BusinessProfile> => {
    console.log('Fetching business profile');
    try {
      // Use the full_profile endpoint to get all fields
      const response = await axiosInstance.get<BusinessProfile>('/business-profiles/full_profile/');
      console.log('Business profile response:', response.data); // Debug log
      return response.data;
    } catch (error) {
      console.error('Error fetching business profile:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  createBusinessProfile: async (profileData: Partial<BusinessProfile>): Promise<BusinessProfile> => {
    console.log('Creating new business profile');
    try {
      await fetchCsrfToken();
      // Get current user from the session
      const userResponse = await axiosInstance.get('/user/');
      const userId = userResponse.data.id;

      // Add user ID to profile data
      const dataWithUser = {
        ...profileData,
        user: userId
      };

      console.log('Creating profile with data:', dataWithUser);
      const response = await axiosInstance.post<BusinessProfile>('/business-profiles/create/', dataWithUser);
      console.log('Business profile created successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error creating business profile:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  updateBusinessProfile: async (profileData: Partial<BusinessProfile>): Promise<BusinessProfile> => {
    console.log('Updating business profile');
    try {
      await fetchCsrfToken();
      // If there's no ID, create a new profile instead of updating
      if (!profileData.id || profileData.id === 0) {
        console.log('No profile ID found, creating new profile instead');
        return await businessService.createBusinessProfile(profileData);
      }

      // Get current user from the session
      const userResponse = await axiosInstance.get('/user/');
      const userId = userResponse.data.id;

      // Add user ID to profile data
      const dataWithUser = {
        ...profileData,
        user: userId
      };

      console.log('Updating profile with data:', dataWithUser); // Debug log
      const response = await axiosInstance.put<BusinessProfile>('/business-profiles/update/', dataWithUser);
      console.log('Business profile updated successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating business profile:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  clearBusinessProfile: async (): Promise<void> => {
    console.log('Clearing business profile');
    try {
      await fetchCsrfToken();
      await axiosInstance.put('/business-profiles/clear/');
      console.log('Business profile cleared successfully');
    } catch (error) {
      console.error('Error clearing business profile:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  deleteBusinessProfile: async (): Promise<void> => {
    console.log('Deleting business profile');
    try {
      await fetchCsrfToken();
      await axiosInstance.delete('/business-profiles/delete/');
      console.log('Business profile deleted successfully');
    } catch (error) {
      console.error('Error deleting business profile:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  getProducts: async (): Promise<Product[]> => {
    console.log('Fetching products');
    try {
      const response = await axiosInstance.get<Product[]>('/products/');
      console.log('Products fetched:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching products:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  getProduct: async (productId: number): Promise<Product> => {
    console.log(`Fetching product ${productId}`);
    try {
      // Add a small delay to ensure the server has processed any recent updates
      await new Promise(resolve => setTimeout(resolve, 100));
      
      const response = await axiosInstance.get<Product>(`/products/${productId}/`);
      console.log('Product fetched:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching product:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  getServices: async (): Promise<Service[]> => {
    console.log('Fetching services');
    try {
      const response = await axiosInstance.get<Service[]>('/services/');
      console.log('Services fetched:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching services:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  createProduct: async (productData: ProductFormData): Promise<Product> => {
    console.log('Creating new product');
    try {
      await fetchCsrfToken();
      const formattedData = {
        name: productData.name,
        description: productData.description,
        category: productData.category,
        sku: productData.sku,
        inventory_count: productData.inventory_count,
        tags: productData.tags,
        is_active: productData.is_active,
        pricing: {
          retail_price: productData.retail_price ? parseFloat(productData.retail_price) : null,
          sale_price: productData.sale_price ? parseFloat(productData.sale_price) : null,
          special_price: productData.special_price ? parseFloat(productData.special_price) : null,
          campaign_price: productData.campaign_price ? parseFloat(productData.campaign_price) : null,
          show_retail_price: productData.show_retail_price || false,
          show_sale_price: productData.show_sale_price || false,
          show_special_price: productData.show_special_price || false,
          show_campaign_price: productData.show_campaign_price || false,
          currency: 'USD', // Default currency
        }
      };
      console.log('Creating product with data:', formattedData);
      const response = await axiosInstance.post<Product>('/products/', formattedData);
      console.log('Product created successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error creating product:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  createService: async (serviceData: ServiceFormData): Promise<Service> => {
    console.log('Creating new service');
    try {
      await fetchCsrfToken();
      const formattedData = {
        name: serviceData.name,
        description: serviceData.description,
        category: serviceData.category,
        duration: serviceData.duration,
        availability: serviceData.availability,
        tags: serviceData.tags,
        is_active: serviceData.is_active,
        pricing: {
          retail_price: serviceData.retail_price ? parseFloat(serviceData.retail_price) : null,
          sale_price: serviceData.sale_price ? parseFloat(serviceData.sale_price) : null,
          special_price: serviceData.special_price ? parseFloat(serviceData.special_price) : null,
          campaign_price: serviceData.campaign_price ? parseFloat(serviceData.campaign_price) : null,
          show_retail_price: serviceData.show_retail_price || false,
          show_sale_price: serviceData.show_sale_price || false,
          show_special_price: serviceData.show_special_price || false,
          show_campaign_price: serviceData.show_campaign_price || false,
          currency: 'USD', // Default currency
        }
      };
      console.log('Creating service with data:', formattedData);
      const response = await axiosInstance.post<Service>('/services/', formattedData);
      console.log('Service created successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error creating service:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  updateProduct: async (id: number, productData: ProductFormData): Promise<Product> => {
    console.log(`Updating product with id: ${id}`);
    try {
      await fetchCsrfToken();
      const formattedData = {
        name: productData.name,
        description: productData.description,
        category: productData.category,
        sku: productData.sku,
        inventory_count: productData.inventory_count,
        tags: productData.tags,
        is_active: productData.is_active,
        pricing: {
          retail_price: productData.retail_price ? parseFloat(productData.retail_price) : null,
          sale_price: productData.sale_price ? parseFloat(productData.sale_price) : null,
          special_price: productData.special_price ? parseFloat(productData.special_price) : null,
          campaign_price: productData.campaign_price ? parseFloat(productData.campaign_price) : null,
          show_retail_price: productData.show_retail_price || false,
          show_sale_price: productData.show_sale_price || false,
          show_special_price: productData.show_special_price || false,
          show_campaign_price: productData.show_campaign_price || false,
          currency: 'USD', // Default currency
        }
      };
      console.log('Updating product with data:', formattedData);
      const response = await axiosInstance.put<Product>(`/products/${id}/`, formattedData);
      console.log('Product updated successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating product:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  updateService: async (id: number, serviceData: ServiceFormData): Promise<Service> => {
    console.log(`Updating service with id: ${id}`);
    try {
      await fetchCsrfToken();
      const formattedData = {
        name: serviceData.name,
        description: serviceData.description,
        category: serviceData.category,
        duration: serviceData.duration,
        availability: serviceData.availability,
        tags: serviceData.tags,
        is_active: serviceData.is_active,
        pricing: {
          retail_price: serviceData.retail_price ? parseFloat(serviceData.retail_price) : null,
          sale_price: serviceData.sale_price ? parseFloat(serviceData.sale_price) : null,
          special_price: serviceData.special_price ? parseFloat(serviceData.special_price) : null,
          campaign_price: serviceData.campaign_price ? parseFloat(serviceData.campaign_price) : null,
          show_retail_price: serviceData.show_retail_price || false,
          show_sale_price: serviceData.show_sale_price || false,
          show_special_price: serviceData.show_special_price || false,
          show_campaign_price: serviceData.show_campaign_price || false,
          currency: 'USD', // Default currency
        }
      };
      console.log('Updating service with data:', formattedData);
      const response = await axiosInstance.put<Service>(`/services/${id}/`, formattedData);
      console.log('Service updated successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating service:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  deleteProduct: async (id: number): Promise<void> => {
    console.log(`Deleting product with id: ${id}`);
    try {
      await fetchCsrfToken();
      await axiosInstance.delete(`/products/${id}/`);
      console.log('Product deleted successfully');
    } catch (error) {
      console.error('Error deleting product:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  deleteService: async (id: number): Promise<void> => {
    console.log(`Deleting service with id: ${id}`);
    try {
      await fetchCsrfToken();
      await axiosInstance.delete(`/services/${id}/`);
      console.log('Service deleted successfully');
    } catch (error) {
      console.error('Error deleting service:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  uploadProductImage: async (productId: number, image: File, order?: number): Promise<ProductImage> => {
    console.log(`Uploading image for product with id: ${productId}`);
    try {
      await fetchCsrfToken();
      const formData = new FormData();
      formData.append('image', image);
      if (order !== undefined) {
        formData.append('order', order.toString());
      }
      const response = await axiosInstance.post<ProductImage>(
        `/products/${productId}/upload_image/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log('Image uploaded successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error uploading product image:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  deleteProductImage: async (productId: number, imageId: number): Promise<void> => {
    console.log(`Deleting image ${imageId} from product ${productId}`);
    try {
      await fetchCsrfToken();
      await axiosInstance.delete(`/products/${productId}/delete_image/`, {
        data: { image_id: imageId }
      });
      console.log('Image deleted successfully');
    } catch (error) {
      console.error('Error deleting product image:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  uploadCSV: async (file: File): Promise<{ products: Product[], services: Service[] }> => {
    console.log('Uploading CSV file');
    try {
      await fetchCsrfToken();
      const formData = new FormData();
      formData.append('file', file);
      const response = await axiosInstance.post<{ products: Product[], services: Service[] }>(
        '/upload-csv/',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log('CSV file uploaded successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error uploading CSV file:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  exportProducts: async (): Promise<void> => {
    console.log('Exporting products to CSV');
    try {
      const response = await axiosInstance.get('/products/export/', {
        responseType: 'blob'
      });
      
      // Create a blob from the response data
      const blob = new Blob([response.data], { type: 'text/csv' });
      
      // Create a link element and trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `products_export_${new Date().toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      
      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      
      console.log('Products exported successfully');
    } catch (error) {
      console.error('Error exporting products:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  generateProductDescription: async (productId: number, temperature?: number): Promise<ProductDescriptionResponse> => {
    console.log(`Generating description for product ${productId} with temperature ${temperature}`);
    try {
      await fetchCsrfToken();
      const requestData = temperature !== undefined ? { temperature } : {};
      const response = await axiosInstance.post<ProductDescriptionResponse>(
        `/products/${productId}/generate-description/`,
        requestData
      );
      console.log('Description generated successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error generating product description:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  saveProductDescription: async (productId: number, description: string): Promise<ProductDescriptionResponse> => {
    console.log(`Saving description for product ${productId}`);
    try {
      await fetchCsrfToken();
      const response = await axiosInstance.post<ProductDescriptionResponse>(
        `/products/${productId}/save-description/`,
        { description }
      );
      console.log('Description saved successfully:', response.data);

      // Immediately fetch the updated product to ensure we have the latest state
      const updatedProduct = await axiosInstance.get<Product>(`/products/${productId}/`);
      console.log('Fetched updated product:', updatedProduct.data);

      // Return both the save response and the updated product
      return {
        product_id: productId,
        product: updatedProduct.data,
        new_description: description
      };
    } catch (error) {
      console.error('Error saving product description:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  batchGenerateDescriptions: async (productIds: number[], temperature?: number): Promise<BatchGenerateResponse> => {
    console.log('Batch generating descriptions for products:', productIds);
    try {
      await fetchCsrfToken();
      const response = await axiosInstance.post<BatchGenerateResponse>(
        '/products/batch-generate-descriptions/',
        { 
          product_ids: productIds,
          ...(temperature !== undefined && { temperature })
        }
      );
      console.log('Batch description generation completed:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error in batch description generation:', error);
      handleAxiosError(error);
      throw error;
    }
  }
};
