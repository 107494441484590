import React from 'react';

interface ImageModalProps {
  imageUrl: string;
  altText: string;
  onClose: () => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ imageUrl, altText, onClose }) => {
  return (
    <div 
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div 
        className="relative max-w-4xl max-h-[90vh] bg-white rounded-lg p-2"
        onClick={e => e.stopPropagation()}
      >
        <button
          className="absolute -top-3 -right-3 w-8 h-8 flex items-center justify-center bg-white rounded-full shadow-lg text-gray-600 hover:text-gray-800"
          onClick={onClose}
        >
          ×
        </button>
        <img
          src={imageUrl}
          alt={altText}
          className="max-w-full max-h-[85vh] object-contain rounded"
        />
      </div>
    </div>
  );
};

export default ImageModal;
