export const BUSINESS_TYPES = [
  { value: 'custom', label: 'Custom' },
  { value: 'accounting', label: 'Accounting & Financial Services' },
  { value: 'agriculture', label: 'Agriculture & Farming' },
  { value: 'automotive', label: 'Automotive & Transportation' },
  { value: 'beauty', label: 'Beauty & Personal Care' },
  { value: 'construction', label: 'Construction & Contracting' },
  { value: 'consulting', label: 'Consulting Services' },
  { value: 'education', label: 'Education & Training' },
  { value: 'entertainment', label: 'Entertainment & Media' },
  { value: 'event_planning', label: 'Event Planning & Services' },
  { value: 'fitness', label: 'Fitness & Wellness' },
  { value: 'food_beverage', label: 'Food & Beverage' },
  { value: 'healthcare', label: 'Healthcare & Medical' },
  { value: 'hospitality', label: 'Hospitality & Tourism' },
  { value: 'insurance', label: 'Insurance Services' },
  { value: 'it_services', label: 'IT & Technology Services' },
  { value: 'legal', label: 'Legal Services' },
  { value: 'manufacturing', label: 'Manufacturing' },
  { value: 'marketing', label: 'Marketing & Advertising' },
  { value: 'nonprofit', label: 'Non-Profit & Charity' },
  { value: 'pet_services', label: 'Pet Services & Veterinary' },
  { value: 'photography', label: 'Photography & Videography' },
  { value: 'professional', label: 'Professional Services' },
  { value: 'real_estate', label: 'Real Estate & Property' },
  { value: 'retail', label: 'Retail & E-commerce' },
  { value: 'salon_spa', label: 'Salon & Spa Services' },
  { value: 'security', label: 'Security Services' },
  { value: 'shipping', label: 'Shipping & Logistics' },
  { value: 'software', label: 'Software & Technology' },
  { value: 'telecommunications', label: 'Telecommunications' },
  { value: 'wholesale', label: 'Wholesale & Distribution' }
] as const;

export const MAX_PRODUCT_IMAGES = 3;

export const CONFIRMATION_MESSAGES = {
  DELETE_PRODUCT: 'Are you sure you want to delete this product?',
  DELETE_SERVICE: 'Are you sure you want to delete this service?',
  DELETE_PROFILE: 'Are you sure you want to delete your business profile? This action cannot be undone.',
} as const;

export const SUCCESS_MESSAGES = {
  PROFILE_CREATED: 'Profile created successfully!',
  PROFILE_UPDATED: 'Profile updated successfully!',
  PROFILE_DELETED: 'Business profile deleted successfully.',
  PRODUCT_ADDED: 'Product added successfully!',
  PRODUCT_UPDATED: 'Product updated successfully!',
  PRODUCT_DELETED: 'Product deleted successfully!',
  SERVICE_ADDED: 'Service added successfully!',
  SERVICE_UPDATED: 'Service updated successfully!',
  SERVICE_DELETED: 'Service deleted successfully!',
  CSV_UPLOADED: 'CSV file uploaded and processed successfully!',
  IMAGE_UPLOADED: 'Image uploaded successfully!',
  IMAGE_DELETED: 'Image deleted successfully!',
} as const;

export const ERROR_MESSAGES = {
  FETCH_PROFILE: 'Failed to fetch business profile. Please try again.',
  SAVE_PROFILE: 'Failed to save business profile. Please try again.',
  DELETE_PROFILE: 'Failed to delete business profile. Please try again.',
  ADD_PRODUCT: 'Failed to add product. Please try again.',
  UPDATE_PRODUCT: 'Failed to update product. Please try again.',
  DELETE_PRODUCT: 'Failed to delete product. Please try again.',
  ADD_SERVICE: 'Failed to add service. Please try again.',
  UPDATE_SERVICE: 'Failed to update service. Please try again.',
  DELETE_SERVICE: 'Failed to delete service. Please try again.',
  UPLOAD_CSV: 'Failed to upload and process CSV file. Please ensure the file follows the required format.',
  UPLOAD_IMAGE: 'Failed to upload image. Please try again.',
  DELETE_IMAGE: 'Failed to delete image. Please try again.',
  INVALID_RESPONSE: 'Invalid response from server',
} as const;
