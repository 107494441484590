import axios from 'axios';

// Use relative URL to work in any environment
const instance = axios.create({
  baseURL: '/api',
  withCredentials: true,
});

// Keep track of token fetch promise to avoid multiple simultaneous requests
let csrfTokenPromise: Promise<string | null> | null = null;

// Function to get CSRF token from cookies with improved parsing
const getCsrfTokenFromCookies = (): string | null => {
  try {
    const cookies = document.cookie.split(';').reduce((acc: { [key: string]: string }, cookie) => {
      const [key, value] = cookie.trim().split('=');
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {});
    return cookies['csrftoken'] || null;
  } catch (error) {
    console.error('Error parsing CSRF token from cookies:', error);
    return null;
  }
};

// Function to fetch CSRF token
const fetchCsrfToken = async (): Promise<string | null> => {
  try {
    const response = await instance.get('/get-csrf-token/', { 
      withCredentials: true,
      headers: { 
        'Skip-Csrf-Check': 'true',
        'Cache-Control': 'no-cache'  // Prevent caching
      }
    });
    return response.data.csrfToken;
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
    return null;
  }
};

// Function to ensure we have a CSRF token with improved error handling
const ensureCsrfToken = async (): Promise<string | null> => {
  try {
    let token = getCsrfTokenFromCookies();
    if (token) {
      return token;
    }

    // If no token in cookies, fetch it
    if (!csrfTokenPromise) {
      csrfTokenPromise = fetchCsrfToken().finally(() => {
        csrfTokenPromise = null;
      });
    }

    await csrfTokenPromise;
    return getCsrfTokenFromCookies();
  } catch (error) {
    console.error('Error ensuring CSRF token:', error);
    return null;
  }
};

// Request interceptor with improved error handling
instance.interceptors.request.use(async config => {
  console.log('Intercepting request:', config.url);
  
  // Skip CSRF token for certain endpoints or if explicitly skipped
  const skipCsrfEndpoints = [
    '/get-csrf-token/',
    '/verify-session/'
  ];
  
  if (config.headers?.['Skip-Csrf-Check'] || skipCsrfEndpoints.some(endpoint => config.url?.endsWith(endpoint))) {
    return config;
  }

  try {
    const token = await ensureCsrfToken();
    if (token) {
      config.headers['X-CSRFToken'] = token;
      console.log('CSRF token set:', token);
    } else {
      // If we can't get a token, try to fetch a new one
      const newToken = await fetchCsrfToken();
      if (newToken) {
        config.headers['X-CSRFToken'] = newToken;
        console.log('New CSRF token set:', newToken);
      } else {
        console.warn('No CSRF token available after retry');
      }
    }
  } catch (error) {
    console.error('Error ensuring CSRF token:', error);
  }

  return config;
}, error => {
  console.error('Error in request interceptor:', error);
  return Promise.reject(error);
});

// Response interceptor with improved retry logic
instance.interceptors.response.use(response => {
  console.log('Response received:', response.config.url, response.status);
  return response;
}, async error => {
  console.error('Error in response:', error.config?.url, error.response?.status, error.message);
  
  // Handle 403 errors with improved retry logic
  if (error.response?.status === 403 && error.config && !error.config.headers?.['Skip-Csrf-Check']) {
    console.log('403 error detected, attempting to fetch new token and retry...');
    try {
      // Clear any existing token promise
      csrfTokenPromise = null;
      // Force a new token fetch
      const token = await fetchCsrfToken();
      if (token) {
        // Create a new config for retry
        const newConfig = { ...error.config };
        newConfig.headers = { 
          ...error.config.headers, 
          'X-CSRFToken': token,
          'Cache-Control': 'no-cache'  // Prevent caching
        };
        console.log('Retrying request with new token');
        return instance(newConfig);
      }
    } catch (retryError) {
      console.error('Failed to retry request after 403 error:', retryError);
    }
  }
  
  return Promise.reject(error);
});

export default instance;
