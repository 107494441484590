import axiosInstance from '../utils/axiosConfig';
import { Campaign, CampaignAnalytics } from '../types/campaign';
import { fetchCsrfToken, handleAxiosError } from './api';

export const campaignService = {
  getCampaigns: async (): Promise<Campaign[]> => {
    console.log('Fetching campaigns');
    try {
      // Changed to use all_content_summaries endpoint
      const response = await axiosInstance.get<Campaign[]>('/campaigns/all_content_summaries/');
      return response.data;
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  createCampaign: async (campaignData: Partial<Campaign>): Promise<Campaign> => {
    console.log('Creating new campaign');
    try {
      await fetchCsrfToken();
      const response = await axiosInstance.post<Campaign>('/campaigns/', campaignData);
      console.log('Campaign created successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error creating campaign:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  updateCampaign: async (id: number, campaignData: Partial<Campaign>): Promise<Campaign> => {
    console.log(`Updating campaign with id: ${id}`);
    try {
      const response = await axiosInstance.put<Campaign>(`/campaigns/${id}/`, campaignData);
      return response.data;
    } catch (error) {
      console.error('Error updating campaign:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  deleteCampaign: async (id: number): Promise<void> => {
    console.log(`Deleting campaign with id: ${id}`);
    try {
      await axiosInstance.delete(`/campaigns/${id}/`);
    } catch (error) {
      console.error('Error deleting campaign:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  getCampaignAnalytics: async (id: number): Promise<CampaignAnalytics> => {
    console.log(`Fetching analytics for campaign with id: ${id}`);
    try {
      const response = await axiosInstance.get<CampaignAnalytics>(`/campaigns/${id}/analytics/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching campaign analytics:', error);
      handleAxiosError(error);
      throw error;
    }
  }
};
