import { ContentType } from '../pages/simplified-content/types';
import { CampaignPurpose } from '../types/campaign';

export interface ValidationRule<T = any> {
  validate: (value: T, formState?: any) => boolean;
  message: string;
}

export type ValidationRules = {
  [key: string]: ValidationRule[];
};

export type ValidationErrors = {
  [key: string]: string;
};

// Define validation rules
const required: ValidationRule<string> = {
  validate: (value: string) => {
    if (Array.isArray(value)) return value.length > 0;
    if (typeof value === 'string') return value.trim().length > 0;
    return value !== null && value !== undefined;
  },
  message: 'This field is required'
};

const minLength = (min: number): ValidationRule<string> => ({
  validate: (value: string) => !value || value.length >= min,
  message: `Must be at least ${min} characters`
});

const maxLength = (max: number): ValidationRule<string> => ({
  validate: (value: string) => !value || value.length <= max,
  message: `Must be no more than ${max} characters`
});

const isNumeric: ValidationRule<string> = {
  validate: (value: string) => !value || /^\d+$/.test(value),
  message: 'Must be a number'
};

const validateNumeric = (value: string): boolean => {
  return isNumeric.validate(value);
};

const email: ValidationRule<string> = {
  validate: (value: string) => !value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
  message: 'Must be a valid email address'
};

const phone: ValidationRule<string> = {
  validate: (value: string) => !value || /^\+?[\d\s-()]{10,}$/.test(value),
  message: 'Must be a valid phone number'
};

const zipCode: ValidationRule<string> = {
  validate: (value: string) => !value || /^\d{5}(-\d{4})?$/.test(value),
  message: 'Must be a valid ZIP code'
};

const isValidHexColor: ValidationRule<string> = {
  validate: (value: string) => !value || /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value),
  message: 'Must be a valid hex color code (e.g., #FF0000)'
};

const validateColorArray: ValidationRule<string[]> = {
  validate: (value: string[]) => {
    if (!Array.isArray(value)) return false;
    return value.every(color => isValidHexColor.validate(color));
  },
  message: 'All colors must be valid hex color codes'
};

const campaignValidationRules: ValidationRules = {
  name: [required, minLength(2), maxLength(100)],
  description: [required, minLength(10), maxLength(500)],
  start_date: [required],
  end_date: [required],
  budget: [required, isNumeric],
  campaign_type: [required],
  social_media_platforms: [required]
};

const validateField = (
  fieldName: string,
  value: any,
  rules: ValidationRules,
  formState?: any
): string | null => {
  if (!rules[fieldName]) {
    return null;
  }

  for (const rule of rules[fieldName]) {
    if (!rule.validate(value, formState)) {
      return rule.message;
    }
  }

  return null;
};

const hasErrors = (errors: ValidationErrors): boolean => {
  return Object.keys(errors).length > 0;
};

const formatValidationError = (error: string): string => {
  return error.charAt(0).toUpperCase() + error.slice(1);
};

const isFieldRequired = (fieldName: string, rules: ValidationRules = {}): boolean => {
  const fieldRules = rules[fieldName];
  return fieldRules?.some(rule => rule.message === required.message) ?? false;
};

const getFieldLabel = (fieldName: string, rules: ValidationRules = {}): string => {
  const label = fieldName
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  
  return isFieldRequired(fieldName, rules) ? `${label} *` : label;
};

const isPlatformSpecificFieldRequired = (
  fieldName: string,
  contentType: ContentType
): boolean => {
  if (contentType !== 'campaign') return false;
  return ['platform', 'parent_content_id'].includes(fieldName);
};

const getInitialValidationState = (rules: ValidationRules = {}): ValidationErrors => {
  return Object.keys(rules).reduce((acc, key) => {
    acc[key] = '';  // Initialize with empty string instead of undefined
    return acc;
  }, {} as ValidationErrors);
};

const validateCampaignKPIs = (
  kpis: Record<string, number>,
  purpose: CampaignPurpose
): ValidationErrors => {
  const errors: ValidationErrors = {};
  const requiredKPIs: Record<CampaignPurpose, string[]> = {
    brand_awareness: ['reach', 'impressions', 'brand_mentions'],
    lead_generation: ['leads', 'conversion_rate', 'cost_per_lead'],
    sales_promotion: ['sales', 'revenue', 'conversion_rate'],
    product_launch: ['reach', 'engagement', 'sales'],
    event_promotion: ['reach', 'registrations', 'attendance'],
    customer_retention: ['repeat_purchases', 'customer_satisfaction', 'churn_rate'],
  };

  const required = requiredKPIs[purpose];
  if (!required) return errors;

  required.forEach(kpi => {
    if (!kpis || typeof kpis[kpi] !== 'number' || kpis[kpi] < 0) {
      errors[kpi] = `${kpi.replace('_', ' ')} is required for ${purpose.replace('_', ' ')} campaigns`;
    }
  });

  return errors;
};

// Export all validation functions and rules
export {
  required,
  minLength,
  maxLength,
  isNumeric,
  validateNumeric,
  email,
  phone,
  zipCode,
  isValidHexColor,
  validateColorArray,
  campaignValidationRules,
  validateField,
  hasErrors,
  formatValidationError,
  isFieldRequired,
  getFieldLabel,
  isPlatformSpecificFieldRequired,
  getInitialValidationState,
  validateCampaignKPIs
};
