import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Campaign, CampaignFormData } from '../types/campaign';
import CampaignForm from '../components/campaign/CampaignForm';

const LoadingSpinner: React.FC = () => (
  <div className="flex items-center justify-center p-8">
    <div className="text-center">
      <div className="animate-spin rounded-full h-16 w-16 border-4 border-blue-200 border-t-transparent mx-auto"></div>
      <p className="mt-6 text-xl font-medium text-gray-600">Loading campaigns...</p>
    </div>
  </div>
);

const CampaignManagement: React.FC = () => {
  const { getCampaigns, updateCampaign, deleteCampaign, isLoading } = useAuth();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [editingCampaign, setEditingCampaign] = useState<CampaignFormData | null>(null);
  const [error, setError] = useState<string | null>(null);

  const fetchCampaigns = useCallback(async () => {
    try {
      const fetchedCampaigns = await getCampaigns();
      setCampaigns(fetchedCampaigns);
    } catch (err) {
      setError('Failed to fetch campaigns');
      console.error('Error fetching campaigns:', err);
    }
  }, [getCampaigns]);

  useEffect(() => {
    fetchCampaigns();
  }, [fetchCampaigns]);

  const handleUpdateCampaign = async (id: number, campaignData: CampaignFormData) => {
    try {
      await updateCampaign(id, campaignData);
      fetchCampaigns();
      setEditingCampaign(null);
    } catch (err) {
      setError('Failed to update campaign');
      console.error('Error updating campaign:', err);
    }
  };

  const handleDeleteCampaign = async (id: number) => {
    try {
      await deleteCampaign(id);
      fetchCampaigns();
    } catch (err) {
      setError('Failed to delete campaign');
      console.error('Error deleting campaign:', err);
    }
  };

  const handleEditCampaign = (campaign: Campaign) => {
    setEditingCampaign({
      id: campaign.id,
      name: campaign.name,
      status: campaign.status,
      primary_purpose: campaign.primary_purpose,
      campaign_type: campaign.campaign_type,
      campaign_category: campaign.campaign_category,
      start_date: campaign.start_date,
      end_date: campaign.end_date,
      budget: campaign.budget,
      social_media_platforms: campaign.social_media_platforms || [],
      target_audience: campaign.target_audience,
      product_ids: campaign.products,
      service_ids: campaign.services,
      campaign_goals: campaign.campaign_goals,
      target_kpis: campaign.target_kpis
    });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="w-full">
      <div className="text-center mb-12 animate-fade-in">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Campaign Management</h1>
        <p className="text-xl text-gray-600">Create and manage your marketing campaigns</p>
      </div>

      {error && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-8 rounded-lg animate-fade-in" role="alert">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}

      {!editingCampaign && (
        <button
          onClick={() => navigate('/campaigns/create')}
          className="mb-8 px-6 py-3 bg-gradient-to-r from-blue-600 to-indigo-600 text-white rounded-lg font-semibold 
                   hover:from-blue-700 hover:to-indigo-700 transition-all duration-200 hover:scale-105 animate-fade-in"
        >
          Create New Campaign
        </button>
      )}

      {editingCampaign && (
        <CampaignForm
          onSubmit={(data: CampaignFormData) => handleUpdateCampaign(editingCampaign.id, data)}
          onCancel={() => setEditingCampaign(null)}
          initialData={editingCampaign}
        />
      )}

      <div className="bg-white rounded-2xl shadow-xl p-8 animate-fade-in overflow-x-auto">
        <h2 className="text-2xl font-bold text-gray-900 mb-6">Your Campaigns</h2>
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Purpose</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Dates</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Budget</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Metrics</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {campaigns.map((campaign, index) => (
              <tr 
                key={campaign.id}
                className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-blue-50 transition-colors duration-200`}
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{campaign.name}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <span className={`px-3 py-1 rounded-full text-xs font-medium
                    ${campaign.status === 'active' ? 'bg-green-100 text-green-800' : 
                      campaign.status === 'paused' ? 'bg-yellow-100 text-yellow-800' : 
                      'bg-gray-100 text-gray-800'}`}>
                    {campaign.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{campaign.primary_purpose}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{campaign.campaign_type}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{campaign.campaign_category}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                  <div>{campaign.start_date}</div>
                  <div className="text-xs text-gray-500">to</div>
                  <div>{campaign.end_date}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">${campaign.budget}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                  <div>Impressions: {campaign.impressions}</div>
                  <div>Clicks: {campaign.clicks}</div>
                  <div>Conversions: {campaign.conversions}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium space-x-2">
                  <button
                    onClick={() => handleEditCampaign(campaign)}
                    className="text-blue-600 hover:text-blue-900 transition-colors duration-200"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteCampaign(campaign.id)}
                    className="text-red-600 hover:text-red-900 transition-colors duration-200"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CampaignManagement;
