import React from 'react';
import { AnimatedCounter } from './AnimatedCounter';

interface StatsCardProps {
  value: number;
  suffix?: string;
  label: string;
  className?: string;
}

/**
 * StatsCard Component
 * 
 * Displays a statistic with an animated counter and label.
 * Used for highlighting key metrics and achievements.
 */
export const StatsCard: React.FC<StatsCardProps> = ({
  value,
  suffix = '',
  label,
  className = ''
}) => {
  return (
    <div className={`bg-gradient-to-br from-blue-600 to-indigo-600 rounded-lg p-4 shadow-lg ${className}`}>
      <AnimatedCounter
        end={value}
        suffix={suffix}
        className="text-2xl text-white"
      />
      <div className="text-sm text-blue-100">
        {label}
      </div>
    </div>
  );
};
