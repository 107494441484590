import axiosInstance from '../utils/axiosConfig';
import { ResearchReport } from '../types/research';
import { handleAxiosError } from './api';

export const researchService = {
  // Get all research reports
  getReports: async (): Promise<ResearchReport[]> => {
    try {
      const response = await axiosInstance.get<ResearchReport[]>('/research/');
      return response.data;
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  // Get a single research report
  getReport: async (id: number): Promise<ResearchReport> => {
    try {
      const response = await axiosInstance.get<ResearchReport>(`/research/${id}/`);
      return response.data;
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  // Generate a new research report
  generateReport: async (data: {
    query: string;
    include_products: boolean;
    include_services: boolean;
    include_campaigns: boolean;
  }): Promise<ResearchReport> => {
    try {
      const response = await axiosInstance.post<ResearchReport>('/research/generate/', data);
      return response.data;
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  // Delete a research report
  deleteReport: async (id: number): Promise<void> => {
    try {
      await axiosInstance.delete(`/research/${id}/`);
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  }
};
