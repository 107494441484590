import React, { useState } from 'react';
import { ProductDescriptionManagerProps } from '../../types/productDescriptions';
import Button from '../common/Button';

const ProductDescriptionManager: React.FC<ProductDescriptionManagerProps> = ({
  product,
  onDescriptionSelect,
  onGenerateDescription,
  isGenerating,
  previewDescription
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [temperature, setTemperature] = useState(0.7);

  const handleTemperatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTemperature(parseFloat(event.target.value));
  };

  const getTemperatureLabel = (temp: number): string => {
    if (temp <= 0.3) return 'Conservative';
    if (temp <= 0.7) return 'Balanced';
    return 'Creative';
  };

  const getTemperatureDescription = (temp: number): string => {
    if (temp <= 0.3) return 'More focused and consistent output';
    if (temp <= 0.7) return 'Good balance of creativity and consistency';
    return 'More varied and creative output';
  };

  return (
    <div className="mt-4 border-t pt-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-gray-900">Description Generator</h3>
        {!previewDescription && (
          <div className="flex flex-col space-y-4 w-full max-w-md">
            <div className="flex flex-col space-y-1">
              <div className="flex justify-between items-center">
                <label htmlFor="temperature" className="text-sm text-gray-600">
                  Creativity Level: <span className="font-medium">{getTemperatureLabel(temperature)}</span>
                </label>
                <span className="text-xs text-gray-500">{temperature.toFixed(1)}</span>
              </div>
              <input
                type="range"
                id="temperature"
                min="0"
                max="1"
                step="0.1"
                value={temperature}
                onChange={handleTemperatureChange}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                title={`Temperature: ${temperature}`}
              />
              <div className="flex justify-between text-xs text-gray-500">
                <span>Conservative</span>
                <span>Creative</span>
              </div>
              <p className="text-xs text-gray-500 mt-1">{getTemperatureDescription(temperature)}</p>
            </div>
            <Button
              onClick={() => onGenerateDescription(product.id, temperature)}
              variant="primary"
              size="sm"
              disabled={isGenerating}
              className="w-full"
            >
              {isGenerating ? (
                <div className="flex items-center justify-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Generating...
                </div>
              ) : 'Generate New Description'}
            </Button>
          </div>
        )}
      </div>

      {/* Preview Description with Actions */}
      {previewDescription ? (
        <div className="bg-white p-4 rounded-lg border border-gray-200">
          <div className="mb-4">
            <div className="flex justify-between items-start mb-2">
              <h4 className="text-sm font-medium text-gray-700">New Description Preview:</h4>
              <div className="flex items-center space-x-2">
                <p className="text-xs text-gray-500">Current description will remain unchanged until you save</p>
                <Button
                  onClick={() => setIsExpanded(!isExpanded)}
                  variant="secondary"
                  size="sm"
                  className="!py-1 !px-2 text-xs"
                >
                  {isExpanded ? 'Collapse' : 'Expand'}
                </Button>
              </div>
            </div>
            <div 
              className={`overflow-y-auto transition-all duration-200 ease-in-out ${
                isExpanded ? 'max-h-[500px]' : 'max-h-[200px]'
              }`}
              style={{
                scrollbarWidth: 'thin',
                scrollbarColor: '#CBD5E0 #EDF2F7'
              }}
            >
              <p className="text-sm text-gray-600 whitespace-pre-wrap pr-2">{previewDescription}</p>
            </div>
          </div>
          <div className="flex justify-end space-x-2 sticky bottom-0 bg-white pt-2 border-t">
            <Button
              onClick={() => onDescriptionSelect(product.id, previewDescription)}
              variant="success"
              size="sm"
            >
              Save Description
            </Button>
            <Button
              onClick={() => onGenerateDescription(product.id, temperature)}
              variant="secondary"
              size="sm"
              disabled={isGenerating}
            >
              {isGenerating ? 'Generating...' : 'Generate Another'}
            </Button>
            <Button
              onClick={() => {
                // This will trigger the parent's discardDescription function
                onGenerateDescription(0); // Using 0 as a signal to just close
              }}
              variant="danger"
              size="sm"
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <div className="text-center py-6 bg-gray-50 rounded-lg">
          <p className="text-gray-500 text-sm">
            Click "Generate New Description" to create an AI-generated description for this product.
          </p>
        </div>
      )}
    </div>
  );
};

export default ProductDescriptionManager;
