import React, { useState, useCallback, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useContentHistory } from '../hooks/content-history/useContentHistory';
import { ContentHistoryHeader } from '../components/content-history/ContentHistoryHeader';
import ContentHistoryFilters from '../components/content-history/ContentHistoryFilters';
import ContentHistoryTable from '../components/content-history/ContentHistoryTable';
import ContentPreviewModal from '../components/ContentPreviewModal';
import ContentExportModal from '../components/content-history/ContentExportModal';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { Content, getContentId, FilterOptions, ContentStatus } from '../types/content';
import { ContentRequirements } from './simplified-content/types';
import { GroupedContent, groupContentsByCampaignAndQuick } from '../utils/contentHistoryUtils';
import { contentService } from '../services/content';

// Define the type that includes requirements
type ContentWithRequirements = Content & { requirements?: ContentRequirements };

const LoadingSkeleton = () => (
  <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-8 animate-fade-in">
    <div className="w-full px-8">
      <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
        <div className="p-6">
          {/* Header Skeleton */}
          <div className="flex justify-between items-center mb-6">
            <div className="h-10 w-64 bg-gray-200 rounded animate-skeleton-pulse"></div>
          </div>

          {/* Table Header Skeleton */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
            <div className="overflow-x-auto">
              <div className="min-w-full">
                <div className="h-16 bg-gray-100 border-b border-gray-200"></div>
                
                {/* Content Group Skeletons */}
                {[...Array(3)].map((_, groupIndex) => (
                  <div key={groupIndex} className={`animate-stagger-fade [animation-delay:${groupIndex * 150}ms]`}>
                    {/* Group Header */}
                    <div className="border-y border-gray-200 p-6">
                      <div className="flex items-center space-x-3">
                        <div className="w-6 h-6 bg-gray-300 rounded animate-skeleton-pulse"></div>
                        <div className="flex-1">
                          <div className="h-7 w-64 bg-gray-300 rounded mb-2 animate-skeleton-pulse"></div>
                          <div className="h-5 w-32 bg-gray-200 rounded animate-skeleton-pulse"></div>
                        </div>
                      </div>
                    </div>
                    
                    {/* Content Row Skeletons */}
                    {[...Array(3)].map((_, rowIndex) => (
                      <div 
                        key={rowIndex}
                        className={`border-b border-gray-200 ${rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'} animate-stagger-fade [animation-delay:${(groupIndex * 3 + rowIndex) * 100}ms]`}
                      >
                        <div className="px-6 py-4 grid grid-cols-8 gap-4">
                          <div className="w-4 h-4 bg-gray-200 rounded animate-skeleton-pulse"></div>
                          <div className="w-24 h-8 bg-gray-200 rounded animate-skeleton-pulse"></div>
                          <div className="w-16 h-16 bg-gray-200 rounded animate-skeleton-pulse"></div>
                          <div className="w-32 h-6 bg-gray-200 rounded animate-skeleton-pulse"></div>
                          <div className="w-24 h-6 bg-gray-200 rounded animate-skeleton-pulse"></div>
                          <div className="w-32 h-6 bg-gray-200 rounded animate-skeleton-pulse"></div>
                          <div className="w-full h-6 bg-gray-200 rounded animate-skeleton-pulse"></div>
                          <div className="w-24 h-8 bg-gray-200 rounded-full animate-skeleton-pulse"></div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const ContentHistory: React.FC = () => {
  const [showSearchFilter, setShowSearchFilter] = useState(false);
  const [previewContent, setPreviewContent] = useState<Content | null>(null);
  const [exportContent, setExportContent] = useState<ContentWithRequirements | null>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');
  const [pendingAction, setPendingAction] = useState<(() => Promise<void>) | null>(null);
  const [isPageMounted, setIsPageMounted] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    dateFrom: '',
    dateTo: '',
    campaign: '',
    status: ''
  });
  const [expandedTopics, setExpandedTopics] = useState<Set<string>>(new Set());
  // Initialize expandedGroups as an empty Set - groups will be collapsed by default
  const [expandedGroups, setExpandedGroups] = useState<Set<string>>(new Set());
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [updatingContentId, setUpdatingContentId] = useState<number | null>(null);

  const { isAuthenticated } = useAuth();
  
  const {
    contents,
    error,
    selectedContentIds,
    toggleContentSelection,
    deleteContent,
    isRefreshing,
    isLoading,
    isInitialLoad,
    refreshContents,
    updateContentInState
  } = useContentHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPageMounted(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  // Only set default filter on initial load
  useEffect(() => {
    if (isInitialLoad && contents?.length > 0) {
      setFilterOptions(prev => ({
        ...prev,
        status: ''  // Empty string will show default statuses
      }));
    }
  }, [isInitialLoad, contents]);

  const handleFilterChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFilterOptions((prev: FilterOptions) => ({
      ...prev,
      [name]: value
    }));
  }, []);

  const handleContentSelect = useCallback((content: Content) => {
    toggleContentSelection(getContentId(content));
  }, [toggleContentSelection]);

  const handleTopicExpand = useCallback((topic: string) => {
    setExpandedTopics(prev => {
      const newSet = new Set(prev);
      if (newSet.has(topic)) {
        newSet.delete(topic);
      } else {
        newSet.add(topic);
      }
      return newSet;
    });
  }, []);

  const handleGroupExpand = useCallback((groupId: string) => {
    setExpandedGroups(prev => {
      const newSet = new Set(prev);
      if (newSet.has(groupId)) {
        newSet.delete(groupId);
      } else {
        newSet.add(groupId);
      }
      return newSet;
    });
  }, []);

  const handlePreview = useCallback(() => {
    if (!contents) return;
    const selectedContent = contents.find(content => 
      content && selectedContentIds.has(getContentId(content))
    );
    if (selectedContent) {
      setPreviewContent(selectedContent);
    }
  }, [contents, selectedContentIds]);

  const handleExport = useCallback((content?: ContentWithRequirements) => {
    if (content) {
      setExportContent(content);
    } else if (contents) {
      const selectedContent = contents.find(content => 
        content && selectedContentIds.has(getContentId(content))
      );
      if (selectedContent) {
        setExportContent(selectedContent as ContentWithRequirements);
      }
    }
  }, [contents, selectedContentIds]);

  const handleDelete = useCallback(async () => {
    if (selectedContentIds.size === 0 || !contents) return;

    setConfirmDialogMessage(`Are you sure you want to delete ${selectedContentIds.size} item(s)?`);
    setPendingAction(() => async () => {
      try {
        const selectedContentObjects = contents.filter(content => 
          content && selectedContentIds.has(getContentId(content))
        );

        if (selectedContentObjects.length === 0) {
          throw new Error('No valid content found for deletion');
        }

        const deletePromises = selectedContentObjects.map(async content => {
          try {
            if (!content) {
              throw new Error('Invalid content object');
            }
            const contentId = getContentId(content);
            await deleteContent(contentId);
          } catch (error) {
            console.error(`Failed to delete content:`, error);
            throw error;
          }
        });

        await Promise.all(deletePromises);
        await refreshContents();
      } catch (error) {
        console.error('Error performing delete:', error);
        throw error;
      }
    });
    setShowConfirmDialog(true);
  }, [contents, selectedContentIds, deleteContent, refreshContents]);

  const executePendingAction = useCallback(async () => {
    if (pendingAction) {
      try {
        await pendingAction();
        setPendingAction(null);
        setShowConfirmDialog(false);
      } catch (error) {
        console.error('Error executing action:', error);
        alert('Failed to delete content. Please try again.');
      }
    }
  }, [pendingAction]);

  const getGroupedContents = useCallback((): GroupedContent => {
    if (!contents) return { campaignGroups: {}, quickContent: [] };

    const filteredContents = contents.filter(content => {
      if (!content) return false;

      const searchLower = searchTerm.toLowerCase();
      const matchesSearch = !searchTerm || 
        content.topic?.toLowerCase().includes(searchLower) ||
        content.content?.toLowerCase().includes(searchLower) ||
        content.campaign?.name?.toLowerCase().includes(searchLower);

      const contentDate = new Date(content.created_at);
      const matchesDateFrom = !filterOptions.dateFrom || contentDate >= new Date(filterOptions.dateFrom);
      const matchesDateTo = !filterOptions.dateTo || contentDate <= new Date(filterOptions.dateTo);

      const matchesCampaign = !filterOptions.campaign || 
        content.campaign?.id.toString() === filterOptions.campaign;

      const status = content.content_status;
      const matchesStatus = filterOptions.status === 'all' ? true :  // Show all statuses
        filterOptions.status ? status === filterOptions.status :      // Show specific status
        ['draft', 'waiting_for_approval', 'approved'].includes(status); // Default view (working content)

      return matchesSearch && matchesDateFrom && matchesDateTo && matchesCampaign && matchesStatus;
    });

    return groupContentsByCampaignAndQuick(filteredContents);
  }, [contents, searchTerm, filterOptions]);

  const handleStatusChange = useCallback(async (contentId: number, newStatus: ContentStatus, platform: string) => {
    try {
      if (updatingContentId === contentId) {
        console.log('Status update already in progress');
        return;
      }

      setErrorMessage(null);
      setUpdatingContentId(contentId);
      console.log('Status change requested:', { contentId, newStatus, platform });

      // Update the status using workflow_status
      await contentService.updateContent(contentId, {
        content_status: newStatus,
        platform: platform
      });

      // Update local state immediately
      updateContentInState(contentId, { content_status: newStatus }, platform);

      // Refresh content list in background
      await refreshContents();
      console.log('Status updated successfully');
    } catch (error) {
      console.error('Failed to update content status:', error);
      setErrorMessage('Failed to update status. Please try again.');
    } finally {
      setUpdatingContentId(null);
    }
  }, [contentService, refreshContents, updateContentInState, updatingContentId]);

  if (!isAuthenticated) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-2">Please Log In</h2>
          <p className="text-gray-600">You need to be logged in to view content history.</p>
        </div>
      </div>
    );
  }

  if (isInitialLoad || isLoading || !isPageMounted) {
    return <LoadingSkeleton />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-8 transition-opacity duration-300">
      <div className="content-history-container">
        <div className="content-history-outer">
          <div className="content-history-inner px-8">
            <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
              <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                  <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent animate-content-appear">
                    Content History
                  </h1>
                </div>

                {(error || errorMessage) && (
                  <div className="mb-4 bg-red-50 border-l-4 border-red-500 p-4 rounded animate-content-appear">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                        </svg>
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-red-700">{error || errorMessage}</p>
                      </div>
                    </div>
                  </div>
                )}

                <ContentHistoryHeader
                  onToggleFilters={() => setShowSearchFilter(!showSearchFilter)}
                  onDelete={handleDelete}
                  onPreview={handlePreview}
                  onExport={() => handleExport()}
                  selectedCount={selectedContentIds.size}
                />

                <div className={`transition-all duration-300 ease-in-out ${showSearchFilter ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}`}>
                  {showSearchFilter && (
                    <ContentHistoryFilters
                      searchTerm={searchTerm}
                      onSearchChange={setSearchTerm}
                      filterOptions={filterOptions}
                      onFilterChange={handleFilterChange}
                      uniqueCampaigns={Array.from(new Set(contents?.filter(content => content?.campaign?.id).map(content => content?.campaign?.id))).filter(Boolean) as number[]}
                      contents={contents || []}
                    />
                  )}
                </div>

                {isRefreshing ? (
                  <div className="flex justify-center items-center py-8">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                  </div>
                ) : (
                  <div className="animate-content-appear">
                    <ContentHistoryTable
                      groupedContents={getGroupedContents()}
                      selectedContents={selectedContentIds}
                      onContentSelect={handleContentSelect}
                      expandedTopics={expandedTopics}
                      onTopicExpand={handleTopicExpand}
                      expandedGroups={expandedGroups}
                      onGroupExpand={handleGroupExpand}
                      onStatusChange={handleStatusChange}
                      onExport={handleExport}
                      updatingContentId={updatingContentId}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContentPreviewModal
        content={previewContent}
        onClose={() => setPreviewContent(null)}
      />

      <ContentExportModal
        content={exportContent}
        onClose={() => setExportContent(null)}
      />

      <ConfirmationDialog
        isOpen={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={executePendingAction}
        message={confirmDialogMessage}
      />
    </div>
  );
};

export default ContentHistory;
