import React, { useState } from 'react';
import { Platform, GeneratedContent, PlatformImages, ContentRequirements, BusinessProfile } from '../types';
import { simplifiedContentApi } from '../api/contentApi';

interface ContentReviewProps {
  platforms: Platform[];
  generatedContent: GeneratedContent;
  platformImages: PlatformImages | null;
  requirements: ContentRequirements;
  businessProfile: BusinessProfile | null;
  onRegenerateText: (platform: string, newContent: string) => void;
  onRegenerateImage: (platform: string, newImageUrl: string) => void;
  onSave: () => void;
  isLoading: boolean;
}

export const ContentReview: React.FC<ContentReviewProps> = ({
  platforms,
  generatedContent,
  platformImages,
  requirements,
  businessProfile,
  onRegenerateText,
  onRegenerateImage,
  onSave,
  isLoading
}) => {
  const [regeneratingText, setRegeneratingText] = useState<string | null>(null);
  const [regeneratingImage, setRegeneratingImage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleRegenerateText = async (platform: string) => {
    try {
      setRegeneratingText(platform);
      setError(null);

      // Generate new content for the platform
      const contentResponse = await simplifiedContentApi.generateContent({
        platforms: [platforms.find(p => p.name === platform)!],
        requirements,
        campaign: null,
        businessProfile
      });

      if (contentResponse?.generated_content?.[platform]?.text) {
        onRegenerateText(platform, contentResponse.generated_content[platform].text);
      } else {
        throw new Error('Failed to regenerate text content');
      }
    } catch (error) {
      console.error('Error regenerating text:', error);
      setError('Failed to regenerate text. Please try again.');
    } finally {
      setRegeneratingText(null);
    }
  };

  const handleRegenerateImage = async (platform: string) => {
    try {
      setRegeneratingImage(platform);
      setError(null);

      // Generate new image using the current content
      const platformContent = {
        [platform]: {
          ...generatedContent[platform],
          text: generatedContent[platform].text
        }
      };

      const imageResults = await simplifiedContentApi.generateImage(
        platformContent,
        requirements
      );

      if (imageResults?.[platform]?.image_url) {
        onRegenerateImage(platform, imageResults[platform].image_url);
      } else {
        throw new Error('Failed to regenerate image');
      }
    } catch (error) {
      console.error('Error regenerating image:', error);
      setError('Failed to regenerate image. Please try again.');
    } finally {
      setRegeneratingImage(null);
    }
  };

  return (
    <div className="space-y-8">
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900">Review Generated Content</h2>
          <button
            onClick={onSave}
            disabled={isLoading}
            className={`px-6 py-2 rounded-lg text-white font-medium transition-all duration-200 ${
              isLoading
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-green-600 hover:bg-green-700 transform hover:scale-[1.02] hover:shadow-lg'
            }`}
          >
            {isLoading ? (
              <span className="flex items-center space-x-2">
                <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span>Saving...</span>
              </span>
            ) : (
              'Save Content'
            )}
          </button>
        </div>

        {error && (
          <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4 rounded-r-lg">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
        )}

        <div className="space-y-6">
          {platforms.map((platform) => (
            <div key={platform.id} className="bg-gray-50 rounded-lg p-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium text-gray-900">{platform.name}</h3>
                <div className="flex space-x-2">
                  <button
                    onClick={() => handleRegenerateText(platform.name)}
                    disabled={regeneratingText === platform.name}
                    className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors ${
                      regeneratingText === platform.name
                        ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
                        : 'text-blue-600 bg-blue-50 hover:bg-blue-100'
                    }`}
                  >
                    {regeneratingText === platform.name ? (
                      <span className="flex items-center space-x-2">
                        <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <span>Regenerating...</span>
                      </span>
                    ) : (
                      'Regenerate Text'
                    )}
                  </button>
                  {requirements.generateImages && (
                    <button
                      onClick={() => handleRegenerateImage(platform.name)}
                      disabled={regeneratingImage === platform.name}
                      className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors ${
                        regeneratingImage === platform.name
                          ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
                          : 'text-purple-600 bg-purple-50 hover:bg-purple-100'
                      }`}
                    >
                      {regeneratingImage === platform.name ? (
                        <span className="flex items-center space-x-2">
                          <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          <span>Regenerating...</span>
                        </span>
                      ) : (
                        'Regenerate Image'
                      )}
                    </button>
                  )}
                </div>
              </div>

              {/* Text Content */}
              <div className="bg-white rounded-lg p-4 mb-4">
                <h4 className="text-sm font-medium text-gray-500 mb-2">Generated Text</h4>
                <div className="prose max-w-none">
                  {generatedContent[platform.name]?.text || 'No content generated'}
                </div>
              </div>

              {/* Image Content */}
              {requirements.generateImages && platformImages && platformImages[platform.name] && (
                <div>
                  <h4 className="text-sm font-medium text-gray-500 mb-2">Generated Image</h4>
                  <div className="relative rounded-lg overflow-hidden bg-gray-100">
                    <img
                      src={platformImages[platform.name].image_url}
                      alt={`Generated content for ${platform.name}`}
                      className="w-full h-auto object-cover"
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContentReview;
