import React, { forwardRef, useCallback } from 'react';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean | string;
  fullWidth?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(({
  error,
  fullWidth = true,
  className = '',
  onChange,
  ...props
}, ref) => {
  // Base styles that ensure input is fully interactive
  const baseClasses = [
    'block',
    'w-full',
    'px-3',
    'py-2',
    'text-base',
    'text-gray-900',
    'placeholder-gray-400',
    'bg-white',
    'rounded-md',
    'border',
    'shadow-sm',
    'appearance-none',
    'focus:outline-none',
    'focus:ring-2',
    'focus:ring-indigo-500',
    'focus:border-indigo-500',
    'disabled:bg-gray-50',
    'disabled:text-gray-500',
    'disabled:border-gray-200',
    'disabled:shadow-none',
    'sm:text-sm'
  ].join(' ');

  const widthClasses = fullWidth ? 'w-full' : 'w-auto';
  const errorClasses = error ? 'border-red-300' : 'border-gray-300';
  const classes = `${baseClasses} ${widthClasses} ${errorClasses} ${className}`.trim();

  // Explicit handler for input changes
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('Input change event:', {
      name: e.target.name,
      value: e.target.value,
      type: e.target.type
    });
    
    if (onChange) {
      onChange(e);
    }
  }, [onChange]);

  return (
    <input
      ref={ref}
      className={classes}
      aria-invalid={!!error}
      onChange={handleChange}
      style={{
        // Ensure the input is fully interactive
        pointerEvents: 'auto',
        cursor: 'text',
        // Remove iOS styling
        WebkitAppearance: 'none',
        // Ensure consistent font rendering
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        // Remove iOS zoom on focus
        fontSize: '16px',
        // Remove any potential interference
        touchAction: 'manipulation',
        userSelect: 'text',
        ...props.style
      }}
      {...props}
    />
  );
});

Input.displayName = 'Input';

export default Input;
