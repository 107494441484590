import React from 'react';
import { Product, ProductImage } from '../../types/business';
import { MAX_PRODUCT_IMAGES } from '../../constants/businessProfile';
import Button from '../common/Button';
import FormField from '../common/FormField';

interface ProductImagesProps {
  product: Product;
  onUpload: (file: File) => Promise<void>;
  onDelete: (imageId: number) => Promise<void>;
}

const ProductImages: React.FC<ProductImagesProps> = ({
  product,
  onUpload,
  onDelete,
}): React.ReactElement => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      await onUpload(files[0]);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <span className="text-sm text-gray-600">
          {product.images?.length || 0} of {MAX_PRODUCT_IMAGES} images
        </span>
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="hidden"
          disabled={product.images?.length >= MAX_PRODUCT_IMAGES}
        />
        <Button
          type="button"
          variant="secondary"
          onClick={() => fileInputRef.current?.click()}
          disabled={product.images?.length >= MAX_PRODUCT_IMAGES}
        >
          Add Image
        </Button>
      </div>

      <div className="flex flex-wrap gap-4">
        {product.images?.map((image: ProductImage, index: number) => (
          <div key={image.id} className="relative group">
            <img
              src={image.image}
              alt={image.alt_text || `Product image ${index + 1}`}
              className="w-24 h-24 object-cover rounded-lg border border-gray-200"
            />
            <button
              type="button"
              onClick={() => onDelete(image.id)}
              className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductImages;
