import React, { useState, useCallback, useEffect } from 'react';
import { Product } from '../../types/business';
import { ProductListProps } from '../../types/businessProfile';
import CollapsibleCard from './CollapsibleCard';
import ProductForm from './ProductForm';
import EditProductForm from './EditProductForm';
import CSVUploadForm from './CSVUploadForm';
import ProductDescriptionManager from './ProductDescriptionManager';
import Button from '../common/Button';
import ImageModal from '../common/ImageModal';
import { useProductDescriptions } from '../../hooks/useProductDescriptions';
import { useAuth } from '../../contexts/AuthContext';

const formatPrice = (price: number | undefined) => {
  if (price === undefined) return '';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(price);
};

const ProductList: React.FC<ProductListProps> = ({
  products: initialProducts,
  isOpen,
  isAdding,
  isUploadingCSV,
  editingProduct,
  onToggle,
  onAdd,
  onUploadCSV,
  onAddSubmit,
  onAddCancel,
  onCSVUpload,
  onCSVCancel,
  onEdit,
  onEditSubmit,
  onEditCancel,
  onDelete,
  refreshProduct,
}) => {
  const [selectedImage, setSelectedImage] = useState<{url: string, alt: string} | null>(null);
  const [expandedProductId, setExpandedProductId] = useState<number | null>(null);
  const [expandedDescriptionId, setExpandedDescriptionId] = useState<number | null>(null);
  const [products, setProducts] = useState<Product[]>(initialProducts);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const { exportProducts } = useAuth();
  const { 
    isGenerating, 
    generateDescription, 
    saveDescription,
    discardDescription,
    error: descriptionError,
    previewDescription
  } = useProductDescriptions();

  // Update local products when parent products change
  useEffect(() => {
    console.log('Initial products updated:', initialProducts);
    setProducts(initialProducts);
  }, [initialProducts]);

  // Clear success message after 5 seconds
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const handleExport = async () => {
    if (isExporting) return;
    
    setIsExporting(true);
    try {
      await exportProducts();
      setSuccessMessage('Products exported successfully!');
    } catch (error) {
      console.error('Error exporting products:', error);
    } finally {
      setIsExporting(false);
    }
  };

  const updateProductInList = useCallback((updatedProduct: Product) => {
    console.log('Updating product in list:', updatedProduct);
    setProducts(currentProducts => 
      currentProducts.map(p => 
        p.id === updatedProduct.id ? { ...updatedProduct, key: Date.now() } : p
      )
    );
  }, []);

  const handleGenerateDescription = useCallback(async (productId: number, temperature?: number) => {
    if (isUpdating) {
      console.log('Update in progress, please wait...');
      return;
    }

    console.log('Generating description for product:', productId, 'temperature:', temperature);
    try {
      await generateDescription(productId, temperature);
      if (productId !== 0) { // Don't show success message for cancel action
        setSuccessMessage('Description generated successfully!');
      }
    } catch (error) {
      console.error('Error generating description:', error);
    }
  }, [generateDescription, isUpdating]);

  const handleSaveDescription = useCallback(async (productId: number, description: string) => {
    if (isUpdating) {
      console.log('Update in progress, please wait...');
      return;
    }

    console.log('Saving description for product:', productId);
    setIsUpdating(true);
    try {
      const updatedProduct = await saveDescription(productId, description);
      if (updatedProduct) {
        console.log('Description saved successfully:', updatedProduct);
        
        // Update the product in the local state immediately
        updateProductInList(updatedProduct);
        
        // Refresh the product from the server
        const refreshedProduct = await refreshProduct(productId);
        if (refreshedProduct) {
          console.log('Product refreshed from server:', refreshedProduct);
          
          // Update local state with refreshed data
          setProducts(currentProducts => 
            currentProducts.map(p => 
              p.id === productId ? { ...refreshedProduct, key: Date.now() } : p
            )
          );
        }
        
        setSuccessMessage('Description saved successfully!');
        
        // Close the description manager and clear preview
        setExpandedProductId(null);
        discardDescription();
      }
    } catch (error) {
      console.error('Error saving description:', error);
    } finally {
      setIsUpdating(false);
    }
  }, [saveDescription, refreshProduct, updateProductInList, discardDescription, isUpdating]);

  const handleDescriptionCancel = useCallback(() => {
    if (isUpdating) {
      console.log('Update in progress, please wait...');
      return;
    }

    discardDescription();
    setExpandedProductId(null);
  }, [discardDescription, isUpdating]);

  return (
    <>
      <CollapsibleCard
        title="Products"
        isOpen={isOpen}
        onToggle={onToggle}
        actionButtons={
          <div className="flex space-x-2">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onAdd();
              }}
              variant="success"
              size="sm"
            >
              Add Product
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onUploadCSV();
              }}
              variant="secondary"
              size="sm"
            >
              Upload CSV
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleExport();
              }}
              variant="secondary"
              size="sm"
              disabled={isExporting}
            >
              {isExporting ? 'Exporting...' : 'Export CSV'}
            </Button>
          </div>
        }
      >
        {descriptionError && (
          <div className="mb-4 p-4 bg-red-50 border-l-4 border-red-500 rounded">
            <p className="text-sm text-red-700">{descriptionError}</p>
          </div>
        )}

        {successMessage && (
          <div className="mb-4 p-4 bg-green-50 border-l-4 border-green-500 rounded">
            <p className="text-sm text-green-700">{successMessage}</p>
          </div>
        )}

        {isAdding && (
          <div className="mb-6">
            <ProductForm onSubmit={onAddSubmit} onCancel={onAddCancel} />
          </div>
        )}
        {isUploadingCSV && (
          <div className="mb-6">
            <CSVUploadForm onUpload={onCSVUpload} onCancel={onCSVCancel} />
          </div>
        )}
        {products && products.length > 0 ? (
          <div className="space-y-4">
            {products.map(product => (
              <div 
                key={`${product.id}-${product.updated_at}-${product.description}-${(product as any).key || ''}`}
                id={`product-${product.id}`}
                className="transition-opacity duration-200"
              >
                {editingProduct?.id === product.id ? (
                  <EditProductForm
                    product={product}
                    onSubmit={onEditSubmit}
                    onCancel={onEditCancel}
                    refreshProduct={refreshProduct}
                  />
                ) : (
                  <div className="flex flex-col p-4 bg-gray-50 rounded-lg">
                    <div className="flex justify-between items-start">
                      <div className="flex items-start space-x-4 flex-grow">
                        {/* Product Images */}
                        {product.images && product.images.length > 0 && (
                          <div className="flex space-x-2">
                            {product.images.map((image) => (
                              <div 
                                key={image.id} 
                                className="relative w-16 h-16 flex-shrink-0 rounded-lg overflow-hidden border border-gray-200 cursor-pointer hover:opacity-90 transition-opacity"
                                onClick={() => setSelectedImage({
                                  url: image.image,
                                  alt: `${product.name} full view`
                                })}
                              >
                                <img
                                  src={image.image}
                                  alt={`${product.name} thumbnail`}
                                  className="w-full h-full object-cover"
                                />
                              </div>
                            ))}
                          </div>
                        )}
                        {/* Product Details */}
                        <div className="flex-grow">
                          <p className="font-semibold text-gray-800">{product.name}</p>
                          <div className="relative">
                            <div 
                              className={`overflow-y-auto transition-all duration-200 ease-in-out mb-2 ${
                                expandedDescriptionId === product.id ? 'max-h-[500px]' : 'max-h-[100px]'
                              }`}
                              style={{
                                scrollbarWidth: 'thin',
                                scrollbarColor: '#CBD5E0 #EDF2F7'
                              }}
                            >
                              <p className="text-sm text-gray-600 whitespace-pre-wrap pr-2">{product.description}</p>
                            </div>
                            {product.description && product.description.length > 300 && (
                              <Button
                                onClick={() => setExpandedDescriptionId(
                                  expandedDescriptionId === product.id ? null : product.id
                                )}
                                variant="secondary"
                                size="sm"
                                className="!py-1 !px-2 text-xs absolute bottom-0 right-0 bg-white"
                              >
                                {expandedDescriptionId === product.id ? 'Show Less' : 'Show More'}
                              </Button>
                            )}
                          </div>
                          {/* Product Prices */}
                          {product.pricing && (
                            <div className="space-y-1">
                              {product.pricing.show_retail_price && product.pricing.retail_price && (
                                <p className="text-sm">
                                  <span className="font-medium">Retail:</span> {formatPrice(product.pricing.retail_price)}
                                </p>
                              )}
                              {product.pricing.show_sale_price && product.pricing.sale_price && (
                                <p className="text-sm text-green-600">
                                  <span className="font-medium">Sale:</span> {formatPrice(product.pricing.sale_price)}
                                </p>
                              )}
                              {product.pricing.show_special_price && product.pricing.special_price && (
                                <p className="text-sm text-blue-600">
                                  <span className="font-medium">Special:</span> {formatPrice(product.pricing.special_price)}
                                </p>
                              )}
                              {product.pricing.show_campaign_price && product.pricing.campaign_price && (
                                <p className="text-sm text-purple-600">
                                  <span className="font-medium">Campaign:</span> {formatPrice(product.pricing.campaign_price)}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex space-x-2 ml-4">
                        <Button
                          onClick={() => {
                            if (expandedProductId === product.id) {
                              handleDescriptionCancel();
                            } else {
                              setExpandedProductId(product.id);
                            }
                          }}
                          variant="secondary"
                          size="sm"
                          disabled={isUpdating}
                        >
                          {expandedProductId === product.id ? 'Cancel' : 'AI Description'}
                        </Button>
                        <Button
                          onClick={() => onEdit(product)}
                          variant="primary"
                          size="sm"
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={() => onDelete(product.id)}
                          variant="danger"
                          size="sm"
                        >
                          Delete
                        </Button>
                      </div>
                    </div>

                    {/* Description Manager */}
                    {expandedProductId === product.id && (
                      <ProductDescriptionManager
                        key={`desc-manager-${product.id}-${product.updated_at}-${product.description}-${(product as any).key || ''}`}
                        product={product}
                        onDescriptionSelect={handleSaveDescription}
                        onGenerateDescription={handleGenerateDescription}
                        isGenerating={isGenerating}
                        previewDescription={previewDescription}
                      />
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-500 text-center py-4">
            No products available. Add your first product or upload a CSV file.
          </p>
        )}
      </CollapsibleCard>

      {/* Image Modal */}
      {selectedImage && (
        <ImageModal
          imageUrl={selectedImage.url}
          altText={selectedImage.alt}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </>
  );
};

export default ProductList;
