import React from 'react';
import FormInput from '../../common/FormInput';
import { BusinessProfileFormSectionProps } from '../types/businessProfileForm';

const ContentGuidelinesForm: React.FC<BusinessProfileFormSectionProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  // Helper function to safely get array value as string
  const getArrayAsString = (arr: string[] | undefined | null): string => {
    if (!arr) return '';
    return arr.join('\n');
  };

  // Helper function to update SEO guidelines
  const updateSeoGuidelines = (field: string, value: any) => {
    handleChange('seo_guidelines', {
      ...values.seo_guidelines,
      [field]: value
    });
  };

  // Helper function to update social media guidelines
  const updateSocialMediaGuidelines = (field: string, value: string) => {
    handleChange('social_media_guidelines', {
      ...values.social_media_guidelines,
      [field]: value
    });
  };

  const keywordPlaceholder = '{keywords}';

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">Content Guidelines</h3>
        <p className="mt-1 text-sm text-gray-500">
          Define your content strategy and guidelines for consistent brand messaging.
        </p>
      </div>

      {/* Messaging Pillars */}
      <FormInput
        label="Messaging Pillars"
        name="messaging_pillars"
        value={getArrayAsString(values.messaging_pillars)}
        onChange={(e) => {
          const lines = e.target.value.split('\n');
          handleChange('messaging_pillars', lines);
        }}
        onBlur={handleBlur}
        error={errors.messaging_pillars}
        touched={touched.messaging_pillars}
        multiline
        rows={4}
        helpText="(One per line)"
        placeholder="Enter your core messaging themes&#10;Example:&#10;Innovation and Technology&#10;Customer Success&#10;Industry Leadership"
      />

      {/* Social Media Guidelines */}
      <div className="space-y-4">
        <h4 className="text-md font-medium text-gray-700">Social Media Guidelines</h4>
        <p className="text-sm text-gray-500">
          Define how your brand communicates across social media platforms.
        </p>
        
        <FormInput
          label="Hashtag Strategy"
          name="social_media_guidelines.hashtag_strategy"
          value={values.social_media_guidelines?.hashtag_strategy || ''}
          onChange={(e) => updateSocialMediaGuidelines('hashtag_strategy', e.target.value)}
          onBlur={handleBlur}
          multiline
          rows={4}
          helpText="Define your hashtag usage rules and list your brand's preferred hashtags"
          placeholder={`Example Hashtag Strategy:

1. Always use #YourBrandName in every post
2. Include 3-5 relevant hashtags per post
3. Preferred hashtags:
   - #CarDetailing #AutoCare #ProfessionalDetailing
   - #CarCare #DetailingServices #AutoDetailing
4. Use location-based hashtags for local reach: #NYCDetailing
5. Campaign-specific hashtags: #SpringClean2024 #SummerShine`}
        />

        <FormInput
          label="Emoji Usage"
          name="social_media_guidelines.emoji_usage"
          value={values.social_media_guidelines?.emoji_usage || ''}
          onChange={(e) => updateSocialMediaGuidelines('emoji_usage', e.target.value)}
          onBlur={handleBlur}
          multiline
          rows={4}
          helpText="Specify when and how to use emojis in your social media content"
          placeholder={`Example Emoji Guidelines:

1. Approved emojis for regular use:
   🚗 - for general car content
   ✨ - for before/after results
   🌟 - for special offers
   🔧 - for service-related posts

2. Usage rules:
   - Limit to 2-3 emojis per post
   - Use in captions, not headlines
   - Avoid excessive emoji clusters

3. Platform-specific guidelines:
   - Instagram: More emoji-friendly
   - LinkedIn: Minimal emoji use
   - Twitter: Strategic emoji placement`}
        />

        <FormInput
          label="Engagement Guidelines"
          name="social_media_guidelines.engagement_guidelines"
          value={values.social_media_guidelines?.engagement_guidelines || ''}
          onChange={(e) => updateSocialMediaGuidelines('engagement_guidelines', e.target.value)}
          onBlur={handleBlur}
          multiline
          rows={4}
          helpText="Define how to interact with followers and handle social media interactions"
          placeholder={`Example Engagement Guidelines:

1. Response Times:
   - Reply to comments within 4 hours
   - Address DMs within 24 hours
   - Acknowledge mentions within 1 business day

2. Interaction Style:
   - Use a friendly, professional tone
   - Address customers by name when possible
   - Sign responses with team member's initials

3. Comment Handling:
   - Like all positive comments
   - Thank customers for positive feedback
   - Move complaints to private messages
   - Delete spam and inappropriate comments

4. Proactive Engagement:
   - Share and comment on customer success stories
   - Participate in relevant industry discussions
   - Engage with partner brands' content`}
        />
      </div>

      {/* SEO Guidelines */}
      <div className="space-y-4">
        <h4 className="text-md font-medium text-gray-700">SEO Guidelines</h4>
        
        <FormInput
          label="Target Keywords"
          name="seo_guidelines.target_keywords"
          value={getArrayAsString(values.seo_guidelines?.target_keywords)}
          onChange={(e) => {
            const lines = e.target.value.split('\n');
            updateSeoGuidelines('target_keywords', lines);
          }}
          onBlur={handleBlur}
          multiline
          rows={4}
          helpText="(One per line)"
          placeholder="Enter target keywords&#10;Example:&#10;professional car detailing&#10;auto detailing services&#10;mobile car wash"
        />

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Meta Description Template
            <span className="ml-1 block text-xs text-gray-500">
              Create a template for your meta descriptions. Use {`{keywords}`} as a placeholder - it will be replaced with relevant keywords for each page.
            </span>
          </label>
          <div className="mt-1 text-sm text-gray-500 bg-gray-50 p-3 rounded-md mb-2">
            <p className="font-medium">How to use:</p>
            <ol className="list-decimal ml-4 space-y-1">
              <li>Write a template that describes your business/content</li>
              <li>Include {`{keywords}`} where you want keywords to appear</li>
              <li>Keep it between 150-160 characters for optimal SEO</li>
            </ol>
            <p className="mt-2 font-medium">Example templates:</p>
            <ul className="list-disc ml-4 space-y-1">
              <li>Expert {`{keywords}`} in [Your City]. Professional service with 100% satisfaction guarantee. Book your appointment today!</li>
              <li>Looking for {`{keywords}`}? Our certified experts deliver top-quality results. Free quotes available!</li>
            </ul>
          </div>
          <FormInput
            label="Meta Description Template"
            name="seo_guidelines.meta_description_template"
            value={values.seo_guidelines?.meta_description_template || ''}
            onChange={(e) => updateSeoGuidelines('meta_description_template', e.target.value)}
            onBlur={handleBlur}
            multiline
            rows={3}
            placeholder={`Example: Professional ${keywordPlaceholder} services in [Your City]. Trusted by 1000+ customers. ✨ Book now for premium results!`}
          />
        </div>
      </div>

      {/* Content Type Guidelines */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Content Type Guidelines
          <span className="ml-1 block text-xs text-gray-500">
            Define formatting and guidelines for different types of content
          </span>
        </label>
        <div className="mt-1 text-sm text-gray-500 bg-gray-50 p-3 rounded-md mb-2">
          <p className="font-medium">How to use JSON format:</p>
          <ol className="list-decimal ml-4 space-y-1">
            <li>Each content type should be a key in the JSON object</li>
            <li>For each type, specify 'format' and 'guidelines'</li>
            <li>Keep the structure consistent for all content types</li>
          </ol>
          <p className="mt-2 font-medium">Example structure:</p>
          <pre className="bg-white p-2 rounded mt-1 text-xs overflow-auto">
{`{
  "blog_posts": {
    "format": "long-form",
    "guidelines": "1500-2000 words, include 2-3 images, use H2/H3 headings"
  },
  "instagram_posts": {
    "format": "image + caption",
    "guidelines": "Square image, 150-200 character caption, 5-7 hashtags"
  },
  "facebook_updates": {
    "format": "text + media",
    "guidelines": "2-3 paragraphs, include CTA, add relevant image/video"
  },
  "email_newsletters": {
    "format": "html email",
    "guidelines": "300-500 words, mobile-friendly, clear CTA button"
  }
}`}
          </pre>
        </div>
        <textarea
          name="content_type_guidelines"
          value={values.content_type_guidelines ? JSON.stringify(values.content_type_guidelines, null, 2) : ''}
          onChange={(e) => {
            try {
              const parsed = JSON.parse(e.target.value);
              handleChange('content_type_guidelines', parsed);
            } catch (err) {
              // Allow invalid JSON while typing
              handleChange('content_type_guidelines', e.target.value);
            }
          }}
          onBlur={(e) => {
            try {
              const parsed = JSON.parse(e.target.value);
              handleChange('content_type_guidelines', parsed);
            } catch (err) {
              // Reset to empty object if invalid JSON on blur
              handleChange('content_type_guidelines', {});
            }
            handleBlur(e);
          }}
          className="mt-1 block w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 hover:border-gray-300 font-mono"
          rows={12}
          placeholder={`{
  "service_pages": {
    "format": "service description",
    "guidelines": "500-800 words, include pricing, add before/after images"
  },
  "testimonials": {
    "format": "customer review",
    "guidelines": "150-200 words, include customer name and service type"
  },
  "social_media": {
    "format": "platform specific",
    "guidelines": "Follow platform character limits, include relevant hashtags"
  }
}`}
        />
      </div>
    </div>
  );
};

export default ContentGuidelinesForm;
