import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSubscription } from '../contexts/SubscriptionContext';
import { useAuth } from '../contexts/AuthContext';

const Subscription: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const {
    hasActiveSubscription,
    isLoading,
    currentPlan,
    currentPeriodEnd,
    cancelAtPeriodEnd,
    subscriptionStatus,
    cancelSubscription,
    reinstateSubscription
  } = useSubscription();

  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    // If user has active subscription and wasn't redirected here due to access restrictions,
    // redirect them to dashboard
    if (hasActiveSubscription && !location.state?.from) {
      navigate('/');
    }
  }, [hasActiveSubscription, location.state, navigate]);

  const handleCancel = async () => {
    try {
      setIsProcessing(true);
      setError(null);
      await cancelSubscription();
      setSuccessMessage('Your subscription has been cancelled. You will have access until the end of your billing period.');
    } catch (err) {
      setError('Failed to cancel subscription. Please try again.');
      console.error('Error cancelling subscription:', err);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleReinstate = async () => {
    try {
      setIsProcessing(true);
      setError(null);
      await reinstateSubscription();
      setSuccessMessage('Your subscription has been reinstated.');
    } catch (err) {
      setError('Failed to reinstate subscription. Please try again.');
      console.error('Error reinstating subscription:', err);
    } finally {
      setIsProcessing(false);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading subscription details...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">Subscription Management</h1>

        {error && (
          <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
        )}

        {successMessage && (
          <div className="mb-6 bg-green-50 border-l-4 border-green-500 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-green-700">{successMessage}</p>
              </div>
            </div>
          </div>
        )}

        <div className="space-y-6">
          {/* Current Plan Info */}
          <div>
            <h2 className="text-lg font-semibold text-gray-900 mb-2">Current Plan</h2>
            <div className="bg-gray-50 rounded-lg p-4">
              <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                  <dt className="text-sm font-medium text-gray-500">Plan Name</dt>
                  <dd className="mt-1 text-sm text-gray-900">{currentPlan || 'No active plan'}</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Status</dt>
                  <dd className="mt-1 text-sm text-gray-900 capitalize">{subscriptionStatus || 'N/A'}</dd>
                </div>
                {currentPeriodEnd && (
                  <div>
                    <dt className="text-sm font-medium text-gray-500">Current Period Ends</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {new Date(currentPeriodEnd).toLocaleDateString()}
                    </dd>
                  </div>
                )}
              </dl>
            </div>
          </div>

          {/* Subscription Actions */}
          <div>
            <h2 className="text-lg font-semibold text-gray-900 mb-2">Subscription Actions</h2>
            <div className="bg-gray-50 rounded-lg p-4">
              {subscriptionStatus === 'active' && !cancelAtPeriodEnd && (
                <button
                  onClick={handleCancel}
                  disabled={isProcessing}
                  className={`w-full sm:w-auto px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 ${
                    isProcessing ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {isProcessing ? 'Processing...' : 'Cancel Subscription'}
                </button>
              )}

              {cancelAtPeriodEnd && (
                <button
                  onClick={handleReinstate}
                  disabled={isProcessing}
                  className={`w-full sm:w-auto px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ${
                    isProcessing ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {isProcessing ? 'Processing...' : 'Resume Subscription'}
                </button>
              )}

              {subscriptionStatus === 'expired' && (
                <div className="text-center">
                  <p className="text-sm text-gray-600 mb-4">
                    Your subscription has expired. Renew now to regain access to all features.
                  </p>
                  <button
                    onClick={() => navigate('/pricing')}
                    className="px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    View Plans
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
