import React, { useState, useEffect } from 'react';
import { Platform } from '../types';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube, FaTiktok } from 'react-icons/fa';

interface PlatformSelectorProps {
  platforms: Platform[];
  selectedPlatforms: Platform[];
  onSelect: (platforms: Platform[]) => void;
  onRequirementsChange?: (platform: string, requirements: any) => void;
}

const PlatformIcon: React.FC<{ platform: string }> = ({ platform }) => {
  const iconProps = {
    className: 'w-5 h-5',
    'aria-hidden': true
  };

  switch (platform) {
    case 'facebook_post':
      return <FaFacebook {...iconProps} className="text-[#1877F2]" />;
    case 'twitter_post':
      return <FaTwitter {...iconProps} className="text-[#1DA1F2]" />;
    case 'instagram_feed':
      return <FaInstagram {...iconProps} className="text-[#E4405F]" />;
    case 'linkedin_post':
      return <FaLinkedin {...iconProps} className="text-[#0A66C2]" />;
    case 'youtube_video':
    case 'youtube_shorts':
      return <FaYoutube {...iconProps} className="text-[#FF0000]" />;
    case 'tiktok_video':
      return <FaTiktok {...iconProps} className="text-[#000000]" />;
    default:
      return null;
  }
};

const formatPlatformName = (name: string): string => {
  return name
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const getDefaultRequirements = (platform: string) => {
  switch (platform) {
    case 'youtube_video':
      return {
        maxDuration: '15:00',
        descriptionLength: 5000,
        hashtagLimit: 15,
        recommendedLength: '10:00'
      };
    case 'youtube_shorts':
      return {
        maxDuration: '01:00',
        descriptionLength: 100,
        hashtagLimit: 3,
        recommendedLength: '00:30'
      };
    case 'tiktok_video':
      return {
        maxDuration: '03:00',
        descriptionLength: 2200,
        hashtagLimit: 5,
        recommendedLength: '00:30'
      };
    default:
      return null;
  }
};

const groupPlatforms = (platforms: Platform[]): Record<string, Platform[]> => {
  return platforms.reduce((acc, platform) => {
    const type = platform.name.includes('youtube') || platform.name.includes('tiktok')
      ? 'Video Platforms'
      : 'Social Media';
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(platform);
    return acc;
  }, {} as Record<string, Platform[]>);
};

export const PlatformSelector: React.FC<PlatformSelectorProps> = ({
  platforms,
  selectedPlatforms,
  onSelect,
  onRequirementsChange
}) => {
  const [requirements, setRequirements] = useState<Record<string, any>>({});
  const groupedPlatforms = groupPlatforms(platforms);

  const handleRequirementChange = (platform: string, field: string, value: string) => {
    const newRequirements = {
      ...requirements,
      [platform]: {
        ...(requirements[platform] || getDefaultRequirements(platform)),
        [field]: value
      }
    };
    setRequirements(newRequirements);
    onRequirementsChange?.(platform, newRequirements[platform]);
  };

  const renderRequirements = (platform: string) => {
    const defaultReqs = getDefaultRequirements(platform);
    if (!defaultReqs) return null;

    const currentReqs = requirements[platform] || defaultReqs;

    return (
      <div className="mt-4 space-y-3 border-t border-gray-200 pt-4">
        <h4 className="text-sm font-medium text-gray-900">Platform Requirements</h4>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Max Duration</label>
            <input
              type="text"
              value={currentReqs.maxDuration}
              onChange={(e) => handleRequirementChange(platform, 'maxDuration', e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              placeholder="MM:SS"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Recommended Length</label>
            <input
              type="text"
              value={currentReqs.recommendedLength}
              onChange={(e) => handleRequirementChange(platform, 'recommendedLength', e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              placeholder="MM:SS"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Description Length</label>
            <input
              type="number"
              value={currentReqs.descriptionLength}
              onChange={(e) => handleRequirementChange(platform, 'descriptionLength', e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Hashtag Limit</label>
            <input
              type="number"
              value={currentReqs.hashtagLimit}
              onChange={(e) => handleRequirementChange(platform, 'hashtagLimit', e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-6">
      {/* Selected Platforms Summary */}
      {selectedPlatforms.length > 0 && (
        <div className="flex flex-wrap gap-2 mb-4">
          {selectedPlatforms.map(platform => (
            <span
              key={platform.id}
              className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800"
            >
              <PlatformIcon platform={platform.name} />
              <span className="ml-2">{formatPlatformName(platform.name)}</span>
            </span>
          ))}
        </div>
      )}

      {/* Platform Groups */}
      {Object.entries(groupedPlatforms).map(([groupName, groupPlatforms]) => (
        <div key={groupName} className="space-y-4">
          <h3 className="text-lg font-medium text-gray-900">{groupName}</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {groupPlatforms.map(platform => {
              const isSelected = selectedPlatforms.some(p => p.id === platform.id);
              return (
                <div
                  key={platform.id}
                  className={`relative rounded-lg border p-4 ${
                    isSelected ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
                  }`}
                >
                  <label className="flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={(e) => {
                          const newSelection = e.target.checked
                            ? [...selectedPlatforms, platform]
                            : selectedPlatforms.filter(p => p.id !== platform.id);
                          onSelect(newSelection);
                        }}
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                    </div>
                    <div className="ml-3 flex-grow">
                      <div className="flex items-center space-x-2">
                        <PlatformIcon platform={platform.name} />
                        <span className={`font-medium ${isSelected ? 'text-blue-700' : 'text-gray-900'}`}>
                          {formatPlatformName(platform.name)}
                        </span>
                      </div>
                      <ul className="mt-1 text-sm text-gray-600 list-disc list-inside">
                        {platform.requirements.map((req, index) => (
                          <li key={index}>{req}</li>
                        ))}
                      </ul>

                      {/* Editable Requirements */}
                      {isSelected && renderRequirements(platform.name)}
                    </div>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      ))}

      {/* Error Message */}
      {selectedPlatforms.length === 0 && (
        <div className="flex items-center mt-4 text-red-600">
          <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
          Please select at least one platform
        </div>
      )}
    </div>
  );
};
