import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { BusinessProfileProvider } from './contexts/BusinessProfileContext';
import { SubscriptionProvider } from './contexts/SubscriptionContext';
import Layout from './components/Layout';
import Login from './pages/Login';
import Register from './pages/Register';
import Signup from './pages/Signup';
import SuccessPage from './pages/SuccessPage';
import Dashboard from './pages/Dashboard';
import ContentHistory from './pages/ContentHistory';
import { SimplifiedContentPage } from './pages/simplified-content/SimplifiedContentPage';
import ImageManagement from './pages/ImageManagement';
import CampaignManagement from './pages/CampaignManagement';
import CreateCampaign from './pages/CampaignManagement/CreateCampaign';
import BusinessProfile from './pages/BusinessProfile';
import Research from './pages/Research';
import Subscription from './pages/Subscription';
import Storage from './pages/Storage';
import PrivateRoute from './components/PrivateRoute';
import NotFound from './pages/NotFound';
import LandingPage from './pages/LandingPage';
import PricingPage from './pages/PricingPage';
import ResetPassword from './pages/ResetPassword';
import { adminRoutes } from './routes/adminRoutes';

const App: React.FC = () => {
  return (
    <Router>
      <SubscriptionProvider>
        <AuthProvider>
          <BusinessProfileProvider>
            <Routes>
              {/* Public routes */}
              <Route path="/" element={<LandingPage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/signup/success" element={<SuccessPage />} />
              <Route path="/signup/cancel" element={<NotFound />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/reset-password/*" element={<ResetPassword />} />
              
              {/* Protected application routes */}
              <Route element={<Layout />}>
                <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path="/simplified-content" element={<PrivateRoute><SimplifiedContentPage /></PrivateRoute>} />
                <Route path="/content-history" element={<PrivateRoute><ContentHistory /></PrivateRoute>} />
                <Route path="/storage" element={<PrivateRoute><Storage /></PrivateRoute>} />
                <Route path="/images" element={<PrivateRoute><ImageManagement /></PrivateRoute>} />
                <Route path="/campaigns" element={<PrivateRoute><CampaignManagement /></PrivateRoute>} />
                <Route path="/campaigns/create" element={<PrivateRoute><CreateCampaign /></PrivateRoute>} />
                <Route path="/business-profile" element={<PrivateRoute><BusinessProfile /></PrivateRoute>} />
                <Route path="/research" element={<PrivateRoute><Research /></PrivateRoute>} />
                <Route path="/subscription" element={<PrivateRoute><Subscription /></PrivateRoute>} />
                
                {/* Admin routes */}
                {adminRoutes}
              </Route>

              {/* Catch all */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BusinessProfileProvider>
        </AuthProvider>
      </SubscriptionProvider>
    </Router>
  );
};

export default App;
