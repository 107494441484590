import axiosInstance from '../utils/axiosConfig';
import { User, LoginResponse, RegistrationResponse } from '../types/auth';
import { handleAxiosError } from './api';

// Function to get CSRF token
const getCsrfToken = async () => {
  try {
    const response = await axiosInstance.get('/get-csrf-token/');
    return response.data.csrfToken;
  } catch (error) {
    console.error('Error getting CSRF token:', error);
    throw error;
  }
};

export const authService = {
  login: async (email: string, password: string): Promise<LoginResponse> => {
    console.log('Attempting login for email:', email);
    try {
      // Get CSRF token before login
      await getCsrfToken();
      
      // Attempt login
      const response = await axiosInstance.post<LoginResponse>('/login/', { 
        email, 
        password 
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true
      });

      console.log('Login response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Login error:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  logout: async (): Promise<void> => {
    console.log('Logging out user');
    try {
      // Get CSRF token before logout
      await getCsrfToken();
      
      await axiosInstance.post('/logout/', {}, {
        withCredentials: true
      });
    } catch (error) {
      console.error('Logout error:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  signup: async (
    username: string,
    email: string,
    password: string,
    subscription_plan: number
  ): Promise<RegistrationResponse> => {
    console.log('Signing up new user:', username);
    try {
      // Get CSRF token before signup
      await getCsrfToken();
      
      const response = await axiosInstance.post<RegistrationResponse>('/register/', {
        username,
        email,
        password,
        subscription_plan
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true
      });
      return response.data;
    } catch (error) {
      console.error('Signup error:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  getCurrentUser: async (): Promise<User> => {
    console.log('Fetching current user data');
    try {
      // Get CSRF token before fetching user data
      await getCsrfToken();
      
      const response = await axiosInstance.get<User>('/user/', {
        withCredentials: true
      });
      console.log('User data received:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      handleAxiosError(error);
      throw error;
    }
  },

  markWelcomeSeen: async (): Promise<void> => {
    console.log('Marking welcome message as seen');
    try {
      // Get CSRF token before marking welcome as seen
      await getCsrfToken();
      
      await axiosInstance.post('/user/mark-welcome-seen/', {}, {
        withCredentials: true
      });
      console.log('Welcome message marked as seen');
    } catch (error) {
      console.error('Error marking welcome as seen:', error);
      handleAxiosError(error);
      throw error;
    }
  }
};
