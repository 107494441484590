import React, { useState, useEffect } from 'react';
import { Campaign } from '../types';
import { simplifiedContentApi } from '../api/contentApi';

interface CampaignSelectorProps {
  selectedCampaign: Campaign | null;
  onSelect: (campaign: Campaign | null) => void;
  optional?: boolean;
}

export const CampaignSelector: React.FC<CampaignSelectorProps> = ({
  selectedCampaign,
  onSelect,
  optional = false
}) => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = async () => {
    if (!isOpen && campaigns.length === 0) {
      setIsOpen(true);
      setIsLoading(true);
      setError(null);
      
      try {
        const campaignsData = await simplifiedContentApi.getCampaigns();
        setCampaigns(campaignsData);
      } catch (err) {
        setError('Failed to load campaigns');
        console.error('Error loading campaigns:', err);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsOpen(!isOpen);
    }
  };

  const handleSelect = (campaign: Campaign | null) => {
    onSelect(campaign);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      {/* Selected Campaign Display */}
      {selectedCampaign && (
        <div className="mb-4 p-4 bg-blue-50 border border-blue-200 rounded-xl">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <div className="flex-shrink-0">
                <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                </svg>
              </div>
              <div>
                <h3 className="text-sm font-medium text-blue-900">Selected Campaign</h3>
                <p className="text-lg font-semibold text-blue-700">{selectedCampaign.name}</p>
              </div>
            </div>
            <button
              onClick={() => handleSelect(null)}
              className="p-2 text-blue-600 hover:text-blue-800 rounded-lg hover:bg-blue-100 transition-colors"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      )}

      {/* Campaign Selector Button */}
      <button
        type="button"
        onClick={handleOpen}
        className={`relative w-full flex items-center justify-between px-4 py-3 bg-white border rounded-xl shadow-sm transition-all duration-200 ${
          isOpen
            ? 'border-blue-500 ring-2 ring-blue-200'
            : 'border-gray-300 hover:border-blue-400'
        }`}
      >
        <div className="flex items-center space-x-3">
          <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
          </svg>
          <span className={`text-sm ${selectedCampaign ? 'text-gray-900' : 'text-gray-500'}`}>
            {isLoading ? 'Loading campaigns...' : (selectedCampaign ? selectedCampaign.name : 'Select a campaign')}
            {optional && !selectedCampaign && ' (optional)'}
          </span>
        </div>
        <svg
          className={`w-5 h-5 text-gray-400 transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute z-10 w-full mt-2 bg-white rounded-xl shadow-lg border border-gray-200 overflow-hidden">
          {optional && (
            <div
              onClick={() => handleSelect(null)}
              className={`flex items-center space-x-3 px-4 py-3 cursor-pointer transition-colors ${
                !selectedCampaign ? 'bg-blue-50 text-blue-700' : 'hover:bg-gray-50 text-gray-900'
              }`}
            >
              <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
              <span className="font-medium">Quick Content (No Campaign)</span>
            </div>
          )}

          {isLoading ? (
            <div className="px-4 py-8">
              <div className="flex flex-col items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                <p className="mt-2 text-sm text-gray-500">Loading campaigns...</p>
              </div>
            </div>
          ) : error ? (
            <div className="px-4 py-6 text-center">
              <svg className="mx-auto h-8 w-8 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <p className="mt-2 text-sm text-red-600">{error}</p>
            </div>
          ) : campaigns.length === 0 ? (
            <div className="px-4 py-6 text-center">
              <svg className="mx-auto h-8 w-8 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
              </svg>
              <p className="mt-2 text-sm text-gray-500">No campaigns available</p>
            </div>
          ) : (
            <div className="max-h-60 overflow-auto">
              {campaigns.map((campaign) => (
                <div
                  key={campaign.id}
                  onClick={() => handleSelect(campaign)}
                  className={`flex items-center justify-between px-4 py-3 cursor-pointer transition-colors ${
                    selectedCampaign?.id === campaign.id
                      ? 'bg-blue-50'
                      : 'hover:bg-gray-50'
                  }`}
                >
                  <div className="flex items-center space-x-3">
                    <svg className="w-5 h-5 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                    </svg>
                    <div>
                      <p className={`font-medium ${selectedCampaign?.id === campaign.id ? 'text-blue-700' : 'text-gray-900'}`}>
                        {campaign.name}
                      </p>
                      <p className="text-sm text-gray-500">ID: {campaign.id}</p>
                    </div>
                  </div>
                  {selectedCampaign?.id === campaign.id && (
                    <svg className="w-5 h-5 text-blue-600" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
