import { useState, useCallback } from 'react';

interface CSVRow {
  type: string;
  name: string;
  description: string;
  [key: string]: string;
}

interface CSVValidationError {
  row: number;
  message: string;
}

interface UseCSVPreviewReturn {
  preview: CSVRow[];
  errors: CSVValidationError[];
  isValid: boolean;
  isLoading: boolean;
  parseCSV: (file: File) => Promise<void>;
}

const validateRow = (row: CSVRow, rowIndex: number): CSVValidationError[] => {
  const errors: CSVValidationError[] = [];

  if (!row.type) {
    errors.push({ row: rowIndex, message: 'Type is required' });
  } else if (!['product', 'service'].includes(row.type.toLowerCase())) {
    errors.push({ row: rowIndex, message: 'Type must be either "product" or "service"' });
  }

  if (!row.name) {
    errors.push({ row: rowIndex, message: 'Name is required' });
  } else if (row.name.length < 2) {
    errors.push({ row: rowIndex, message: 'Name must be at least 2 characters long' });
  } else if (row.name.length > 100) {
    errors.push({ row: rowIndex, message: 'Name must be less than 100 characters' });
  }

  if (row.description && row.description.length > 500) {
    errors.push({ row: rowIndex, message: 'Description must be less than 500 characters' });
  }

  return errors;
};

export const useCSVPreview = (): UseCSVPreviewReturn => {
  const [preview, setPreview] = useState<CSVRow[]>([]);
  const [errors, setErrors] = useState<CSVValidationError[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const parseCSV = useCallback(async (file: File): Promise<void> => {
    setIsLoading(true);
    setErrors([]);
    setPreview([]);

    try {
      const text = await file.text();
      const lines = text.split('\n');
      
      // Parse header
      const header = lines[0].trim().split(',');
      const requiredColumns = ['type', 'name', 'description'];
      const missingColumns = requiredColumns.filter(col => !header.includes(col));
      
      if (missingColumns.length > 0) {
        setErrors([{
          row: 0,
          message: `Missing required columns: ${missingColumns.join(', ')}`,
        }]);
        return;
      }

      // Parse rows
      const rows: CSVRow[] = [];
      const validationErrors: CSVValidationError[] = [];

      for (let i = 1; i < lines.length; i++) {
        const line = lines[i].trim();
        if (!line) continue;

        const values = line.split(',');
        if (values.length !== header.length) {
          validationErrors.push({
            row: i,
            message: `Invalid number of columns in row ${i}`,
          });
          continue;
        }

        const row = header.reduce((acc, col, index) => {
          acc[col] = values[index];
          return acc;
        }, {} as CSVRow);

        rows.push(row);
        validationErrors.push(...validateRow(row, i));
      }

      setPreview(rows);
      setErrors(validationErrors);
    } catch (err) {
      setErrors([{
        row: 0,
        message: 'Failed to parse CSV file. Please ensure it is properly formatted.',
      }]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    preview,
    errors,
    isValid: errors.length === 0 && preview.length > 0,
    isLoading,
    parseCSV,
  };
};
