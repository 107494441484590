import { useState, useCallback } from 'react';
import { StateChange } from '../types';

export const useStateTracker = () => {
  const [stateHistory, setStateHistory] = useState<StateChange[]>([]);

  const logStateChange = useCallback((field: string, previousValue: any, newValue: any) => {
    const stateChange: StateChange = {
      timestamp: new Date().toISOString(),
      field,
      previousValue,
      newValue
    };

    setStateHistory(prev => [...prev, stateChange]);
  }, []);

  return {
    stateHistory,
    logStateChange
  };
};
