import React, { useEffect, useState } from 'react';
import { BusinessProfileViewProps } from '../../types/businessProfile';
import Button from '../common/Button';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

interface SectionProps {
  title: string;
  isOpen: boolean;
  onToggle: () => void;
  children: React.ReactNode;
}

// Helper function to check if an array has items
const hasItems = (arr: any[] | undefined | null): boolean => {
  return Array.isArray(arr) && arr.length > 0;
};

const Section: React.FC<SectionProps> = ({ title, isOpen, onToggle, children }) => (
  <div className="bg-white rounded-lg shadow-sm border border-gray-100 overflow-hidden transition-all duration-200">
    <button
      onClick={onToggle}
      className="w-full px-6 py-4 flex items-center justify-between bg-gradient-to-r from-white to-gray-50 hover:from-gray-50 hover:to-gray-100 transition-all duration-200"
    >
      <h2 className="text-lg font-semibold text-gray-800">{title}</h2>
      <ChevronDownIcon 
        className={`h-5 w-5 text-gray-500 transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''}`}
      />
    </button>
    {isOpen && (
      <div className="px-6 py-4 bg-white">
        {children}
      </div>
    )}
  </div>
);

const BusinessProfileView: React.FC<BusinessProfileViewProps> = ({
  profile,
  onEdit,
  onDelete,
  isOpen: isMainOpen,
  onToggle: onMainToggle,
}) => {
  const [openSections, setOpenSections] = useState({
    basic: false,
    contact: false,
    brand: false,
    visual: false,
    voice: false,
    content: false,
  });

  const toggleSection = (section: keyof typeof openSections) => {
    setOpenSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  useEffect(() => {
    console.log('Profile data in BusinessProfileView:', profile);
  }, [profile]);

  // Filter out "Platform 1" from platform_tone_variations
  const filteredPlatformVariations = profile.platform_tone_variations ? 
    Object.entries(profile.platform_tone_variations).filter(([platform]) => platform !== 'Platform 1') :
    [];

  return (
    <div className="space-y-6">
      {/* Header Actions */}
      <div className="flex justify-end space-x-3">
        <Button
          onClick={onEdit}
          variant="primary"
          className="px-4 py-2 text-sm"
        >
          Edit Profile
        </Button>
        <Button
          onClick={onDelete}
          variant="danger"
          className="px-4 py-2 text-sm"
        >
          Delete Profile
        </Button>
      </div>

      {/* Basic Information */}
      <Section
        title="Basic Information"
        isOpen={openSections.basic}
        onToggle={() => toggleSection('basic')}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-2">
            <p className="text-sm font-medium text-gray-500">Business Name</p>
            <p className="text-base font-semibold text-gray-900">{profile.business_name}</p>
          </div>
          <div className="space-y-2">
            <p className="text-sm font-medium text-gray-500">Business Type</p>
            <p className="text-base font-semibold text-gray-900">
              {profile.business_type === 'custom' ? profile.custom_business_type : profile.business_type}
            </p>
          </div>
          <div className="md:col-span-2 space-y-2">
            <p className="text-sm font-medium text-gray-500">Description</p>
            <p className="text-base text-gray-900 whitespace-pre-wrap">
              {profile.description || 'No description provided'}
            </p>
          </div>
        </div>
      </Section>

      {/* Contact Information */}
      <Section
        title="Contact Information"
        isOpen={openSections.contact}
        onToggle={() => toggleSection('contact')}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-2">
            <p className="text-sm font-medium text-gray-500">Address</p>
            <p className="text-base text-gray-900">{profile.full_address}</p>
          </div>
          <div className="space-y-2">
            <p className="text-sm font-medium text-gray-500">Phone Number</p>
            <p className="text-base text-gray-900">{profile.phone_number}</p>
          </div>
          <div className="space-y-2">
            <p className="text-sm font-medium text-gray-500">Contact Name</p>
            <p className="text-base text-gray-900">{profile.contact_name}</p>
          </div>
          <div className="space-y-2">
            <p className="text-sm font-medium text-gray-500">Email</p>
            <p className="text-base text-gray-900">{profile.email}</p>
          </div>
        </div>
      </Section>

      {/* Style Guide Title */}
      <div className="pt-6 pb-2">
        <h2 className="text-2xl font-bold text-gray-900 border-b border-gray-200 pb-2">Style Guide</h2>
      </div>

      {/* Brand Identity */}
      <Section
        title="Brand Identity"
        isOpen={openSections.brand}
        onToggle={() => toggleSection('brand')}
      >
        <div className="space-y-6">
          <div className="space-y-2">
            <p className="text-sm font-medium text-gray-500">Mission Statement</p>
            <p className="text-base text-gray-900 whitespace-pre-wrap">
              {profile.mission_statement || 'No mission statement provided'}
            </p>
          </div>
          <div className="space-y-2">
            <p className="text-sm font-medium text-gray-500">Brand Personality</p>
            <p className="text-base text-gray-900 whitespace-pre-wrap">
              {profile.brand_personality || 'No brand personality defined'}
            </p>
          </div>
          <div className="space-y-2">
            <p className="text-sm font-medium text-gray-500">Target Demographics</p>
            <p className="text-base text-gray-900 whitespace-pre-wrap">
              {profile.industry_target_demographics || 'No target demographics defined'}
            </p>
          </div>
          <div className="space-y-2">
            <p className="text-sm font-medium text-gray-500">Market Segments</p>
            <p className="text-base text-gray-900 whitespace-pre-wrap">
              {profile.market_segments || 'No market segments defined'}
            </p>
          </div>
        </div>
      </Section>

      {/* Visual Guidelines */}
      {(profile.logo_usage_guidelines || 
        hasItems(profile.primary_colors) || 
        hasItems(profile.secondary_colors) ||
        profile.heading_font || profile.body_font || profile.special_font) && (
        <Section
          title="Visual Guidelines"
          isOpen={openSections.visual}
          onToggle={() => toggleSection('visual')}
        >
          <div className="space-y-6">
            {profile.logo_usage_guidelines && (
              <div className="space-y-2">
                <p className="text-sm font-medium text-gray-500">Logo Usage Guidelines</p>
                <p className="text-base text-gray-900 whitespace-pre-wrap">
                  {profile.logo_usage_guidelines}
                </p>
              </div>
            )}

            {hasItems(profile.primary_colors) && (
              <div className="space-y-3">
                <p className="text-sm font-medium text-gray-500">Primary Colors</p>
                <div className="flex flex-wrap gap-3">
                  {profile.primary_colors?.map((color, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <div
                        className="w-8 h-8 rounded shadow-sm border border-gray-200"
                        style={{ backgroundColor: color }}
                      />
                      <span className="text-sm font-medium text-gray-700">{color}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {hasItems(profile.secondary_colors) && (
              <div className="space-y-3">
                <p className="text-sm font-medium text-gray-500">Secondary Colors</p>
                <div className="flex flex-wrap gap-3">
                  {profile.secondary_colors?.map((color, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <div
                        className="w-8 h-8 rounded shadow-sm border border-gray-200"
                        style={{ backgroundColor: color }}
                      />
                      <span className="text-sm font-medium text-gray-700">{color}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {(profile.heading_font || profile.body_font || profile.special_font) && (
              <div className="space-y-3">
                <p className="text-sm font-medium text-gray-500">Typography</p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {profile.heading_font && (
                    <div className="space-y-1">
                      <p className="text-sm text-gray-500">Heading Font</p>
                      <p className="text-base font-semibold" style={{ fontFamily: profile.heading_font }}>
                        {profile.heading_font}
                      </p>
                    </div>
                  )}
                  {profile.body_font && (
                    <div className="space-y-1">
                      <p className="text-sm text-gray-500">Body Font</p>
                      <p className="text-base font-semibold" style={{ fontFamily: profile.body_font }}>
                        {profile.body_font}
                      </p>
                    </div>
                  )}
                  {profile.special_font && (
                    <div className="space-y-1">
                      <p className="text-sm text-gray-500">Special Font</p>
                      <p className="text-base font-semibold" style={{ fontFamily: profile.special_font }}>
                        {profile.special_font}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Section>
      )}

      {/* Voice and Tone */}
      {(profile.tone_style ||
        profile.writing_style_guidelines ||
        (profile.vocabulary_preferences && 
          (hasItems(profile.vocabulary_preferences.preferred_terms) || 
           hasItems(profile.vocabulary_preferences.avoided_terms))) ||
        filteredPlatformVariations.length > 0) && (
        <Section
          title="Voice and Tone"
          isOpen={openSections.voice}
          onToggle={() => toggleSection('voice')}
        >
          <div className="space-y-6">
            {profile.tone_style && (
              <div className="space-y-2">
                <p className="text-sm font-medium text-gray-500">Tone Style</p>
                <p className="text-base font-medium text-gray-900 capitalize">
                  {profile.tone_style}
                </p>
              </div>
            )}

            {profile.writing_style_guidelines && (
              <div className="space-y-2">
                <p className="text-sm font-medium text-gray-500">Writing Style Guidelines</p>
                <p className="text-base text-gray-900 whitespace-pre-wrap">
                  {profile.writing_style_guidelines}
                </p>
              </div>
            )}

            {profile.vocabulary_preferences && (
              <div className="space-y-4">
                <p className="text-sm font-medium text-gray-500">Vocabulary Preferences</p>
                
                {hasItems(profile.vocabulary_preferences.preferred_terms) && (
                  <div className="space-y-2">
                    <p className="text-sm text-gray-500">Preferred Terms</p>
                    <div className="flex flex-wrap gap-2">
                      {profile.vocabulary_preferences.preferred_terms?.map((term, index) => (
                        <span key={index} className="px-2 py-1 bg-green-100 text-green-800 rounded text-sm">
                          {term}
                        </span>
                      ))}
                    </div>
                  </div>
                )}

                {hasItems(profile.vocabulary_preferences.avoided_terms) && (
                  <div className="space-y-2">
                    <p className="text-sm text-gray-500">Avoided Terms</p>
                    <div className="flex flex-wrap gap-2">
                      {profile.vocabulary_preferences.avoided_terms?.map((term, index) => (
                        <span key={index} className="px-2 py-1 bg-red-100 text-red-800 rounded text-sm">
                          {term}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}

            {filteredPlatformVariations.length > 0 && (
              <div className="space-y-4">
                <p className="text-sm font-medium text-gray-500">Platform-specific Tone Variations</p>
                <div className="grid grid-cols-1 gap-4">
                  {filteredPlatformVariations.map(([platform, variation]) => (
                    <div key={platform} className="p-4 bg-gray-50 rounded-lg">
                      <p className="font-medium text-gray-900">{platform}</p>
                      <div className="mt-2 space-y-2">
                        <div>
                          <p className="text-sm text-gray-500">Tone</p>
                          <p className="text-sm text-gray-900">{variation.tone}</p>
                        </div>
                        <div>
                          <p className="text-sm text-gray-500">Guidelines</p>
                          <p className="text-sm text-gray-900 whitespace-pre-wrap">{variation.guidelines}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Section>
      )}

      {/* Content Guidelines */}
      {(hasItems(profile.messaging_pillars) ||
        profile.social_media_guidelines ||
        profile.seo_guidelines ||
        Object.keys(profile.content_type_guidelines || {}).length > 0) && (
        <Section
          title="Content Guidelines"
          isOpen={openSections.content}
          onToggle={() => toggleSection('content')}
        >
          <div className="space-y-6">
            {hasItems(profile.messaging_pillars) && (
              <div className="space-y-2">
                <p className="text-sm font-medium text-gray-500">Messaging Pillars</p>
                <div className="flex flex-wrap gap-2">
                  {profile.messaging_pillars?.map((pillar, index) => (
                    <span key={index} className="px-3 py-1 bg-blue-100 text-blue-800 rounded">
                      {pillar}
                    </span>
                  ))}
                </div>
              </div>
            )}

            {profile.social_media_guidelines && (
              <div className="space-y-4">
                <p className="text-sm font-medium text-gray-500">Social Media Guidelines</p>
                {profile.social_media_guidelines.hashtag_strategy && (
                  <div className="space-y-2">
                    <p className="text-sm text-gray-500">Hashtag Strategy</p>
                    <p className="text-base text-gray-900 whitespace-pre-wrap">
                      {profile.social_media_guidelines.hashtag_strategy}
                    </p>
                  </div>
                )}
                {profile.social_media_guidelines.emoji_usage && (
                  <div className="space-y-2">
                    <p className="text-sm text-gray-500">Emoji Usage</p>
                    <p className="text-base text-gray-900 whitespace-pre-wrap">
                      {profile.social_media_guidelines.emoji_usage}
                    </p>
                  </div>
                )}
                {profile.social_media_guidelines.engagement_guidelines && (
                  <div className="space-y-2">
                    <p className="text-sm text-gray-500">Engagement Guidelines</p>
                    <p className="text-base text-gray-900 whitespace-pre-wrap">
                      {profile.social_media_guidelines.engagement_guidelines}
                    </p>
                  </div>
                )}
              </div>
            )}

            {profile.seo_guidelines && (
              <div className="space-y-4">
                <p className="text-sm font-medium text-gray-500">SEO Guidelines</p>
                {hasItems(profile.seo_guidelines.target_keywords) && (
                  <div className="space-y-2">
                    <p className="text-sm text-gray-500">Target Keywords</p>
                    <div className="flex flex-wrap gap-2">
                      {profile.seo_guidelines.target_keywords?.map((keyword, index) => (
                        <span key={index} className="px-2 py-1 bg-purple-100 text-purple-800 rounded text-sm">
                          {keyword}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
                {profile.seo_guidelines.meta_description_template && (
                  <div className="space-y-2">
                    <p className="text-sm text-gray-500">Meta Description Template</p>
                    <p className="text-base text-gray-900 whitespace-pre-wrap">
                      {profile.seo_guidelines.meta_description_template}
                    </p>
                  </div>
                )}
              </div>
            )}

            {Object.keys(profile.content_type_guidelines || {}).length > 0 && (
              <div className="space-y-3">
                <p className="text-sm font-medium text-gray-500">Content Type Guidelines</p>
                <div className="grid grid-cols-1 gap-4">
                  {Object.entries(profile.content_type_guidelines || {}).map(([type, guidelines]) => (
                    <div key={type} className="p-4 bg-gray-50 rounded-lg">
                      <p className="font-medium text-gray-900">{type}</p>
                      <div className="mt-2 space-y-2">
                        <div>
                          <p className="text-sm text-gray-500">Format</p>
                          <p className="text-sm text-gray-900">{guidelines.format}</p>
                        </div>
                        <div>
                          <p className="text-sm text-gray-500">Guidelines</p>
                          <p className="text-sm text-gray-900 whitespace-pre-wrap">{guidelines.guidelines}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Section>
      )}
    </div>
  );
};

export default BusinessProfileView;
