import React from 'react';
import FormInput from '../../common/FormInput';
import { BusinessProfileFormSectionProps } from '../types/businessProfileForm';

const AddressForm: React.FC<BusinessProfileFormSectionProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  return (
    <div className="space-y-6">
      <FormInput
        label="Street Address"
        name="street1"
        value={values.street1}
        onChange={(e) => handleChange('street1', e.target.value)}
        onBlur={handleBlur}
        error={errors.street1}
        touched={touched.street1}
        placeholder="Enter street address"
        required
      />

      <FormInput
        label="Apartment, suite, etc. (optional)"
        name="street2"
        value={values.street2}
        onChange={(e) => handleChange('street2', e.target.value)}
        onBlur={handleBlur}
        error={errors.street2}
        touched={touched.street2}
        placeholder="Enter additional address info"
      />

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <FormInput
          label="City"
          name="city"
          value={values.city}
          onChange={(e) => handleChange('city', e.target.value)}
          onBlur={handleBlur}
          error={errors.city}
          touched={touched.city}
          placeholder="Enter city"
          required
        />

        <FormInput
          label="State"
          name="state"
          value={values.state}
          onChange={(e) => handleChange('state', e.target.value)}
          onBlur={handleBlur}
          error={errors.state}
          touched={touched.state}
          placeholder="Enter state"
          required
        />

        <FormInput
          label="ZIP Code"
          name="zip"
          value={values.zip}
          onChange={(e) => handleChange('zip', e.target.value)}
          onBlur={handleBlur}
          error={errors.zip}
          touched={touched.zip}
          placeholder="Enter ZIP code"
          required
        />
      </div>
    </div>
  );
};

export default AddressForm;
