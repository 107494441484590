import axios from 'axios';
import { useCallback } from 'react';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8020',
  withCredentials: true
});

export const useApi = () => {
  const get = useCallback(async (url: string) => {
    const response = await api.get(url);
    return response;
  }, []);

  const post = useCallback(async (url: string, data?: any) => {
    const response = await api.post(url, data);
    return response;
  }, []);

  const put = useCallback(async (url: string, data: any) => {
    const response = await api.put(url, data);
    return response;
  }, []);

  const del = useCallback(async (url: string) => {
    const response = await api.delete(url);
    return response;
  }, []);

  return {
    get,
    post,
    put,
    delete: del
  };
};
