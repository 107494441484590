import React from 'react';
import { Content, getContentId, ContentStatus } from '../../types/content';
import ContentHistoryRow from './ContentHistoryRow';
import { GroupedContent } from '../../utils/contentHistoryUtils';
import '../../styles/content-history.css';

interface ContentHistoryTableProps {
  groupedContents: GroupedContent;
  selectedContents: Set<string>;
  onContentSelect: (content: Content) => void;
  expandedTopics: Set<string>;
  onTopicExpand: (topic: string) => void;
  expandedGroups: Set<string>;
  onGroupExpand: (groupId: string) => void;
  onStatusChange: (contentId: number, newStatus: ContentStatus, platform: string) => Promise<void>;
  onExport: (content: Content) => void;
  updatingContentId: number | null;
}

const TableHeader: React.FC = () => (
  <tr className="bg-gray-100 border-b border-gray-200">
    <th className="sticky top-0 px-2 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-gray-100 z-10 w-[3%]">
      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
      </svg>
    </th>
    <th className="sticky top-0 px-2 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-gray-100 z-10 w-[10%]">
      Export
    </th>
    <th className="sticky top-0 px-2 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-gray-100 z-10 w-[5%]">
      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
      </svg>
    </th>
    <th className="sticky top-0 px-2 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-gray-100 z-10 w-[9%]">
      Created
    </th>
    <th className="sticky top-0 px-2 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-gray-100 z-10 w-[7%]">
      Platform
    </th>
    <th className="sticky top-0 px-2 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-gray-100 z-10 w-[7%]">
      Audience
    </th>
    <th className="sticky top-0 px-2 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-gray-100 z-10 w-[37%]">
      Content
    </th>
    <th className="sticky top-0 px-2 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-gray-100 z-10 w-[22%]">
      Status
    </th>
  </tr>
);

const GroupHeader: React.FC<{
  title: string;
  isExpanded: boolean;
  onToggle: () => void;
  count: number;
}> = ({ title, isExpanded, onToggle, count }) => (
  <tr className="group-header bg-gray-50 border-y border-gray-200">
    <td colSpan={8} className="px-2 py-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <button
            onClick={onToggle}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <svg
              className={`w-5 h-5 transform transition-transform ${isExpanded ? 'rotate-90' : ''}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
          <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
          <span className="text-sm text-gray-500">({count} items)</span>
        </div>
      </div>
    </td>
  </tr>
);

const ContentHistoryTable: React.FC<ContentHistoryTableProps> = ({
  groupedContents,
  selectedContents,
  onContentSelect,
  expandedTopics,
  onTopicExpand,
  expandedGroups,
  onGroupExpand,
  onStatusChange,
  onExport,
  updatingContentId
}) => {
  const hasContent = 
    Object.keys(groupedContents.campaignGroups).length > 0 || 
    groupedContents.quickContent.length > 0;

  // Use 'quick_content' as the group ID for Quick Content section
  const QUICK_CONTENT_GROUP_ID = 'quick_content';

  return (
    <div className="content-history-outer overflow-x-auto">
      <div className="content-history-inner min-w-full">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-white sticky top-0 z-10">
            <TableHeader />
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {!hasContent ? (
              <tr>
                <td colSpan={8} className="px-2 py-4 text-center text-gray-500">
                  No content found
                </td>
              </tr>
            ) : (
              <>
                {/* Quick Content Section */}
                {groupedContents.quickContent.length > 0 && (
                  <>
                    <GroupHeader
                      title="Quick Content"
                      isExpanded={expandedGroups.has(QUICK_CONTENT_GROUP_ID)}
                      onToggle={() => onGroupExpand(QUICK_CONTENT_GROUP_ID)}
                      count={groupedContents.quickContent.length}
                    />
                    {expandedGroups.has(QUICK_CONTENT_GROUP_ID) && groupedContents.quickContent.map((content, index) => (
                      content && (
                        <ContentHistoryRow
                          key={getContentId(content)}
                          content={content}
                          isSelected={selectedContents.has(getContentId(content))}
                          onSelect={() => onContentSelect(content)}
                          isExpanded={expandedTopics.has(content.topic || '')}
                          onExpand={() => onTopicExpand(content.topic || '')}
                          onStatusChange={onStatusChange}
                          onExport={onExport}
                          isAlternate={index % 2 === 1}
                          isUpdating={updatingContentId === content.id}
                        />
                      )
                    ))}
                  </>
                )}

                {/* Campaign Groups */}
                {Object.entries(groupedContents.campaignGroups).map(([campaignId, group], index) => (
                  <React.Fragment key={campaignId}>
                    {group && group.campaign && (
                      <>
                        <GroupHeader
                          title={group.campaign.name || `Campaign ${campaignId}`}
                          isExpanded={expandedGroups.has(campaignId)}
                          onToggle={() => onGroupExpand(campaignId)}
                          count={group.contents.length}
                        />
                        {expandedGroups.has(campaignId) && group.contents.map((content, contentIndex) => (
                          content && (
                            <ContentHistoryRow
                              key={getContentId(content)}
                              content={content}
                              isSelected={selectedContents.has(getContentId(content))}
                              onSelect={() => onContentSelect(content)}
                              isExpanded={expandedTopics.has(content.topic || '')}
                              onExpand={() => onTopicExpand(content.topic || '')}
                              onStatusChange={onStatusChange}
                              onExport={onExport}
                              isAlternate={contentIndex % 2 === 1}
                              isUpdating={updatingContentId === content.id}
                            />
                          )
                        ))}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContentHistoryTable;
