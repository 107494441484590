import React, { useState, useEffect } from 'react';
import { simplifiedContentApi } from './simplified-content/api/contentApi';
import { SimplifiedContent, PlatformImage } from './simplified-content/types';

const Storage: React.FC = () => {
  const [contents, setContents] = useState<SimplifiedContent[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const getImageUrl = (url: string | undefined | null): string | undefined => {
    if (!url) return undefined;
    if (url.startsWith('http') && !url.includes('oaidalleapiprodscus')) {
      // If it's a localhost URL, replace it with the media URL
      if (url.includes('localhost:8020/media')) {
        const mediaUrl = process.env.REACT_APP_MEDIA_URL || 'http://50.184.85.141/media';
        return url.replace('http://localhost:8020/media', mediaUrl);
      }
      return url;
    }
    if (url.startsWith('data:')) return url;
    
    // Use REACT_APP_MEDIA_URL for media files
    if (url.startsWith('/media/') || url.includes('/media/')) {
      const mediaUrl = process.env.REACT_APP_MEDIA_URL || 'http://50.184.85.141/media';
      const cleanPath = url.replace(/^\/media\//, '').replace(/.*\/media\//, '');
      return `${mediaUrl}/${cleanPath}`;
    }
    
    // Use API URL for other URLs
    const baseUrl = process.env.REACT_APP_API_URL || 'http://50.184.85.141';
    return `${baseUrl}${url.startsWith('/') ? '' : '/'}${url}`;
  };

  useEffect(() => {
    const fetchContent = async () => {
      try {
        setIsLoading(true);
        const data = await simplifiedContentApi.getAllContent();
        setContents(data);
        setError(null);
      } catch (err) {
        console.error('Error fetching content:', err);
        setError('Failed to load content. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchContent();
  }, []);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading content...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 p-6">
        <div className="max-w-4xl mx-auto bg-red-50 border-l-4 border-red-500 p-4 rounded">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
          <div className="p-6">
            <h1 className="text-2xl font-bold text-gray-900 mb-6">Generated Content Storage</h1>
            
            {contents.length === 0 ? (
              <div className="text-center py-12">
                <p className="text-gray-500">No content has been generated yet.</p>
              </div>
            ) : (
              <div className="space-y-6">
                {contents.map((content) => {
                  // Get the first platform's content if it exists
                  const platformName = content.platforms[0]?.name || 'Unknown Platform';
                  const generatedContent = content.generated_content?.[platformName]?.text || 'No content available';
                  const platformImage: PlatformImage | undefined = content.platform_images?.[platformName];
                  
                  return (
                    <div 
                      key={content.id}
                      className="bg-white rounded-lg border border-gray-200 p-6 hover:shadow-md transition-shadow"
                    >
                      <div className="flex items-start justify-between mb-4">
                        <div>
                          <h3 className="text-lg font-semibold text-gray-900">
                            {content.requirements?.topic || 'Untitled Content'}
                          </h3>
                          <p className="text-sm text-gray-500 mt-1">
                            {new Date(content.created_at).toLocaleDateString()}
                          </p>
                        </div>
                        <span className="px-3 py-1 text-sm font-medium rounded-full bg-blue-100 text-blue-800">
                          {platformName}
                        </span>
                      </div>
                      
                      <div className="prose prose-sm max-w-none">
                        <p className="text-gray-700 whitespace-pre-wrap">{generatedContent}</p>
                      </div>

                      {platformImage && !platformImage.error && (
                        <div className="mt-4">
                          <img 
                            src={getImageUrl(platformImage.local_image_url || platformImage.image_url)} 
                            alt="Generated content"
                            className="rounded-lg max-h-48 object-cover"
                          />
                        </div>
                      )}
                      
                      {content.requirements && (
                        <div className="mt-4 pt-4 border-t border-gray-100">
                          <div className="flex flex-wrap gap-2">
                            {content.requirements.tone && (
                              <span className="px-2 py-1 text-xs rounded bg-gray-100 text-gray-700">
                                Tone: {content.requirements.tone}
                              </span>
                            )}
                            {content.requirements.objective && (
                              <span className="px-2 py-1 text-xs rounded bg-gray-100 text-gray-700">
                                Objective: {content.requirements.objective}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Storage;
