import React, { createContext, useContext, useState, useEffect } from 'react';
import { BusinessProfile } from '../types/business';

interface BusinessProfileContextType {
  businessProfile: BusinessProfile | null;
  setBusinessProfile: (profile: BusinessProfile | null) => void;
  loading: boolean;
}

const BusinessProfileContext = createContext<BusinessProfileContextType>({
  businessProfile: null,
  setBusinessProfile: () => {},
  loading: true
});

export const useBusinessProfile = () => useContext(BusinessProfileContext);

export const BusinessProfileProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [businessProfile, setBusinessProfile] = useState<BusinessProfile | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Load business profile from API if needed
    setLoading(false);
  }, []);

  return (
    <BusinessProfileContext.Provider value={{ businessProfile, setBusinessProfile, loading }}>
      {children}
    </BusinessProfileContext.Provider>
  );
};
