import React, { useState } from 'react';
import { ContentState, StateChange } from '../types';

interface StateLoggerProps {
  currentState: ContentState;
  stateHistory: StateChange[];
}

export const StateLogger: React.FC<StateLoggerProps> = ({ currentState, stateHistory }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!currentState) return null;

  return (
    <div className="bg-white rounded-lg shadow mb-8">
      <div 
        className="p-6 cursor-pointer flex items-center justify-between"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <h2 className="text-lg font-semibold">State Logger</h2>
        <svg
          className={`w-5 h-5 transform transition-transform ${isExpanded ? 'rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
      
      {isExpanded && (
        <div className="p-6 pt-0">
          <div className="mb-6">
            <h3 className="text-md font-medium mb-2">Current State:</h3>
            <pre className="bg-gray-50 p-4 rounded overflow-auto max-h-60">
              {JSON.stringify(currentState, null, 2)}
            </pre>
          </div>

          <div>
            <h3 className="text-md font-medium mb-2">State History:</h3>
            <div className="space-y-4">
              {stateHistory.map((change, index) => (
                <div key={index} className="bg-gray-50 p-4 rounded">
                  <div className="text-sm text-gray-500 mb-2">
                    {new Date(change.timestamp).toLocaleString()}
                  </div>
                  <div className="font-medium mb-1">
                    Field: {change.field}
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <div className="text-sm text-gray-500 mb-1">Previous:</div>
                      <pre className="text-sm overflow-auto">
                        {JSON.stringify(change.previousValue, null, 2)}
                      </pre>
                    </div>
                    <div>
                      <div className="text-sm text-gray-500 mb-1">New:</div>
                      <pre className="text-sm overflow-auto">
                        {JSON.stringify(change.newValue, null, 2)}
                      </pre>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
