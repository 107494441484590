import React, { useState, useEffect } from 'react';
import { Content, VideoPlatform, VideoGenerationOptions, VideoPricing, calculateVideoPricing, isVideoPlatform } from '../../types/content';
import { VideoScript } from '../../pages/simplified-content/types';
import axiosInstance from '../../utils/axiosConfig';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

// Initialize Stripe outside component to avoid multiple instances
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

// Status check configuration
const MAX_STATUS_CHECKS = 12; // Maximum number of status checks (2 minutes total with 10s interval)
const STATUS_CHECK_INTERVAL = 10000; // 10 seconds between checks

interface PaymentMethodInfo {
  last4: string;
  brand: string;
  exp_month: number;
  exp_year: number;
  cardholder_name: string;
  payment_method_id?: string;
}

interface OrderResponse {
  order_id: string;
  client_secret: string | null;
  total_amount: number;
  payment_status: string;
}

interface OrderStatusResponse {
  status: 'pending_payment' | 'paid' | 'processing' | 'completed' | 'failed';
  payment_status: string | null;
  video_url: string | null;
  created_at: string;
  updated_at: string;
}

interface VideoGenerationButtonProps {
  content: Content;
}

const PricingDisplay: React.FC<{ pricing: VideoPricing }> = ({ pricing }) => (
  <div className="mt-4 p-4 bg-gray-50 rounded-lg">
    <h3 className="text-lg font-semibold mb-3">Price Breakdown</h3>
    <div className="space-y-2">
      <div className="flex justify-between">
        <span>Base Price:</span>
        <span>${pricing.basePrice.toFixed(2)}</span>
      </div>
      {pricing.brandingPrice > 0 && (
        <div className="flex justify-between">
          <span>Custom Branding:</span>
          <span>${pricing.brandingPrice.toFixed(2)}</span>
        </div>
      )}
      {pricing.expeditedPrice > 0 && (
        <div className="flex justify-between">
          <span>Expedited Service:</span>
          <span>${pricing.expeditedPrice.toFixed(2)}</span>
        </div>
      )}
      <div className="flex justify-between pt-2 border-t border-gray-200 font-semibold">
        <span>Total:</span>
        <span>${pricing.totalPrice.toFixed(2)}</span>
      </div>
    </div>
  </div>
);

const PaymentMethodDisplay: React.FC<{ paymentMethod: PaymentMethodInfo }> = ({ paymentMethod }) => (
  <div className="mt-4 p-4 bg-gray-50 rounded-lg">
    <h3 className="text-lg font-semibold mb-3">Payment Method</h3>
    <div className="space-y-2">
      <p className="text-sm text-gray-600">
        {paymentMethod.brand.toUpperCase()} ending in {paymentMethod.last4}
      </p>
      <p className="text-sm text-gray-600">
        Expires {paymentMethod.exp_month.toString().padStart(2, '0')}/{paymentMethod.exp_year}
      </p>
      {paymentMethod.cardholder_name && (
        <p className="text-sm text-gray-600">
          {paymentMethod.cardholder_name}
        </p>
      )}
    </div>
  </div>
);

const PaymentForm: React.FC<{ clientSecret: string; onSuccess: () => void; onError: (error: string) => void }> = ({
  clientSecret,
  onSuccess,
  onError,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    try {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/content-history`,
        },
      });

      if (error) {
        onError(error.message || 'An error occurred during payment');
      } else {
        onSuccess();
      }
    } catch (e) {
      onError('An unexpected error occurred');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        type="submit"
        disabled={!stripe || isProcessing}
        className="mt-4 w-full px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isProcessing ? 'Processing...' : 'Pay Now'}
      </button>
    </form>
  );
};

const VideoGenerationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  content: Content;
  platform: VideoPlatform;
  videoScript: VideoScript;
}> = ({ isOpen, onClose, content, platform, videoScript }) => {
  const [options, setOptions] = useState<VideoGenerationOptions>({
    customBranding: false,
    subtitleLanguages: 0,
    expedited: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodInfo | null>(null);
  const [orderId, setOrderId] = useState<string | null>(null);

  useEffect(() => {
    // Fetch the user's current payment method when the modal opens
    if (isOpen) {
      axiosInstance.get('/payment-methods/current/')
        .then(response => {
          setPaymentMethod({
            ...response.data,
            payment_method_id: response.data.id
          });
        })
        .catch(error => {
          console.error('Error fetching payment method:', error);
        });
    }
  }, [isOpen]);

  // Check order status periodically after payment
  useEffect(() => {
    if (orderId && success) {
      let checkCount = 0;
      const interval = setInterval(async () => {
        try {
          const response = await axiosInstance.get<OrderStatusResponse>(`/video-generation/status/${orderId}/`);
          checkCount++;
          
          // Stop checking if:
          // 1. Order status has progressed beyond 'paid'
          // 2. Payment status indicates completion or failure
          // 3. Max checks reached
          const shouldStopChecking = 
            response.data.status !== 'paid' || 
            (response.data.payment_status && ['succeeded', 'canceled', 'failed'].includes(response.data.payment_status)) ||
            checkCount >= MAX_STATUS_CHECKS;

          if (shouldStopChecking) {
            clearInterval(interval);
            if (checkCount >= MAX_STATUS_CHECKS) {
              console.log('Stopped status checks after maximum attempts');
            } else {
              console.log(`Order status: ${response.data.status}, Payment status: ${response.data.payment_status}`);
            }
          }
        } catch (error) {
          console.error('Error checking order status:', error);
          clearInterval(interval);
        }
      }, STATUS_CHECK_INTERVAL);

      return () => clearInterval(interval);
    }
  }, [orderId, success]);

  const getTotalMinutes = (script: VideoScript): number => {
    const nonInstructionSections = script.sections.filter(section => section.name !== 'Final Instructions');
    const totalSeconds = nonInstructionSections.reduce((total, section) => {
      const [minutes, seconds] = section.duration.split(':').map(Number);
      return total + (minutes * 60) + seconds;
    }, 0);
    return Math.max(1, Math.ceil(totalSeconds / 60));
  };

  const estimatedMinutes = getTotalMinutes(videoScript);
  const pricing = calculateVideoPricing(estimatedMinutes, options);

  const handleCreateOrder = async () => {
    setIsLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      if (!paymentMethod?.payment_method_id) {
        throw new Error('No payment method available');
      }

      // First calculate the price
      const priceResponse = await axiosInstance.post('/video-generation/calculate-price/', {
        content_id: content.id,
        platform: platform.replace('_shorts', '_short'),
        video_length_minutes: estimatedMinutes,
        options: {
          custom_branding: options.customBranding,
          subtitle_languages: options.subtitleLanguages,
          expedited: options.expedited
        },
      });

      if (!priceResponse.data) {
        throw new Error('Failed to calculate price');
      }

      // Then create the order
      const response = await axiosInstance.post<OrderResponse>('/video-generation/create-video-order/', {
        content_id: content.id,
        platform: platform.replace('_shorts', '_short'),
        video_length_minutes: estimatedMinutes,
        payment_method_id: paymentMethod.payment_method_id,
        options: {
          custom_branding: options.customBranding,
          subtitle_languages: options.subtitleLanguages,
          expedited: options.expedited
        },
      });

      setOrderId(response.data.order_id);

      // Check if payment was already processed by the backend
      if (response.data.payment_status === 'succeeded' || response.data.payment_status === 'paid') {
        setSuccess('Payment successful! Your video will be generated shortly.');
      } else if (response.data.client_secret) {
        // Only attempt to confirm payment if we have a client secret and payment wasn't already processed
        const stripe = await stripePromise;
        if (!stripe) {
          throw new Error('Failed to initialize Stripe');
        }

        const { error } = await stripe.confirmCardPayment(response.data.client_secret, {
          payment_method: paymentMethod.payment_method_id
        });

        if (error) {
          throw new Error(error.message);
        }

        setSuccess('Payment successful! Your video will be generated shortly.');
      } else {
        setClientSecret(response.data.client_secret);
      }
    } catch (error) {
      console.error('Error creating video order:', error);
      setError(error instanceof Error ? error.message : 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentSuccess = () => {
    setSuccess('Payment successful! Your video will be generated shortly.');
  };

  const handlePaymentError = (errorMessage: string) => {
    setError(errorMessage);
    setClientSecret(null);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-lg w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Generate Video</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {success ? (
          <div className="mt-4 space-y-4">
            <div className="p-4 bg-green-50 rounded-lg text-green-800">
              <p>{success}</p>
            </div>
            <div className="flex justify-end">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Close
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="mt-4 mb-6 p-4 bg-blue-50 rounded-lg text-blue-800">
              <div className="space-y-2">
                <p className="text-sm">
                  Video generation is an add-on service
                </p>
                <p className="text-sm">
                  that transforms your content into professional videos.
                </p>
                <p className="text-sm">
                  Pricing: $20 for first minute, $10 for each additional minute.
                </p>
              </div>
            </div>

            <div className="mt-4 space-y-4">
              {!clientSecret ? (
                <>
                  <div>
                    <h3 className="text-lg font-medium mb-2">Video Details</h3>
                    <p className="text-sm text-gray-600">Platform: {platform}</p>
                    <p className="text-sm text-gray-600">Estimated Length: {estimatedMinutes} minutes</p>
                  </div>

                  <div className="space-y-3">
                    <h3 className="text-lg font-medium">Options</h3>
                    
                    <div className="flex items-center justify-between">
                      <label className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          checked={options.customBranding}
                          onChange={(e) => setOptions({...options, customBranding: e.target.checked})}
                          className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        />
                        <span>Custom Branding ($5) - Add your logo and brand colors</span>
                      </label>
                    </div>

                    <div className="flex items-center justify-between">
                      <label className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          checked={options.expedited}
                          onChange={(e) => setOptions({...options, expedited: e.target.checked})}
                          className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        />
                        <span>Expedited Service ($15) - 24-hour turnaround guarantee</span>
                      </label>
                    </div>
                  </div>

                  <PricingDisplay pricing={pricing} />

                  {paymentMethod && (
                    <PaymentMethodDisplay paymentMethod={paymentMethod} />
                  )}

                  {error && (
                    <div className="text-red-600 text-sm mt-2">
                      {error}
                    </div>
                  )}

                  <div className="mt-6 flex justify-end space-x-3">
                    <button
                      onClick={onClose}
                      className="px-4 py-2 text-gray-600 hover:text-gray-800"
                      disabled={isLoading}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleCreateOrder}
                      disabled={isLoading}
                      className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {isLoading ? 'Processing...' : 'Create Order'}
                    </button>
                  </div>
                </>
              ) : (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <PaymentForm
                    clientSecret={clientSecret}
                    onSuccess={handlePaymentSuccess}
                    onError={handlePaymentError}
                  />
                </Elements>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const VideoGenerationButton: React.FC<VideoGenerationButtonProps> = ({ content }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const rawPlatform = content.platform.split(',')[0].trim();

  // Only show button for video platforms
  if (!isVideoPlatform(rawPlatform)) {
    return null;
  }

  const createVideoScript = (content: Content): VideoScript => {
    const scriptContent = content.content || '';
    
    // Default sections based on platform
    const sections = rawPlatform === 'youtube_video' ? [
      { name: 'Hook', content: '', duration: '00:30', timestamps: [] },
      { name: 'Main Content', content: scriptContent, duration: '10:00', timestamps: [] },
      { name: 'Call to Action', content: '', duration: '00:30', timestamps: [] }
    ] : [
      { name: 'Hook', content: '', duration: '00:03', timestamps: [] },
      { name: 'Main Content', content: scriptContent, duration: '00:45', timestamps: [] },
      { name: 'Call to Action', content: '', duration: '00:12', timestamps: [] }
    ];

    return {
      platform: rawPlatform as VideoPlatform,
      totalDuration: rawPlatform === 'youtube_video' ? '11:00' : '01:00',
      sections,
      formatInstructions: {
        resolution: rawPlatform === 'youtube_video' ? '1920x1080' : '1080x1920',
        aspectRatio: rawPlatform === 'youtube_video' ? '16:9' : '9:16',
        maxDuration: rawPlatform === 'youtube_video' ? '12:00:00' : '01:00',
        recommendedLength: rawPlatform === 'youtube_video' ? '10-15 minutes' : '30-45 seconds',
        format: 'MP4',
        additionalRequirements: [
          'High-quality audio with minimal background noise',
          'Good lighting and clear visuals',
          'Include captions for accessibility',
          'Strong hook in first few seconds'
        ]
      }
    };
  };

  const videoScript = createVideoScript(content);

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="flex items-center space-x-1 px-3 py-1 bg-green-50 text-green-700 rounded-lg hover:bg-green-100 transition-colors duration-150 min-w-[120px] relative z-10 shadow-sm"
        title="Generate video"
      >
        <svg className="w-4 h-4 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
        </svg>
        <span className="text-sm font-medium whitespace-nowrap">Generate Video</span>
      </button>

      <VideoGenerationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={content}
        platform={rawPlatform as VideoPlatform}
        videoScript={videoScript}
      />
    </>
  );
};

export default VideoGenerationButton;
