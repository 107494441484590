import React, { useState } from 'react';
import { researchTopics, getTopicsByCategory } from '../../data/researchTopics';

interface ResearchTopicsProps {
    onSelectTopic: (question: string) => void;
    maxHeight?: number;
}

const ResearchTopics: React.FC<ResearchTopicsProps> = ({ onSelectTopic, maxHeight = 400 }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState<string>('all');
    
    const topicsByCategory = getTopicsByCategory();
    const categories = ['all', ...Array.from(topicsByCategory.keys())];
    
    const filteredTopics = researchTopics.filter(topic => {
        const matchesSearch = topic.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            topic.description?.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesCategory = selectedCategory === 'all' || topic.category === selectedCategory;
        return matchesSearch && matchesCategory;
    });

    return (
        <div className="flex flex-col h-full">
            <div className="flex-none">
                <div className="mb-3">
                    <input
                        type="text"
                        placeholder="Search topics..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>

                <div className="mb-3 flex flex-wrap gap-2">
                    {categories.map(category => (
                        <button
                            key={category}
                            onClick={() => setSelectedCategory(category)}
                            className={`px-3 py-1 rounded-full text-sm ${
                                selectedCategory === category
                                    ? 'bg-blue-600 text-white'
                                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                            }`}
                        >
                            {category.charAt(0).toUpperCase() + category.slice(1)}
                        </button>
                    ))}
                </div>
            </div>

            <div 
                className="flex-grow overflow-y-auto border border-gray-200 rounded-lg"
                style={{ maxHeight: `${maxHeight}px` }}
            >
                {filteredTopics.map(topic => (
                    <div
                        key={topic.id}
                        onClick={() => onSelectTopic(topic.question)}
                        className="p-3 hover:bg-gray-50 cursor-pointer border-b border-gray-200 last:border-b-0"
                    >
                        <div className="text-sm font-medium text-blue-600 mb-1">
                            {topic.category}
                        </div>
                        <div className="text-gray-900 mb-1">
                            {topic.question}
                        </div>
                        {topic.description && (
                            <div className="text-sm text-gray-500">
                                {topic.description}
                            </div>
                        )}
                    </div>
                ))}
                {filteredTopics.length === 0 && (
                    <div className="p-4 text-center text-gray-500">
                        No topics found matching your search
                    </div>
                )}
            </div>
        </div>
    );
};

export default ResearchTopics;
