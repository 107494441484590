import React, { useState, useEffect } from 'react';
import { adminService } from '../../services/admin';
import { PlatformSettings, TechnicalRequirements } from '../../types/admin';
import toast from 'react-hot-toast';

interface PlatformInstructionsPanelProps {
    setError: (error: string | null) => void;
}

interface PlatformForm {
    instructions: string;
    max_text_length: string;
    image_size: string;
    hashtag_limit: string;
}

const SUPPORTED_PLATFORMS = ['facebook_post', 'twitter_post', 'instagram_feed', 'linkedin_post'];

const getDefaultInstructions = (platform: string, maxLength: number, hashtagLimit: number): string => {
    switch (platform) {
        case 'facebook_post':
            return `Create a concise, engaging post that encourages interaction.\nKeep text under ${maxLength} characters for optimal display.\nInclude a clear call-to-action.`;
        case 'twitter_post':
            return `Create a concise post under ${maxLength} characters.\nUse engaging language that encourages retweets and replies.\nConsider adding relevant hashtags (max ${hashtagLimit}).`;
        case 'instagram_feed':
            return `Create a visually descriptive post that works with an image.\nInclude relevant hashtags (max ${hashtagLimit}).\nFocus on visual storytelling elements.`;
        case 'linkedin_post':
            return `Create a professional, business-focused post.\nInclude industry-relevant insights or statistics if applicable.\nMaintain a formal yet approachable tone.\nKeep text under ${maxLength} characters.`;
        default:
            return '';
    }
};

const PlatformInstructionsPanel: React.FC<PlatformInstructionsPanelProps> = ({ setError }) => {
    const [settings, setSettings] = useState<PlatformSettings>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [originalSettings, setOriginalSettings] = useState<PlatformSettings>({});
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [forms, setForms] = useState<{ [key: string]: PlatformForm }>({});
    const [originalForms, setOriginalForms] = useState<{ [key: string]: PlatformForm }>({});

    useEffect(() => {
        fetchPlatformSettings();
    }, []);

    useEffect(() => {
        // Compare current forms with original forms
        const formChanged = Object.keys(forms).some(platform => {
            const currentForm = forms[platform];
            const originalForm = originalForms[platform];
            
            return currentForm.max_text_length !== originalForm.max_text_length ||
                   currentForm.image_size !== originalForm.image_size ||
                   currentForm.hashtag_limit !== originalForm.hashtag_limit;
        });
        
        setHasChanges(formChanged);
    }, [forms, originalForms]);

    const fetchPlatformSettings = async () => {
        try {
            setIsLoading(true);
            const response = await adminService.getPlatformSettings();
            setSettings(response.settings);
            setOriginalSettings(response.settings);
            
            // Initialize forms
            const initialForms: { [key: string]: PlatformForm } = {};
            Object.entries(response.settings).forEach(([platform, data]) => {
                initialForms[platform] = {
                    instructions: data.instructions.join('\n'),
                    max_text_length: data.technical_requirements.max_text_length.toString(),
                    image_size: data.technical_requirements.image_size,
                    hashtag_limit: data.technical_requirements.hashtag_limit.toString()
                };
            });
            setForms(initialForms);
            setOriginalForms(initialForms); // Store original form values
            
            setError(null);
        } catch (error) {
            console.error('Error fetching platform settings:', error);
            setError('Failed to load platform settings');
            toast.error('Failed to load platform settings');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSave = async () => {
        try {
            setIsSaving(true);
            
            // Convert forms back to settings format
            const updatedSettings: PlatformSettings = {};
            Object.entries(forms).forEach(([platform, form]) => {
                // Update instructions based on current technical requirements
                const maxLength = parseInt(form.max_text_length);
                const hashtagLimit = parseInt(form.hashtag_limit);
                const defaultInstructions = getDefaultInstructions(platform, maxLength, hashtagLimit);
                
                updatedSettings[platform] = {
                    instructions: defaultInstructions.split('\n').filter(line => line.trim()),
                    technical_requirements: {
                        max_text_length: maxLength,
                        image_size: form.image_size,
                        hashtag_limit: hashtagLimit
                    }
                };
            });

            await adminService.updatePlatformSettings(updatedSettings);
            setSettings(updatedSettings);
            setOriginalSettings(updatedSettings);
            setOriginalForms({...forms}); // Update original forms after successful save
            setHasChanges(false);
            toast.success('Platform settings updated successfully');
            setError(null);
        } catch (error) {
            console.error('Error saving platform settings:', error);
            setError('Failed to update platform settings');
            toast.error('Failed to update platform settings');
        } finally {
            setIsSaving(false);
        }
    };

    const handleReset = () => {
        // Reset forms to original values
        setForms({...originalForms});
        setHasChanges(false);
    };

    const handleFormChange = (platform: string, field: keyof PlatformForm, value: string) => {
        setForms(prev => {
            const newForms = {
                ...prev,
                [platform]: {
                    ...prev[platform],
                    [field]: value
                }
            };

            // If technical requirements changed, update instructions
            if (field === 'max_text_length' || field === 'hashtag_limit') {
                const maxLength = parseInt(newForms[platform].max_text_length);
                const hashtagLimit = parseInt(newForms[platform].hashtag_limit);
                const defaultInstructions = getDefaultInstructions(platform, maxLength, hashtagLimit);
                newForms[platform].instructions = defaultInstructions;
            }

            return newForms;
        });
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <div className="bg-white rounded-lg shadow p-6">
            <div className="mb-6">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">Platform Instructions</h2>
                <p className="text-gray-600 mb-4">
                    Configure platform-specific instructions and technical requirements for content generation.
                    Instructions will automatically update based on technical parameters.
                </p>
            </div>
            
            <div className="space-y-8">
                {SUPPORTED_PLATFORMS.map(platform => (
                    <div key={platform} className="border-b pb-6">
                        <h3 className="text-lg font-medium text-gray-700 mb-4 capitalize">
                            {platform.replace('_', ' ')} Settings
                        </h3>
                        
                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Instructions (Auto-generated based on parameters)
                                </label>
                                <textarea
                                    value={forms[platform]?.instructions || ''}
                                    readOnly
                                    className="w-full h-32 p-2 border rounded-lg bg-gray-50"
                                />
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Maximum Text Length
                                    </label>
                                    <input
                                        type="number"
                                        value={forms[platform]?.max_text_length || ''}
                                        onChange={(e) => handleFormChange(platform, 'max_text_length', e.target.value)}
                                        className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        disabled={isSaving}
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Image Size
                                    </label>
                                    <input
                                        type="text"
                                        value={forms[platform]?.image_size || ''}
                                        onChange={(e) => handleFormChange(platform, 'image_size', e.target.value)}
                                        className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="e.g., 1200x630"
                                        disabled={isSaving}
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Hashtag Limit
                                    </label>
                                    <input
                                        type="number"
                                        value={forms[platform]?.hashtag_limit || ''}
                                        onChange={(e) => handleFormChange(platform, 'hashtag_limit', e.target.value)}
                                        className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        disabled={isSaving}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="flex justify-end space-x-4 mt-6">
                {hasChanges && (
                    <button
                        onClick={handleReset}
                        disabled={isSaving}
                        className="px-6 py-2 rounded-lg text-gray-700 bg-gray-100 hover:bg-gray-200"
                    >
                        Reset
                    </button>
                )}
                <button
                    onClick={handleSave}
                    disabled={isSaving || !hasChanges}
                    className={`px-6 py-2 rounded-lg text-white ${
                        isSaving || !hasChanges
                            ? 'bg-gray-400 cursor-not-allowed' 
                            : 'bg-blue-600 hover:bg-blue-700'
                    }`}
                >
                    {isSaving ? 'Saving...' : 'Save Changes'}
                </button>
            </div>
        </div>
    );
};

export default PlatformInstructionsPanel;
