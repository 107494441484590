import React, { useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Product } from '../../types/business';
import { ProductFormData } from '../../types/businessProfile';
import { SUCCESS_MESSAGES, ERROR_MESSAGES } from '../../constants/businessProfile';
import FormField from '../common/FormField';
import FormActions from '../common/FormActions';
import Input from '../common/Input';
import TextArea from '../common/TextArea';
import AlertBanner from '../common/AlertBanner';
import Card from '../common/Card';
import ProductImages from './ProductImages';
import { useForm } from '../../hooks/useForm';
import { required, minLength, maxLength, validateNumeric, ValidationRule } from '../../utils/validation';

interface EditProductFormProps {
  product: Product;
  onSubmit: (id: number, product: ProductFormData) => Promise<void>;
  onCancel: () => void;
  refreshProduct: (id: number) => Promise<Product | undefined>;
}

const EditProductForm: React.FC<EditProductFormProps> = ({
  product,
  onSubmit,
  onCancel,
  refreshProduct,
}): React.ReactElement => {
  const { uploadProductImage, deleteProductImage } = useAuth();
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);

  const optionalPriceValidation: ValidationRule<string | undefined> = {
    validate: (value: string | undefined) => !value || validateNumeric(value),
    message: 'Must be a number'
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    formError,
    handleChange,
    handleBlur,
    setFormError,
    validateForm,
  } = useForm<ProductFormData>(
    {
      name: {
        initialValue: product.name,
        validate: [
          required,
          minLength(2),
          maxLength(100),
        ],
      },
      description: {
        initialValue: product.description,
        validate: [
          maxLength(500),
        ],
      },
      retail_price: {
        initialValue: product.pricing?.retail_price?.toString() || '',
        validate: [optionalPriceValidation],
      },
      sale_price: {
        initialValue: product.pricing?.sale_price?.toString() || '',
        validate: [optionalPriceValidation],
      },
      special_price: {
        initialValue: product.pricing?.special_price?.toString() || '',
        validate: [optionalPriceValidation],
      },
      campaign_price: {
        initialValue: product.pricing?.campaign_price?.toString() || '',
        validate: [optionalPriceValidation],
      },
      show_retail_price: {
        initialValue: product.pricing?.show_retail_price || false,
      },
      show_sale_price: {
        initialValue: product.pricing?.show_sale_price || false,
      },
      show_special_price: {
        initialValue: product.pricing?.show_special_price || false,
      },
      show_campaign_price: {
        initialValue: product.pricing?.show_campaign_price || false,
      },
      is_active: {
        initialValue: product.is_active,
      },
    },
    {
      persistenceKey: `edit-product-${product.id}`,
      persistenceEnabled: true,
      warnOnUnsavedChanges: true,
      unsavedChangesMessage: 'You have unsaved changes to this product. Are you sure you want to leave?',
    }
  );

  // Log initial values and any changes
  useEffect(() => {
    console.log('Initial product:', product);
    console.log('Initial form values:', values);
  }, []);

  useEffect(() => {
    console.log('Form values changed:', values);
  }, [values]);

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    
    if (!validateForm()) {
      console.log('Form validation failed');
      return;
    }

    try {
      console.log('Submitting form with values:', values);
      
      const formData: ProductFormData = {
        name: values.name,
        description: values.description,
        retail_price: values.retail_price,
        sale_price: values.sale_price,
        special_price: values.special_price,
        campaign_price: values.campaign_price,
        show_retail_price: values.show_retail_price,
        show_sale_price: values.show_sale_price,
        show_special_price: values.show_special_price,
        show_campaign_price: values.show_campaign_price,
        is_active: values.is_active,
      };

      console.log('Formatted form data:', formData);
      await onSubmit(product.id, formData);
      console.log('Form submitted successfully');
    } catch (err) {
      console.error('Error submitting form:', err);
      setFormError('Failed to update product. Please try again.');
    }
  };

  const handleImageUpload = async (file: File): Promise<void> => {
    try {
      await uploadProductImage(product.id, file);
      setSuccessMessage(SUCCESS_MESSAGES.IMAGE_UPLOADED);
      const updatedProduct = await refreshProduct(product.id);
      if (!updatedProduct) {
        throw new Error('Failed to refresh product after image upload');
      }
    } catch (err) {
      setFormError(ERROR_MESSAGES.UPLOAD_IMAGE);
      console.error('Error uploading image:', err);
    }
  };

  const handleImageDelete = async (imageId: number): Promise<void> => {
    try {
      await deleteProductImage(product.id, imageId);
      setSuccessMessage(SUCCESS_MESSAGES.IMAGE_DELETED);
      const updatedProduct = await refreshProduct(product.id);
      if (!updatedProduct) {
        throw new Error('Failed to refresh product after image delete');
      }
    } catch (err) {
      setFormError(ERROR_MESSAGES.DELETE_IMAGE);
      console.error('Error deleting image:', err);
    }
  };

  const handlePriceChange = (name: string, value: string) => {
    console.log(`Price change - ${name}:`, value);
    handleChange(name as keyof ProductFormData, value);
  };

  const handleCheckboxChange = (name: string, checked: boolean) => {
    console.log(`Checkbox change - ${name}:`, checked);
    handleChange(name as keyof ProductFormData, checked);
  };

  return (
    <Card>
      <form onSubmit={handleSubmit} className="space-y-4">
        {formError && (
          <AlertBanner
            type="error"
            message={formError}
            onDismiss={() => setFormError(null)}
          />
        )}
        {successMessage && (
          <AlertBanner
            type="success"
            message={successMessage}
            onDismiss={() => setSuccessMessage(null)}
          />
        )}

        <FormField
          label="Product Name"
          id="name"
          required
          error={touched.name ? errors.name : undefined}
        >
          <Input
            id="name"
            name="name"
            value={values.name}
            onChange={(e) => handleChange('name', e.target.value)}
            onBlur={() => handleBlur('name')}
            required
            placeholder="Enter product name"
            autoFocus
            error={touched.name && !!errors.name}
          />
        </FormField>

        <FormField
          label="Description"
          id="description"
          error={touched.description ? errors.description : undefined}
        >
          <TextArea
            id="description"
            name="description"
            value={values.description}
            onChange={(e) => handleChange('description', e.target.value)}
            onBlur={() => handleBlur('description')}
            placeholder="Enter product description"
          />
        </FormField>

        <div className="border-t pt-4 mt-4">
          <h3 className="text-lg font-medium mb-4">Pricing Information</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Retail Price */}
            <div className="flex flex-col space-y-2">
              <FormField
                label="Retail Price"
                id="retail_price"
                error={touched.retail_price ? errors.retail_price : undefined}
              >
                <div className="flex items-center space-x-4">
                  <Input
                    id="retail_price"
                    name="retail_price"
                    type="number"
                    step="0.01"
                    value={values.retail_price}
                    onChange={(e) => handlePriceChange('retail_price', e.target.value)}
                    onBlur={() => handleBlur('retail_price')}
                    placeholder="0.00"
                    className="flex-1"
                  />
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={values.show_retail_price}
                      onChange={(e) => handleCheckboxChange('show_retail_price', e.target.checked)}
                      className="form-checkbox h-4 w-4 text-blue-600"
                    />
                    <span className="text-sm text-gray-600">Show in content</span>
                  </label>
                </div>
              </FormField>
            </div>

            {/* Sale Price */}
            <div className="flex flex-col space-y-2">
              <FormField
                label="Sale Price"
                id="sale_price"
                error={touched.sale_price ? errors.sale_price : undefined}
              >
                <div className="flex items-center space-x-4">
                  <Input
                    id="sale_price"
                    name="sale_price"
                    type="number"
                    step="0.01"
                    value={values.sale_price}
                    onChange={(e) => handlePriceChange('sale_price', e.target.value)}
                    onBlur={() => handleBlur('sale_price')}
                    placeholder="0.00"
                    className="flex-1"
                  />
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={values.show_sale_price}
                      onChange={(e) => handleCheckboxChange('show_sale_price', e.target.checked)}
                      className="form-checkbox h-4 w-4 text-blue-600"
                    />
                    <span className="text-sm text-gray-600">Show in content</span>
                  </label>
                </div>
              </FormField>
            </div>

            {/* Special Price */}
            <div className="flex flex-col space-y-2">
              <FormField
                label="Special Price"
                id="special_price"
                error={touched.special_price ? errors.special_price : undefined}
              >
                <div className="flex items-center space-x-4">
                  <Input
                    id="special_price"
                    name="special_price"
                    type="number"
                    step="0.01"
                    value={values.special_price}
                    onChange={(e) => handlePriceChange('special_price', e.target.value)}
                    onBlur={() => handleBlur('special_price')}
                    placeholder="0.00"
                    className="flex-1"
                  />
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={values.show_special_price}
                      onChange={(e) => handleCheckboxChange('show_special_price', e.target.checked)}
                      className="form-checkbox h-4 w-4 text-blue-600"
                    />
                    <span className="text-sm text-gray-600">Show in content</span>
                  </label>
                </div>
              </FormField>
            </div>

            {/* Campaign Price */}
            <div className="flex flex-col space-y-2">
              <FormField
                label="Campaign Price"
                id="campaign_price"
                error={touched.campaign_price ? errors.campaign_price : undefined}
              >
                <div className="flex items-center space-x-4">
                  <Input
                    id="campaign_price"
                    name="campaign_price"
                    type="number"
                    step="0.01"
                    value={values.campaign_price}
                    onChange={(e) => handlePriceChange('campaign_price', e.target.value)}
                    onBlur={() => handleBlur('campaign_price')}
                    placeholder="0.00"
                    className="flex-1"
                  />
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={values.show_campaign_price}
                      onChange={(e) => handleCheckboxChange('show_campaign_price', e.target.checked)}
                      className="form-checkbox h-4 w-4 text-blue-600"
                    />
                    <span className="text-sm text-gray-600">Show in content</span>
                  </label>
                </div>
              </FormField>
            </div>
          </div>
        </div>

        <FormField
          label="Product Images"
          id="product-images"
        >
          <ProductImages
            product={product}
            onUpload={handleImageUpload}
            onDelete={handleImageDelete}
          />
        </FormField>

        <FormField
          label="Status"
          id="is_active"
        >
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={values.is_active}
              onChange={(e) => handleChange('is_active', e.target.checked)}
              className="form-checkbox h-4 w-4 text-blue-600"
            />
            <span className="text-sm text-gray-600">Active</span>
          </label>
        </FormField>

        <FormActions
          onCancel={onCancel}
          submitLabel="Update Product"
          cancelLabel="Cancel"
          isSubmitting={isSubmitting}
        />
      </form>
    </Card>
  );
};

export default EditProductForm;
