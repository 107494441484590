import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useNotification } from '../../hooks/shared/useNotification';
import { FormProvider } from '../../contexts/FormContext';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import NotificationSystem from '../../components/shared/NotificationSystem';
import { CampaignFormData } from '../../types/campaign';
import CampaignForm from '../../components/campaign/CampaignForm';
import { campaignValidationRules } from '../../utils/validation';

const initialFormState = {
  errors: {},
  touched: {},
};

const CreateCampaign: React.FC = () => {
  const { createCampaign, user } = useAuth();
  const navigate = useNavigate();
  const { notification, showNotification } = useNotification();

  if (!user) {
    return <div>Please log in to create campaigns.</div>;
  }

  const handleSubmit = async (campaignData: CampaignFormData) => {
    try {
      await createCampaign(campaignData);
      showNotification('Campaign created successfully!', 'success');
      navigate('/campaigns');
    } catch (error) {
      console.error('Error creating campaign:', error);
      showNotification(
        error instanceof Error ? error.message : 'Failed to create campaign',
        'error'
      );
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Create New Campaign</h1>
        <button
          onClick={() => navigate('/campaigns')}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Back to Campaigns
        </button>
      </div>

      <NotificationSystem
        message={notification.message}
        type={notification.type}
      />

      <ErrorBoundary>
        <FormProvider 
          initialState={initialFormState}
          validationRules={campaignValidationRules}
        >
          <CampaignForm 
            onSubmit={handleSubmit}
            onCancel={() => navigate('/campaigns')}
          />
        </FormProvider>
      </ErrorBoundary>
    </div>
  );
};

export default CreateCampaign;
