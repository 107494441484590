import React from 'react';
import Button from '../common/Button';

interface CreateProfilePromptProps {
  onCreateClick: () => void;
}

const CreateProfilePrompt: React.FC<CreateProfilePromptProps> = ({ onCreateClick }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <p className="text-gray-600 mb-4">You don't have a business profile yet.</p>
      <Button
        onClick={onCreateClick}
        variant="primary"
      >
        Create Profile
      </Button>
    </div>
  );
};

export default CreateProfilePrompt;
