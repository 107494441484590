import React, { useState, useEffect } from 'react';
import { adminService } from '../../services/admin';
import { format } from 'date-fns';

interface ErrorLog {
  id: number;
  timestamp: string;
  error_type: string;
  error_message: string;
  subscription_id: string | null;
  user_email: string | null;
  stripe_event_type: string | null;
  resolved: boolean;
  resolution_notes: string | null;
  stack_trace: string | null;
}

const SubscriptionErrors: React.FC = () => {
  const [errorLogs, setErrorLogs] = useState<ErrorLog[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedLog, setSelectedLog] = useState<ErrorLog | null>(null);
  const [filters, setFilters] = useState({
    days: '7',
    error_type: '',
    resolved: false,
    search: ''
  });

  const fetchErrorLogs = async () => {
    try {
      setLoading(true);
      const response = await adminService.getSubscriptionErrors(filters);
      setErrorLogs(response.error_logs);
      setError(null);
    } catch (err: any) {
      setError(err.message || 'Error fetching error logs');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchErrorLogs();
  }, [filters]);

  const handleFilterChange = (name: string, value: string | boolean) => {
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const markResolved = async (errorId: number, notes: string) => {
    try {
      await adminService.markErrorResolved(errorId, notes);
      fetchErrorLogs();
      setSelectedLog(null);
    } catch (err: any) {
      setError(err.message || 'Error updating error status');
    }
  };

  const getErrorTypeColor = (type: string) => {
    switch (type) {
      case 'webhook':
        return 'bg-blue-100 text-blue-800';
      case 'sync':
        return 'bg-yellow-100 text-yellow-800';
      case 'payment':
        return 'bg-red-100 text-red-800';
      case 'status':
        return 'bg-purple-100 text-purple-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const ErrorDetailsModal = ({ log }: { log: ErrorLog }) => (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-3/4 shadow-lg rounded-md bg-white">
        <div className="flex justify-between items-start">
          <h3 className="text-lg font-medium">Error Details</h3>
          <button 
            onClick={() => setSelectedLog(null)}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>
        <div className="mt-4 space-y-4">
          <div>
            <h4 className="font-medium">Error Message</h4>
            <p className="mt-1 text-sm text-gray-600">{log.error_message}</p>
          </div>
          {log.stack_trace && (
            <div>
              <h4 className="font-medium">Stack Trace</h4>
              <pre className="mt-1 p-2 bg-gray-50 rounded text-sm overflow-x-auto">
                {log.stack_trace}
              </pre>
            </div>
          )}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h4 className="font-medium">Event Type</h4>
              <p className="mt-1 text-sm text-gray-600">{log.stripe_event_type || 'N/A'}</p>
            </div>
            <div>
              <h4 className="font-medium">Subscription ID</h4>
              <p className="mt-1 text-sm text-gray-600">{log.subscription_id || 'N/A'}</p>
            </div>
          </div>
          {!log.resolved && (
            <div className="mt-4">
              <button
                onClick={() => {
                  const notes = window.prompt('Enter resolution notes:');
                  if (notes !== null) {
                    markResolved(log.id, notes);
                  }
                }}
                className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700"
              >
                Mark as Resolved
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Subscription Error Logs</h1>

      {/* Filters */}
      <div className="mb-6 grid grid-cols-4 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Time Period</label>
          <select
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            value={filters.days}
            onChange={(e) => handleFilterChange('days', e.target.value)}
          >
            <option value="1">Last 24 hours</option>
            <option value="7">Last 7 days</option>
            <option value="30">Last 30 days</option>
            <option value="90">Last 90 days</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Error Type</label>
          <select
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            value={filters.error_type}
            onChange={(e) => handleFilterChange('error_type', e.target.value)}
          >
            <option value="">All Types</option>
            <option value="webhook">Webhook</option>
            <option value="sync">Sync</option>
            <option value="payment">Payment</option>
            <option value="status">Status</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Status</label>
          <select
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            value={filters.resolved.toString()}
            onChange={(e) => handleFilterChange('resolved', e.target.value === 'true')}
          >
            <option value="false">Unresolved</option>
            <option value="true">Resolved</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Search</label>
          <input
            type="text"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            placeholder="Search errors..."
            value={filters.search}
            onChange={(e) => handleFilterChange('search', e.target.value)}
          />
        </div>
      </div>

      {/* Error display */}
      {error && (
        <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-md">
          {error}
        </div>
      )}

      {/* Loading state */}
      {loading ? (
        <div className="text-center py-4">Loading...</div>
      ) : (
        /* Error logs table */
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Timestamp
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Message
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  User
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {errorLogs.map((log) => (
                <tr 
                  key={log.id}
                  className="hover:bg-gray-50 cursor-pointer"
                  onClick={() => setSelectedLog(log)}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {format(new Date(log.timestamp), 'MMM d, yyyy HH:mm:ss')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getErrorTypeColor(log.error_type)}`}>
                      {log.error_type}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <div className="max-w-lg truncate">
                      {log.error_message}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {log.user_email || 'N/A'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      log.resolved ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      {log.resolved ? 'Resolved' : 'Unresolved'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedLog(log);
                      }}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      View Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {selectedLog && <ErrorDetailsModal log={selectedLog} />}
    </div>
  );
};

export default SubscriptionErrors;
