import React from 'react';

export const ValueProposition: React.FC = () => {
  return (
    <section className="py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-4xl font-extrabold text-gray-900 mb-4">
            Transform Your Content Creation
          </h2>
          <p className="text-xl text-gray-600 mb-16 max-w-3xl mx-auto">
            Our AI-powered platform helps you create engaging, platform-optimized content in minutes, not hours.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* AI-Powered Content Creation */}
          <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-lg p-8 shadow-sm">
            <div className="flex items-center gap-3 mb-6">
              <div className="bg-blue-600 rounded-lg p-3">
                <svg className="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-gray-900">AI-Powered Content Creation</h3>
            </div>
            <p className="text-gray-700 mb-6">
              Create engaging content in seconds with our advanced AI technology
            </p>
            <ul className="space-y-4">
              <li className="flex items-center text-gray-700">
                <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Save hours of work
              </li>
              <li className="flex items-center text-gray-700">
                <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Consistent quality
              </li>
              <li className="flex items-center text-gray-700">
                <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Platform-optimized content
              </li>
            </ul>
          </div>

          {/* AI-Powered Market Research */}
          <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-lg p-8 shadow-sm">
            <div className="flex items-center gap-3 mb-6">
              <div className="bg-blue-600 rounded-lg p-3">
                <svg className="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-gray-900">AI-Powered Market Research</h3>
            </div>
            <p className="text-gray-700 mb-6">
              Generate comprehensive market insights and reports automatically
            </p>
            <ul className="space-y-4">
              <li className="flex items-center text-gray-700">
                <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Competitor analysis
              </li>
              <li className="flex items-center text-gray-700">
                <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Market trends insights
              </li>
              <li className="flex items-center text-gray-700">
                <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Data-driven recommendations
              </li>
            </ul>
          </div>

          {/* AI-Powered Product Descriptions */}
          <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-lg p-8 shadow-sm">
            <div className="flex items-center gap-3 mb-6">
              <div className="bg-blue-600 rounded-lg p-3">
                <svg className="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-gray-900">AI-Powered Product Descriptions</h3>
            </div>
            <p className="text-gray-700 mb-6">
              Create compelling product descriptions that convert
            </p>
            <ul className="space-y-4">
              <li className="flex items-center text-gray-700">
                <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                SEO-optimized content
              </li>
              <li className="flex items-center text-gray-700">
                <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Benefit-focused writing
              </li>
              <li className="flex items-center text-gray-700">
                <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Bulk description generation
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
