import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from '../utils/axiosConfig';

const SuccessPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    const verifySessionAndLogin = async () => {
      try {
        setIsProcessing(true);
        // Get session_id from URL query params
        const params = new URLSearchParams(location.search);
        const sessionId = params.get('session_id');

        if (!sessionId) {
          setError('No session ID found');
          setIsProcessing(false);
          return;
        }

        console.log('Verifying session:', sessionId);

        try {
          // First verify the Stripe session
          const verifyResponse = await axios.get(`/subscriptions/verify-session/?session_id=${sessionId}`, {
            withCredentials: true
          });

          console.log('Session verified:', verifyResponse.data);

          // Wait a bit for the session to be properly set
          await new Promise(resolve => setTimeout(resolve, 1000));

          // Get stored credentials
          const email = localStorage.getItem('pendingUserEmail');
          const password = localStorage.getItem('pendingUserPassword');

          if (!email || !password) {
            setError('No login credentials found. Please try logging in manually.');
            setIsProcessing(false);
            return;
          }

          // Attempt login with stored credentials
          try {
            await login(email, password);
            
            // Clear stored credentials
            localStorage.removeItem('pendingUserEmail');
            localStorage.removeItem('pendingUserPassword');
            
            // Navigate to dashboard
            navigate('/dashboard');
          } catch (loginError) {
            console.error('Auto-login failed:', loginError);
            setError('Login failed. Please try logging in manually.');
          }
        } catch (verifyError: any) {
          console.error('Session verification failed:', verifyError);
          setError(verifyError.response?.data?.error || 'Failed to verify your subscription. Please contact support.');
        }
      } catch (error: any) {
        console.error('Error in success page:', error);
        setError(error.response?.data?.error || 'An unexpected error occurred. Please try logging in manually.');
      } finally {
        setIsProcessing(false);
      }
    };

    verifySessionAndLogin();
  }, [login, navigate, location]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-900 to-indigo-900 py-12 px-4 sm:px-6 lg:px-8">
      {/* Background Pattern */}
      <div className="absolute inset-0 opacity-10">
        <div 
          className="absolute inset-0 bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]"
          style={{ backgroundImage: 'url(/images/grid-pattern.svg)' }}
        ></div>
      </div>

      <div className="max-w-md w-full mx-auto">
        <div className="bg-white/95 backdrop-blur-sm rounded-2xl shadow-xl p-8 animate-fade-in">
          <div className="text-center">
            {isProcessing ? (
              <>
                <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-blue-100 mb-6">
                  <svg className="animate-spin h-8 w-8 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
                <h2 className="text-3xl font-bold text-gray-900 mb-4">
                  Verifying Your Subscription
                </h2>
                <p className="text-gray-600 mb-8">
                  Please wait while we verify your payment and activate your account...
                </p>
              </>
            ) : error ? (
              <>
                <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-red-100 mb-6">
                  <svg className="h-10 w-10 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                </div>
                <h2 className="text-3xl font-bold text-gray-900 mb-4">
                  Oops!
                </h2>
                <p className="text-red-600 mb-8">
                  {error}
                </p>
                <div className="space-y-4">
                  <Link
                    to="/login"
                    className="block w-full py-3 px-4 rounded-lg shadow-sm text-white bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 text-sm font-medium transition-all duration-200 hover:scale-[1.02]"
                  >
                    Go to Login
                  </Link>
                  <Link
                    to="/"
                    className="block w-full py-3 px-4 rounded-lg border-2 border-blue-600 text-blue-600 hover:bg-blue-50 text-sm font-medium transition-all duration-200"
                  >
                    Return to Home
                  </Link>
                </div>
              </>
            ) : (
              <>
                <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100 mb-6">
                  <svg className="h-10 w-10 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <h2 className="text-3xl font-bold text-gray-900 mb-4">
                  Registration Successful!
                </h2>
                <p className="text-gray-600 mb-8">
                  Your account has been created and your subscription is now active. You'll be redirected to the dashboard shortly.
                </p>
                <div className="space-y-4">
                  <Link
                    to="/dashboard"
                    className="block w-full py-3 px-4 rounded-lg shadow-sm text-white bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 text-sm font-medium transition-all duration-200 hover:scale-[1.02]"
                  >
                    Go to Dashboard
                  </Link>
                  <Link
                    to="/"
                    className="block w-full py-3 px-4 rounded-lg border-2 border-blue-600 text-blue-600 hover:bg-blue-50 text-sm font-medium transition-all duration-200"
                  >
                    Return to Home
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
