import axios, { AxiosError } from 'axios';
import axiosInstance from '../utils/axiosConfig';

export const handleAxiosError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;
    console.error('Axios error details:', {
      message: axiosError.message,
      response: axiosError.response,
      request: axiosError.request,
      config: axiosError.config,
    });
  } else {
    console.error('Non-Axios error:', error);
  }
};

export const fetchCsrfToken = async (): Promise<string> => {
  console.log('Fetching CSRF token');
  try {
    const response = await axiosInstance.get<{ csrfToken: string }>('/get-csrf-token/');
    const token = response.data.csrfToken;
    axiosInstance.defaults.headers.common['X-CSRFToken'] = token;
    console.log('CSRF token fetched and set:', token);
    return token;
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
    handleAxiosError(error);
    throw error;
  }
};
