import React from 'react';

interface ProgressBarProps {
  progress: number;
  className?: string;
  showPercentage?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ 
  progress, 
  className = '',
  showPercentage = true,
}) => {
  return (
    <div className={`w-full ${className}`}>
      <div className="relative">
        <div className="overflow-hidden h-2.5 text-xs flex rounded-full bg-gray-200">
          <div
            style={{ width: `${progress}%` }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500 transition-all duration-300 ease-out"
          />
        </div>
        {showPercentage && (
          <div className="absolute right-0 -top-6 text-xs text-gray-600">
            {Math.round(progress)}%
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
