import React, { useEffect, useRef } from 'react';

interface AnimatedCounterProps {
  end: number;
  duration?: number;
  suffix?: string;
  className?: string;
}

/**
 * AnimatedCounter Component
 * 
 * Displays a number that animates from 0 to the target value.
 * Supports optional suffix and custom styling.
 */
export const AnimatedCounter: React.FC<AnimatedCounterProps> = ({
  end,
  duration = 2000,
  suffix = '',
  className = ''
}) => {
  const counterRef = useRef<HTMLDivElement>(null);
  const startTimestampRef = useRef<number | null>(null);
  const currentValueRef = useRef(0);

  useEffect(() => {
    if (!counterRef.current) return;

    const animate = (timestamp: number) => {
      if (!startTimestampRef.current) {
        startTimestampRef.current = timestamp;
      }

      const progress = timestamp - startTimestampRef.current;
      const percentage = Math.min(progress / duration, 1);
      
      // Easing function for smooth animation
      const easeOutQuart = 1 - Math.pow(1 - percentage, 4);
      currentValueRef.current = Math.floor(end * easeOutQuart);

      if (counterRef.current) {
        counterRef.current.textContent = `${currentValueRef.current}${suffix}`;
      }

      if (percentage < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);

    return () => {
      startTimestampRef.current = null;
      currentValueRef.current = 0;
    };
  }, [end, duration, suffix]);

  return (
    <div 
      ref={counterRef}
      className={`font-bold ${className}`}
      aria-live="polite"
    >
      0{suffix}
    </div>
  );
};
