import React from 'react';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  const navigate = useNavigate();
  
  const adminSections = [
    {
      title: 'Audit Logs',
      description: 'View and manage system audit logs',
      icon: '📝',
      path: '/admin/audit-logs'
    },
    {
      title: 'AI Configuration',
      description: 'Configure AI settings and parameters',
      icon: '⚙️',
      path: '/admin/ai-configuration'
    },
    {
      title: 'Testing',
      description: 'Run and monitor AI tests',
      icon: '🧪',
      path: '/admin/testing'
    },
    {
      title: 'Users',
      description: 'Manage user accounts and permissions',
      icon: '👥',
      path: '/admin/users'
    },
    {
      title: 'Prompt History',
      description: 'View and analyze AI prompt usage across the system',
      icon: '🔍',
      path: '/admin/prompt-history'
    },
    {
      title: 'System Prompt',
      description: 'Configure the base system prompt for content generation',
      icon: '💬',
      path: '/admin/system-prompt'
    },
    {
      title: 'Platform Instructions',
      description: 'Configure platform-specific instructions for content generation',
      icon: '🎯',
      path: '/admin/platform-instructions'
    },
    {
      title: 'Subscription Errors',
      description: 'Monitor and manage subscription-related errors and issues',
      icon: '🔔',
      path: '/admin/subscription-errors'
    }
  ];

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">Admin Dashboard</h1>
        <p className="mt-2 text-gray-600">Manage your application settings and monitor system activity</p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {adminSections.map((section) => (
          <div
            key={section.title}
            onClick={() => navigate(section.path)}
            className="bg-white rounded-lg shadow-sm p-6 cursor-pointer hover:shadow-md transition-shadow duration-200 border border-gray-100"
          >
            <div className="text-3xl mb-4">{section.icon}</div>
            <h2 className="text-lg font-semibold text-gray-900 mb-2">{section.title}</h2>
            <p className="text-gray-600 text-sm">{section.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminDashboard;
