import { useState, useCallback, useEffect, useRef } from 'react';
import { Content, ContentStatus, CONTENT_STATUSES } from '../../types/content';
import { SimplifiedContent, Platform, ContentRequirements } from '../../pages/simplified-content/types';
import { Campaign } from '../../types/campaign';
import { simplifiedContentApi } from '../../pages/simplified-content/api/contentApi';

const getContentId = (content: Content | SimplifiedContent): string => {
  const id = content.id;
  const platform = 'platform' in content ? content.platform : '';
  return `simplified-${id}${platform ? `-${platform}` : ''}`;
};

// Helper function to validate and convert status
const validateStatus = (status: string): ContentStatus => {
  return CONTENT_STATUSES.includes(status as ContentStatus) ? status as ContentStatus : 'draft';
};

export const useContentHistory = () => {
  const [contents, setContents] = useState<(Content & { requirements?: ContentRequirements })[]>([]);
  const [selectedContentIds, setSelectedContentIds] = useState<Set<string>>(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const contentCache = useRef<Map<number, (Content & { requirements?: ContentRequirements })[]>>(new Map());
  const statusUpdates = useRef<Map<string, ContentStatus>>(new Map());

  const convertSimplifiedToContent = useCallback(async (simplified: SimplifiedContent): Promise<(Content & { requirements?: ContentRequirements })[]> => {
    // Check cache first
    if (contentCache.current.has(simplified.id)) {
      return contentCache.current.get(simplified.id)!;
    }

    // Properly handle campaign data
    let campaignData: Campaign | null = null;
    if (simplified.campaign) {
      if (typeof simplified.campaign === 'object') {
        campaignData = simplified.campaign as Campaign;
      } else if (typeof simplified.campaign === 'number') {
        try {
          const campaignDetails = await simplifiedContentApi.getCampaignDetails(simplified.campaign);
          campaignData = {
            id: simplified.campaign,
            name: campaignDetails.name,
            status: 'active',
            primary_purpose: campaignDetails.primary_purpose as any,
            campaign_type: campaignDetails.type as any,
            campaign_category: campaignDetails.category as any || 'other',
            start_date: campaignDetails.start_date,
            end_date: campaignDetails.end_date,
            budget: 0,
            social_media_platforms: campaignDetails.social_media_platforms,
            target_audience: campaignDetails.target_audience,
            products: [],
            services: [],
            campaign_goals: {
              reach_target: 0,
              engagement_target: 0,
              conversion_target: 0,
              revenue_target: 0,
              lead_target: 0
            },
            success_metrics: {
              impressions: 0,
              clicks: 0,
              conversions: 0,
              click_through_rate: 0,
              conversion_rate: 0
            },
            target_kpis: {},
            impressions: 0,
            clicks: 0,
            conversions: 0,
            campaign_content_count: 0,
            total_content_count: 0,
            created_at: simplified.created_at,
            updated_at: simplified.updated_at
          };
        } catch (error) {
          console.error('Error fetching campaign details:', error);
          campaignData = {
            id: simplified.campaign,
            name: `Campaign ${simplified.campaign}`,
            status: 'active',
            primary_purpose: 'sales_promotion',
            campaign_type: 'social_media',
            campaign_category: 'other',
            start_date: simplified.created_at,
            end_date: simplified.updated_at,
            budget: 0,
            social_media_platforms: [],
            target_audience: '',
            products: [],
            services: [],
            campaign_goals: {
              reach_target: 0,
              engagement_target: 0,
              conversion_target: 0,
              revenue_target: 0,
              lead_target: 0
            },
            success_metrics: {
              impressions: 0,
              clicks: 0,
              conversions: 0,
              click_through_rate: 0,
              conversion_rate: 0
            },
            target_kpis: {},
            impressions: 0,
            clicks: 0,
            conversions: 0,
            campaign_content_count: 0,
            total_content_count: 0,
            created_at: simplified.created_at,
            updated_at: simplified.updated_at
          };
        }
      }
    }

    const contentType = campaignData ? 'campaign' : 'quick';

    const baseContent: Omit<Content & { requirements?: ContentRequirements }, 'platform' | 'content' | 'content_id' | 'generated_image_url' | 'platform_images'> = {
      id: simplified.id,
      content_type: contentType,
      content_status: validateStatus(simplified.workflow_status || 'draft'),
      content_format: 'text',
      platform_objective: '',
      target_audience: simplified.requirements?.targetAudience || '',
      platform_metadata: {},
      platform_metrics: {},
      topic: simplified.requirements?.topic || '',
      campaign: campaignData,
      created_at: simplified.created_at,
      user: simplified.user,
      metadata: simplified.metadata,
      requirements: simplified.requirements
    };

    // Only use platforms that have generated content or images
    const platformsWithContent = Object.entries(simplified.generated_content || {})
      .filter(([platform, content]) => {
        const hasContent = !!content?.text;
        const hasImage = !!simplified.platform_images?.[platform]?.image_url;
        return hasContent || hasImage;
      })
      .map(([platform]) => platform);

    // Create platform-specific content entries
    const platformContents = platformsWithContent.map(platform => ({
      ...baseContent,
      platform,
      content: simplified.generated_content[platform]?.text || '',
      content_id: `simplified-${simplified.id}-${platform}`,
      generated_image_url: simplified.platform_images?.[platform]?.image_url,
      platform_images: simplified.platform_images
    }));

    // Cache the result
    contentCache.current.set(simplified.id, platformContents);

    return platformContents;
  }, []);

  const fetchContents = useCallback(async () => {
    try {
      setIsRefreshing(true);
      setError(null);

      const allContent = await simplifiedContentApi.getAllContent();
      
      // Process content in batches to avoid UI blocking
      const batchSize = 5;
      const processedContents: (Content & { requirements?: ContentRequirements })[] = [];
      
      for (let i = 0; i < allContent.length; i += batchSize) {
        const batch = allContent.slice(i, i + batchSize);
        const batchResults = await Promise.all(batch.map(simplified => convertSimplifiedToContent(simplified)));
        const updatedBatchResults = batchResults.map(contentArray => 
          contentArray.map(content => {
            // If we have a cached status update, use it
            const statusKey = `${content.id}-${content.platform}`;
            const cachedStatus = statusUpdates.current.get(statusKey);
            if (cachedStatus) {
              return { ...content, content_status: cachedStatus };
            }
            return content;
          })
        );
        processedContents.push(...updatedBatchResults.flat());
      }

      // Update state with all processed contents at once
      setContents(processedContents);
    } catch (err) {
      console.error('Error fetching content:', err);
      setError(err instanceof Error ? err.message : 'Failed to fetch content');
    } finally {
      setIsRefreshing(false);
      setIsInitialLoad(false);
      setIsLoading(false);
    }
  }, [convertSimplifiedToContent]);

  useEffect(() => {
    fetchContents();
  }, [fetchContents]);

  const toggleContentSelection = useCallback((contentId: string) => {
    setSelectedContentIds(prev => {
      const newSet = new Set(prev);
      if (newSet.has(contentId)) {
        newSet.delete(contentId);
      } else {
        newSet.add(contentId);
      }
      return newSet;
    });
  }, []);

  const deleteContent = useCallback(async (contentId: string) => {
    const simplifiedId = contentId.match(/simplified-(\d+)/)?.[1];
    if (!simplifiedId) return;

    try {
      await simplifiedContentApi.deleteContent(parseInt(simplifiedId));
      
      setContents(prev => prev.filter(content => !getContentId(content).startsWith(`simplified-${simplifiedId}`)));
      setSelectedContentIds(prev => {
        const newSet = new Set(prev);
        Array.from(prev).forEach(id => {
          if (id.startsWith(`simplified-${simplifiedId}`)) {
            newSet.delete(id);
          }
        });
        return newSet;
      });
      
      // Clear cache for deleted content
      contentCache.current.delete(parseInt(simplifiedId));
    } catch (err) {
      console.error('Error deleting content:', err);
      throw err;
    }
  }, []);

  const clearSelection = useCallback(() => {
    setSelectedContentIds(new Set());
  }, []);

  const updateContentInState = useCallback((contentId: number, updates: Partial<Content>, targetPlatform: string) => {
    // Store status updates in our ref to persist across refreshes
    if (updates.content_status) {
      // Use composite key of contentId and platform for status updates
      const statusKey = `${contentId}-${targetPlatform}`;
      statusUpdates.current.set(statusKey, updates.content_status);
    }

    setContents(prevContents => {
      const newContents = prevContents.map(content => {
        // Only update content that exactly matches both ID and platform
        if (content.id === contentId && content.platform.trim() === targetPlatform.trim()) {
          // Update the content with new values
          const updatedContent = { ...content, ...updates };
          
          // Update cache
          const cacheEntry = contentCache.current.get(contentId);
          if (cacheEntry) {
            const updatedCacheEntry = cacheEntry.map(item => 
              item.id === contentId && item.platform.trim() === targetPlatform.trim() 
                ? { ...item, ...updates } 
                : item
            );
            contentCache.current.set(contentId, updatedCacheEntry);
          }
          
          return updatedContent;
        }
        return content;
      });

      return newContents;
    });
  }, []);

  return {
    contents,
    selectedContentIds,
    isLoading,
    isInitialLoad,
    isRefreshing,
    error,
    toggleContentSelection,
    deleteContent,
    clearSelection,
    refreshContents: fetchContents,
    updateContentInState
  };
};

export default useContentHistory;
