import React, { useState, useCallback } from 'react';
import { BusinessProfile } from '../types';
import { simplifiedContentApi } from '../api/contentApi';

interface TopicGeneratorProps {
  businessProfile: BusinessProfile | null;
  contentType: string;
  onTopicSelect: (topic: string) => void;
  inputs: {
    targetAudience: string;
    keywords: string;
    creativityLevel: number;
    selectedProducts: {
      name: string;
      description: string;
      isPrimary: boolean;
    }[];
    selectedServices: {
      name: string;
      description: string;
      isPrimary: boolean;
    }[];
  };
}

export const TopicGenerator: React.FC<TopicGeneratorProps> = ({
  businessProfile,
  contentType,
  onTopicSelect,
  inputs
}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [topics, setTopics] = useState<string[]>([]);
  const [selectedTopic, setSelectedTopic] = useState<string>('');
  const [error, setError] = useState<string>('');

  const generateTopics = useCallback(async () => {
    if (!businessProfile) {
      setError('Business profile is required');
      return;
    }

    if (!businessProfile.business_type) {
      setError('Business type is required');
      return;
    }

    setIsGenerating(true);
    setError('');

    try {
      const response = await simplifiedContentApi.generateTopicIdeas({
        company_type: businessProfile.business_type,
        company_name: businessProfile.business_name,
        content_type: contentType,
        target_audience: inputs.targetAudience || '',
        keywords: inputs.keywords || '',
        creativity_level: Math.round((inputs.creativityLevel || 0.5) * 10),
        selected_products: inputs.selectedProducts.map(p => ({
          ...p,
          isPrimary: true
        })),
        selected_services: inputs.selectedServices.map(s => ({
          ...s,
          isPrimary: false
        })),
        num_topics: 5,
        business_profile_id: businessProfile.id  // Add business profile ID
      });

      const generatedTopics = response.topic_ideas.slice(0, 5);
      setTopics(generatedTopics);
    } catch (err) {
      setError('Failed to generate topics. Please try again.');
      console.error('Error generating topics:', err);
    } finally {
      setIsGenerating(false);
    }
  }, [businessProfile, contentType, inputs]);

  const handleTopicSelect = useCallback((topic: string) => {
    setSelectedTopic(topic);
    onTopicSelect(topic);
  }, [onTopicSelect]);

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <svg className="w-5 h-5 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
          </svg>
          <span className="text-sm text-gray-600">AI-powered topic suggestions based on your inputs</span>
        </div>
        <button
          onClick={generateTopics}
          disabled={isGenerating || !businessProfile}
          className={`px-4 py-2 rounded-lg text-white font-medium transition-all duration-200 transform hover:scale-105 ${
            isGenerating || !businessProfile
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-blue-600 hover:bg-blue-700 shadow-md hover:shadow-lg'
          }`}
        >
          {isGenerating ? (
            <div className="flex items-center space-x-2">
              <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <span>Generating...</span>
            </div>
          ) : (
            <div className="flex items-center space-x-2">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
              <span>Generate Topics</span>
            </div>
          )}
        </button>
      </div>

      {error && (
        <div className="flex items-center p-4 bg-red-50 border-l-4 border-red-500 rounded-r-lg">
          <svg className="w-5 h-5 text-red-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <span className="text-red-700">{error}</span>
        </div>
      )}

      {topics.length > 0 && (
        <div className="space-y-3">
          {topics.map((topic: string, index: number) => (
            <div
              key={index}
              onClick={() => handleTopicSelect(topic)}
              className={`p-4 border rounded-lg cursor-pointer transition-all duration-200 ${
                selectedTopic === topic
                  ? 'bg-blue-50 border-blue-500 shadow-md transform scale-[1.02]'
                  : 'hover:border-blue-300 hover:bg-gray-50 hover:shadow-sm'
              }`}
            >
              <div className="flex items-center space-x-3">
                {selectedTopic === topic && (
                  <svg className="w-5 h-5 text-blue-600 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                )}
                <span className={`${selectedTopic === topic ? 'text-blue-700 font-medium' : 'text-gray-700'}`}>
                  {topic}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}

      {topics.length > 0 && (
        <button
          onClick={generateTopics}
          className="inline-flex items-center space-x-2 text-blue-600 hover:text-blue-800 font-medium transition-colors duration-200"
        >
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
          </svg>
          <span>Generate New Topics</span>
        </button>
      )}
    </div>
  );
};
