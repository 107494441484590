import React, { useState } from 'react';
import { CampaignFormData } from '../../types/campaign';

interface CampaignFormProps {
  onSubmit: (campaignData: CampaignFormData) => void;
  onCancel: () => void;
  initialData?: CampaignFormData;
}

const CAMPAIGN_PURPOSES = [
  { value: 'product_launch', label: 'Product Launch' },
  { value: 'brand_awareness', label: 'Brand Awareness' },
  { value: 'lead_generation', label: 'Lead Generation' },
  { value: 'sales_promotion', label: 'Sales Promotion' },
  { value: 'event_promotion', label: 'Event Promotion' },
  { value: 'customer_retention', label: 'Customer Retention' },
] as const;

const CAMPAIGN_TYPES = [
  { value: 'social_media', label: 'Social Media' },
  { value: 'email', label: 'Email Marketing' },
  { value: 'content', label: 'Content Marketing' },
  { value: 'search', label: 'Search Engine Marketing' },
  { value: 'display', label: 'Display Advertising' },
  { value: 'influencer', label: 'Influencer Marketing' },
  { value: 'affiliate', label: 'Affiliate Marketing' },
] as const;

const CAMPAIGN_CATEGORIES = [
  // Seasonal Campaigns
  { value: 'spring_season', label: 'Spring Season' },
  { value: 'summer_season', label: 'Summer Season' },
  { value: 'fall_season', label: 'Fall Season' },
  { value: 'winter_season', label: 'Winter Season' },

  // Major Shopping Events
  { value: 'black_friday', label: 'Black Friday' },
  { value: 'cyber_monday', label: 'Cyber Monday' },
  { value: 'prime_day', label: 'Prime Day' },
  { value: 'singles_day', label: 'Singles Day' },
  { value: 'boxing_day', label: 'Boxing Day' },
  { value: 'small_business_saturday', label: 'Small Business Saturday' },

  // Holiday Campaigns
  { value: 'holiday_general', label: 'Holiday Campaign' },
  { value: 'christmas', label: 'Christmas' },
  { value: 'halloween', label: 'Halloween' },
  { value: 'valentines_day', label: 'Valentine\'s Day' },
  { value: 'mothers_day', label: 'Mother\'s Day' },
  { value: 'fathers_day', label: 'Father\'s Day' },
  { value: 'easter', label: 'Easter' },
  { value: 'new_year', label: 'New Year' },

  // Business Cycles
  { value: 'back_to_school', label: 'Back to School' },
  { value: 'end_of_season', label: 'End of Season' },
  { value: 'end_of_financial_year', label: 'End of Financial Year' },
  { value: 'clearance_sale', label: 'Clearance Sale' },
  { value: 'inventory_liquidation', label: 'Inventory Liquidation' },

  // Special Events
  { value: 'anniversary_sale', label: 'Anniversary Sale' },
  { value: 'grand_opening', label: 'Grand Opening' },
  { value: 'store_launch', label: 'Store Launch' },
  { value: 'flash_sale', label: 'Flash Sale' },
  { value: 'popup_event', label: 'Pop-up Event' },

  // Industry-Specific
  { value: 'travel_season', label: 'Travel Season' },
  { value: 'wedding_season', label: 'Wedding Season' },
  { value: 'festival_season', label: 'Festival Season' },
  { value: 'sports_season', label: 'Sports Season' },

  // Other
  { value: 'other', label: 'Other' },
] as const;

const TARGET_AUDIENCES = [
  // Customer Status
  { value: 'new_customers', label: 'New customers' },
  { value: 'existing_customers', label: 'Existing customers' },
  { value: 'lapsed_customers', label: 'Lapsed customers' },
  
  // Gender
  { value: 'men', label: 'Men' },
  { value: 'women', label: 'Women' },
  { value: 'non_binary', label: 'Non-binary individuals' },
  
  // Age Groups
  { value: 'teenagers', label: 'Teenagers (13–17 years old)' },
  { value: 'young_adults', label: 'Young Adults (18–24 years old)' },
  { value: 'adults', label: 'Adults (25–40 years old)' },
  { value: 'middle_aged', label: 'Middle-Aged Adults (41–60 years old)' },
  { value: 'seniors', label: 'Seniors (60+ years old)' },
  
  // Event Related
  { value: 'event_attendees', label: 'Event attendees' },
  { value: 'event_organizers', label: 'Event organizers or volunteers' },
  { value: 'potential_attendees', label: 'Potential attendees' },
  
  // Shopping Behavior
  { value: 'frequent_shoppers', label: 'Frequent shoppers' },
  { value: 'bargain_hunters', label: 'Bargain hunters or deal seekers' },
  { value: 'brand_loyalists', label: 'Brand loyalists' },
  { value: 'online_shoppers', label: 'Online-only shoppers' },
  { value: 'instore_shoppers', label: 'In-store shoppers' },
  
  // Business Decision Makers
  { value: 'small_business', label: 'Small business owners' },
  { value: 'startup_founders', label: 'Startup founders' },
  { value: 'corporate_decision_makers', label: 'Corporate decision-makers (e.g., CEOs, CMOs)' },
  { value: 'industry_professionals', label: 'Industry professionals' },
  
  // Life Stage
  { value: 'students', label: 'Students' },
  { value: 'recent_graduates', label: 'Recent graduates' },
  { value: 'newlyweds', label: 'Newlyweds or couples' },
  { value: 'parents', label: 'Parents (e.g., parents of toddlers, school-age children, or teens)' },
  { value: 'retirees', label: 'Retirees' },
  
  // Location Based
  { value: 'local_community', label: 'Local community members' },
  { value: 'regional_national', label: 'Regional, national, or international customers' },
  { value: 'urban_dwellers', label: 'Urban dwellers' },
  { value: 'suburban_rural', label: 'Suburban or rural residents' },
  
  // Interest Based
  { value: 'eco_conscious', label: 'Eco-conscious consumers' },
  { value: 'social_justice', label: 'Social justice advocates' },
  { value: 'wellness_seekers', label: 'Wellness and mindfulness seekers' },
] as const;

const SOCIAL_MEDIA_PLATFORMS = [
  { value: 'instagram', label: 'Instagram' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'twitter', label: 'Twitter (X)' },
  { value: 'linkedin', label: 'LinkedIn' },
  { value: 'youtube', label: 'YouTube' },
  { value: 'tiktok', label: 'TikTok' },
] as const;

export const CampaignForm: React.FC<CampaignFormProps> = ({ onSubmit, onCancel, initialData }) => {
  const [formData, setFormData] = useState<CampaignFormData>(initialData || {
    id: 0,
    name: '',
    status: 'active',
    primary_purpose: 'brand_awareness',
    campaign_type: 'social_media',
    campaign_category: 'holiday_general',
    start_date: '',
    end_date: '',
    budget: 0,
    social_media_platforms: [],
    target_audience: '',
    product_ids: [],
    service_ids: [],
    campaign_goals: {
      reach_target: 0,
      engagement_target: 0,
      conversion_target: 0
    },
    target_kpis: {}
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));

    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleMultiSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, options } = e.target;
    const selectedValues = Array.from(options)
      .filter(option => option.selected)
      .map(option => option.value);

    setFormData((prev) => ({
      ...prev,
      [name]: selectedValues
    }));
  };

  const handleBlur = (fieldName: string) => {
    setTouched((prev) => ({
      ...prev,
      [fieldName]: true
    }));

    const fieldError = validateField(fieldName, formData[fieldName as keyof CampaignFormData]);
    if (fieldError) {
      setErrors((prev) => ({
        ...prev,
        [fieldName]: fieldError
      }));
    }
  };

  const validateField = (fieldName: string, value: any): string => {
    switch (fieldName) {
      case 'name':
        if (!value) return 'Campaign name is required';
        if (value.length < 3) return 'Campaign name must be at least 3 characters';
        return '';
      case 'start_date':
        if (!value) return 'Start date is required';
        return '';
      case 'end_date':
        if (!value) return 'End date is required';
        if (formData.start_date && new Date(value) <= new Date(formData.start_date)) {
          return 'End date must be after start date';
        }
        return '';
      case 'budget':
        if (!value) return 'Budget is required';
        if (Number(value) <= 0) return 'Budget must be greater than 0';
        return '';
      case 'primary_purpose':
        if (!value) return 'Campaign purpose is required';
        return '';
      case 'campaign_type':
        if (!value) return 'Campaign type is required';
        return '';
      case 'campaign_category':
        if (!value) return 'Campaign category is required';
        return '';
      case 'target_audience':
        if (!value) return 'Target audience is required';
        return '';
      default:
        return '';
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const newErrors: Record<string, string> = {};
    Object.keys(formData).forEach(key => {
      const error = validateField(key, formData[key as keyof CampaignFormData]);
      if (error) {
        newErrors[key] = error;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      const touchedFields: Record<string, boolean> = {};
      Object.keys(formData).forEach(key => {
        touchedFields[key] = true;
      });
      setTouched(touchedFields);
      return;
    }

    onSubmit(formData);
  };

  return (
    <div className="bg-white rounded-2xl shadow-xl p-8 animate-fade-in">
      <h2 className="text-2xl font-bold text-gray-900 mb-6">
        {initialData ? 'Edit Campaign' : 'Create New Campaign'}
      </h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Basic Information */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-2">
              Campaign Name
              <span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              onBlur={() => handleBlur('name')}
              className={`w-full px-4 py-3 rounded-lg border ${
                errors.name ? 'border-red-500' : 'border-gray-300'
              } focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200`}
              required
            />
            {touched.name && errors.name && (
              <p className="mt-1 text-sm text-red-500">{errors.name}</p>
            )}
          </div>

          <div>
            <label htmlFor="status" className="block text-sm font-medium text-gray-700 mb-2">Status</label>
            <select
              id="status"
              name="status"
              value={formData.status}
              onChange={handleInputChange}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
            >
              <option value="active">Active</option>
              <option value="paused">Paused</option>
              <option value="draft">Draft</option>
            </select>
          </div>

          <div>
            <label htmlFor="primary_purpose" className="block text-sm font-medium text-gray-700 mb-2">
              Campaign Purpose
              <span className="text-red-500 ml-1">*</span>
            </label>
            <select
              id="primary_purpose"
              name="primary_purpose"
              value={formData.primary_purpose}
              onChange={handleInputChange}
              onBlur={() => handleBlur('primary_purpose')}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
              required
            >
              <option value="">Select Purpose</option>
              {CAMPAIGN_PURPOSES.map(purpose => (
                <option key={purpose.value} value={purpose.value}>
                  {purpose.label}
                </option>
              ))}
            </select>
            {touched.primary_purpose && errors.primary_purpose && (
              <p className="mt-1 text-sm text-red-500">{errors.primary_purpose}</p>
            )}
          </div>

          <div>
            <label htmlFor="campaign_type" className="block text-sm font-medium text-gray-700 mb-2">
              Campaign Type
              <span className="text-red-500 ml-1">*</span>
            </label>
            <select
              id="campaign_type"
              name="campaign_type"
              value={formData.campaign_type}
              onChange={handleInputChange}
              onBlur={() => handleBlur('campaign_type')}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
              required
            >
              <option value="">Select Type</option>
              {CAMPAIGN_TYPES.map(type => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
            {touched.campaign_type && errors.campaign_type && (
              <p className="mt-1 text-sm text-red-500">{errors.campaign_type}</p>
            )}
          </div>

          <div>
            <label htmlFor="campaign_category" className="block text-sm font-medium text-gray-700 mb-2">
              Campaign Category
              <span className="text-red-500 ml-1">*</span>
            </label>
            <select
              id="campaign_category"
              name="campaign_category"
              value={formData.campaign_category}
              onChange={handleInputChange}
              onBlur={() => handleBlur('campaign_category')}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
              required
            >
              <option value="">Select Category</option>
              {CAMPAIGN_CATEGORIES.map(category => (
                <option key={category.value} value={category.value}>
                  {category.label}
                </option>
              ))}
            </select>
            {touched.campaign_category && errors.campaign_category && (
              <p className="mt-1 text-sm text-red-500">{errors.campaign_category}</p>
            )}
          </div>

          <div>
            <label htmlFor="target_audience" className="block text-sm font-medium text-gray-700 mb-2">
              Target Audience
              <span className="text-red-500 ml-1">*</span>
            </label>
            <select
              id="target_audience"
              name="target_audience"
              value={formData.target_audience}
              onChange={handleInputChange}
              onBlur={() => handleBlur('target_audience')}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
              required
            >
              <option value="">Select Target Audience</option>
              {TARGET_AUDIENCES.map(audience => (
                <option key={audience.value} value={audience.value}>
                  {audience.label}
                </option>
              ))}
            </select>
            {touched.target_audience && errors.target_audience && (
              <p className="mt-1 text-sm text-red-500">{errors.target_audience}</p>
            )}
          </div>
        </div>

        {/* Timeline & Budget */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <label htmlFor="start_date" className="block text-sm font-medium text-gray-700 mb-2">
              Start Date
              <span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="date"
              id="start_date"
              name="start_date"
              value={formData.start_date}
              onChange={handleInputChange}
              onBlur={() => handleBlur('start_date')}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
              required
            />
            {touched.start_date && errors.start_date && (
              <p className="mt-1 text-sm text-red-500">{errors.start_date}</p>
            )}
          </div>

          <div>
            <label htmlFor="end_date" className="block text-sm font-medium text-gray-700 mb-2">
              End Date
              <span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="date"
              id="end_date"
              name="end_date"
              value={formData.end_date}
              onChange={handleInputChange}
              onBlur={() => handleBlur('end_date')}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
              required
            />
            {touched.end_date && errors.end_date && (
              <p className="mt-1 text-sm text-red-500">{errors.end_date}</p>
            )}
          </div>

          <div>
            <label htmlFor="budget" className="block text-sm font-medium text-gray-700 mb-2">
              Budget
              <span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="number"
              id="budget"
              name="budget"
              value={formData.budget}
              onChange={handleInputChange}
              onBlur={() => handleBlur('budget')}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
              required
              min="0"
              step="0.01"
            />
            {touched.budget && errors.budget && (
              <p className="mt-1 text-sm text-red-500">{errors.budget}</p>
            )}
          </div>
        </div>

        {/* Hidden Social Media Platforms field - keeps functionality but not visible */}
        <div style={{ display: 'none' }}>
          <select
            id="social_media_platforms"
            name="social_media_platforms"
            value={formData.social_media_platforms}
            onChange={handleMultiSelectChange}
            multiple
          >
            {SOCIAL_MEDIA_PLATFORMS.map(platform => (
              <option key={platform.value} value={platform.value}>
                {platform.label}
              </option>
            ))}
          </select>
        </div>

        <div className="flex justify-end space-x-4 mt-8">
          <button
            type="button"
            onClick={onCancel}
            className="px-6 py-3 border-2 border-gray-300 rounded-lg font-semibold text-gray-700 hover:bg-gray-50 transition-all duration-200"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-6 py-3 bg-gradient-to-r from-blue-600 to-indigo-600 text-white rounded-lg font-semibold hover:from-blue-700 hover:to-indigo-700 transition-all duration-200"
          >
            {initialData ? 'Save Changes' : 'Create Campaign'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CampaignForm;
