// ... (previous imports remain the same)
import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useSubscription } from '../contexts/SubscriptionContext';
import contentSummaryService, { BillingPeriodTotals } from '../services/contentSummaryService';

// Previous interfaces and constants remain the same...
interface SubscriptionLimits {
  totalContent: number;
  imageGeneration: number;
  researchReports: number;
  productDescriptions: number;
}

const SUBSCRIPTION_LIMITS: Record<string, SubscriptionLimits> = {
  'basic': {
    totalContent: 50,
    imageGeneration: 150,
    researchReports: 5,
    productDescriptions: 5
  },
  'pro': {
    totalContent: 200,
    imageGeneration: 600,
    researchReports: 20,
    productDescriptions: 20
  },
  'enterprise': {
    totalContent: -1,
    imageGeneration: -1,
    researchReports: -1,
    productDescriptions: -1
  }
};

const WARNING_THRESHOLD = 0.8;

const SubscriptionStatus: React.FC = () => {
  const { user } = useAuth();
  const { 
    inRetryPeriod, 
    nextPaymentAttempt, 
    daysUntilRetry, 
    subscriptionStatus,
    paymentFailureReason 
  } = useSubscription();
  const [totals, setTotals] = useState<BillingPeriodTotals | null>(null);
  const [daysRemaining, setDaysRemaining] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshKey, setRefreshKey] = useState<number>(0);

  const limits = SUBSCRIPTION_LIMITS[user?.subscription_plan || 'basic'];

  // Previous helper functions remain the same...
  const fetchTotals = useCallback(async () => {
    try {
      const data = await contentSummaryService.getBillingPeriodTotals();
      setTotals(data);
      
      if (data.billing_period_end) {
        const endDate = new Date(data.billing_period_end);
        const today = new Date();
        const diffTime = endDate.getTime() - today.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        setDaysRemaining(diffDays);
      }
    } catch (error) {
      console.error('Error fetching subscription totals:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTotals();
    
    const intervalId = setInterval(() => {
      fetchTotals();
    }, 300000);
    
    return () => clearInterval(intervalId);
  }, [fetchTotals, refreshKey]);

  const formatLimit = (limit: number) => limit === -1 ? '∞' : limit;

  const getUsagePercentage = (current: number, limit: number) => {
    if (limit === -1) return 0;
    return (current / limit) * 100;
  };

  const getUsageClass = (current: number, limit: number) => {
    if (limit === -1) return 'text-gray-700 font-bold';
    const percentage = getUsagePercentage(current, limit);
    if (percentage >= WARNING_THRESHOLD * 100) return 'text-red-500 font-bold';
    return 'text-gray-700 font-bold';
  };

  const getUsageBarClass = (current: number, limit: number) => {
    if (limit === -1) return 'bg-blue-500';
    const percentage = getUsagePercentage(current, limit);
    if (percentage >= WARNING_THRESHOLD * 100) return 'bg-red-500';
    return 'bg-blue-500';
  };

  const getTotalContent = () => {
    return (totals?.quick_content_total || 0) + (totals?.campaign_content_total || 0);
  };

  const getUpgradeMessage = (current: number, limit: number, type: 'content' | 'image' | 'research' | 'product') => {
    if (limit === -1) return null;
    const percentage = getUsagePercentage(current, limit);
    
    if (percentage >= 100) {
      return (
        <div className="mt-1 text-xs text-red-500">
          Limit reached. <Link to="/subscription" className="underline">Upgrade your plan</Link> for more {type}s.
        </div>
      );
    }
    
    if (percentage >= WARNING_THRESHOLD * 100) {
      return (
        <div className="mt-1 text-xs text-red-500">
          Approaching limit. Consider <Link to="/subscription" className="underline">upgrading your plan</Link>.
        </div>
      );
    }
    
    return null;
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      month: 'short', 
      day: 'numeric',
      year: 'numeric'
    });
  };

  if (isLoading) {
    return <div className="text-sm text-gray-500">Loading subscription status...</div>;
  }

  const totalContent = getTotalContent();

  return (
    <div className="space-y-4 p-4 border-t border-gray-200">
      {/* Payment Status Alert */}
      {subscriptionStatus === 'past_due' && (
        <div className={`p-3 rounded-lg mb-4 ${inRetryPeriod ? 'bg-yellow-50 border border-yellow-200' : 'bg-red-50 border border-red-200'}`}>
          <div className={`flex items-center ${inRetryPeriod ? 'text-yellow-800' : 'text-red-800'}`}>
            <svg className="h-5 w-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
            <div>
              <p className="font-medium">Payment Required</p>
              {inRetryPeriod ? (
                <>
                  <p className="text-sm mb-1">{paymentFailureReason || 'We were unable to process your last payment.'}</p>
                  <p className="text-sm">Next payment attempt in {daysUntilRetry} days</p>
                  <Link 
                    to="/business-profile"
                    className="mt-2 inline-block text-sm font-medium hover:text-yellow-900 underline"
                  >
                    Update Payment Method
                  </Link>
                </>
              ) : (
                <>
                  <p className="text-sm">Your subscription has expired</p>
                  <Link 
                    to="/business-profile"
                    className="mt-2 inline-block text-sm font-medium hover:text-red-900 underline"
                  >
                    Renew Subscription
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Usage Section */}
      <div className="flex justify-between items-center">
        <h3 className="text-sm font-semibold text-gray-700">Usage</h3>
        <button
          onClick={() => setRefreshKey(prev => prev + 1)}
          className="text-gray-500 hover:text-gray-700"
          title="Refresh usage stats"
        >
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
          </svg>
        </button>
      </div>
      
      {/* Usage Sections */}
      <div className="space-y-4">
        {/* Generated Content Section */}
        <div className="space-y-2">
          <div className="flex justify-between text-sm font-medium text-gray-700">
            <span>Generated Content</span>
            <span className={getUsageClass(totalContent, limits.totalContent)}>
              {totalContent}/{formatLimit(limits.totalContent)}
            </span>
          </div>
          
          <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
            <div 
              className={`h-full ${getUsageBarClass(totalContent, limits.totalContent)} transition-all duration-300`}
              style={{ 
                width: `${Math.min(getUsagePercentage(totalContent, limits.totalContent), 100)}%`
              }}
            />
          </div>
          
          {getUpgradeMessage(totalContent, limits.totalContent, 'content')}
          
          <div className="pl-4 space-y-1 text-sm">
            <div className="flex justify-between">
              <span className="text-gray-500">• Quick Content:</span>
              <span className="text-gray-600">{totals?.quick_content_total || 0}</span>
            </div>
            
            <div className="flex justify-between">
              <span className="text-gray-500">• Campaign Content:</span>
              <span className="text-gray-600">{totals?.campaign_content_total || 0}</span>
            </div>
          </div>
        </div>

        {/* Generated Images Section */}
        <div className="space-y-2 pt-2 border-t border-gray-100">
          <div className="flex justify-between text-sm font-medium text-gray-700">
            <span>Generated Images</span>
            <span className={getUsageClass(totals?.image_generation_total || 0, limits.imageGeneration)}>
              {totals?.image_generation_total || 0}/{formatLimit(limits.imageGeneration)}
            </span>
          </div>
          
          <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
            <div 
              className={`h-full ${getUsageBarClass(totals?.image_generation_total || 0, limits.imageGeneration)} transition-all duration-300`}
              style={{ 
                width: `${Math.min(getUsagePercentage(totals?.image_generation_total || 0, limits.imageGeneration), 100)}%`
              }}
            />
          </div>
          
          {getUpgradeMessage(totals?.image_generation_total || 0, limits.imageGeneration, 'image')}
        </div>

        {/* Generated Research Reports Section */}
        <div className="space-y-2 pt-2 border-t border-gray-100">
          <div className="flex justify-between text-sm font-medium text-gray-700">
            <span>Generated Research Reports</span>
            <span className={getUsageClass(totals?.research_reports_total || 0, limits.researchReports)}>
              {totals?.research_reports_total || 0}/{formatLimit(limits.researchReports)}
            </span>
          </div>
          
          <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
            <div 
              className={`h-full ${getUsageBarClass(totals?.research_reports_total || 0, limits.researchReports)} transition-all duration-300`}
              style={{ 
                width: `${Math.min(getUsagePercentage(totals?.research_reports_total || 0, limits.researchReports), 100)}%`
              }}
            />
          </div>
          
          {getUpgradeMessage(totals?.research_reports_total || 0, limits.researchReports, 'research')}
        </div>

        {/* Product Descriptions Section */}
        <div className="space-y-2 pt-2 border-t border-gray-100">
          <div className="flex justify-between text-sm font-medium text-gray-700">
            <span>Product Descriptions</span>
            <span className={getUsageClass(totals?.product_descriptions_this_period || 0, limits.productDescriptions)}>
              {totals?.product_descriptions_this_period || 0}/{formatLimit(limits.productDescriptions)}
            </span>
          </div>
          
          <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
            <div 
              className={`h-full ${getUsageBarClass(totals?.product_descriptions_this_period || 0, limits.productDescriptions)} transition-all duration-300`}
              style={{ 
                width: `${Math.min(getUsagePercentage(totals?.product_descriptions_this_period || 0, limits.productDescriptions), 100)}%`
              }}
            />
          </div>
          
          {getUpgradeMessage(totals?.product_descriptions_this_period || 0, limits.productDescriptions, 'product')}
        </div>
      </div>

      {/* Billing Period Section */}
      <div className="pt-2 border-t border-gray-200 space-y-2">
        <div className="flex justify-between text-sm">
          <span className="text-gray-600">Days Remaining:</span>
          <span className="font-bold text-gray-700">{daysRemaining}</span>
        </div>
        
        {totals?.billing_period_start && (
          <div className="text-xs text-gray-500">
            <div className="flex justify-between">
              <span>Start:</span>
              <span>{formatDate(totals.billing_period_start)}</span>
            </div>
            <div className="flex justify-between">
              <span>End:</span>
              <span>{formatDate(totals.billing_period_end)}</span>
            </div>
          </div>
        )}

        {/* Subscription Status */}
        <div className="flex justify-between text-xs">
          <span className="text-gray-600">Status:</span>
          <span className={`font-medium ${subscriptionStatus === 'past_due' ? 'text-yellow-600' : 'text-green-600'}`}>
            {subscriptionStatus === 'past_due' ? 'Payment Due' : 'Active'}
          </span>
        </div>

        {/* Next Payment Attempt */}
        {nextPaymentAttempt && (
          <div className="flex justify-between text-xs">
            <span className="text-gray-600">Next Payment:</span>
            <span className="text-gray-700">{formatDate(nextPaymentAttempt)}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionStatus;
