import React, { useState } from 'react';
import PromptHistoryPanel from '../../components/admin/PromptHistoryPanel';

const PromptHistory = () => {
  const [error, setError] = useState<string | null>(null);

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">Prompt History</h1>
        <p className="mt-2 text-gray-600">
          View and analyze AI prompt usage across different content generation flows
        </p>
      </div>

      {error && (
        <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-md">
          <p className="text-red-600">{error}</p>
        </div>
      )}

      <PromptHistoryPanel setError={setError} />
    </div>
  );
};

export default PromptHistory;
