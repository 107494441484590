import axios, { AxiosError } from 'axios';
import axiosInstance from '../../../utils/axiosConfig';
import { Campaign, Platform, ContentRequirements, GenerateContentResponse, GenerateContentParams, PlatformImages, CampaignDetails, BusinessProfile, ContentMetadata, UpdateContentParams } from '../types';
import { Product } from '../../../types/content';

interface Service {
  id: number;
  name: string;
  description: string;
}

interface PaginatedResponse<T> {
  results: T[];
  next: string | null;
  previous: string | null;
  count: number;
}

interface TopicIdeasRequest {
  company_type: string;
  company_name: string;
  content_type: string;
  target_audience: string;
  keywords: string;
  creativity_level: number;
  selected_products: Array<{
    name: string;
    description: string;
    isPrimary: boolean;
  }>;
  selected_services: Array<{
    name: string;
    description: string;
    isPrimary: boolean;
  }>;
  num_topics: number;
  business_profile_id?: number;
}

interface TopicIdeasResponse {
  topic_ideas: string[];
}

interface SaveResult {
  successes: GenerateContentResponse[];
  failures: Array<{ platform: string; error: string }>;
}

interface SaveContentParams {
  platforms: Platform[];
  requirements: ContentRequirements;
  campaign: Campaign | null;
  generated_content: any;
  platform_images?: PlatformImages | null;
  business_profile?: BusinessProfile | null;
  metadata?: ContentMetadata;
}

export const simplifiedContentApi = {
  generateContent: async (data: GenerateContentParams): Promise<GenerateContentResponse> => {
    try {
      console.log('Preparing content generation request:', data);

      const requestData = {
        platforms: data.platforms.map(platform => ({
          id: platform.id,
          name: platform.name,
          requirements: platform.requirements || []
        })),
        requirements: {
          ...data.requirements,
          keyMessage: data.requirements.topic  // Map topic to keyMessage for backend
        },
        campaign: data.campaign ? data.campaign.id : null,
        content_type: data.campaign ? 'campaign' : 'quick',
        business_profile: data.businessProfile ? data.businessProfile.id : null
      };

      console.log('Sending content generation request:', requestData);
      const response = await axiosInstance.post('/simplified-content/generate/', requestData);
      console.log('Content generation response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Content generation error:', error);
      throw error;
    }
  },

  generateImage: async (generatedContent: any, requirements: ContentRequirements): Promise<PlatformImages> => {
    try {
      console.log('Generating images with content:', generatedContent);
      const response = await axiosInstance.post('/simplified-content/generate-images/', {
        generated_content: generatedContent,
        requirements: {
          ...requirements,
          keyMessage: requirements.topic  // Map topic to keyMessage for backend
        }
      });
      console.log('Image generation response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error generating images:', error);
      throw error;
    }
  },

  regenerateText: async (contentId: string, platform: string, requirements: ContentRequirements): Promise<{ platform: string; generated_content: string }> => {
    try {
      console.log('Regenerating text for platform:', platform);
      const response = await axiosInstance.post(`/simplified-content/${contentId}/regenerate-text/`, {
        platform,
        requirements
      });
      console.log('Text regeneration response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error regenerating text:', error);
      throw error;
    }
  },

  regenerateImage: async (contentId: string, platform: string): Promise<{ platform: string; generated_image_url: string }> => {
    try {
      console.log('Regenerating image for platform:', platform);
      const response = await axiosInstance.post(`/simplified-content/${contentId}/regenerate-image/`, {
        platform
      });
      console.log('Image regeneration response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error regenerating image:', error);
      throw error;
    }
  },

  updateContent: async (contentId: string, data: UpdateContentParams): Promise<GenerateContentResponse> => {
    try {
      console.log('Updating content:', { contentId, data });
      
      // Map content_status to workflow_status if it exists
      const requestData: UpdateContentParams = {
        workflow_status: data.workflow_status,
        platform_images: data.platform_images,
        metadata: data.metadata
      };

      console.log('Sending update request:', requestData);
      const response = await axiosInstance.patch(`/simplified-content/${contentId}/`, requestData);
      console.log('Content update response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Content update error:', error);
      throw error;
    }
  },

  getBusinessProfile: async (): Promise<BusinessProfile | null> => {
    try {
      console.log('Fetching business profile...');
      const response = await axiosInstance.get('/business-profiles/');
      console.log('Business profile response:', response.data);
      return Object.keys(response.data).length > 0 ? response.data : null;
    } catch (error) {
      console.error('Error fetching business profile:', error);
      throw error;
    }
  },

  saveContent: async (data: SaveContentParams): Promise<SaveResult> => {
    try {
      console.log('Preparing save content request:', data);

      const requestData = {
        platforms: data.platforms.map(platform => ({
          id: platform.id,
          name: platform.name,
          requirements: platform.requirements || []
        })),
        requirements: {
          ...data.requirements,
          keyMessage: data.requirements.topic  // Add mapping for keyMessage
        },
        campaign: data.campaign ? data.campaign.id : null,
        content_type: data.campaign ? 'campaign' : 'quick',
        status: 'draft',
        workflow_status: 'draft',  // Set initial workflow status
        generated_content: data.generated_content,
        platform_images: data.platform_images || null,
        business_profile: data.business_profile ? data.business_profile.id : null,
        metadata: data.metadata || {
          source: 'simplified_content',
          version: '2.0',
          generated_at: new Date().toISOString()
        }
      };

      console.log('Sending save request:', requestData);
      const response = await axiosInstance.post('/simplified-content/save-content/', requestData);
      console.log('Content saved successfully:', response.data);
      return {
        successes: [response.data],
        failures: []
      };
    } catch (error) {
      console.error('Error saving content:', error);
      if (error instanceof Error) {
        const axiosError = error as AxiosError<{ detail?: string; error?: string }>;
        if (axiosError.response?.data) {
          const errorMessage = axiosError.response.data.detail || 
                             axiosError.response.data.error || 
                             axiosError.message;
          return {
            successes: [],
            failures: data.platforms.map(platform => ({
              platform: platform.name,
              error: errorMessage
            }))
          };
        }
      }
      throw error;
    }
  },

  deleteContent: async (id: number): Promise<void> => {
    try {
      console.log('Deleting simplified content:', id);
      await axiosInstance.delete(`/simplified-content/${id}/`);
      console.log('Content deleted successfully');
    } catch (error) {
      console.error('Error deleting content:', error);
      throw error;
    }
  },

  getContent: async (id: string): Promise<GenerateContentResponse> => {
    try {
      console.log('Fetching content:', id);
      const response = await axiosInstance.get(`/simplified-content/${id}/`);
      console.log('Content response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching content:', error);
      throw error;
    }
  },

  getAllContent: async (): Promise<GenerateContentResponse[]> => {
    try {
      console.log('Fetching all content');
      const response = await axiosInstance.get('/simplified-content/');
      console.log('All content response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching all content:', error);
      throw error;
    }
  },

  getCampaignDetails: async (campaignId: number): Promise<CampaignDetails> => {
    try {
      console.log('Fetching campaign details:', campaignId);
      const response = await axiosInstance.get('/simplified-content/get_campaign_details/', {
        params: { campaign_id: campaignId }
      });
      console.log('Campaign details response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching campaign details:', error);
      throw error;
    }
  },

  getCampaigns: async (): Promise<Campaign[]> => {
    try {
      console.log('Fetching campaigns...');
      const response = await axiosInstance.get('/campaigns/');
      console.log('Raw campaign data:', response.data);

      const campaigns = response.data.map((campaign: any) => ({
        ...campaign,
        id: Number(campaign.id)
      }));

      console.log('Transformed campaigns:', campaigns);
      return campaigns;
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      throw error;
    }
  },

  getProducts: async (): Promise<PaginatedResponse<Product>> => {
    try {
      console.log('Fetching products...');
      const response = await axiosInstance.get('/products/');
      console.log('Products response:', response.data);
      return {
        results: response.data || [],
        next: null,
        previous: null,
        count: response.data?.length || 0
      };
    } catch (error) {
      console.error('Error fetching products:', error);
      return {
        results: [],
        next: null,
        previous: null,
        count: 0
      };
    }
  },

  getServices: async (): Promise<PaginatedResponse<Service>> => {
    try {
      console.log('Fetching services...');
      const response = await axiosInstance.get('/services/');
      console.log('Services response:', response.data);
      return {
        results: response.data || [],
        next: null,
        previous: null,
        count: response.data?.length || 0
      };
    } catch (error) {
      console.error('Error fetching services:', error);
      return {
        results: [],
        next: null,
        previous: null,
        count: 0
      };
    }
  },

  generateTopicIdeas: async (params: TopicIdeasRequest): Promise<TopicIdeasResponse> => {
    try {
      console.log('Generating topic ideas with params:', params);

      const enhancedKeywords = params.keywords;
      const enhancedProducts = params.selected_products.map(product => ({
        ...product,
        isPrimary: true
      }));
      const enhancedServices = params.selected_services.map(service => ({
        ...service,
        isPrimary: false
      }));

      const requestData = {
        company_type: params.company_type,
        company_name: params.company_name,
        content_type: params.content_type,
        target_audience: params.target_audience,
        keywords: enhancedKeywords,
        creativity_level: params.creativity_level,
        selected_products: enhancedProducts,
        selected_services: enhancedServices,
        num_topics: params.num_topics,
        business_profile_id: params.business_profile_id
      };

      const response = await axiosInstance.post('/simplified-content/generate-topics/', requestData);
      console.log('Topic ideas response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error generating topic ideas:', error);
      throw error;
    }
  },

  getPlatforms: async (): Promise<Platform[]> => {
    try {
      console.log('Fetching platforms...');
      const platforms: Platform[] = [
        {
          id: '1',
          name: 'facebook_post',
          requirements: ['Image size: 1200x630px', 'Max text: 125 characters']
        },
        {
          id: '2',
          name: 'instagram_feed',
          requirements: ['Image size: 1080x1080px', 'Max hashtags: 30']
        },
        {
          id: '3',
          name: 'twitter_post',
          requirements: ['Image size: 1200x675px', 'Max text: 280 characters']
        },
        {
          id: '4',
          name: 'linkedin_post',
          requirements: ['Image size: 1200x627px', 'Professional tone recommended']
        },
        {
          id: '5',
          name: 'youtube_video',
          requirements: [
            'Resolution: 1920x1080 (HD)',
            'Length: 10-15 minutes',
            'Video script with timestamps',
            'Description: 5000 characters max',
            'Hashtags: Up to 15'
          ]
        },
        {
          id: '6',
          name: 'youtube_shorts',  // Changed from youtube_short to youtube_shorts
          requirements: [
            'Resolution: 1080x1920 (9:16)',
            'Length: 60 seconds max',
            'Video script with timestamps',
            'Description: 100 characters max',
            'Hashtags: Up to 3'
          ]
        },
        {
          id: '7',
          name: 'tiktok_video',
          requirements: [
            'Resolution: 1080x1920 (9:16)',
            'Length: 15s to 3m',
            'Optimal length: 21-34 seconds',
            'Video script with timestamps',
            'Description: 2200 characters max',
            'Hashtags: Up to 5'
          ]
        }
      ];
      console.log('Available platforms:', platforms);
      return platforms;
    } catch (error) {
      console.error('Error fetching platforms:', error);
      throw error;
    }
  }

  // ... (rest of the methods remain the same)
};
