import axiosInstance from '../utils/axiosConfig';
import { handleAxiosError } from './api';
import { 
  AIConfig, 
  TestResult, 
  Statistics, 
  AuditLogResponse, 
  AdminUser,
  PromptHistoryResponse,
  PromptHistoryStats,
  PromptHistorySettings,
  SystemPromptResponse,
  PlatformSettingsResponse,
  PlatformSettings,
  ErrorLogFilters,
  ErrorLogResponse
} from '../types/admin';

export const adminService = {
  // System Prompt
  getSystemPrompt: async (): Promise<SystemPromptResponse> => {
    try {
      const response = await axiosInstance.get('/admin/system-prompt/');
      if (!response.data) {
        throw new Error('No system prompt data received');
      }
      return response.data;
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  updateSystemPrompt: async (prompt: string): Promise<{ status: string; prompt: string }> => {
    try {
      const response = await axiosInstance.post('/admin/system-prompt/', { prompt });
      if (!response.data) {
        throw new Error('Failed to update system prompt');
      }
      return response.data;
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  // Platform Settings
  getPlatformSettings: async (): Promise<PlatformSettingsResponse> => {
    try {
      const response = await axiosInstance.get('/admin/platform-settings/');
      if (!response.data) {
        throw new Error('No platform settings data received');
      }
      return response.data;
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  updatePlatformSettings: async (settings: PlatformSettings): Promise<{ status: string; settings: PlatformSettings }> => {
    try {
      const response = await axiosInstance.post('/admin/platform-settings/', { settings });
      if (!response.data) {
        throw new Error('Failed to update platform settings');
      }
      return response.data;
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  // AI Configuration
  getAIConfig: async (): Promise<AIConfig> => {
    try {
      const response = await axiosInstance.get('/admin/settings/');
      if (!response.data) {
        return {
          model: 'gpt-4',
          api_type: 'chat',
          temperature: 0.7,
        };
      }
      const aiConfig = response.data.find((setting: any) => setting.key === 'ai_config');
      return aiConfig?.value || {
        model: 'gpt-4',
        api_type: 'chat',
        temperature: 0.7,
      };
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  updateAIConfig: async (config: AIConfig): Promise<AIConfig> => {
    try {
      const response = await axiosInstance.post('/admin/settings/update-ai-config/', { config });
      if (!response.data) {
        throw new Error('Failed to update AI configuration');
      }
      return response.data.config;
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  getOpenAIModels: async (): Promise<string[]> => {
    try {
      const response = await axiosInstance.get('/admin/models/');
      if (!response.data) {
        // Return default models if API call fails
        return ['gpt-4-1106-preview', 'gpt-4', 'gpt-3.5-turbo'];
      }
      return response.data;
    } catch (error) {
      handleAxiosError(error);
      // Return default models on error
      return ['gpt-4-1106-preview', 'gpt-4', 'gpt-3.5-turbo'];
    }
  },

  // Testing
  runTest: async (prompt: string): Promise<TestResult> => {
    try {
      const response = await axiosInstance.post('/admin/ai-tests/run-test/', { prompt });
      if (!response.data) {
        throw new Error('No test results received');
      }
      return response.data;
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  getTestStatistics: async (days: number = 7): Promise<Statistics> => {
    try {
      const response = await axiosInstance.get(`/admin/ai-tests/statistics/?days=${days}`);
      if (!response.data) {
        throw new Error('No statistics data received');
      }
      // Ensure we have the expected data structure
      if (!response.data.statistics) {
        return {
          statistics: {
            chat: {
              avg_duration: 0,
              avg_tokens: 0,
              avg_cost: 0,
              total_tests: 0,
              error_count: 0,
              performance_breakdown: {
                excellent: 0,
                good: 0,
                fair: 0,
                poor: 0
              }
            },
            assistant: {
              avg_duration: 0,
              avg_tokens: 0,
              avg_cost: 0,
              total_tests: 0,
              error_count: 0,
              performance_breakdown: {
                excellent: 0,
                good: 0,
                fair: 0,
                poor: 0
              }
            }
          },
          time_period_days: days
        };
      }
      return response.data;
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  // Audit Logs
  getAuditLogs: async (params: {
    page?: number;
    limit?: number;
    action_type?: string;
    start_date?: string;
    end_date?: string;
  } = {}): Promise<AuditLogResponse> => {
    try {
      const response = await axiosInstance.get('/admin/audit-logs/', { params });
      if (!response.data) {
        return {
          results: [],
          count: 0,
          page: params.page || 1,
          total_pages: 1
        };
      }
      return {
        results: response.data.results || [],
        count: response.data.count || 0,
        page: response.data.page || params.page || 1,
        total_pages: response.data.total_pages || 1
      };
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  // Admin Users
  getAdminUsers: async (): Promise<AdminUser[]> => {
    try {
      const response = await axiosInstance.get('/admin/users/');
      if (!response.data) {
        return [];
      }
      return response.data;
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  toggleSuperuser: async (userId: number): Promise<AdminUser> => {
    try {
      const response = await axiosInstance.post(`/admin/users/${userId}/toggle-superuser/`);
      if (!response.data) {
        throw new Error('Failed to toggle superuser status');
      }
      return response.data;
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  // Prompt History
  getPromptHistory: async (params: {
    page?: number;
    page_size?: number;
    prompt_type?: string;
    days?: number;
    user_id?: number;
  } = {}): Promise<PromptHistoryResponse> => {
    try {
      const response = await axiosInstance.get('/admin/prompt-history/', { params });
      if (!response.data) {
        return {
          results: [],
          total_pages: 1,
          current_page: params.page || 1,
          has_next: false,
          has_previous: false,
          total_count: 0
        };
      }
      return response.data;
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  getPromptHistoryStats: async (days: number = 7): Promise<PromptHistoryStats> => {
    try {
      const response = await axiosInstance.get(`/admin/prompt-history/statistics/?days=${days}`);
      if (!response.data) {
        throw new Error('No statistics data received');
      }
      return response.data;
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  downloadPromptHistory: async (): Promise<void> => {
    try {
      const response = await axiosInstance.get('/admin/prompt-history/download/', {
        responseType: 'blob'
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'prompt_history.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  // Prompt History Settings
  getPromptHistorySettings: async (): Promise<PromptHistorySettings> => {
    try {
      const response = await axiosInstance.get('/admin/settings/');
      const settings = response.data?.find((setting: any) => setting.key === 'prompt_history_settings');
      return settings?.value || {
        enabled: true,
        retention_days: 30,
        default_page_size: 50
      };
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  updatePromptHistorySettings: async (settings: PromptHistorySettings): Promise<PromptHistorySettings> => {
    try {
      const response = await axiosInstance.post('/admin/prompt-history/update-settings/', { settings });
      if (!response.data) {
        throw new Error('Failed to update prompt history settings');
      }
      return response.data.settings;
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  // Subscription Error Logs
  getSubscriptionErrors: async (filters: ErrorLogFilters): Promise<ErrorLogResponse> => {
    try {
      const response = await axiosInstance.get('/admin/subscription-errors/', { params: filters });
      if (!response.data) {
        throw new Error('No error logs received');
      }
      return response.data;
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  },

  markErrorResolved: async (errorId: number, notes: string): Promise<{ status: string }> => {
    try {
      const response = await axiosInstance.post(`/admin/subscription-errors/${errorId}/resolve/`, {
        resolution_notes: notes
      });
      if (!response.data) {
        throw new Error('Failed to mark error as resolved');
      }
      return response.data;
    } catch (error) {
      handleAxiosError(error);
      throw error;
    }
  }
};
