import React from 'react';
import FormInput from '../../common/FormInput';
import { BusinessProfileFormSectionProps } from '../types/businessProfileForm';

const VoiceAndToneForm: React.FC<BusinessProfileFormSectionProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  // Helper function to update platform tone variations
  const updatePlatformTone = (platform: string, field: 'tone' | 'guidelines', value: string) => {
    const currentVariations = values.platform_tone_variations || {};
    handleChange('platform_tone_variations', {
      ...currentVariations,
      [platform]: {
        ...currentVariations[platform],
        [field]: value
      }
    });
  };

  // Helper function to get platform value
  const getPlatformValue = (platform: string, field: 'tone' | 'guidelines'): string => {
    return values.platform_tone_variations?.[platform]?.[field] || '';
  };

  const platforms = {
    social: [
      {
        name: 'Facebook Post',
        description: 'Image size: 1200x630px, Max text: 125 characters',
        defaultTone: 'Conversational and engaging'
      },
      {
        name: 'Instagram Feed',
        description: 'Image size: 1080x1080px, Max hashtags: 30',
        defaultTone: 'Visual and inspirational'
      },
      {
        name: 'Twitter Post',
        description: 'Image size: 1200x675px, Max text: 280 characters',
        defaultTone: 'Concise and dynamic'
      },
      {
        name: 'LinkedIn Post',
        description: 'Image size: 1200x627px, Professional tone recommended',
        defaultTone: 'Professional and informative'
      }
    ],
    video: [
      {
        name: 'YouTube Video',
        description: 'Resolution: 1920x1080 (HD), Length: 10-15 minutes',
        defaultTone: 'Educational and detailed'
      },
      {
        name: 'YouTube Shorts',
        description: 'Resolution: 1080x1920 (9:16), Length: 60 seconds max',
        defaultTone: 'Quick and engaging'
      },
      {
        name: 'TikTok Video',
        description: 'Resolution: 1080x1920 (9:16), Length: 15s to 3m',
        defaultTone: 'Trendy and entertaining'
      }
    ]
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">Voice and Tone</h3>
        <p className="mt-1 text-sm text-gray-500">
          Define your brand's voice and communication style across different platforms.
        </p>
      </div>

      {/* Tone Style */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Overall Tone Style
        </label>
        <select
          name="tone_style"
          value={values.tone_style || 'friendly'}
          onChange={(e) => handleChange('tone_style', e.target.value)}
          onBlur={handleBlur}
          className="mt-1 block w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 hover:border-gray-300"
        >
          <option value="friendly">Friendly</option>
          <option value="formal">Formal</option>
          <option value="playful">Playful</option>
          <option value="authoritative">Authoritative</option>
        </select>
      </div>

      {/* Writing Style Guidelines */}
      <FormInput
        label="Writing Style Guidelines"
        name="writing_style_guidelines"
        value={values.writing_style_guidelines}
        onChange={(e) => handleChange('writing_style_guidelines', e.target.value)}
        onBlur={handleBlur}
        error={errors.writing_style_guidelines}
        touched={touched.writing_style_guidelines}
        multiline
        rows={4}
        placeholder="Define your brand's writing style and content creation guidelines..."
      />

      {/* Vocabulary Preferences */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-4">
          Vocabulary Preferences
        </label>
        <div className="space-y-4">
          <FormInput
            label="Preferred Terms"
            name="preferred_terms"
            value={values.vocabulary_preferences?.preferred_terms?.join('\n') || ''}
            onChange={(e) => handleChange('vocabulary_preferences', {
              ...values.vocabulary_preferences,
              preferred_terms: e.target.value.split('\n').filter(term => term.trim() !== '')
            })}
            onBlur={handleBlur}
            multiline
            rows={3}
            placeholder="Enter preferred terms (one per line)"
          />
          <FormInput
            label="Avoided Terms"
            name="avoided_terms"
            value={values.vocabulary_preferences?.avoided_terms?.join('\n') || ''}
            onChange={(e) => handleChange('vocabulary_preferences', {
              ...values.vocabulary_preferences,
              avoided_terms: e.target.value.split('\n').filter(term => term.trim() !== '')
            })}
            onBlur={handleBlur}
            multiline
            rows={3}
            placeholder="Enter terms to avoid (one per line)"
          />
        </div>
      </div>

      {/* Platform-specific Tone Variations */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Platform-specific Tone Variations
        </label>
        <p className="mt-1 text-sm text-gray-500 mb-4">
          Define specific tone and guidelines for each content platform.
        </p>

        {/* Social Media Platforms */}
        <div className="mt-4">
          <h4 className="text-sm font-medium text-gray-700">Social Media Platforms</h4>
          <div className="mt-2 space-y-4">
            {platforms.social.map((platform) => (
              <div key={platform.name} className="bg-gray-50 p-4 rounded-md">
                <h5 className="text-sm font-medium text-gray-900">{platform.name}</h5>
                <p className="text-xs text-gray-500 mt-1">{platform.description}</p>
                <div className="mt-3 space-y-3">
                  <FormInput
                    label="Tone"
                    name={`${platform.name}_tone`}
                    value={getPlatformValue(platform.name, 'tone')}
                    onChange={(e) => updatePlatformTone(platform.name, 'tone', e.target.value)}
                    onBlur={handleBlur}
                    placeholder={platform.defaultTone}
                  />
                  <FormInput
                    label="Guidelines"
                    name={`${platform.name}_guidelines`}
                    value={getPlatformValue(platform.name, 'guidelines')}
                    onChange={(e) => updatePlatformTone(platform.name, 'guidelines', e.target.value)}
                    onBlur={handleBlur}
                    multiline
                    rows={2}
                    placeholder={`Specific guidelines for ${platform.name}...`}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Video Platforms */}
        <div className="mt-6">
          <h4 className="text-sm font-medium text-gray-700">Video Platforms</h4>
          <div className="mt-2 space-y-4">
            {platforms.video.map((platform) => (
              <div key={platform.name} className="bg-gray-50 p-4 rounded-md">
                <h5 className="text-sm font-medium text-gray-900">{platform.name}</h5>
                <p className="text-xs text-gray-500 mt-1">{platform.description}</p>
                <div className="mt-3 space-y-3">
                  <FormInput
                    label="Tone"
                    name={`${platform.name}_tone`}
                    value={getPlatformValue(platform.name, 'tone')}
                    onChange={(e) => updatePlatformTone(platform.name, 'tone', e.target.value)}
                    onBlur={handleBlur}
                    placeholder={platform.defaultTone}
                  />
                  <FormInput
                    label="Guidelines"
                    name={`${platform.name}_guidelines`}
                    value={getPlatformValue(platform.name, 'guidelines')}
                    onChange={(e) => updatePlatformTone(platform.name, 'guidelines', e.target.value)}
                    onBlur={handleBlur}
                    multiline
                    rows={2}
                    placeholder={`Specific guidelines for ${platform.name}...`}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoiceAndToneForm;
