import React, { forwardRef, InputHTMLAttributes } from 'react';
import { useDragAndDrop } from '../../hooks/useDragAndDrop';

interface FileDropZoneProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  onFilesDrop: (files: FileList) => void;
  acceptedFileTypes?: string[];
  children?: React.ReactNode;
  className?: string;
}

const FileDropZone = forwardRef<HTMLInputElement, FileDropZoneProps>(({
  onFilesDrop,
  acceptedFileTypes = [],
  children,
  className = '',
  ...props
}, ref) => {
  const {
    isDragging,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
  } = useDragAndDrop(onFilesDrop, acceptedFileTypes);

  return (
    <div
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className={`
        relative rounded-lg border-2 border-dashed
        transition-colors duration-200
        ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-gray-400'}
        ${className}
      `}
    >
      <input
        ref={ref}
        type="file"
        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        {...props}
      />
      {children}
    </div>
  );
});

FileDropZone.displayName = 'FileDropZone';

export default FileDropZone;
