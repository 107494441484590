import React from 'react';
import { SelectedProductWithImage } from '../types';

interface ProductImagePreviewProps {
  selectedProduct: SelectedProductWithImage;
  onClearSelection: () => void;
}

export const ProductImagePreview: React.FC<ProductImagePreviewProps> = ({
  selectedProduct,
  onClearSelection
}) => {
  if (!selectedProduct.selectedImage) return null;

  return (
    <div className="mb-8 bg-white rounded-xl shadow-sm border border-gray-100 p-6">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-3">
          <svg className="w-5 h-5 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
          <div>
            <h3 className="text-lg font-semibold text-gray-900">Selected Product Image</h3>
            <p className="text-sm text-gray-600">This image will be used in your content</p>
          </div>
        </div>
        <button
          onClick={onClearSelection}
          className="text-sm text-gray-600 hover:text-gray-800 flex items-center space-x-1"
        >
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
          <span>Clear Selection</span>
        </button>
      </div>
      
      <div className="relative rounded-lg overflow-hidden border border-gray-200">
        <img
          src={selectedProduct.selectedImage.image}
          alt={`Selected product: ${selectedProduct.name}`}
          className="w-full h-64 object-contain bg-gray-50"
        />
        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/50 to-transparent p-4">
          <div className="text-white">
            <h4 className="font-medium">{selectedProduct.name}</h4>
            <p className="text-sm opacity-90">Product image selected for content generation</p>
          </div>
        </div>
      </div>

      <div className="mt-4 bg-blue-50 border border-blue-100 rounded-lg p-4">
        <div className="flex items-start space-x-3">
          <svg className="w-5 h-5 text-blue-600 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <div className="flex-1">
            <p className="text-sm text-blue-800">
              AI image generation has been disabled. This product image will be used across all selected platforms in your content.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
