import React, { useState, useEffect } from 'react';
import { adminService } from '../../services/admin';
import toast from 'react-hot-toast';

interface SystemPromptPanelProps {
    setError: (error: string | null) => void;
}

const SystemPromptPanel: React.FC<SystemPromptPanelProps> = ({ setError }) => {
    const [prompt, setPrompt] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [originalPrompt, setOriginalPrompt] = useState<string>('');
    const [hasChanges, setHasChanges] = useState<boolean>(false);

    useEffect(() => {
        fetchSystemPrompt();
    }, []);

    useEffect(() => {
        setHasChanges(prompt !== originalPrompt);
    }, [prompt, originalPrompt]);

    const fetchSystemPrompt = async () => {
        try {
            setIsLoading(true);
            const response = await adminService.getSystemPrompt();
            setPrompt(response.prompt);
            setOriginalPrompt(response.prompt);
            setError(null);
        } catch (error) {
            console.error('Error fetching system prompt:', error);
            setError('Failed to load system prompt');
            toast.error('Failed to load system prompt');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSave = async () => {
        try {
            setIsSaving(true);
            await adminService.updateSystemPrompt(prompt);
            setOriginalPrompt(prompt);
            toast.success('System prompt updated successfully');
            setError(null);
        } catch (error) {
            console.error('Error saving system prompt:', error);
            setError('Failed to update system prompt');
            toast.error('Failed to update system prompt');
        } finally {
            setIsSaving(false);
        }
    };

    const handleReset = () => {
        setPrompt(originalPrompt);
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <div className="bg-white rounded-lg shadow p-6">
            <div className="mb-6">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">System Prompt</h2>
                <p className="text-gray-600 mb-4">
                    This is the base system prompt used for content generation. Edit with caution as changes will affect all new content generation.
                </p>
            </div>
            
            <div className="mb-6">
                <textarea
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    className="w-full h-64 p-4 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter system prompt..."
                    disabled={isSaving}
                />
            </div>

            <div className="flex justify-end space-x-4">
                {hasChanges && (
                    <button
                        onClick={handleReset}
                        disabled={isSaving}
                        className="px-6 py-2 rounded-lg text-gray-700 bg-gray-100 hover:bg-gray-200"
                    >
                        Reset
                    </button>
                )}
                <button
                    onClick={handleSave}
                    disabled={isSaving || !hasChanges}
                    className={`px-6 py-2 rounded-lg text-white ${
                        isSaving || !hasChanges
                            ? 'bg-gray-400 cursor-not-allowed' 
                            : 'bg-blue-600 hover:bg-blue-700'
                    }`}
                >
                    {isSaving ? 'Saving...' : 'Save Changes'}
                </button>
            </div>
        </div>
    );
};

export default SystemPromptPanel;
