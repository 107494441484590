import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { BusinessProfile } from '../../types/business';
import Card from '../common/Card';
import AlertBanner from '../common/AlertBanner';
import FormActions from '../common/FormActions';
import FormSection from './FormSection';
import { useForm } from '../../hooks/useForm';
import { required, email, phone, zipCode, minLength, maxLength, ValidationRule } from '../../utils/validation';
import BusinessInfoForm from './forms/BusinessInfoForm';
import AddressForm from './forms/AddressForm';
import ContactInfoForm from './forms/ContactInfoForm';
import BrandIdentityForm from './forms/BrandIdentityForm';
import VisualGuidelinesForm from './forms/VisualGuidelinesForm';
import VoiceAndToneForm from './forms/VoiceAndToneForm';
import ContentGuidelinesForm from './forms/ContentGuidelinesForm';
import { 
  BusinessProfileFormProps, 
  BusinessProfileFormData, 
  ToneStyle,
  SocialMediaGuidelines,
  SEOGuidelines 
} from './types/businessProfileForm';

const DEFAULT_SOCIAL_MEDIA_GUIDELINES: SocialMediaGuidelines = {
  hashtag_strategy: '',
  emoji_usage: '',
  engagement_guidelines: ''
};

const DEFAULT_SEO_GUIDELINES: SEOGuidelines = {
  target_keywords: [],
  meta_description_template: ''
};

const BusinessProfileForm: React.FC<BusinessProfileFormProps> = ({ 
  profile, 
  onSubmit, 
  onCancel 
}): React.ReactElement => {
  const { user } = useAuth();
  
  const [openSections, setOpenSections] = useState({
    businessInfo: false,
    address: false,
    contact: false,
    brandIdentity: false,
    visualGuidelines: false,
    voiceAndTone: false,
    contentGuidelines: false
  });

  const toggleSection = (section: keyof typeof openSections) => {
    setOpenSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  const customBusinessTypeValidation: ValidationRule<string> = {
    validate: (value: string, formState?: any) => {
      return !(formState?.business_type === 'custom' && !value.trim());
    },
    message: 'Custom business type is required'
  };

  // Initialize social media guidelines with defaults
  const initialSocialMediaGuidelines: SocialMediaGuidelines = {
    ...DEFAULT_SOCIAL_MEDIA_GUIDELINES,
    ...(profile?.social_media_guidelines || {})
  };

  // Initialize SEO guidelines with defaults
  const initialSEOGuidelines: SEOGuidelines = {
    ...DEFAULT_SEO_GUIDELINES,
    ...(profile?.seo_guidelines || {})
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    formError,
    handleChange,
    handleBlur,
    setFormError,
    validateForm,
  } = useForm<BusinessProfileFormData>(
    {
      business_name: {
        initialValue: profile?.business_name ?? '',
        validate: [required, minLength(2), maxLength(100)],
      },
      business_type: {
        initialValue: profile?.business_type ?? '',
        validate: [required],
      },
      custom_business_type: {
        initialValue: profile?.custom_business_type ?? '',
        validate: [customBusinessTypeValidation],
      },
      description: {
        initialValue: profile?.description ?? '',
        validate: [maxLength(500)],
      },
      mission_statement: {
        initialValue: profile?.mission_statement ?? '',
        validate: [maxLength(1000)],
      },
      brand_personality: {
        initialValue: profile?.brand_personality ?? '',
        validate: [maxLength(500)],
      },
      industry_target_demographics: {
        initialValue: profile?.industry_target_demographics ?? '',
        validate: [maxLength(500)],
      },
      market_segments: {
        initialValue: profile?.market_segments ?? '',
        validate: [maxLength(1000)],
      },
      street1: {
        initialValue: profile?.street1 ?? '',
        validate: [required],
      },
      street2: {
        initialValue: profile?.street2 ?? '',
      },
      city: {
        initialValue: profile?.city ?? '',
        validate: [required],
      },
      state: {
        initialValue: profile?.state ?? '',
        validate: [required],
      },
      zip: {
        initialValue: profile?.zip ?? '',
        validate: [required, zipCode],
      },
      phone_number: {
        initialValue: profile?.phone_number ?? '',
        validate: [required, phone],
      },
      contact_name: {
        initialValue: profile?.contact_name ?? '',
        validate: [required],
      },
      email: {
        initialValue: profile?.email ?? '',
        validate: [required, email],
      },
      logo_usage_guidelines: {
        initialValue: profile?.logo_usage_guidelines ?? '',
        validate: [maxLength(1000)],
      },
      primary_colors: {
        initialValue: profile?.primary_colors ?? [],
      },
      secondary_colors: {
        initialValue: profile?.secondary_colors ?? [],
      },
      heading_font: {
        initialValue: profile?.heading_font ?? '',
        validate: [maxLength(100)],
      },
      body_font: {
        initialValue: profile?.body_font ?? '',
        validate: [maxLength(100)],
      },
      special_font: {
        initialValue: profile?.special_font ?? '',
        validate: [maxLength(100)],
      },
      tone_style: {
        initialValue: (profile?.tone_style as ToneStyle) ?? 'friendly',
        validate: [required],
      },
      writing_style_guidelines: {
        initialValue: profile?.writing_style_guidelines ?? '',
        validate: [maxLength(1000)],
      },
      vocabulary_preferences: {
        initialValue: profile?.vocabulary_preferences ?? {
          preferred_terms: [],
          avoided_terms: []
        },
      },
      platform_tone_variations: {
        initialValue: profile?.platform_tone_variations ?? {},
      },
      messaging_pillars: {
        initialValue: profile?.messaging_pillars ?? [],
      },
      social_media_guidelines: {
        initialValue: initialSocialMediaGuidelines,
      },
      seo_guidelines: {
        initialValue: initialSEOGuidelines,
      },
      content_type_guidelines: {
        initialValue: profile?.content_type_guidelines ?? {},
      },
    },
    {
      persistenceEnabled: false,
      warnOnUnsavedChanges: true,
      unsavedChangesMessage: 'You have unsaved changes in your business profile. Are you sure you want to leave?',
    }
  );

  const handleInputChange = (name: keyof BusinessProfileFormData, value: any) => {
    console.log('handleInputChange:', { name, value });
    handleChange(name, value);
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    console.log('Form submitted with values:', values);
    
    if (!validateForm()) {
      // Open sections with errors
      const sectionsWithErrors = Object.keys(errors).reduce((acc: any, field) => {
        if (field.includes('business') || field === 'description') acc.businessInfo = true;
        if (field.includes('street') || field.includes('city') || field.includes('state') || field.includes('zip')) acc.address = true;
        if (field.includes('phone') || field.includes('contact') || field.includes('email')) acc.contact = true;
        if (field.includes('mission') || field.includes('brand') || field.includes('market')) acc.brandIdentity = true;
        if (field.includes('logo') || field.includes('color') || field.includes('font')) acc.visualGuidelines = true;
        if (field.includes('tone') || field.includes('writing') || field.includes('vocabulary')) acc.voiceAndTone = true;
        if (field.includes('messaging') || field.includes('social') || field.includes('seo') || field.includes('content')) acc.contentGuidelines = true;
        return acc;
      }, {});
      
      setOpenSections(prev => ({ ...prev, ...sectionsWithErrors }));
      return;
    }

    if (!user?.id) {
      setFormError('User information not available. Please try logging in again.');
      return;
    }

    const formData: BusinessProfile = {
      id: profile?.id ?? 0,
      user_id: user.id,
      business_name: values.business_name,
      business_type: values.business_type,
      custom_business_type: values.custom_business_type,
      contact_name: values.contact_name,
      phone_number: values.phone_number,
      email: values.email,
      street1: values.street1,
      street2: values.street2,
      city: values.city,
      state: values.state,
      zip: values.zip,
      country: 'US',
      full_address: `${values.street1}${values.street2 ? `, ${values.street2}` : ''}, ${values.city}, ${values.state} ${values.zip}`,
      created_at: profile?.created_at ?? '',
      updated_at: profile?.updated_at ?? '',
      description: values.description || '',
      mission_statement: values.mission_statement || '',
      brand_personality: values.brand_personality || '',
      industry_target_demographics: values.industry_target_demographics || '',
      market_segments: values.market_segments || '',
      products: profile?.products ?? [],
      services: profile?.services ?? [],
      logo_usage_guidelines: values.logo_usage_guidelines || '',
      primary_colors: values.primary_colors || [],
      secondary_colors: values.secondary_colors || [],
      heading_font: values.heading_font || '',
      body_font: values.body_font || '',
      special_font: values.special_font || '',
      tone_style: values.tone_style,
      writing_style_guidelines: values.writing_style_guidelines || '',
      vocabulary_preferences: values.vocabulary_preferences,
      platform_tone_variations: values.platform_tone_variations,
      messaging_pillars: values.messaging_pillars || [],
      social_media_guidelines: values.social_media_guidelines,
      seo_guidelines: values.seo_guidelines,
      content_type_guidelines: values.content_type_guidelines || {},
    };

    try {
      await onSubmit(formData);
    } catch (err: any) {
      console.error('Error submitting form:', err);
      const errorMessage = err.response?.data?.error || 'Failed to save business profile. Please try again.';
      setFormError(errorMessage);
    }
  };

  const sectionProps = {
    values,
    errors,
    touched,
    handleChange: handleInputChange,
    handleBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      const name = e.target.name as keyof BusinessProfileFormData;
      handleBlur(name);
    },
  };

  return (
    <Card>
      <form onSubmit={handleSubmit} className="space-y-6" autoComplete="off">
        {formError && (
          <AlertBanner
            type="error"
            message={formError}
            onDismiss={() => setFormError(null)}
          />
        )}

        <div className="space-y-4">
          <FormSection
            title="Business Information"
            isOpen={openSections.businessInfo}
            onToggle={() => toggleSection('businessInfo')}
          >
            <BusinessInfoForm {...sectionProps} />
          </FormSection>

          <FormSection
            title="Address"
            isOpen={openSections.address}
            onToggle={() => toggleSection('address')}
          >
            <AddressForm {...sectionProps} />
          </FormSection>

          <FormSection
            title="Contact Information"
            isOpen={openSections.contact}
            onToggle={() => toggleSection('contact')}
          >
            <ContactInfoForm {...sectionProps} />
          </FormSection>

          <div className="pt-6 pb-2">
            <h2 className="text-2xl font-bold text-gray-900 border-b border-gray-200 pb-2">Style Guide</h2>
          </div>

          <FormSection
            title="Brand Identity"
            isOpen={openSections.brandIdentity}
            onToggle={() => toggleSection('brandIdentity')}
          >
            <BrandIdentityForm {...sectionProps} />
          </FormSection>

          <FormSection
            title="Visual Guidelines"
            isOpen={openSections.visualGuidelines}
            onToggle={() => toggleSection('visualGuidelines')}
          >
            <VisualGuidelinesForm {...sectionProps} />
          </FormSection>

          <FormSection
            title="Voice and Tone"
            isOpen={openSections.voiceAndTone}
            onToggle={() => toggleSection('voiceAndTone')}
          >
            <VoiceAndToneForm {...sectionProps} />
          </FormSection>

          <FormSection
            title="Content Guidelines"
            isOpen={openSections.contentGuidelines}
            onToggle={() => toggleSection('contentGuidelines')}
          >
            <ContentGuidelinesForm {...sectionProps} />
          </FormSection>
        </div>

        <FormActions
          onCancel={onCancel}
          submitLabel={profile ? 'Save Changes' : 'Create Profile'}
          cancelLabel="Cancel"
          isSubmitting={isSubmitting}
        />
      </form>
    </Card>
  );
};

export default BusinessProfileForm;
