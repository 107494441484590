import React from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

interface FormSectionProps {
  title: string;
  isOpen: boolean;
  onToggle: () => void;
  children: React.ReactNode;
}

const FormSection: React.FC<FormSectionProps> = ({ title, isOpen, onToggle, children }) => (
  <div className="bg-white rounded-lg shadow-sm border border-gray-100 overflow-hidden transition-all duration-200">
    <button
      type="button"
      onClick={onToggle}
      className="w-full px-6 py-4 flex items-center justify-between bg-gradient-to-r from-white to-gray-50 hover:from-gray-50 hover:to-gray-100 transition-all duration-200"
    >
      <h2 className="text-lg font-semibold text-gray-800">{title}</h2>
      <ChevronDownIcon 
        className={`h-5 w-5 text-gray-500 transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''}`}
      />
    </button>
    {isOpen && (
      <div className="px-6 py-4 bg-white">
        {children}
      </div>
    )}
  </div>
);

export default FormSection;
