import React, { useState } from 'react';
import UsersPanel from '../../components/admin/UsersPanel';

const Users = () => {
  const [error, setError] = useState<string | null>(null);

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">User Management</h1>
        <p className="mt-2 text-gray-600">Manage admin users and permissions</p>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-50 rounded-md">
          <p className="text-sm text-red-800">{error}</p>
        </div>
      )}

      <UsersPanel setError={setError} />
    </div>
  );
};

export default Users;
