import React from 'react';

type AlertType = 'success' | 'error' | 'warning' | 'info';

interface AlertBannerProps {
  type: AlertType;
  message: string;
  onDismiss?: () => void;
}

const alertStyles: Record<AlertType, string> = {
  success: 'bg-green-100 border-green-400 text-green-700',
  error: 'bg-red-100 border-red-400 text-red-700',
  warning: 'bg-yellow-100 border-yellow-400 text-yellow-700',
  info: 'bg-blue-100 border-blue-400 text-blue-700',
};

const AlertBanner: React.FC<AlertBannerProps> = ({
  type,
  message,
  onDismiss,
}) => {
  return (
    <div className={`${alertStyles[type]} px-4 py-3 rounded-lg relative border`} role="alert">
      <span className="block sm:inline">{message}</span>
      {onDismiss && (
        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
          <button
            onClick={onDismiss}
            className="text-current hover:text-gray-900 transition-colors duration-200"
          >
            <span className="sr-only">Dismiss</span>
            <svg className="h-5 w-5" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </span>
      )}
    </div>
  );
};

export default AlertBanner;
