import React from 'react';
import FormInput from '../../common/FormInput';
import { BusinessProfileFormSectionProps } from '../types/businessProfileForm';

const BusinessInfoForm: React.FC<BusinessProfileFormSectionProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  return (
    <div className="space-y-6">
      <FormInput
        label="Business Name"
        name="business_name"
        value={values.business_name}
        onChange={(e) => handleChange('business_name', e.target.value)}
        onBlur={handleBlur}
        error={errors.business_name}
        touched={touched.business_name}
        placeholder="Enter your business name"
        required
      />

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Business Type
          </label>
          <select
            name="business_type"
            value={values.business_type}
            onChange={(e) => handleChange('business_type', e.target.value)}
            onBlur={handleBlur}
            className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 hover:border-gray-300"
            required
          >
            <option value="">Select a business type</option>
            <option value="retail">Retail</option>
            <option value="service">Service</option>
            <option value="manufacturing">Manufacturing</option>
            <option value="technology">Technology</option>
            <option value="healthcare">Healthcare</option>
            <option value="hospitality">Hospitality</option>
            <option value="education">Education</option>
            <option value="custom">Other (Custom)</option>
          </select>
          {touched.business_type && errors.business_type && (
            <p className="mt-1 text-sm text-red-600">{errors.business_type}</p>
          )}
        </div>

        {values.business_type === 'custom' && (
          <FormInput
            label="Custom Business Type"
            name="custom_business_type"
            value={values.custom_business_type}
            onChange={(e) => handleChange('custom_business_type', e.target.value)}
            onBlur={handleBlur}
            error={errors.custom_business_type}
            touched={touched.custom_business_type}
            placeholder="Specify your business type"
            required
          />
        )}
      </div>

      <FormInput
        label="Business Description"
        name="description"
        value={values.description}
        onChange={(e) => handleChange('description', e.target.value)}
        onBlur={handleBlur}
        error={errors.description}
        touched={touched.description}
        placeholder="Describe your business"
        multiline
        rows={4}
      />
    </div>
  );
};

export default BusinessInfoForm;
