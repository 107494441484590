import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axiosInstance from '../utils/axiosConfig';
import WelcomeMessage from '../components/WelcomeMessage';
import imageService from '../services/imageService';
import { researchService } from '../services/research';
import contentSummaryService, { BillingPeriodTotals } from '../services/contentSummaryService';

interface ContentAssetsSummary {
  platformSpecificContent: number;
  quickContent: number;
}

interface Campaign {
  id: number;
  name: string;
  status: string;
  campaign_type: string;
  campaign_category: string;
  start_date: string;
  end_date: string;
  budget: number | null;
  contentSummary: ContentAssetsSummary;
}

const CampaignCard: React.FC<{ campaign: Campaign; index: number; isUpdating: boolean }> = ({ campaign, index, isUpdating }) => {
  const totalContent = campaign.contentSummary.platformSpecificContent + campaign.contentSummary.quickContent;

  return (
    <div className={`rounded-lg p-6 bg-white border border-gray-200 relative shadow-md hover:shadow-lg transition-all duration-200 animate-fade-in`} style={{ animationDelay: `${index * 100}ms` }}>
      {isUpdating && (
        <div className="absolute inset-0 bg-gray-200 bg-opacity-50 flex items-center justify-center rounded-lg">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      )}
      <h3 className="text-lg font-semibold text-gray-900 mb-3">{campaign.name}</h3>
      <div className="space-y-3 text-sm">
        <p>
          <span className="font-semibold">Status:</span>{' '}
          <span className={`px-2 py-1 rounded-full text-white text-xs ${
            campaign.status === 'active' ? 'bg-green-500' : 'bg-yellow-500'
          }`}>
            {campaign.status}
          </span>
        </p>
        <p>
          <span className="font-semibold">Type:</span> {campaign.campaign_type}
        </p>
        <p>
          <span className="font-semibold">Category:</span> {campaign.campaign_category || 'N/A'}
        </p>
        <p>
          <span className="font-semibold">Budget:</span> {campaign.budget ? `$${campaign.budget.toLocaleString()}` : 'N/A'}
        </p>
        <p>
          <span className="font-semibold">Start Date:</span> {new Date(campaign.start_date).toLocaleDateString()}
        </p>
        <p>
          <span className="font-semibold">End Date:</span> {new Date(campaign.end_date).toLocaleDateString()}
        </p>
        <div className="mt-4 pt-4 border-t border-gray-100">
          <h4 className="font-semibold mb-2 text-gray-800">Content Assets Summary</h4>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <p className="text-sm text-gray-600">Total Content</p>
              <p className="text-lg font-semibold text-blue-600">{totalContent}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TotalSummary: React.FC = () => {
  const [imageGenerationCount, setImageGenerationCount] = useState<number>(0);
  const [billingTotals, setBillingTotals] = useState<BillingPeriodTotals | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [refreshKey, setRefreshKey] = useState<number>(0);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      console.group('TotalSummary: Fetching data');
      const [imageCount, totals] = await Promise.all([
        imageService.getImageGenerationCount(),
        contentSummaryService.getBillingPeriodTotals()
      ]);
      
      console.log('Received image count:', imageCount);
      console.log('Received billing totals:', totals);
      
      console.log('Setting state with values:', {
        imageCount,
        totals: {
          total_campaigns: totals.total_campaigns,
          quick_content: totals.quick_content_total,
          campaign_content: totals.campaign_content_total,
          research_reports: totals.research_reports_total,
          grand_total: totals.grand_total
        }
      });
      
      setImageGenerationCount(imageCount);
      setBillingTotals(totals);
      console.groupEnd();
    } catch (error) {
      console.error('Error fetching dashboard totals:', error);
      if (error instanceof Error) {
        setError(`Failed to fetch content totals: ${error.message}`);
      } else {
        setError('Failed to fetch content totals. Please try refreshing.');
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    console.log('TotalSummary: Initial data fetch');
    fetchData();
  }, [fetchData, refreshKey]);

  if (isLoading) {
    return (
      <div className="bg-gradient-to-br from-blue-900 to-indigo-900 text-white shadow-lg rounded-lg p-6 mb-4">
        <div className="flex justify-center items-center h-40">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-200"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-gradient-to-br from-blue-900 to-indigo-900 text-white shadow-lg rounded-lg p-6 mb-4">
        <div className="text-center">
          <p className="text-red-200 mb-2">{error}</p>
          <button
            onClick={() => setRefreshKey(prev => prev + 1)}
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  console.log('TotalSummary: Rendering with values:', {
    total_campaigns: billingTotals?.total_campaigns,
    quick_content: billingTotals?.quick_content_total,
    campaign_content: billingTotals?.campaign_content_total,
    research_reports: billingTotals?.research_reports_total,
    grand_total: billingTotals?.grand_total,
    image_count: imageGenerationCount
  });

  const totalContent = (billingTotals?.quick_content_total || 0) + 
                      (billingTotals?.campaign_content_total || 0) + 
                      (billingTotals?.research_reports_total || 0);

  return (
    <div className="bg-gradient-to-br from-blue-900 to-indigo-900 text-white shadow-lg rounded-lg p-6 mb-4 animate-fade-in">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Generated Marketing Content</h2>
        <button
          onClick={() => setRefreshKey(prev => prev + 1)}
          className="text-blue-200 hover:text-white"
          title="Refresh totals"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
          </svg>
        </button>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        <div className="bg-white/10 rounded-lg p-4">
          <p className="text-sm text-blue-200">Total Campaigns</p>
          <p className="text-3xl font-bold mt-1">{billingTotals?.total_campaigns || 0}</p>
        </div>
        <div className="bg-white/10 rounded-lg p-4">
          <p className="text-sm text-blue-200">Research Reports</p>
          <p className="text-3xl font-bold mt-1">{billingTotals?.research_reports_total || 0}</p>
        </div>
        <div className="bg-white/10 rounded-lg p-4">
          <p className="text-sm text-blue-200">Image Generation</p>
          <p className="text-3xl font-bold mt-1">{imageGenerationCount}</p>
        </div>
        <div className="bg-white/10 rounded-lg p-4">
          <p className="text-sm text-blue-200">Quick Content</p>
          <p className="text-3xl font-bold mt-1">{billingTotals?.quick_content_total || 0}</p>
        </div>
        <div className="bg-white/10 rounded-lg p-4">
          <p className="text-sm text-blue-200">Campaign Content</p>
          <p className="text-3xl font-bold mt-1">{billingTotals?.campaign_content_total || 0}</p>
        </div>
        <div className="bg-white/10 rounded-lg p-4">
          <p className="text-sm text-blue-200">Total Generated Content</p>
          <p className="text-3xl font-bold mt-1">{totalContent}</p>
          <p className="text-xs text-blue-200 mt-1">
            (Quick Content + Campaign Content + Research Reports)
          </p>
        </div>
      </div>
      <div className="mt-4 text-xs text-blue-200">
        Billing period ends: {billingTotals?.billing_period_end ? new Date(billingTotals.billing_period_end).toLocaleDateString() : 'N/A'}
      </div>
    </div>
  );
};

const ProductServiceSummary: React.FC = () => {
  const { getProducts, getServices } = useAuth();
  const [products, setProducts] = useState<number>(0);
  const [services, setServices] = useState<number>(0);
  const [businessProfile, setBusinessProfile] = useState<any>(null);
  const [billingTotals, setBillingTotals] = useState<BillingPeriodTotals | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.group('ProductServiceSummary: Fetching data');
        const [productsData, servicesData, profileResponse, totalsResponse] = await Promise.all([
          getProducts(),
          getServices(),
          axiosInstance.get('/business-profiles/'),
          contentSummaryService.getBillingPeriodTotals()
        ]);
        
        console.log('Products data:', productsData);
        console.log('Services data:', servicesData);
        console.log('Profile response:', profileResponse.data);
        console.log('Billing totals response:', totalsResponse);
        console.log('Products with AI descriptions:', totalsResponse.total_products_with_descriptions);
        console.log('Descriptions this period:', totalsResponse.product_descriptions_this_period);
        console.log('Debug info:', totalsResponse.debug_info);
        
        setProducts(productsData.length);
        setServices(servicesData.length);
        if (profileResponse.data) {
          setBusinessProfile(profileResponse.data);
        }
        setBillingTotals(totalsResponse);
        console.groupEnd();
      } catch (error) {
        console.error('Error fetching business data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [getProducts, getServices]);

  if (isLoading) {
    return <div className="text-sm text-gray-500">Loading assets...</div>;
  }

  const totalAssets = products + services;
  // Only show AI descriptions count if it's greater than 0
  const hasAiDescriptions = billingTotals?.total_products_with_descriptions && billingTotals.total_products_with_descriptions > 0;
  const aiDescriptionsCount = billingTotals?.total_products_with_descriptions || 0;
  const descriptionsThisPeriod = billingTotals?.product_descriptions_this_period || 0;

  console.log('ProductServiceSummary: Rendering with values:', {
    products,
    services,
    totalAssets,
    hasAiDescriptions,
    aiDescriptionsCount,
    descriptionsThisPeriod,
    billingTotals: {
      total_products_with_descriptions: billingTotals?.total_products_with_descriptions,
      product_descriptions_this_period: billingTotals?.product_descriptions_this_period,
      debug_info: billingTotals?.debug_info
    }
  });

  return (
    <div className="bg-gradient-to-br from-indigo-900 to-blue-900 text-white shadow-lg rounded-lg p-6 mb-4 animate-fade-in">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Business Assets</h2>
        {businessProfile?.business_type && (
          <span className="text-sm bg-white/20 px-3 py-1 rounded-full">
            {businessProfile.business_type}
          </span>
        )}
      </div>
      
      {/* Products and Services */}
      <div className="grid grid-cols-2 gap-6 mb-4">
        <div className="bg-white/10 rounded-lg p-4">
          <p className="text-sm text-blue-200">Products</p>
          <p className="text-3xl font-bold mt-1">{products}</p>
          {/* Only show AI descriptions count if greater than 0 */}
          {hasAiDescriptions && (
            <p className="text-xs text-blue-200 mt-1">
              {aiDescriptionsCount} with AI descriptions
            </p>
          )}
        </div>
        <div className="bg-white/10 rounded-lg p-4">
          <p className="text-sm text-blue-200">Services</p>
          <p className="text-3xl font-bold mt-1">{services}</p>
        </div>
      </div>

      {/* Total Business Assets */}
      <div className="bg-white/10 rounded-lg p-4 mb-4">
        <p className="text-sm text-blue-200">Total Business Assets</p>
        <p className="text-3xl font-bold mt-1">{totalAssets}</p>
      </div>

      {/* AI Descriptions */}
      <div className="bg-white/10 rounded-lg p-4 mb-4">
        <div className="flex justify-between items-center">
          <p className="text-sm text-blue-200">AI Descriptions</p>
          <p className="text-xs text-blue-200">Generated this billing period</p>
        </div>
        <p className="text-3xl font-bold mt-1">{descriptionsThisPeriod}</p>
      </div>

      {businessProfile && (
        <div className="text-sm text-blue-200 space-y-1">
          {businessProfile.city && businessProfile.state && (
            <p>📍 {businessProfile.city}, {businessProfile.state}</p>
          )}
          {businessProfile.updated_at && (
            <p>🔄 Business Profile Last Updated: {new Date(businessProfile.updated_at).toLocaleDateString()}</p>
          )}
        </div>
      )}
    </div>
  );
};

const Dashboard: React.FC = () => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const [updatingCampaigns, setUpdatingCampaigns] = useState<Set<number>>(new Set());
  const [showWelcome, setShowWelcome] = useState(false);
  const { isAuthenticated, user, refreshUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.has_seen_welcome === false) {
      setShowWelcome(true);
    } else {
      setShowWelcome(false);
    }
  }, [user]);

  const fetchData = useCallback(async () => {
    if (!isAuthenticated) {
      return;
    }
    setIsLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get('/campaigns/all_content_summaries/');
      if (Array.isArray(response.data) && response.data.length > 0) {
        setCampaigns(response.data);
      } else {
        setCampaigns([]);
      }
      setLastUpdated(new Date());
    } catch (error) {
      console.error('Error fetching campaign data:', error);
      setError('Failed to fetch campaign data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData().catch(error => {
        console.error('Error during automatic refresh:', error);
        setError('Failed to refresh data automatically. Please try manual refresh.');
      });
    }, 300000);
    
    return () => clearInterval(intervalId);
  }, [fetchData]);

  const handleManualRefresh = () => {
    fetchData();
  };

  const handleCloseWelcome = async () => {
    try {
      await axiosInstance.post('/user/mark-welcome-seen/');
      await refreshUser();
      setShowWelcome(false);
      navigate('/business-profile');
    } catch (error) {
      console.error('Error handling welcome message:', error);
      setShowWelcome(false);
      navigate('/business-profile');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50">
      {showWelcome && <WelcomeMessage onClose={handleCloseWelcome} />}
      
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-8 animate-fade-in">
          <h1 className="text-4xl font-bold text-gray-900">Marketing Dashboard</h1>
          <div className="flex items-center space-x-4">
            <button
              onClick={handleManualRefresh}
              className="bg-blue-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors duration-200 hover:scale-105 transform"
              disabled={isLoading}
            >
              {isLoading ? 'Refreshing...' : 'Refresh Data'}
            </button>
            {lastUpdated && (
              <span className="text-sm text-gray-600">
                Last updated: {lastUpdated.toLocaleTimeString()}
              </span>
            )}
          </div>
        </div>

        {isLoading && (
          <div className="text-center py-8 animate-fade-in">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-600 mx-auto"></div>
            <p className="text-gray-600 mt-4">Loading campaign data...</p>
          </div>
        )}

        {error && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-lg mb-8 animate-fade-in" role="alert">
            <p className="font-bold">Error</p>
            <p>{error}</p>
          </div>
        )}

        {!isLoading && !error && (
          <div className="space-y-8 animate-fade-in">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <TotalSummary />
              <ProductServiceSummary />
            </div>

            {campaigns.length > 0 ? (
              <div className="bg-white shadow-lg rounded-lg p-8 animate-fade-in">
                <h2 className="text-2xl font-bold text-gray-900 mb-6">Your Campaigns</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {campaigns.map((campaign, index) => (
                    <CampaignCard
                      key={campaign.id}
                      campaign={campaign}
                      index={index}
                      isUpdating={updatingCampaigns.has(campaign.id)}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className="text-center py-12 bg-white shadow-lg rounded-lg animate-fade-in">
                <p className="text-gray-600 mb-4">No campaigns found. Create your first campaign to get started!</p>
                <button className="bg-blue-600 text-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-all duration-200 hover:scale-105 transform">
                  Create Campaign
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
