import { useState, useCallback, useRef } from 'react';
import { useChunkUpload } from './useChunkUpload';

interface UseFileUploadReturn {
  progress: number;
  isUploading: boolean;
  uploadFile: (file: File) => Promise<void>;
  resetProgress: () => void;
  cancelUpload: () => void;
  retryUpload: () => Promise<void>;
  error: Error | null;
  retryCount: number;
  maxRetries: number;
  uploadDetails: {
    currentChunk?: number;
    totalChunks?: number;
  };
}

const MAX_RETRIES = 3;
const RETRY_DELAY_BASE = 1000; // 1 second
const CHUNK_THRESHOLD = 5 * 1024 * 1024; // 5MB

export const useFileUpload = (
  onUpload: (file: File) => Promise<void>
): UseFileUploadReturn => {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const [uploadDetails, setUploadDetails] = useState<{
    currentChunk?: number;
    totalChunks?: number;
  }>({});
  
  const xhrRef = useRef<XMLHttpRequest | null>(null);
  const fileRef = useRef<File | null>(null);

  const {
    uploadFile: uploadChunks,
    cancelUpload: cancelChunkUpload,
    progress: chunkProgress,
    isUploading: isChunkUploading,
    error: chunkError,
  } = useChunkUpload(onUpload);

  const cancelUpload = useCallback(() => {
    if (xhrRef.current) {
      xhrRef.current.abort();
      xhrRef.current = null;
    }
    cancelChunkUpload();
    setProgress(0);
    setIsUploading(false);
    setError(null);
    setRetryCount(0);
    setUploadDetails({});
  }, [cancelChunkUpload]);

  const delay = useCallback((ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }, []);

  const performUpload = useCallback(async (file: File): Promise<void> => {
    setIsUploading(true);
    setProgress(0);
    setError(null);

    try {
      if (file.size > CHUNK_THRESHOLD) {
        await uploadChunks(file);
        setProgress(100);
        setUploadDetails({
          currentChunk: chunkProgress.currentChunk,
          totalChunks: chunkProgress.totalChunks,
        });
      } else {
        await new Promise<void>((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhrRef.current = xhr;

          xhr.upload.addEventListener('progress', (event: ProgressEvent) => {
            if (event.lengthComputable) {
              const percentComplete = (event.loaded / event.total) * 100;
              setProgress(Math.round(percentComplete));
            }
          });

          xhr.upload.addEventListener('load', () => {
            setProgress(100);
          });

          xhr.upload.addEventListener('error', () => {
            reject(new Error('Upload failed'));
          });

          xhr.upload.addEventListener('abort', () => {
            reject(new Error('Upload cancelled'));
          });

          xhr.open('POST', '/api/upload', true);
          
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                resolve();
              } else if (xhr.status === 0) {
                reject(new Error('Upload cancelled'));
              } else {
                reject(new Error(`Upload failed with status ${xhr.status}`));
              }
            }
          };

          const formData = new FormData();
          formData.append('file', file);
          xhr.send(formData);
        });

        await onUpload(file);
      }

      setRetryCount(0);
      fileRef.current = null;
    } catch (error) {
      if (error instanceof Error) {
        setError(error);
        throw error;
      }
      const genericError = new Error('Upload failed');
      setError(genericError);
      throw genericError;
    } finally {
      setIsUploading(false);
      xhrRef.current = null;
    }
  }, [onUpload, uploadChunks, chunkProgress]);

  const uploadFile = useCallback(async (file: File) => {
    fileRef.current = file;
    setRetryCount(0);
    return performUpload(file);
  }, [performUpload]);

  const retryUpload = useCallback(async () => {
    if (!fileRef.current || retryCount >= MAX_RETRIES) {
      return;
    }

    setRetryCount(prev => prev + 1);
    const delayMs = RETRY_DELAY_BASE * Math.pow(2, retryCount);
    await delay(delayMs);

    return performUpload(fileRef.current);
  }, [performUpload, retryCount, delay]);

  const resetProgress = useCallback(() => {
    cancelUpload();
    fileRef.current = null;
  }, [cancelUpload]);

  return {
    progress: isChunkUploading ? (chunkProgress.uploaded / chunkProgress.total) * 100 : progress,
    isUploading: isUploading || isChunkUploading,
    uploadFile,
    resetProgress,
    cancelUpload,
    retryUpload,
    error: error || chunkError,
    retryCount,
    maxRetries: MAX_RETRIES,
    uploadDetails: isChunkUploading ? {
      currentChunk: chunkProgress.currentChunk,
      totalChunks: chunkProgress.totalChunks,
    } : uploadDetails,
  };
};
