import React, { useState } from 'react';
import SystemPromptPanel from '../../components/admin/SystemPromptPanel';
import { Toaster } from 'react-hot-toast';

const SystemPrompt: React.FC = () => {
    const [error, setError] = useState<string | null>(null);

    return (
        <div className="container mx-auto px-4 py-8">
            <Toaster position="top-right" />
            
            {error && (
                <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <span className="block sm:inline">{error}</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setError(null)}>
                        <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <title>Close</title>
                            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
                        </svg>
                    </span>
                </div>
            )}

            <div className="bg-white rounded-lg shadow">
                <div className="p-6 border-b border-gray-200">
                    <h1 className="text-2xl font-bold text-gray-900">System Prompt Configuration</h1>
                    <p className="mt-2 text-gray-600">
                        Configure the base system prompt used for all content generation tasks.
                    </p>
                </div>
                <div className="p-6">
                    <SystemPromptPanel setError={setError} />
                </div>
            </div>
        </div>
    );
};

export default SystemPrompt;
