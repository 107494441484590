import React, { useState, useEffect } from 'react';
import { adminService } from '../../services/admin';
import { PromptHistorySettings as Settings } from '../../types/admin';

interface PromptHistorySettingsProps {
  setError: (error: string | null) => void;
}

const PromptHistorySettings: React.FC<PromptHistorySettingsProps> = ({ setError }) => {
  const [settings, setSettings] = useState<Settings>({
    enabled: true,
    retention_days: 30,
    default_page_size: 50
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await adminService.getPromptHistorySettings();
        setSettings(settings);
        setError(null);
      } catch (err) {
        setError('Failed to load prompt history settings');
        console.error('Error loading settings:', err);
      }
    };

    fetchSettings();
  }, [setError]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await adminService.updatePromptHistorySettings(settings);
      setError(null);
    } catch (err) {
      setError('Failed to update settings');
      console.error('Error updating settings:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
      <h3 className="text-lg font-semibold mb-4">Prompt History Settings</h3>
      
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <label className="text-sm font-medium text-gray-700">Enable Prompt History</label>
          <div className="relative inline-block w-12 mr-2 align-middle select-none">
            <input
              type="checkbox"
              checked={settings.enabled}
              onChange={(e) => setSettings({ ...settings, enabled: e.target.checked })}
              className="absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer transition-transform duration-200 ease-in-out translate-x-0 checked:translate-x-6"
              style={{
                borderColor: settings.enabled ? '#3B82F6' : '#D1D5DB',
                boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)'
              }}
            />
            <label
              className={`block overflow-hidden h-6 rounded-full cursor-pointer transition-colors duration-200 ease-in-out ${
                settings.enabled ? 'bg-blue-500' : 'bg-gray-300'
              }`}
            ></label>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Retention Period (days)
          </label>
          <input
            type="number"
            min="1"
            max="365"
            value={settings.retention_days}
            onChange={(e) => setSettings({ ...settings, retention_days: parseInt(e.target.value) })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Default Page Size
          </label>
          <input
            type="number"
            min="10"
            max="100"
            value={settings.default_page_size}
            onChange={(e) => setSettings({ ...settings, default_page_size: parseInt(e.target.value) })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>

        <div className="flex justify-end mt-4">
          <button
            onClick={handleSave}
            disabled={isLoading}
            className={`px-4 py-2 text-sm font-medium rounded-md text-white ${
              isLoading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
            }`}
          >
            {isLoading ? 'Saving...' : 'Save Settings'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PromptHistorySettings;
