import React, { useState, useEffect, useRef } from 'react';
import { Content, CONTENT_STATUSES, ContentStatus } from '../../types/content';
import ImageModal from '../shared/ImageModal';
import VideoGenerationButton from './VideoGenerationButton';
import { createPortal } from 'react-dom';
import '../../styles/content-history.css';

interface ContentHistoryRowProps {
  content: Content;
  isSelected: boolean;
  onSelect: () => void;
  isExpanded: boolean;
  onExpand: () => void;
  onStatusChange: (contentId: number, newStatus: ContentStatus, platform: string) => Promise<void>;
  onExport: (content: Content) => void;
  isAlternate: boolean;
  isUpdating: boolean;
}

const ContentHistoryRow: React.FC<ContentHistoryRowProps> = ({
  content,
  isSelected,
  onSelect,
  isExpanded,
  onExpand,
  onStatusChange,
  onExport,
  isAlternate,
  isUpdating
}) => {
  const [imageError, setImageError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [shouldLoadImage, setShouldLoadImage] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState<string | null>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const selectRef = useRef<HTMLSelectElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShouldLoadImage(true);
            observerRef.current?.disconnect();
          }
        });
      },
      {
        rootMargin: '50px',
        threshold: 0.1
      }
    );

    if (imageRef.current) {
      observerRef.current.observe(imageRef.current);
    }

    return () => {
      observerRef.current?.disconnect();
    };
  }, []);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const getStatusColor = (status: ContentStatus) => {
    switch (status) {
      case 'draft':
        return 'bg-yellow-100 text-yellow-800 ring-1 ring-yellow-400';
      case 'waiting_for_approval':
        return 'bg-blue-100 text-blue-800 ring-1 ring-blue-400';
      case 'approved':
        return 'bg-indigo-100 text-indigo-800 ring-1 ring-indigo-400';
      case 'published':
        return 'bg-green-100 text-green-800 ring-1 ring-green-400';
      case 'archived':
        return 'bg-gray-100 text-gray-800 ring-1 ring-gray-400';
      default:
        return 'bg-gray-100 text-gray-800 ring-1 ring-gray-400';
    }
  };

  const formatStatusLabel = (status: ContentStatus) => {
    switch (status) {
      case 'waiting_for_approval':
        return 'Waiting for Approval';
      default:
        return status.charAt(0).toUpperCase() + status.slice(1);
    }
  };

  const handleStatusChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    e.stopPropagation();
    
    const newStatus = e.target.value as ContentStatus;
    const platform = content.platform.split(',')[0].trim(); // Get first platform
    console.log('Status change requested:', { contentId: content.id, newStatus, platform });
    
    if (!CONTENT_STATUSES.includes(newStatus)) {
      console.error('Invalid status:', newStatus);
      return;
    }

    if (isUpdating) {
      console.log('Status update already in progress');
      return;
    }

    try {
      await onStatusChange(content.id, newStatus, platform);
      console.log('Status change completed');
    } catch (error) {
      console.error('Status change failed:', error);
      // Revert select value on error
      if (selectRef.current) {
        selectRef.current.value = content.content_status;
      }
    }
  };

  const getPlatformIcon = (platform: string) => {
    const normalizedPlatform = platform.toLowerCase().trim().replace(/[_\s]/g, '_');
    
    switch (normalizedPlatform) {
      case 'facebook':
      case 'facebook_post':
        return (
          <svg className="w-4 h-4 text-blue-600" fill="currentColor" viewBox="0 0 24 24">
            <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
          </svg>
        );
        
      case 'twitter':
      case 'twitter_post':
        return (
          <svg className="w-4 h-4 text-blue-400" fill="currentColor" viewBox="0 0 24 24">
            <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/>
          </svg>
        );
        
      case 'youtube':
      case 'youtube_video':
      case 'youtube_short':
      case 'youtube_shorts':
        return (
          <svg className="w-4 h-4 text-red-600" fill="currentColor" viewBox="0 0 24 24">
            <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/>
          </svg>
        );
        
      case 'tiktok':
      case 'tiktok_video':
        return (
          <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 24 24">
            <path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z"/>
          </svg>
        );

      case 'instagram':
      case 'instagram_feed':
      case 'instagram_post':
        return (
          <svg className="w-4 h-4 text-pink-600" fill="currentColor" viewBox="0 0 24 24">
            <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zM12 0C8.741 0 8.333.014 7.053.072 2.695.272.273 2.69.073 7.052.014 8.333 0 8.741 0 12c0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98C8.333 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98C15.668.014 15.259 0 12 0zm0 5.838a6.162 6.162 0 100 12.324 6.162 6.162 0 000-12.324zM12 16a4 4 0 110-8 4 4 0 010 8zm6.406-11.845a1.44 1.44 0 100 2.881 1.44 1.44 0 000-2.881z"/>
          </svg>
        );

      case 'linkedin':
      case 'linkedin_post':
        return (
          <svg className="w-4 h-4 text-blue-700" fill="currentColor" viewBox="0 0 24 24">
            <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 01-2.063-2.065 2.064 2.064 0 112.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
          </svg>
        );

      default:
        console.log('Unknown platform:', platform, 'Normalized:', normalizedPlatform);
        return null;
    }
  };

  const handleImageError = () => {
    setImageError(true);
    setIsImageLoading(false);
  };

  const handleImageLoad = () => {
    setIsImageLoading(false);
    setIsImageLoaded(true);
  };

  const handleImageClick = () => {
    if (thumbnailUrl && !imageError) {
      const modalUrl = fullSizeUrl || thumbnailUrl;
      setModalImageUrl(modalUrl);
      setIsModalOpen(true);
    }
  };

  const getFullUrl = (url: string | undefined | null): string | null => {
    if (!url) return null;
    if (url.startsWith('http') && !url.includes('oaidalleapiprodscus')) {
      // If it's a localhost URL, replace it with the media URL
      if (url.includes('localhost:8020/media')) {
        const mediaUrl = process.env.REACT_APP_MEDIA_URL || 'http://50.184.85.141/media';
        return url.replace('http://localhost:8020/media', mediaUrl);
      }
      return url;
    }
    if (url.startsWith('data:')) return url;
    
    // Use REACT_APP_MEDIA_URL for media files
    if (url.startsWith('/media/') || url.includes('/media/')) {
      const mediaUrl = process.env.REACT_APP_MEDIA_URL || 'http://50.184.85.141/media';
      const cleanPath = url.replace(/^\/media\//, '').replace(/.*\/media\//, '');
      return `${mediaUrl}/${cleanPath}`;
    }
    
    // Use API URL for other URLs
    const baseUrl = process.env.REACT_APP_API_URL || 'http://50.184.85.141';
    return `${baseUrl}${url.startsWith('/') ? '' : '/'}${url}`;
  };

  const getImageUrl = (content: Content): { thumbnail: string | null; fullSize: string | null } => {
    // Try platform-specific image first
    const platforms = content.platform.split(',').map(p => p.trim());
    const firstPlatform = platforms[0];
    
    if (firstPlatform && content.platform_images?.[firstPlatform]) {
      const platformImage = content.platform_images[firstPlatform];
      const localUrl = getFullUrl(platformImage.local_image_url);
      if (localUrl) {
        return { thumbnail: localUrl, fullSize: localUrl };
      }
    }
    
    // Try product image
    if (content.product?.images?.[0]?.image) {
      const url = getFullUrl(content.product.images[0].image);
      return { thumbnail: url, fullSize: url };
    }
    
    // Try direct content images
    if (content.local_image_url) {
      const localUrl = getFullUrl(content.local_image_url);
      return { thumbnail: localUrl, fullSize: localUrl };
    }
    
    // Try image history
    const imageHistory = content.imageHistory;
    if (imageHistory && imageHistory.length > 0) {
      const latestImage = imageHistory[imageHistory.length - 1];
      if (latestImage) {
        const localUrl = getFullUrl(latestImage.local_image_url);
        return { thumbnail: localUrl, fullSize: localUrl };
      }
    }
    
    return { thumbnail: null, fullSize: null };
  };

  const { thumbnail: thumbnailUrl, fullSize: fullSizeUrl } = React.useMemo(
    () => getImageUrl(content),
    [content]
  );

  const imageSource = React.useMemo(() => {
    const platforms = content.platform.split(',').map(p => p.trim());
    const firstPlatform = platforms[0];
    
    if (firstPlatform && content.platform_images?.[firstPlatform]) {
      return `${firstPlatform} Image`;
    }
    if (content.product?.name) {
      return `Product: ${content.product.name}`;
    }
    return 'Generated Image';
  }, [content]);

  const renderThumbnail = () => {
    return (
      <div 
        className={`w-16 h-16 bg-gray-50 rounded-lg overflow-hidden flex items-center justify-center group relative shadow-sm ${
          thumbnailUrl && !imageError ? 'cursor-pointer hover:shadow-md transition-shadow duration-200' : ''
        }`}
        onClick={handleImageClick}
        title={imageSource}
        ref={imageRef}
      >
        {thumbnailUrl && !imageError ? (
          <>
            {isImageLoading && (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-50">
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500"></div>
              </div>
            )}
            {shouldLoadImage && (
              <img
                src={thumbnailUrl}
                alt={`Thumbnail for ${content.topic || 'content'}`}
                className={`content-image w-full h-full object-cover transition-opacity duration-300 ${isImageLoaded ? 'loaded' : ''}`}
                onError={handleImageError}
                onLoad={handleImageLoad}
                loading="lazy"
              />
            )}
            <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 transition-all duration-200 flex items-center justify-center">
              <span className="text-white text-xs opacity-0 group-hover:opacity-100 transition-all duration-200 px-2 text-center">
                {imageSource}
              </span>
            </div>
          </>
        ) : (
          <svg className="w-6 h-6 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
        )}
      </div>
    );
  };

  return (
    <>
      <tr className={`content-row group transition-colors duration-150 ${
        isSelected 
          ? 'bg-blue-50' 
          : isAlternate 
            ? 'bg-gray-50 hover:bg-gray-100' 
            : 'hover:bg-gray-50'
      }`}>
        {/* Checkbox column */}
        <td className="px-2 py-4 table-cell">
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={onSelect}
              className="rounded border-gray-300 text-blue-600 focus:ring-2 focus:ring-blue-500 transition-colors"
            />
          </div>
        </td>

        {/* Export/Generate column */}
        <td className="px-2 py-4 whitespace-nowrap">
          <div className="flex items-center space-x-4">
            <button
              onClick={() => onExport(content)}
              className="flex items-center space-x-1 px-3 py-1 bg-purple-50 text-purple-700 rounded-lg hover:bg-purple-100 transition-colors duration-150 flex-shrink-0"
              title="Export content"
            >
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
              </svg>
              <span className="text-sm font-medium">Export</span>
            </button>
            
            <div className="flex-shrink-0">
              <VideoGenerationButton content={content} />
            </div>
          </div>
        </td>

        {/* Thumbnail column */}
        <td className="px-2 py-4 table-cell">
          {renderThumbnail()}
        </td>

        {/* Created date column */}
        <td className="px-2 py-4 table-cell">
          <div className="space-y-1">
            <div className="text-sm text-gray-900 font-medium">
              {formatDate(content.created_at)}
            </div>
            {content.metadata && (
              <div className="text-xs text-gray-500">
                v{content.metadata.version}
                {content.metadata.source && (
                  <span className="ml-2">{content.metadata.source}</span>
                )}
              </div>
            )}
          </div>
        </td>

        {/* Platform column */}
        <td className="px-2 py-4 table-cell">
          <div className="flex items-center space-x-2">
            {content.platform.split(',').map((platform, index) => (
              <React.Fragment key={platform}>
                {index > 0 && <span className="text-gray-300">/</span>}
                <div className="flex items-center space-x-1">
                  {getPlatformIcon(platform.trim())}
                  <span className="text-sm text-gray-900">{platform.trim()}</span>
                </div>
              </React.Fragment>
            ))}
          </div>
        </td>

        {/* Audience column */}
        <td className="px-2 py-4 table-cell">
          <span className="text-sm text-gray-900">{content.target_audience}</span>
        </td>

        {/* Content column */}
        <td className="px-2 py-4 table-cell">
          <div className={`relative ${isExpanded ? '' : 'h-[120px]'}`}>
            <div 
              ref={contentRef}
              className={`text-sm text-gray-900 whitespace-pre-wrap text-content ${
                isExpanded ? '' : 'overflow-y-auto h-[120px] custom-scrollbar'
              }`}
              style={{ paddingRight: '8px' }}
            >
              {content.content}
              {content.metadata?.platform_specific && (
                <div className="mt-2 text-xs text-gray-500">
                  {Object.entries(content.metadata.platform_specific).map(([platform, data]) => (
                    <div key={platform} className="flex items-center space-x-1">
                      {getPlatformIcon(platform)}
                      <span>{data.content_type}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {content.content.length > 100 && (
              <button
                onClick={onExpand}
                className="text-blue-600 hover:text-blue-800 text-xs mt-2 font-medium transition-colors duration-150"
              >
                {isExpanded ? 'Show Less' : 'Show More'}
              </button>
            )}
          </div>
        </td>

        {/* Status column */}
        <td className="px-2 py-4 table-cell">
          <div className="space-y-1">
            <select
              ref={selectRef}
              value={content.content_status}
              onChange={handleStatusChange}
              disabled={isUpdating}
              className={`text-[11px] rounded-full px-1.5 py-0.5 font-medium focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-blue-500 transition-colors cursor-pointer w-full ${
                isUpdating ? 'opacity-50 cursor-not-allowed' : ''
              } ${getStatusColor(content.content_status)}`}
              style={{ fontSize: '11px', lineHeight: '14px' }}
            >
              {CONTENT_STATUSES.map(status => (
                <option 
                  key={status} 
                  value={status}
                  className="bg-white text-gray-900 text-[11px] py-0.5"
                  style={{ fontSize: '11px', lineHeight: '14px' }}
                >
                  {formatStatusLabel(status)}
                </option>
              ))}
            </select>
            {isUpdating && (
              <div className="text-[10px] text-blue-600">
                Updating...
              </div>
            )}
            {content.metadata?.generated_at && (
              <div className="text-[9px] text-gray-500 truncate">
                Generated: {formatDate(content.metadata.generated_at)}
              </div>
            )}
          </div>
        </td>
      </tr>

      {/* Modal portal */}
      {isModalOpen && modalImageUrl && createPortal(
        <ImageModal
          imageUrl={modalImageUrl}
          alt={`Full size image for ${content.topic || imageSource}`}
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setModalImageUrl(null);
          }}
        />,
        document.body
      )}
    </>
  );
};

export default ContentHistoryRow;
