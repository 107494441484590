import { useState, useCallback } from 'react';
import { Campaign, Platform, ContentRequirements, ContentState, BusinessProfile } from '../types';
import { useStateTracker } from './useStateTracker';

const initialRequirements: ContentRequirements = {
  objective: '',
  tone: '',
  targetAudience: '',
  topic: '',
  selectedProducts: [], // Initialize empty array
  selectedServices: [], // Initialize empty array
  generateImages: true  // Default to true for backward compatibility
};

const initialState: ContentState = {
  campaign: null,
  platforms: [],
  requirements: initialRequirements,
  businessProfile: null
};

export const useContentForm = () => {
  const [state, setState] = useState<ContentState>(initialState);
  const { logStateChange } = useStateTracker();

  const setCampaign = useCallback((campaign: Campaign | null) => {
    setState((prev: ContentState) => {
      logStateChange('campaign', prev.campaign, campaign);
      return {
        ...prev,
        campaign
      };
    });
  }, [logStateChange]);

  const setPlatforms = useCallback((platforms: Platform[]) => {
    setState((prev: ContentState) => {
      logStateChange('platforms', prev.platforms, platforms);
      return {
        ...prev,
        platforms
      };
    });
  }, [logStateChange]);

  const setRequirements = useCallback((requirements: ContentRequirements) => {
    setState((prev: ContentState) => {
      // Ensure arrays are initialized and generateImages is preserved
      const updatedRequirements = {
        ...requirements,
        selectedProducts: requirements.selectedProducts || [],
        selectedServices: requirements.selectedServices || [],
        generateImages: requirements.generateImages !== undefined ? requirements.generateImages : true // Preserve generateImages or default to true
      };
      logStateChange('requirements', prev.requirements, updatedRequirements);
      return {
        ...prev,
        requirements: updatedRequirements
      };
    });
  }, [logStateChange]);

  const setBusinessProfile = useCallback((businessProfile: BusinessProfile | null) => {
    setState((prev: ContentState) => {
      logStateChange('businessProfile', prev.businessProfile, businessProfile);
      return {
        ...prev,
        businessProfile
      };
    });
  }, [logStateChange]);

  return {
    state,
    setCampaign,
    setPlatforms,
    setRequirements,
    setBusinessProfile
  };
};
