import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';

interface PricingTier {
  id: number;
  name: string;
  description: string;
  price: number;
  features: Record<string, any>;
  content_limit: number;
  image_generation_limit: number;
  research_limit: number;
  product_description_limit: number;  // Added product_description_limit
}

interface PricingResponse {
  pricing_tiers: PricingTier[];
  stripe_config: {
    publicKey: string;
    currency: string;
  };
  features: Record<string, {
    name: string;
    content_limit: number;
    image_generation_limit: number;
    research_limit: number;
    product_description_limit: number;  // Added product_description_limit
    description: string;
  }>;
  signup_flow: {
    steps: Array<{
      step: number;
      name: string;
      route: string;
      description: string;
    }>;
    routes: {
      landing_to_pricing: string;
      pricing_to_signup: string;
      signup_success: string;
      signup_cancel: string;
    };
  };
}

const PricingPage: React.FC = () => {
  const [pricingData, setPricingData] = useState<PricingResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPricingData = async () => {
      try {
        const response = await axiosInstance.get<PricingResponse>('/pricing-tiers/');
        console.log('Pricing data response:', {
          pricing_tiers: response.data.pricing_tiers,
          features: response.data.features
        });
        setPricingData(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching pricing data:', err);
        setError('Failed to load pricing information. Please try again later.');
        setLoading(false);
      }
    };

    fetchPricingData();
  }, []);

  const handleSelectPlan = (tier: PricingTier) => {
    navigate('/signup', {
      state: {
        selectedPlan: {
          id: tier.id,
          name: tier.name,
          description: tier.description,
          price: tier.price
        },
        stripeConfig: pricingData?.stripe_config
      }
    });
  };

  const handleAgencyContact = () => {
    window.location.href = 'mailto:agency@mediamagic.com?subject=Agency Plan Consultation';
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-900 to-indigo-900 flex items-center justify-center">
        <div className="text-center text-white animate-fade-in">
          <div className="animate-spin rounded-full h-16 w-16 border-4 border-blue-200 border-t-transparent mx-auto"></div>
          <p className="mt-6 text-xl font-medium text-blue-200">Loading pricing information...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-900 to-indigo-900 flex items-center justify-center px-4">
        <div className="text-center bg-white/95 backdrop-blur-sm rounded-2xl shadow-xl p-8 max-w-md animate-fade-in">
          <div className="text-red-500 text-5xl mb-4">⚠️</div>
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Oops! Something went wrong</h2>
          <p className="text-gray-600 mb-6">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="bg-gradient-to-r from-blue-600 to-indigo-600 text-white px-8 py-3 rounded-lg font-semibold
                     hover:from-blue-700 hover:to-indigo-700 transition-all duration-200 hover:scale-105"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-900 to-indigo-900 py-16 relative overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0 opacity-10">
        <div 
          className="absolute inset-0 bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]"
          style={{ backgroundImage: 'url(/images/grid-pattern.svg)' }}
        ></div>
      </div>

      {/* Back to Home Link */}
      <div className="absolute top-4 left-4">
        <Link
          to="/"
          className="flex items-center text-white hover:text-blue-200 transition-colors duration-200"
        >
          <svg
            className="w-5 h-5 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
          <span>Back to Home</span>
        </Link>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Header */}
        <div className="text-center mb-16 animate-fade-in">
          <h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold text-white mb-6">
            Choose Your Perfect Plan
          </h1>
          <p className="text-xl text-blue-200 max-w-2xl mx-auto">
            Select a plan that best fits your needs and start creating amazing content today
          </p>
        </div>

        {/* Pricing Tiers */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          {/* Standard Plans */}
          {pricingData?.pricing_tiers.map((tier: PricingTier, index: number) => {
            const isBasic = tier.name.toLowerCase() === 'basic';
            const isPro = tier.name.toLowerCase() === 'pro';
            const isEnterprise = tier.name.toLowerCase() === 'enterprise';
            
            console.log(`Rendering tier ${tier.name}:`, {
              content_limit: tier.content_limit,
              image_generation_limit: tier.image_generation_limit,
              research_limit: tier.research_limit,
              product_description_limit: tier.product_description_limit
            });
            
            return (
              <div
                key={tier.id}
                className="bg-white/95 backdrop-blur-sm rounded-2xl shadow-xl overflow-hidden transform hover:scale-105 transition-all duration-300 animate-fade-in"
                style={{ animationDelay: `${index * 100}ms` }}
              >
                <div className="p-8">
                  <div className="h-full flex flex-col">
                    <h2 className="text-2xl font-bold text-gray-900 mb-2">{tier.name}</h2>
                    <p className="text-gray-600 mb-6 flex-grow">{tier.description}</p>
                    <div className="mb-8">
                      <span className="text-4xl font-extrabold text-blue-600">${tier.price}</span>
                      <span className="text-gray-500 ml-2">/month</span>
                    </div>
                    <button
                      onClick={() => handleSelectPlan(tier)}
                      className="w-full bg-gradient-to-r from-blue-600 to-indigo-600 text-white rounded-lg py-3 px-6
                               font-semibold hover:from-blue-700 hover:to-indigo-700 transition-all duration-200"
                    >
                      Get Started
                    </button>
                  </div>
                </div>
                <div className="bg-gray-50 p-8 border-t border-gray-100">
                  <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wide mb-4">
                    What's included
                  </h3>
                  <ul className="space-y-4">
                    <li className="flex items-center text-gray-600">
                      <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      Unlimited Campaigns
                    </li>
                    <li className="flex items-center text-gray-600">
                      <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      {tier.content_limit === -1 ? 'Unlimited' : tier.content_limit} Content Pieces Per Month
                    </li>
                    <li className="flex items-center text-gray-600">
                      <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      {tier.image_generation_limit === -1 ? 'Unlimited' : tier.image_generation_limit} Image Generations Per Month
                    </li>
                    <li className="flex items-center text-gray-600">
                      <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      {tier.research_limit === -1 ? 'Unlimited' : tier.research_limit} AI Research Reports Per Month
                    </li>
                    <li className="flex items-center text-gray-600">
                      <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      {tier.product_description_limit === -1 ? 'Unlimited' : tier.product_description_limit} Product Descriptions Per Month
                    </li>
                    <li className="flex items-center text-gray-600">
                      <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      AI Topic Generation {isBasic ? '(Limited)' : '(Unlimited)'}
                    </li>
                    <li className="flex items-center text-gray-600">
                      <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      AI Content Generation {isBasic ? '(Basic)' : isPro ? '(Advanced)' : '(Premium)'}
                    </li>
                    <li className="flex items-center text-gray-600">
                      <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      Product/Service Integration {isBasic ? '(Basic)' : '(Advanced)'}
                    </li>
                    <li className="flex items-center text-gray-600">
                      <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      Quick-Content & Campaign Management
                    </li>
                    <li className="flex items-center text-gray-600">
                      <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      Content History & Image Storage {isBasic ? '(30 days)' : isPro ? '(1 year)' : '(Unlimited)'}
                    </li>
                    <li className="flex items-center text-gray-600">
                      <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      Export Generated Content & Images {isBasic ? '(Basic)' : '(Advanced)'}
                    </li>
                    <li className="flex items-center text-gray-600">
                      <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      Human-in-the-loop Options {isBasic ? '(Basic)' : isPro ? '(Advanced)' : '(Premium)'}
                    </li>
                    {(isPro || isEnterprise) && (
                      <li className="flex items-center text-gray-600">
                        <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        Scheduling of Social Posts
                      </li>
                    )}
                    {isEnterprise && (
                      <li className="flex items-center text-gray-600">
                        <svg className="h-5 w-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        API Integration with Legacy Apps
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            );
          })}

          {/* Agency Plan */}
          <div className="bg-gradient-to-br from-blue-900 to-indigo-900 text-white rounded-2xl shadow-xl overflow-hidden transform hover:scale-105 transition-all duration-300 animate-fade-in border-2 border-white/30">
            <div className="p-8">
              <div className="h-full flex flex-col">
                <div className="bg-blue-500/20 px-3 py-1 rounded-full text-sm inline-flex items-center w-fit mb-4">
                  <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                  Premium
                </div>
                <h2 className="text-2xl font-bold mb-2">Agency</h2>
                <p className="mb-6 flex-grow text-blue-100">Take your agency to the next level with white-label solutions and advanced integrations</p>
                <div className="mb-8">
                  <div className="text-xl font-medium">Custom Pricing</div>
                  <div className="text-blue-200">Based on your needs</div>
                </div>
                <button
                  onClick={handleAgencyContact}
                  className="w-full bg-white text-blue-900 rounded-lg py-3 px-6 font-semibold hover:bg-blue-50 transition-all duration-200"
                >
                  Contact for Consultation
                </button>
              </div>
            </div>
            <div className="bg-blue-800/30 p-8 border-t border-white/10">
              <h3 className="text-sm font-semibold uppercase tracking-wide mb-4">
                Agency Features
              </h3>
              <ul className="space-y-4">
                <li className="flex items-center text-blue-100">
                  <svg className="h-5 w-5 text-blue-300 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  White-label Solutions
                </li>
                <li className="flex items-center text-blue-100">
                  <svg className="h-5 w-5 text-blue-300 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  Unlimited Client Businesses
                </li>
                <li className="flex items-center text-blue-100">
                  <svg className="h-5 w-5 text-blue-300 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  API Integration Support
                </li>
                <li className="flex items-center text-blue-100">
                  <svg className="h-5 w-5 text-blue-300 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  Custom Client Pricing
                </li>
                <li className="flex items-center text-blue-100">
                  <svg className="h-5 w-5 text-blue-300 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  Dedicated Support
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* FAQ Section */}
        <div className="text-center animate-fade-in">
          <h2 className="text-2xl font-bold text-white mb-4">
            Have questions?
          </h2>
          <p className="text-blue-200 mb-6">
            Our team is here to help you choose the perfect plan for your needs
          </p>
          <a
            href="mailto:support@example.com"
            className="inline-flex items-center bg-white/10 hover:bg-white/20 text-white px-6 py-3 rounded-lg
                     transition-all duration-200 hover:scale-105"
          >
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            Contact Support
          </a>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
