import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSubscription } from '../../contexts/SubscriptionContext';
import { useAuth } from '../../contexts/AuthContext';
import { subscriptionService, PaymentMethodInfo } from '../../services/subscription';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import BillingHistory from './BillingHistory';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

const PaymentMethodDisplay: React.FC<{ refreshTrigger?: number }> = ({ refreshTrigger }) => {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodInfo | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPaymentMethod = async () => {
      try {
        const data = await subscriptionService.getCurrentPaymentMethod();
        setPaymentMethod(data);
      } catch (err: any) {
        console.error('Error fetching payment method:', err);
        // Don't show error for 404, just show "No payment method found"
        if (err.response?.status === 404) {
          setPaymentMethod(null);
        } else {
          setError('Unable to load payment information');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchPaymentMethod();
  }, [refreshTrigger]);

  if (isLoading) {
    return <div className="text-sm text-gray-500">Loading payment information...</div>;
  }

  if (error) {
    return <div className="text-sm text-red-500">{error}</div>;
  }

  if (!paymentMethod) {
    return <div className="text-sm text-gray-500">No payment method found</div>;
  }

  const getCardBrandIcon = (brand: string) => {
    switch (brand.toLowerCase()) {
      case 'visa':
        return '💳';
      case 'mastercard':
        return '💳';
      default:
        return '💳';
    }
  };

  return (
    <div>
      <div className="flex items-center space-x-2">
        <span className="text-lg">{getCardBrandIcon(paymentMethod.brand)}</span>
        <span className="font-medium text-gray-900">
          {paymentMethod.brand.charAt(0).toUpperCase() + paymentMethod.brand.slice(1)} ending in {paymentMethod.last4}
        </span>
      </div>
      <div className="text-sm text-gray-500">
        <div>Cardholder: {paymentMethod.cardholder_name || 'Not provided'}</div>
        <div>Expires: {paymentMethod.exp_month.toString().padStart(2, '0')}/{paymentMethod.exp_year}</div>
      </div>
    </div>
  );
};

interface PaymentMethodFormProps {
  onClose: () => void;
  onSuccess: () => void;
  isRetryPeriod?: boolean;
}

const PaymentMethodForm: React.FC<PaymentMethodFormProps> = ({ onClose, onSuccess, isRetryPeriod = false }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { updatePaymentMethod } = useSubscription();
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [cardholderName, setCardholderName] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) {
      console.error('Stripe.js has not loaded yet');
      setError('Payment system is not ready. Please try again.');
      return;
    }

    if (!cardholderName.trim()) {
      setError('Cardholder name is required');
      return;
    }

    setIsProcessing(true);
    setError(null);

    try {
      console.log('Creating payment method...');
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        console.error('Card element not found');
        setError('Payment form is not properly loaded');
        return;
      }

      const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: cardholderName.trim(),
        },
      });

      if (stripeError) {
        console.error('Stripe error creating payment method:', stripeError);
        setError(stripeError.message || 'An error occurred while processing your card');
        return;
      }

      if (paymentMethod) {
        console.log('Payment method created:', paymentMethod.id);
        try {
          // Use different update method based on whether we're in retry period
          if (isRetryPeriod) {
            await subscriptionService.updatePaymentMethodForRetry(paymentMethod.id, cardholderName.trim());
          } else {
            await updatePaymentMethod(paymentMethod.id, cardholderName.trim());
          }
          console.log('Payment method successfully updated');
          onClose();
          onSuccess();
        } catch (err: any) {
          console.error('Error updating payment method:', err);
          if (err.response?.status === 404) {
            setError('The payment update service is currently unavailable. Please try again later.');
          } else if (err.response?.data?.error) {
            setError(err.response.data.error);
          } else {
            setError('Failed to update payment method. Please try again.');
          }
        }
      }
    } catch (err: any) {
      console.error('Error in payment method update:', err);
      setError(err.message || 'Failed to process payment method. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="space-y-4">
        <div>
          <label htmlFor="cardholderName" className="block text-sm font-medium text-gray-700">
            Cardholder Name
          </label>
          <input
            type="text"
            id="cardholderName"
            name="cardholderName"
            value={cardholderName}
            onChange={(e) => setCardholderName(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Name on card"
            required
          />
        </div>
        <div className="p-3 border rounded-md bg-white">
          <CardElement options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }} />
        </div>
      </div>
      {error && (
        <div className="text-sm text-red-600 bg-red-50 p-2 rounded">
          {error}
        </div>
      )}
      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={!stripe || isProcessing}
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 disabled:opacity-50"
        >
          {isProcessing ? 'Updating...' : 'Update Payment Method'}
        </button>
      </div>
    </form>
  );
};

interface AccountSectionProps {
  isOpen: boolean;
  onToggle: () => void;
}

const AccountSection: React.FC<AccountSectionProps> = ({ isOpen, onToggle }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [activeForm, setActiveForm] = useState<'changePlan' | 'updatePayment' | 'resetPassword' | 'cancelConfirm' | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<number | null>(null);
  const [isChangingPlan, setIsChangingPlan] = useState(false);
  const [isRequestingReset, setIsRequestingReset] = useState(false);
  const [resetRequestSuccess, setResetRequestSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const {
    hasActiveSubscription,
    currentPlan,
    currentPeriodEnd,
    cancelAtPeriodEnd,
    subscriptionStatus,
    inRetryPeriod,
    nextPaymentAttempt,
    daysUntilRetry,
    daysUntilRenewal,
    paymentRetryCount,
    paymentFailureReason,
    cancelSubscription,
    reinstateSubscription
  } = useSubscription();

  const plans = [
    { id: 1, name: 'Basic', stripePriceId: 'price_1QDSl8HmLRWWrRb63jGkOR0n' },
    { id: 2, name: 'Pro', stripePriceId: 'price_1QDSlXHmLRWWrRb6DU6yV41H' },
    { id: 3, name: 'Enterprise', stripePriceId: 'price_1QDSluHmLRWWrRb6wmMJ7ecc' }
  ];

  const formatDate = (date: string | null) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

const getStatusDisplay = () => {
    if (inRetryPeriod) {
      return {
        label: 'Payment Processing',
        color: 'yellow',
        message: `Your subscription is active while we process your payment. Next attempt in ${daysUntilRetry} days`,
        subMessage: paymentFailureReason || 'Payment processing failed'
      };
    }

    // Handle null or undefined status
    if (!subscriptionStatus) {
      return {
        label: 'No Subscription',
        color: 'gray',
        message: 'No active subscription found'
      };
    }

    switch (subscriptionStatus) {
      case 'active':
        return {
          label: 'Active',
          color: 'green',
          message: typeof daysUntilRenewal === 'number' 
            ? `Renews in ${daysUntilRenewal} days`
            : 'Active subscription'
        };
      case 'past_due':
        return {
          label: 'Payment Processing',
          color: 'yellow',
          message: 'Your subscription remains active while we process your payment',
          subMessage: paymentFailureReason
        };
      case 'cancelled':
        return {
          label: 'Canceled',
          color: 'red',
          message: cancelAtPeriodEnd ? `Active until ${formatDate(currentPeriodEnd)}` : 'Subscription ended'
        };
      case 'expired':
        return {
          label: 'Expired',
          color: 'red',
          message: 'Subscription has ended'
        };
      case 'incomplete':
        return {
          label: 'Payment Required',
          color: 'yellow',
          message: 'Please update your payment method to activate your subscription'
        };
      default:
        return {
          label: (subscriptionStatus as string).charAt(0).toUpperCase() + (subscriptionStatus as string).slice(1),
          color: 'gray',
          message: ''
        };
    }
  };

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription();
      setActiveForm(null);
    } catch (err: any) {
      console.error('Error canceling subscription:', err);
      setError(err.response?.data?.error || 'Failed to cancel subscription. Please try again.');
    }
  };

  const handleReinstateSubscription = async () => {
    try {
      await reinstateSubscription();
    } catch (err: any) {
      console.error('Error reinstating subscription:', err);
      setError(err.response?.data?.error || 'Failed to reinstate subscription. Please try again.');
    }
  };

  const handlePaymentMethodUpdate = () => {
    setRefreshTrigger(prev => prev + 1);
    setActiveForm(null);
  };

  const handlePlanChange = async () => {
    if (!selectedPlan) {
      setError('Please select a plan');
      return;
    }

    const plan = plans.find(p => p.id === selectedPlan);
    if (!plan) {
      setError('Invalid plan selected');
      return;
    }

    setIsChangingPlan(true);
    setError(null);

    try {
      await subscriptionService.changePlan(plan.id.toString(), plan.stripePriceId);
      setActiveForm(null);
      // Refresh the page to show updated subscription status
      window.location.reload();
    } catch (err: any) {
      console.error('Error changing plan:', err);
      setError(err.response?.data?.error || 'Failed to change plan. Please try again.');
    } finally {
      setIsChangingPlan(false);
    }
  };

  const handlePasswordReset = async () => {
    try {
      setIsRequestingReset(true);
      setError(null);

      const csrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];

      if (!csrfToken) {
        console.error('CSRF token not found in cookies');
        throw new Error('CSRF token not found');
      }

      if (!user?.email) {
        console.error('User email not found in auth context');
        throw new Error('User email not found');
      }

      const response = await fetch('/api/password/request-reset/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        credentials: 'include',
        body: JSON.stringify({
          email: user.email
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to request password reset');
      }

      setResetRequestSuccess(true);
    } catch (err: any) {
      console.error('Error requesting password reset:', err);
      setError(err.message || 'Failed to request password reset. Please try again.');
    } finally {
      setIsRequestingReset(false);
    }
  };

  const handleButtonClick = (formName: 'changePlan' | 'updatePayment' | 'resetPassword' | 'cancelConfirm') => {
    if (activeForm === formName) {
      setActiveForm(null);
    } else {
      setActiveForm(formName);
      setError(null);
      setSelectedPlan(null);
      setResetRequestSuccess(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow">
      <button
        onClick={onToggle}
        className="w-full p-4 flex items-center justify-between text-left focus:outline-none"
      >
        <h3 className="text-lg font-medium text-gray-900">Account & Billing</h3>
        <svg
          className={`h-6 w-6 transform transition-transform ${isOpen ? 'rotate-180' : ''}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {isOpen && (
        <div className="p-4">
          <div className="mt-4 space-y-6">
            {/* Account Information */}
            <div className="grid grid-cols-3 gap-4">
              <div>
                <h4 className="text-sm font-medium text-gray-500">Current Plan</h4>
                <p className="mt-1 text-lg font-medium text-gray-900">{currentPlan || 'Basic'}</p>
              </div>

      <div>
        <h4 className="text-sm font-medium text-gray-500">Status</h4>
        <div className="mt-1 space-y-2">
          {/* Status Badge */}
          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-${getStatusDisplay().color}-100 text-${getStatusDisplay().color}-800`}>
            {getStatusDisplay().label}
          </span>
          
          {/* Status Message */}
          {getStatusDisplay().message && (
            <p className="text-sm text-gray-600">
              {getStatusDisplay().message}
            </p>
          )}

          {/* Sub Message (e.g., failure reason) */}
          {getStatusDisplay().subMessage && (
            <p className="text-sm text-yellow-700">
              {getStatusDisplay().subMessage}
            </p>
          )}

          {/* Retry Period Information */}
          {inRetryPeriod && (
            <div className="text-sm text-yellow-700 bg-yellow-50 p-2 rounded">
              <p>Payment retry attempt {paymentRetryCount}</p>
              <p>Next attempt: {formatDate(nextPaymentAttempt)}</p>
            </div>
          )}
        </div>
      </div>

              <div>
                <h4 className="text-sm font-medium text-gray-500">Payment Method</h4>
                <div className="mt-1">
                  <PaymentMethodDisplay refreshTrigger={refreshTrigger} />
                  {/* Payment Update Notice */}
                  {inRetryPeriod && (
                    <div className="mt-2">
                      <button
                        onClick={() => handleButtonClick('updatePayment')}
                        className="text-sm text-indigo-600 hover:text-indigo-500"
                      >
                        Update payment method to retry now
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex flex-wrap gap-3">
              <button
                onClick={() => handleButtonClick('changePlan')}
                className={`py-2 px-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors ${
                  activeForm === 'changePlan' 
                    ? 'bg-indigo-700 text-white'
                    : 'bg-indigo-600 text-white hover:bg-indigo-700'
                }`}
              >
                Change Plan
              </button>

              <button
                onClick={() => handleButtonClick('updatePayment')}
                className={`py-2 px-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors ${
                  activeForm === 'updatePayment'
                    ? 'bg-gray-100 text-gray-900 border-2 border-indigo-500'
                    : 'border border-gray-300 text-gray-700 hover:bg-gray-50'
                }`}
              >
                Update Payment
              </button>

              <button
                onClick={() => handleButtonClick('resetPassword')}
                className={`py-2 px-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors ${
                  activeForm === 'resetPassword'
                    ? 'bg-gray-100 text-gray-900 border-2 border-indigo-500'
                    : 'border border-gray-300 text-gray-700 hover:bg-gray-50'
                }`}
              >
                Reset Password
              </button>

              {hasActiveSubscription && !cancelAtPeriodEnd && (
                <button
                  onClick={() => handleButtonClick('cancelConfirm')}
                  className={`py-2 px-4 font-medium focus:outline-none transition-colors ${
                    activeForm === 'cancelConfirm'
                      ? 'text-red-800'
                      : 'text-red-600 hover:text-red-700'
                  }`}
                >
                  Cancel
                </button>
              )}

              {cancelAtPeriodEnd && (
                <button
                  onClick={handleReinstateSubscription}
                  className="py-2 px-4 font-medium text-green-600 hover:text-green-700 focus:outline-none transition-colors"
                >
                  Reinstate Subscription
                </button>
              )}
            </div>

  {/* Payment Retry Notice */}
  {inRetryPeriod && (
    <div className="mt-4 p-4 bg-yellow-50 border-l-4 border-yellow-400 text-yellow-700">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
          </svg>
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium">Payment Retry in Progress</h3>
          <div className="mt-2 text-sm">
            <p className="mb-2">
              {paymentFailureReason || 'We were unable to process your last payment.'}
            </p>
            <p>
              Your subscription is still active while we retry the payment.
              You can update your payment method to resolve this immediately.
            </p>
            <p className="mt-2 font-medium">
              Next payment attempt: {formatDate(nextPaymentAttempt)}
            </p>
          </div>
        </div>
      </div>
    </div>
  )}

            {/* Forms */}
            {activeForm && (
              <div className="mt-4">
                {activeForm === 'changePlan' && (
                  <div className="bg-gray-50 p-6 rounded-lg">
                    <h3 className="text-lg font-medium text-gray-900 mb-4">Change Plan</h3>
                    <div className="grid grid-cols-3 gap-4">
                      {plans.map((plan) => (
                        <label key={plan.id} className="flex items-center space-x-3 p-4 border rounded-lg hover:bg-gray-50">
                          <input
                            type="radio"
                            name="plan"
                            value={plan.id}
                            checked={selectedPlan === plan.id}
                            onChange={(e) => setSelectedPlan(Number(e.target.value))}
                            className="h-4 w-4 text-indigo-600"
                          />
                          <span className="text-gray-900">{plan.name}</span>
                        </label>
                      ))}
                    </div>
                    <div className="mt-4 flex justify-end space-x-3">
                      <button
                        onClick={() => setActiveForm(null)}
                        className="px-4 py-2 text-gray-700 hover:text-gray-900"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handlePlanChange}
                        disabled={isChangingPlan || !selectedPlan}
                        className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
                      >
                        {isChangingPlan ? 'Changing Plan...' : 'Confirm Change'}
                      </button>
                    </div>
                  </div>
                )}

                {activeForm === 'updatePayment' && (
                  <div className="bg-gray-50 p-6 rounded-lg">
                    <h3 className="text-lg font-medium text-gray-900 mb-4">Update Payment Method</h3>
                    <Elements stripe={stripePromise}>
                      <PaymentMethodForm
                        onClose={() => setActiveForm(null)}
                        onSuccess={handlePaymentMethodUpdate}
                        isRetryPeriod={inRetryPeriod}
                      />
                    </Elements>
                  </div>
                )}

                {activeForm === 'cancelConfirm' && (
                  <div className="bg-red-50 p-6 rounded-lg">
                    <h3 className="text-lg font-medium text-red-900 mb-4">Cancel Subscription</h3>
                    <p className="text-sm text-red-700 mb-4">
                      Are you sure you want to cancel your subscription? Your subscription will remain active until the end of the current period.
                    </p>
                    <div className="flex justify-end space-x-3">
                      <button
                        onClick={() => setActiveForm(null)}
                        className="px-4 py-2 text-gray-700 hover:text-gray-900"
                      >
                        Keep Subscription
                      </button>
                      <button
                        onClick={handleCancelSubscription}
                        className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                      >
                        Confirm Cancel
                      </button>
                    </div>
                  </div>
                )}

                {activeForm === 'resetPassword' && (
                  <div className="bg-gray-50 p-6 rounded-lg">
                    <h3 className="text-lg font-medium text-gray-900 mb-4">Reset Password</h3>
                    {!resetRequestSuccess ? (
                      <>
                        <p className="text-sm text-gray-600 mb-4">
                          Click the button below to receive a password reset link via email. The link will be valid for 24 hours.
                        </p>
                        <div className="flex justify-end space-x-3">
                          <button
                            onClick={() => setActiveForm(null)}
                            className="px-4 py-2 text-gray-700 hover:text-gray-900"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={handlePasswordReset}
                            disabled={isRequestingReset}
                            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
                          >
                            {isRequestingReset ? 'Sending...' : 'Send Reset Link'}
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="text-sm text-green-600 bg-green-50 p-4 rounded-md mb-4">
                          Password reset instructions have been sent to your email. Please check your inbox and follow the link to reset your password.
                        </div>
                        <div className="flex justify-end">
                          <button
                            onClick={() => {
                              setActiveForm(null);
                              setResetRequestSuccess(false);
                            }}
                            className="px-4 py-2 text-gray-700 hover:text-gray-900"
                          >
                            Close
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            )}

            {error && (
              <div className="mt-4 p-4 bg-red-50 border-l-4 border-red-500 text-red-700">
                {error}
              </div>
            )}

            {/* Billing History */}
            <div className="mt-6 border-t border-gray-200 pt-6">
              <BillingHistory />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountSection;
