import React, { useState, useEffect } from 'react';
import { adminService } from '../../services/admin';
import { TestResult, AIConfig } from '../../types/admin';

interface TestingPanelProps {
  setError: (error: string | null) => void;
}

const TestingPanel: React.FC<TestingPanelProps> = ({ setError }) => {
  const [prompt, setPrompt] = useState('');
  const [result, setResult] = useState<TestResult | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [config, setConfig] = useState<AIConfig | null>(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const data = await adminService.getAIConfig();
        setConfig(data);
      } catch (err) {
        console.error('Error loading AI config:', err);
      }
    };
    fetchConfig();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!prompt.trim()) {
      setError('Please enter a test prompt');
      return;
    }

    setIsLoading(true);
    try {
      const testResult = await adminService.runTest(prompt);
      setResult(testResult);
      setError(null);
    } catch (err) {
      setError('Failed to run test');
      console.error('Error running test:', err);
      setResult(null);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDuration = (duration: number) => {
    return `${duration.toFixed(2)}s`;
  };

  const formatCost = (cost: number | string | undefined) => {
    if (cost === undefined || cost === null) return '$0.0000';
    // Convert string to number if needed
    const numericCost = typeof cost === 'string' ? parseFloat(cost) : cost;
    return `$${numericCost.toFixed(4)}`;
  };

  const calculateEfficiency = (tokens: number, responseLength: number) => {
    if (responseLength === 0) return '0.00';
    return (tokens / responseLength).toFixed(2);
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">AI Testing</h2>
        
        {config && (
          <div className="mb-4 p-3 bg-blue-50 rounded-md">
            <h3 className="text-sm font-medium text-blue-800 mb-2">Current Configuration:</h3>
            <div className="grid grid-cols-3 gap-4 text-sm text-blue-600">
              <div>Model: {config.model}</div>
              <div>Temperature: {config.temperature}</div>
              <div>API Type: {config.api_type}</div>
            </div>
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Test Prompt
            </label>
            <textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              rows={4}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter a test prompt..."
              required
              disabled={isLoading}
            />
          </div>

          <button
            type="submit"
            disabled={isLoading || !prompt.trim()}
            className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
              ${prompt.trim() ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-400'} 
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
              ${isLoading || !prompt.trim() ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isLoading ? (
              <div className="flex items-center justify-center">
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2"></div>
                Running Test...
              </div>
            ) : (
              'Run Test'
            )}
          </button>
        </form>

        {result && (
          <div className="mt-8 space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Chat API Results */}
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-lg font-medium text-gray-900 mb-2">Chat API</h3>
                <div className="space-y-2">
                  <div className="text-sm text-gray-500">
                    Duration: {formatDuration(result.chat_api.metadata.duration)}
                  </div>
                  <div className="text-sm text-gray-500">
                    Tokens: {result.chat_api.metadata.tokens}
                  </div>
                  <div className="text-sm text-gray-500">
                    Cost: {formatCost(result.chat_api.metadata.cost)}
                  </div>
                  {result.chat_api.metadata.model && (
                    <div className="text-sm text-gray-500">
                      Model: {result.chat_api.metadata.model}
                    </div>
                  )}
                  {result.chat_api.metadata.temperature !== undefined && (
                    <div className="text-sm text-gray-500">
                      Temperature: {result.chat_api.metadata.temperature}
                    </div>
                  )}
                  <div className="mt-4">
                    <div className="text-sm font-medium text-gray-700 mb-1">Response:</div>
                    <div className="text-sm text-gray-600 whitespace-pre-wrap bg-white p-3 rounded border border-gray-200 max-h-60 overflow-y-auto">
                      {result.chat_api.response}
                    </div>
                  </div>
                </div>
              </div>

              {/* Assistant API Results */}
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-lg font-medium text-gray-900 mb-2">Assistant API</h3>
                <div className="space-y-2">
                  <div className="text-sm text-gray-500">
                    Duration: {formatDuration(result.assistant_api.metadata.duration)}
                  </div>
                  <div className="text-sm text-gray-500">
                    Tokens: {result.assistant_api.metadata.tokens}
                  </div>
                  <div className="text-sm text-gray-500">
                    Cost: {formatCost(result.assistant_api.metadata.cost)}
                  </div>
                  {result.assistant_api.metadata.model && (
                    <div className="text-sm text-gray-500">
                      Model: {result.assistant_api.metadata.model}
                    </div>
                  )}
                  {result.assistant_api.metadata.temperature !== undefined && (
                    <div className="text-sm text-gray-500">
                      Temperature: {result.assistant_api.metadata.temperature}
                    </div>
                  )}
                  <div className="mt-4">
                    <div className="text-sm font-medium text-gray-700 mb-1">Response:</div>
                    <div className="text-sm text-gray-600 whitespace-pre-wrap bg-white p-3 rounded border border-gray-200 max-h-60 overflow-y-auto">
                      {result.assistant_api.response}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Comparison */}
            <div className="bg-blue-50 rounded-lg p-4">
              <h3 className="text-lg font-medium text-blue-900 mb-2">Comparison</h3>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div>
                  <div className="font-medium text-blue-800">Chat API</div>
                  <div className="text-blue-600">
                    Speed: {formatDuration(result.chat_api.metadata.duration)}
                  </div>
                  <div className="text-blue-600">
                    Efficiency: {calculateEfficiency(
                      result.chat_api.metadata.tokens,
                      result.chat_api.response?.length || 0
                    )} tokens/char
                  </div>
                </div>
                <div>
                  <div className="font-medium text-blue-800">Assistant API</div>
                  <div className="text-blue-600">
                    Speed: {formatDuration(result.assistant_api.metadata.duration)}
                  </div>
                  <div className="text-blue-600">
                    Efficiency: {calculateEfficiency(
                      result.assistant_api.metadata.tokens,
                      result.assistant_api.response?.length || 0
                    )} tokens/char
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TestingPanel;
