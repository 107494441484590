import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';

interface PaymentMethodDetails {
    type: string;
    brand: string;
    last4: string;
}

interface BillingRecord {
    id: string;
    amount: number;
    currency: string;
    status: string;
    date: number;
    invoice_pdf: string;
    description: string;
    payment_method_details: PaymentMethodDetails;
}

const BillingHistory: React.FC = () => {
    const [billingHistory, setBillingHistory] = useState<BillingRecord[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchBillingHistory = async () => {
            try {
                const response = await axios.get('/api/payment-methods/billing-history/');
                // Sort by date in descending order (newest first)
                const sortedHistory = response.data.sort((a: BillingRecord, b: BillingRecord) => b.date - a.date);
                setBillingHistory(sortedHistory);
            } catch (err: any) {
                console.error('Error fetching billing history:', err);
                setError(err.response?.data?.error || 'Failed to load billing history');
            } finally {
                setIsLoading(false);
            }
        };

        fetchBillingHistory();
    }, []);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center py-4">
                <div className="animate-spin rounded-full h-8 w-8 border-2 border-indigo-500 border-t-transparent"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-red-600 bg-red-50 p-4 rounded-md">
                {error}
            </div>
        );
    }

    if (!billingHistory.length) {
        return (
            <div className="text-gray-500 text-center py-4">
                No billing history available
            </div>
        );
    }

    const formatCurrency = (amount: number, currency: string) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency.toUpperCase(),
        }).format(amount);
    };

    const getCardIcon = (brand: string) => {
        switch (brand.toLowerCase()) {
            case 'visa':
                return '💳';
            case 'mastercard':
                return '💳';
            default:
                return '💳';
        }
    };

    return (
        <div className="mt-6">
            <h4 className="text-lg font-medium text-gray-900 mb-4">Billing History</h4>
            <div className="max-h-[400px] overflow-y-auto">
                <div className="inline-block min-w-full align-middle">
                    <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50 sticky top-0">
                                <tr>
                                    <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-48 bg-gray-50">
                                        Date & Time
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                                        Description
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32 bg-gray-50">
                                        Amount
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-48 bg-gray-50">
                                        Payment Method
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-24 bg-gray-50">
                                        Status
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-24 bg-gray-50">
                                        Receipt
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {billingHistory.map((record) => (
                                    <tr key={record.id} className="hover:bg-gray-50">
                                        <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                                            {format(record.date * 1000, 'MMM d, yyyy h:mm a')}
                                        </td>
                                        <td className="px-4 py-3 text-sm text-gray-900">
                                            {record.description || 'Subscription Payment'}
                                        </td>
                                        <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                                            {formatCurrency(record.amount, record.currency)}
                                        </td>
                                        <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                            <div className="flex items-center space-x-1">
                                                <span>{getCardIcon(record.payment_method_details.brand)}</span>
                                                <span>
                                                    {record.payment_method_details.brand} •••• {record.payment_method_details.last4}
                                                </span>
                                            </div>
                                        </td>
                                        <td className="px-4 py-3 whitespace-nowrap">
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                                record.status === 'paid' 
                                                    ? 'bg-green-100 text-green-800' 
                                                    : 'bg-gray-100 text-gray-800'
                                            }`}>
                                                {record.status}
                                            </span>
                                        </td>
                                        <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                            {record.invoice_pdf && (
                                                <a
                                                    href={record.invoice_pdf}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-indigo-600 hover:text-indigo-900"
                                                >
                                                    Download
                                                </a>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BillingHistory;
