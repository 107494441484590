import React from 'react';

interface LoadingSpinnerProps {
  size?: 'small' | 'medium' | 'large';
  color?: string;
  fullScreen?: boolean;
  label?: string;
}

const sizeClasses = {
  small: 'h-6 w-6 border-2',
  medium: 'h-12 w-12 border-3',
  large: 'h-32 w-32 border-4',
};

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = 'medium',
  color = 'border-gray-900',
  fullScreen = false,
  label = 'Loading...',
}) => {
  const spinnerClasses = `animate-spin rounded-full border-b-transparent ${sizeClasses[size]} ${color}`;
  const wrapperClasses = fullScreen ? 'fixed inset-0 bg-white bg-opacity-75 z-50' : '';
  const contentClasses = fullScreen ? 'flex flex-col items-center justify-center min-h-screen' : 'flex flex-col items-center';

  return (
    <div className={wrapperClasses}>
      <div className={contentClasses}>
        <div className={spinnerClasses} role="status" aria-label={label} />
        {label && (
          <span className="mt-2 text-gray-700">{label}</span>
        )}
      </div>
    </div>
  );
};

export default LoadingSpinner;
