import { useState, useCallback, DragEvent } from 'react';

interface UseDragAndDropReturn {
  isDragging: boolean;
  handleDragEnter: (e: DragEvent<HTMLDivElement>) => void;
  handleDragLeave: (e: DragEvent<HTMLDivElement>) => void;
  handleDragOver: (e: DragEvent<HTMLDivElement>) => void;
  handleDrop: (e: DragEvent<HTMLDivElement>) => void;
}

export const useDragAndDrop = (
  onFilesDrop: (files: FileList) => void,
  acceptedFileTypes: string[] = []
): UseDragAndDropReturn => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const { files } = e.dataTransfer;
    if (!files || files.length === 0) return;

    if (acceptedFileTypes.length > 0) {
      const isValidFileType = Array.from(files).every(file => 
        acceptedFileTypes.some(type => 
          file.type === type || file.name.endsWith(type.replace('*', ''))
        )
      );

      if (!isValidFileType) return;
    }

    onFilesDrop(files);
  }, [acceptedFileTypes, onFilesDrop]);

  return {
    isDragging,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
  };
};
