import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axiosInstance from '../utils/axiosConfig';

interface WelcomeMessageProps {
  onClose: () => void;
}

const WelcomeMessage: React.FC<WelcomeMessageProps> = ({ onClose }) => {
  const { user, refreshUser } = useAuth();
  const navigate = useNavigate();

  const handleClose = async () => {
    try {
      // Update user's profile to mark welcome message as seen
      await axiosInstance.post('/user/mark-welcome-seen/');
      // Refresh user data to get updated has_seen_welcome status
      await refreshUser();
      onClose();
      // Navigate to business profile page
      navigate('/business-profile');
    } catch (error) {
      console.error('Error marking welcome message as seen:', error);
      // Still close and navigate even if marking as seen fails
      onClose();
      navigate('/business-profile');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-2xl mx-4 relative">
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        
        <h2 className="text-2xl font-bold text-blue-600 mb-4">
          Welcome to Media Magic! 🎉
        </h2>
        
        <div className="space-y-6 text-gray-600">
          <p>
            Thank you for joining Media Magic! We're excited to help you create amazing content
            and grow your business.
          </p>
          
          <div className="bg-blue-50 p-6 rounded-lg border-l-4 border-blue-600">
            <h3 className="font-semibold text-blue-800 text-lg mb-2">Important First Step:</h3>
            <p className="mb-4 text-blue-900">
              Before creating campaigns or content, please set up your business profile first. 
              This information serves as the foundation for all your content creation:
            </p>
            <ul className="list-disc list-inside space-y-2 ml-4 text-blue-800">
              <li>Add your business details and contact information</li>
              <li>List your products and services</li>
              <li>Provide any unique selling points</li>
            </ul>
          </div>
          
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-semibold text-gray-800 mb-2">After Profile Setup:</h3>
            <ul className="list-disc list-inside space-y-2">
              <li>Create your first campaign</li>
              <li>Generate engaging content based on your profile</li>
              <li>Track your campaign performance</li>
            </ul>
          </div>
          
          <p className="text-sm bg-yellow-50 p-4 rounded-lg">
            <span className="font-semibold">💡 Pro Tip:</span> The more detailed your business profile,
            the better we can tailor content to your specific needs and target audience.
          </p>
          
          <p>
            Need help getting started? Our support team is here to help you every step
            of the way.
          </p>
        </div>
        
        <div className="mt-6 flex justify-end">
          <button
            onClick={handleClose}
            className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
          >
            Set Up My Profile
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeMessage;
