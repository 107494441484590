import { useState, useCallback } from 'react';

export type NotificationType = 'success' | 'error' | 'info';

interface NotificationState {
  message: string | null;
  type: NotificationType;
}

export const useNotification = (timeout: number = 5000) => {
  const [notification, setNotification] = useState<NotificationState>({
    message: null,
    type: 'info'
  });

  const showNotification = useCallback((message: string, type: NotificationType) => {
    setNotification({ message, type });
    
    // Automatically clear notification after timeout
    setTimeout(() => {
      setNotification({ message: null, type: 'info' });
    }, timeout);
  }, [timeout]);

  const clearNotification = useCallback(() => {
    setNotification({ message: null, type: 'info' });
  }, []);

  return {
    notification,
    showNotification,
    clearNotification
  };
};
