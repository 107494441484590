import { useState } from 'react';
import { businessService } from '../services/business';
import { ProductDescriptionResponse } from '../types/productDescriptions';
import { Product } from '../types/products';

export const useProductDescriptions = () => {
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [previewDescription, setPreviewDescription] = useState<string | null>(null);

  const generateDescription = async (productId: number, temperature?: number): Promise<void> => {
    // If productId is 0, it's a signal to just clear the preview
    if (productId === 0) {
      setPreviewDescription(null);
      setError(null);
      return;
    }

    setIsGenerating(true);
    setError(null);
    try {
      console.log('Generating description for product:', productId, 'temperature:', temperature);
      const response = await businessService.generateProductDescription(productId, temperature);
      console.log('Description generated:', response);
      
      if (response && response.new_description) {
        setPreviewDescription(response.new_description);
      } else {
        throw new Error('No description generated');
      }
    } catch (err: any) {
      console.error('Error generating description:', err);
      setError(err.response?.data?.error || 'Failed to generate description');
      setPreviewDescription(null);
    } finally {
      setIsGenerating(false);
    }
  };

  const saveDescription = async (productId: number, description: string): Promise<Product | null> => {
    setError(null);
    try {
      console.log('Saving description for product:', productId);
      const response = await businessService.saveProductDescription(productId, description);
      console.log('Description saved:', response);

      if (!response || !response.product) {
        throw new Error('Invalid response from server');
      }

      // Clear preview after saving
      setPreviewDescription(null);
      
      return response.product;
    } catch (err: any) {
      console.error('Error saving description:', err);
      setError(err.response?.data?.error || 'Failed to save description');
      return null;
    }
  };

  const discardDescription = () => {
    setPreviewDescription(null);
    setError(null);
  };

  return {
    isGenerating,
    error,
    previewDescription,
    generateDescription,
    saveDescription,
    discardDescription
  };
};
