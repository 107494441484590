import React, { useState, useEffect } from 'react';
import { adminService } from '../../services/admin';
import { AdminUser } from '../../types/admin';
import { useAuth } from '../../contexts/AuthContext';

interface UsersPanelProps {
  setError: (error: string | null) => void;
}

const UsersPanel: React.FC<UsersPanelProps> = ({ setError }) => {
  const [users, setUsers] = useState<AdminUser[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState<number | null>(null);
  const { user: currentUser } = useAuth();

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const data = await adminService.getAdminUsers();
        setUsers(data || []);
        setError(null);
      } catch (err) {
        setError('Failed to load admin users');
        console.error('Error loading admin users:', err);
        setUsers([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [setError]);

  const handleToggleSuperuser = async (userId: number) => {
    try {
      setIsUpdating(userId);
      await adminService.toggleSuperuser(userId);
      // Refresh the users list
      const updatedUsers = await adminService.getAdminUsers();
      setUsers(updatedUsers || []);
      setError(null);
    } catch (err) {
      setError('Failed to update user permissions');
      console.error('Error updating user permissions:', err);
    } finally {
      setIsUpdating(null);
    }
  };

  const formatDate = (dateString: string | null) => {
    if (!dateString) return 'Never';
    return new Date(dateString).toLocaleString();
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-900">Admin Users</h2>
        </div>

        {users.length === 0 ? (
          <div className="p-6 text-center text-gray-500">
            No admin users found
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Username
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Role
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Last Login
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {users.map((adminUser) => (
                  <tr key={adminUser.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {adminUser.username}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {adminUser.email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {adminUser.is_superuser ? 'Superuser' : 'Admin'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(adminUser.last_login)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {currentUser?.is_superuser && adminUser.id !== currentUser.id && (
                        <button
                          onClick={() => handleToggleSuperuser(adminUser.id)}
                          disabled={isUpdating === adminUser.id}
                          className={`text-blue-600 hover:text-blue-900 font-medium ${
                            isUpdating === adminUser.id ? 'opacity-50 cursor-not-allowed' : ''
                          }`}
                        >
                          {isUpdating === adminUser.id ? (
                            <span className="flex items-center">
                              <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-500 mr-2"></div>
                              Updating...
                            </span>
                          ) : (
                            adminUser.is_superuser ? 'Remove Superuser' : 'Make Superuser'
                          )}
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default UsersPanel;
