import React from 'react';

interface FileIconProps {
  fileType: string;
  className?: string;
}

const FileIcon: React.FC<FileIconProps> = ({ fileType, className = '' }) => {
  const getIconPath = () => {
    switch (fileType.toLowerCase()) {
      case 'csv':
        return (
          <path
            d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8l-6-6zm4 18H6V4h7v5h5v11zm-8.666-5.81C8.835 13.537 8 13.175 7 13.175c-1.326 0-2.083.781-2.083 1.802 0 .898.592 1.46 1.674 1.776l.764.229c.618.181.889.411.889.796 0 .411-.41.714-1.023.714-.613 0-1.116-.259-1.544-.766l-.87.804c.58.729 1.373 1.112 2.414 1.112 1.399 0 2.253-.771 2.253-1.888 0-.919-.587-1.463-1.634-1.766l-.764-.224c-.662-.196-.934-.42-.934-.811 0-.401.381-.69.934-.69.561 0 .998.224 1.361.71l.897-.773zm3.448 3.452h1.309v-3.452h2.357v-1.089h-2.357V9.995h-1.309v3.006h-2.357v1.089h2.357v3.452zm5.583 0h1.309v-3.452h2.357v-1.089h-2.357V9.995h-1.309v3.006h-2.357v1.089h2.357v3.452z"
            fill="currentColor"
          />
        );
      default:
        return (
          <path
            d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8l-6-6zm4 18H6V4h7v5h5v11z"
            fill="currentColor"
          />
        );
    }
  };

  return (
    <svg
      className={`w-6 h-6 ${className}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {getIconPath()}
    </svg>
  );
};

export default FileIcon;
